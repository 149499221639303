export const RouteConstants = {
    ONBOARDING: 'onboarding',
    PAYMENT_PROFILE: 'payment_profile',
    ADMIN: 'admin',
    PROFILE: 'profile',
    COMPLIANCE_FORM_DETAILS: 'compliance_form_details',
    ONBOARDING_STATUS: 'onboarding_status',
    ONBOARDING_SUCCESS: 'onboarding_success',
    ONBOARDING_UPLOAD_DOCUMENTS: 'onboarding_upload_documents',
    ONBOARDING_EDIT_OWNER_INFO: 'onboarding_edit_owner_info',
    ONBOARDING_EDIT_BUSINESS_INFORMATION: 'onboarding_edit_business_information',
    ONBOARDING_EDIT_BANK_ACCOUNT: 'onboarding_edit_bank_account',
    ONBOARDING_EDIT_BUSINESS_STRUCTURE: 'onboarding_edit_business_structure',
    ERROR: 'error',
    DUPLICATE: 'duplicate',
    DASHBOARD: 'dashboard',
    TRANSACTIONS: 'transactions',
    AUTHORIZATIONS : 'authorizations',
    DEPOSITS: 'deposits',
    DISPUTES: 'disputes',
    DISPUTE_DETAILS: 'dispute_details',
    TRANSACTION_DETAILS: 'transaction_details',
    AUTHORIZATION_DETAILS : 'authorization_details',
    DEPOSIT_DETAILS: 'deposit_details',
    DISPUTE_RESPONSE: 'dispute_response',
    LOGIN: 'login',
    LOGOUT: 'logout',
    DEFAULT: 'default',
    EXPORTS: 'exports',
    REPORTS: 'reports',
    NOTIFICATIONS: 'notifications',
    ADD_FUNDS: 'add_funds'
}