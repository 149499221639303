<template>
  <div class="bo-container">
    <div class="header">
      {{ $t('businessOwnership.title') }}
    </div>
    <div class="subtitle">
      {{ $t('businessOwnership.subtitle') }}
    </div>
    <div class="separator"></div>

    <div class="control-person beneficiary-section">
      <div class="sub-header">
        {{ $t('businessOwnership.controlPersonDetails.title') }}
        <span class="hint">{{ $t('global.hint.mandatory') }}</span>
      </div>
      <div class="subtitle">
        {{ $t('businessOwnership.controlPersonDetails.subtitle') }}
      </div>
      <BeneficiariesList
        :edit-action="editBeneficiary"
        :show-control-person="true"
        :delete-action="deleteBeneficiary"
      />
      <c-button @click="openBeneficiaryPopup" v-if="!isControlPersonValid">
        <div class="slot">
          <c-icon name="plus-outline" :size="16" />
          <span class="button-label">{{
            $t('businessOwnership.controlPersonDetails.addControlPerson')
          }}</span>
        </div>
      </c-button>
      <c-inline-error
        v-if="Boolean(ownershipError)"
        :message="ownershipError"
      />
    </div>

    <div class="additional-beneficiaries beneficiary-section">
      <div class="sub-header">
        {{ $t('businessOwnership.beneficiaryDetails.title') }}
        <span class="hint">{{ $t('global.hint.ifAny') }}</span>
      </div>
      <div class="subtitle">
        {{ $t('businessOwnership.beneficiaryDetails.subtitle') }}
      </div>
      <BeneficiariesList
        :edit-action="editBeneficiary"
        :show-additional-owners="true"
        :delete-action="deleteBeneficiary"
        :read-only="readOnly"
      />
      <c-button @click="createAndOpenBeneficiaryPopup" :disabled="(!canAddMoreBeneficiaries) || readOnly || !isControlPersonValid">
        <div class="slot">
          <c-icon name="plus-outline" :size="16" />
          <span class="button-label">{{
            $t('businessOwnership.beneficiaryDetails.addBeneficiary')
          }}</span>
        </div>
      </c-button>
      <div class="subtitle" v-if="!canAddMoreBeneficiaries">
        {{ $t('addBeneficiary.exceededMaxNumberOfBeneficiaries') }}
      </div>
    </div>

    <ModalPopup :show="openModel">
      <div slot="body">
        <AddBeneficiary
          :edit-mode="editMode"
          :i="activeBeneficiaryIndex"
          :is-control-person="activeBeneficiaryIndex === 0"
          :on-dismiss="onDismissFromAddBeneficiary"
          :close="() => {openModel = false}"
          :read-only="readOnly"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ModalPopup from "@/components/common/ModalPopup";
import AddBeneficiary from "@/components/onboarding/AddBeneficiary";
import BeneficiariesList from "@/components/onboarding/BeneficiariesList";

export default {
  name: 'BusinessOwnership',
  components: {BeneficiariesList, AddBeneficiary, ModalPopup},
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
      activeBeneficiaryIndex: 0,
      openModel: false,
      editMode: false
    }
  },
  computed: {
    ...mapGetters('stakeholdersDetails', [
        'getControlPerson',
        'isControlPersonValid',
        'getBeneficiaries',
        'getAdditionalBeneficiaries',
        'canAddMoreBeneficiaries',
        'getOwnershipErrors',
        'getErrors'
    ]),
    ...mapState({
      ownershipError: state => state.stakeholdersDetails.errors.ownership,
    }),
  },
  methods: {
    ...mapActions('stakeholdersDetails', [
      'createStackholder',
      'deleteStackholder',
      'resetControlPerson'
    ]),
    openBeneficiaryPopup: function () {
        this.activeBeneficiaryIndex = 0;
        this.openModel = true;
        this.editMode = false;
    },
    createAndOpenBeneficiaryPopup: function () {
      this.createStackholder().then((stackholderIndex) => {
        this.activeBeneficiaryIndex = stackholderIndex;
        this.openModel = true;
        this.editMode = false;
      })
    },
    editBeneficiary: function (index) {
      this.activeBeneficiaryIndex = index;
      this.editMode = true;
      this.openModel = true;
    },
    onDismissFromAddBeneficiary: function() {
      if(this.editMode) {
        return;
      }
      if (this.activeBeneficiaryIndex !== 0) {
        this.deleteStackholder(this.activeBeneficiaryIndex)
      } else {
        this.resetControlPerson();
      }
    },
    deleteBeneficiary: function (index) {
      this.deleteStackholder(index);
    },
  }
}
</script>

<style lang="scss" scoped>
.bo-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $primary_text;
    text-align: left;
    margin-bottom: 8px;
  }

  & .subtitle {
    font-size: 12px;
    color: $neutral_600;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }


  & .additional-beneficiaries {
    margin-top: 40px;
  }

  & .beneficiary-section {
    width: 100%;

    & .c-inline-error {
      margin-top: 10px;
    }

    & .sub-header {
      margin-top: 20px;
      margin-bottom: 8px;
      font-weight: $weight_bolder;
      font-size: 16px;

      & .hint {
        color: $subtitle-text;
        font-weight: $weight-normal;
      }
    }

    & .subtitle {
      color: $subtitle_text;
      font-size: 12px;
      margin-bottom: 30px;
      margin-top: 20px;
    }

    & svg {
      color: $icon_color;
    }

    & .c-button:disabled {
      color: darkgray;
      background: none;

      & svg {
        color: darkgray
      }
    }
    & .c-button--secondary {
      background: none;

      &:hover {
        background: none;
      }
    }

    & .slot {
      display: flex;
      align-items: center;
      font-weight: $weight_medium;

      & .button-label {
        margin-left: 10px;
      }
    }
  }
}
</style>

<style src="../../../node_modules/v-calendar/lib/v-calendar.min.css"/>