/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import {validateAndGenerateError, validateAndSetValid} from "@/store/validationFromStore.js";
import {copyFromApiResponse, copyFromErrorResponse} from "@/store/storeMappers";
import {formatPhoneNumber, unFormatPhoneNumber} from "@/app/utils/common/functions/functions";
import {OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";

export const businessProfileDefaultState: OnboardingSectionData = {
    api: "createMerchant",
    errors: {},
    data: {
        companyName: {
            apiPath: "business_info.name",
            value: null,
            valid: false
        },
        doingBusinessAs: {
            apiPath: "business_info.doing_business_as",
            value: null,
            valid: false
        },
        description: {
            apiPath: "business_info.description",
            value: null,
            valid: false
        },
        phone: {
            apiPath: "business_info.phone",
            value: null,
            formattedValue: undefined,
            valid: false,
        },
        website: {
            apiPath: "business_info.url",
            value: "https://",
            valid: false
        },
    }
};

const getters = {
    isValid: (state) => {
        return Object.keys(state.data).every(key => state.data[key].valid);
    },
    getErrors: (state) => { return state.errors }
}


const actions = {
    resetErrors({commit}) {
        commit('resetErrors');
    },
    setErrors({commit}, {key, errors}) {
        commit('setErrors', {key, errors});
    },
    validate({commit, state, rootGetters}) {
        commit('resetErrors')
        return validateAndGenerateError(commit, state.api, rootGetters["businessAddress/getCountry"], state.data)
    },
    validateField({commit, state, rootGetters}, key) {
        validateAndSetValid(commit, businessProfileDefaultState.api, state.data, rootGetters["businessAddress/getCountry"], key)
    },
    validateAllFields({state, dispatch}) {
        Object.keys(state.data).forEach(key => dispatch('validateField', key))
    },
    updateCompanyName({commit, dispatch}, event) {
        commit('updateCompanyName', event.target.value);
        dispatch('validateField', 'companyName')
    },
    updateDoingBusinessAs({commit, dispatch}, event) {
        commit('updateDoingBusinessAs', event.target.value);
        dispatch('validateField', 'doingBusinessAs')
    },
    updateDescription({commit, dispatch}, event) {
        commit('updateDescription', event.target.value);
        dispatch('validateField', 'description')
    },
    updateWebsite({commit, dispatch}, event) {
        commit('updateWebsite', event.target.value);
        dispatch('validateField', 'website')
    },
    updatePhone({commit, dispatch}, {countryCode, value}) {
        commit('updatePhone', {countryCode, value});
        dispatch('validateField', 'phone')
    },
    copyFromFieldErrorsResponse({commit}, subErrors) {
        commit('copyFromFieldErrorsResponse', subErrors)
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('copyFromMerchantApiResponse', merchantApiResponse)
    }
};

const mutations = {
    resetErrors(state): void {
        state.errors = {}
    },
    setErrors(state, {key, errors}): void {
        state.errors[key] = errors;
    },
    setInvalid(state, key) {
        state.data[key].valid = false;
    },
    setValid(state, key) {
        state.data[key].valid = true;
    },
    updateCompanyName(state, companyName: string): void {
        state.data.companyName.value = companyName;
        delete state.errors.companyName;
    },
    updateDoingBusinessAs(state, doingBusinessAs: string): void {
        state.data.doingBusinessAs.value = doingBusinessAs;
        delete state.errors.doingBusinessAs;
    },
    updateDescription(state, description: string): void {
        state.data.description.value = description;
        delete state.errors.description;
    },
    updateWebsite(state, website: string): void {
        state.data.website.value = website;
        delete state.errors.website;
    },
    updatePhone(state, {countryCode, value}): void {
        state.data.phone.value = `${countryCode} ${unFormatPhoneNumber(value)}`;
        state.data.phone.formattedValue = value;
        delete state.errors.phone;
    },
    copyFromFieldErrorsResponse: function (state, subErrors): void {
        state.errors = copyFromErrorResponse(subErrors, state.data)
    },
    copyFromMerchantApiResponse(state, merchantApiResponse): void {
        state.data = copyFromApiResponse(merchantApiResponse, state.data);
        if (state.data.phone.value?.includes(" ")) {
            const phoneNumber = state.data.phone.value?.split(" ")[1];
            state.data.phone.formattedValue = formatPhoneNumber(phoneNumber)
        } else {
            state.data.phone.formattedValue = formatPhoneNumber(state.data.phone.value)
        }
    }
};

export const businessProfile = {
    namespaced: true,
    state:businessProfileDefaultState,
    getters,
    actions,
    mutations
};