<template>
  <svg width="20" height="17" viewBox="0 0 20 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.833313 16.4993L9.99998 0.666016L19.1666 16.4993H0.833313ZM3.70831 14.8327H16.2916L9.99998 3.99935L3.70831 14.8327ZM9.99998 13.9993C10.2361 13.9993 10.4341 13.9193 10.5941 13.7593C10.7536 13.5999 10.8333 13.4021 10.8333 13.166C10.8333 12.9299 10.7536 12.7321 10.5941 12.5727C10.4341 12.4127 10.2361 12.3327 9.99998 12.3327C9.76387 12.3327 9.56609 12.4127 9.40665 12.5727C9.24665 12.7321 9.16665 12.9299 9.16665 13.166C9.16665 13.4021 9.24665 13.5999 9.40665 13.7593C9.56609 13.9193 9.76387 13.9993 9.99998 13.9993ZM9.16665 11.4993H10.8333V7.33268H9.16665V11.4993Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'DisputeIcon',
}
</script>