<template>
  <div class="authorization-overview">
    <div class="title">
      Overview
    </div>
    <div class="fields" v-if="authorization">
      <div class="column">
        <FieldValue
          :field-value="formatDateWithTimezone(authorization.created_at.toString())
            + ' ' +$t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()})"
          :field-name="$t('fieldNames.authorizationDate')"
        />
      </div>
      <div class="column">
        <FieldValue
          :field-value="getAmount()"
          :field-name="$t('fieldNames.amount')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";
import {AuthorizationsResponseDto} from "@/api/paymentApi";
import {PendoPrefixes} from "@/app/utils/common/constants";
import {getUtcTimezoneDifference} from "@/app/utils/common/functions/dateUtils";

export default {
  name: "AuthorizationOverview",
  components: {FieldValue},
  props: {
    authorization: {
      type: AuthorizationsResponseDto,
      required: true,
    },
  },
  methods: {
    getUtcTimezoneDifference,
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    getAmount() {
      return Formatter.format({
        type: 'currency',
        data: this.authorization.amount,
        currencyCode: this.authorization.currency,
      }) + " " + this.authorization.currency
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    },
  },
}
</script>

<style lang="scss">
.authorization-overview {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }
  }
}
</style>