<template>
  <div class="dispute-physical-goods-details-form">
    <div class="dispute-details-title">
      Details
    </div>
    <div class="dispute-details-content">
      <div class="row">
        <InputField
          label="Shipping Tracking No."
          :error="error.shipping_tracking_number"
          :value="disputeResponse.physical_goods_details.shipping_tracking_number"
          :set-value="(event) => updateDisputeDetail('shipping_tracking_number', event.target.value)"
          id="shipping-tracking-number"
          :show-mandatory="true"
        />

        <InputField
          label="Delivery Partner"
          :error="error.delivery_partner"
          :value="disputeResponse.physical_goods_details.delivery_partner"
          :set-value="(event) => updateDisputeDetail('delivery_partner', event.target.value)"
          id="delivery-partner"
          :show-mandatory="true"
        />
      </div>

      <InputTextAreaField
        label="Purchase Details"
        :value="disputeResponse.physical_goods_details.purchase_details"
        id="purchase-details"
        data-testid="purchase-details"
        :error="error.purchase_details"
        hint="Specify the goods or services that were purchased. Include links, where relevant."
        :set-value="(value) =>updateDisputeDetail('purchase_details', value)"
        :show-mandatory="true"
      />
      <InputTextAreaField
        label="Refund Policy Details"
        :value="disputeResponse.physical_goods_details.refund_policy_details"
        id="refund-policy-details"
        :error="error.refund_policy_details"
        data-testid="refund-policy-details"
        hint="Specify how the refund policy does not apply to this purchase. Include links, where relevant."
        :set-value="(value) => updateDisputeDetail('refund_policy_details', value)"
        :show-mandatory="true"
      />
    </div>
  </div>
</template>

<script>
import InputTextAreaField from "@/components/common/InputTextAreaField";
import InputField from "@/components/common/InputField";

export default {
  name: "DisputeDetailsPhysicalGoodsInput",
  components: {InputField, InputTextAreaField},
  props: {
    disputeResponse: {
      type: Object,
      required: true
    },
    updateDisputeDetail: {
      type: Function,
      required: true
    },
    error: {
      type: Object,
      required: true
    }
  },
}
</script>
<style lang="scss">
.dispute-physical-goods-details-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;

  & .dispute-details-title {
    font-size: 16px;
    font-weight: $weight_bold;
  }

  & .row {
    display: flex;
    gap: 20px;
    & .field {
      flex: 1;
    }
  }

}
</style>