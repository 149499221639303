<template>
  <div class="search-container">
    <c-input
      v-model="searchQuery"
      type="text"
      :placeholder="placeholder"
      @update="$emit('search', searchQuery)"
      :id="getElementId('search')"
    >
      <span slot="prepend" class="c-select__icon">
        <img class="search-icon" src="@/app/assets/images/search.svg" alt="search" />
      </span>
      <span slot="append" class="c-select__icon">
        <div
          v-if="searchQuery"
          @click="clearSearch"
          :id="getElementId('search-x')"
        >
          <img class="search-clear-icon" src="@/app/assets/images/search_clear.svg" alt="search-clear" />
        </div>
      </span>
    </c-input>
  </div>
</template>

<script>
import {PendoPrefixes} from "@/app/utils/common/constants";

export default {
  name: "Search",
  props: {
    placeholder: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      searchQuery: "",
    }
  },
  methods: {
    clearSearch: function() {
      this.searchQuery = "";
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + "-" + id;
    }
  }
}
</script>

<style lang="scss">
.search-icon {
  display: block;
}
.search-clear-icon {
  cursor: pointer;
  display: block;
}
.search-container {
  & .c-input__group {
    border-color: $neutral_200
  }
}
</style>