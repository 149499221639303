export function getFileSizeFromFile(file) {
    const sizeInBytes = file.size
    return convertFileSizeToNearestUnit(sizeInBytes)
}

export function convertFileSizeToNearestUnit(sizeInBytes) {
    let fileSize;
    let fileNotation;
    if (sizeInBytes < 1000) {
        fileSize = sizeInBytes;
        fileNotation = "Bytes";
    } else if (sizeInBytes >= 1000 && sizeInBytes < 1000000) {
        fileSize = sizeInBytes / 1000;
        fileNotation = "KB"
    } else if (sizeInBytes >= 1000000 && sizeInBytes < 1000000000) {
        fileSize = sizeInBytes / 1000000;
        fileNotation = "MB"
    }
    fileSize = Number((fileSize).toFixed(1));

    return {fileSize, fileNotation}
}

export function validateFileSize(fileContentType, sizeInBytes) {
    const sizeInKb = sizeInBytes / 1000;
    const sizeInMb = sizeInBytes / 1000000;
    const {fileSize, fileNotation} = convertFileSizeToNearestUnit(sizeInBytes)
    if (fileContentType == "application/pdf") {
        if (!(sizeInKb >= 1 && sizeInMb <= 2)) {
            return "PDF size should be between 1KB and 2MB. But found " + fileSize + fileNotation
        }
        return null;
    }

    if (!(sizeInKb >= 100 && sizeInMb <= 10)) {
        return "File size should be between 100KB and 10MB. But found " + fileSize + fileNotation
    }
}

export function validateFile(fileName, fileContentType) {
    if (!["image/jpg", "image/jpeg", "image/tiff", "application/pdf"].some(s => fileContentType == s)) {
        return "Unsupported format. The file formats allowed include JPG, PDF, or TIFF.";
    }
    if (fileName.length > 50) {
        return "File name cannot be more than 50 characters";
    }
    if (!(/^[a-zA-Z0-9.\-_ ]+$/.test(fileName))) {
        return "File name can only contain alphanumeric, dot, hyphen and underscore characters";
    }
    return null;
}