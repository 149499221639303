import { assertBankAccountFromStore } from "@/store/modules/onboarding/conditionalApiFields/conditionHelpers";
import { CountryBasedApiField, OnboardingSectionData } from "@/store/modules/onboarding/onboardingTypes";
import { accountNumber, bankCode, sortCode } from "@/app/validators/countrySpecificValidationSpec";

export default {
  apiFields: {
    name: {
      apiPath: "payment_instrument.bank_account.name",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.accountHolderName.label",
      },
      instruction: {
        default: "bankAccountForPayouts.accountHolderName.instruction"
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "BACS_DEBIT", "SEPA_DEBIT", "ACH_DEBIT"),
    },
    accountNumber: {
      apiPath: "payment_instrument.bank_account.account_number",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.accountHolderName.label",
      },
      instruction: {
        default: "bankAccountForPayouts.accountNumber.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "ACH_DEBIT", "BACS_DEBIT"),
      validationSpec: accountNumber
    },
    iban: {
      apiPath: "payment_instrument.bank_account.iban",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.ibanNumber.label",
      },
      instruction: {
        default: "bankAccountForPayouts.ibanNumber.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "SEPA_DEBIT"),
      // validationSpec: iban will add validations after onboarding some merchants
    },
    accountType: {
      apiPath: "payment_instrument.bank_account.account_type",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.accountType.label",
      },
      instruction: {
        default: "bankAccountForPayouts.accountType.instruction"
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "ACH_DEBIT"),
    },
    bankCode: {
      apiPath: "payment_instrument.bank_account.bank_code",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.bankCode.label",
      },
      instruction: {
        default: "bankAccountForPayouts.bankCode.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "ACH_DEBIT"),
      validationSpec: bankCode
    },
    sortCode: {
      apiPath: "payment_instrument.bank_account.sort_code",
      value: null,
      formattedValue: undefined,
      valid: false,
      label: {
        default: "bankAccountForPayouts.sortCode.label",
      },
      instruction: {
        default: "bankAccountForPayouts.sortCode.instruction",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "BACS_DEBIT"),
      validationSpec: sortCode
    },
    reenterAccountNumber: {
      apiPath: "payment_instrument.bank_account.account_number",
      value: null,
      valid: false,
      label: {
        default: "bankAccountForPayouts.reenterAccountNumber.accountNumber.label",
        ["DEU"]: "bankAccountForPayouts.reenterAccountNumber.ibanNumber.label",
        ["FRA"]: "bankAccountForPayouts.reenterAccountNumber.ibanNumber.label",
      },
      instruction: {
        default: "bankAccountForPayouts.reenterAccountNumber.accountNumber.label",
        ["DEU"]: "bankAccountForPayouts.reenterAccountNumber.ibanNumber.label",
        ["FRA"]: "bankAccountForPayouts.reenterAccountNumber.ibanNumber.label",
      },
      shouldShowInView: (rootGetters) =>
        assertBankAccountFromStore(rootGetters, "ACH_DEBIT", "BACS_DEBIT", "SEPA_DEBIT"),
      validationSpec: accountNumber
    },
  },
  getters: {
    shouldShowApiField: (state: OnboardingSectionData, getters, rootState, rootGetters) => (apiFieldName) => {
      return (state.data[apiFieldName] as CountryBasedApiField).shouldShowInView(rootGetters)
    },
    shouldAllowCopyFromApiResponseToApiField: (state, getters, rootState) => (flatApiResponse, apiFieldName) => {
      return (state.data[apiFieldName] as CountryBasedApiField).shouldAllowCopyFromApiResponse(rootState, flatApiResponse)
    },
  },
  actions: {
  },
  mutations: {
  },
}