<template>
  <svg width="19" height="15" viewBox="0 0 19 15" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.83331 14.5L4.66665 13.3334L5.97915 11.9584C4.52081 11.7917 3.29859 11.1598 2.31248 10.0625C1.32637 8.96532 0.833313 7.66671 0.833313 6.16671C0.833313 4.54171 1.39942 3.1631 2.53165 2.03087C3.66331 0.899207 5.04165 0.333374 6.66665 0.333374H9.16665V2.00004H6.66665C5.51387 2.00004 4.53137 2.40615 3.71915 3.21837C2.90637 4.03115 2.49998 5.01393 2.49998 6.16671C2.49998 7.19449 2.82276 8.08337 3.46831 8.83337C4.11442 9.58337 4.92359 10.0556 5.89581 10.25L4.66665 9.02087L5.83331 7.83337L9.16665 11.1667L5.83331 14.5ZM10.8333 13.6667V7.83337H18.3333V13.6667H10.8333ZM10.8333 6.16671V0.333374H18.3333V6.16671H10.8333ZM12.5 4.50004H16.6666V2.00004H12.5V4.50004Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'DepositIcon',
}
</script>