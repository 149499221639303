<template>
  <div class="notification-todo">
    <div class="content">
      <span class="icon">
        <DisputeIcon />
      </span>
      <span>
        <div class="id-title">
          <div class="title">{{ title }}</div>
          <c-tooltip placement="top" v-if="showUnreadMarker" :content="$t('notifications.markAsRead')" variant="dark">
            <div class="read-marker" @click="handleMarkAsRead">
              <svg class="dot" viewBox="0 0 10 10">
                <circle cx="5" cy="5" r="4" />
              </svg>
            </div>
          </c-tooltip>
        </div>

        <div class="description">
          {{ description }}
          <div class="id">
            <div class="id-key"> {{ $t('notifications.id') }} </div>
          </div>
          <div class="id">
            <div class="id_tag">{{ audit.resource_id }}</div>
          </div>
        </div>
        <div class="links" v-if="currentTab === 'TODO'">
          <div class="clickable" @click="() => { openDetails(); handleMarkAsRead(); }" :id="getElementId('todo-see_details')">
            {{ $t('notifications.seeDetails') }}
          </div>
          <div class="clickable" @click="() => dismiss(audit.id)" :id="getElementId('todo-dismiss')">
            {{ $t('notifications.clear') }}
          </div>
        </div>
        <div v-if="currentTab === 'EVENTS' && isStatusChangeEvent()">
          <div class="statusTag" :class="getPreState()">
            {{ $t('filters.statusFilter.' + getPreState()) }}
          </div>
          <span class="arrow"> -> </span>
          <div class="statusTag" :class="getPostState()">
            {{ $t('filters.statusFilter.' + getPostState()) }}
          </div>
        </div>
      </span>
    </div>
    <div class="relative-date-container">
      {{ relativeDate(audit.created_at) }}
    </div>
  </div>
</template>


<script>
import {navigateWithParam} from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import {AuditResponseDto} from "@/api/paymentApi";
import DisputeIcon from "@/components/icons/DisputeIcon.vue";
import {notificationMixin} from "@/mixin/notificationMixin";

export default {
  name: "DisputeNotif",
  components: {DisputeIcon},
  mixins: [notificationMixin],
  props: {
    audit: {
      type: AuditResponseDto,
      default: null,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    hideAudit: {
      type: Function,
      required: true
    },
  },
  methods: {
    openDetails() {
      navigateWithParam(RouteConstants.DISPUTE_DETAILS, { disputeId: this.audit.resource_id }, this.$route.query);
    },
    isStatusChangeEvent() {
      const statusChangeEvents = ["DISPUTE_WON", "DISPUTE_LOST"];
      return statusChangeEvents.includes(this.audit.event);
    },
    getPreState() {
      switch (this.audit.event) {
        case "DISPUTE_WON":
        case "DISPUTE_LOST":
          return "RESPONDED";
      }
    },
    getPostState() {
      switch (this.audit.event) {
        case "DISPUTE_WON":
          return "WON";
        case "DISPUTE_LOST":
          return "LOST";
      }
    },
  }
};
</script>

<style lang="scss">
@import 'notification-common-style.scss';
</style>