<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="settlements-container"
      :class="{['settlements-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="settlements-sub-container">
        <div class="settlements-page-header">
          <div class="settlements-page-header-title">
            {{ $t('deposits.title') }}
          </div>
          <div class="view-deposit-schedule" @click="showDepositScheduleModal = true">
            <EventIcon />
            <span>{{ $t('deposits.view_deposit_schedule') }}</span>
          </div>
        </div>

        <div class="table-header-description">
          {{ $t('deposits.description') }}
        </div>

        <div class="deposit-overview-data" :key="sidebarNavCollapsed + windowWidth">
          <div class="sub-data-container">
            <div class="section-header">
              {{ $t('deposits.overview') }}
            </div>
            <div class="sub-data">
              <div class="deposit-statistics-container">
                <div class="deposit-data-container" v-if="!quarterDepositLoading">
                  <div class="deposit-main-container">
                    <div class="deposit-sub-data-container">
                      <div class="title-container">
                        <div class="statistics-title">
                          {{ getQuarterInfo }}
                        </div>
                      </div>
                      <div class="statistics-amount">
                        {{
                          formatter({
                            type: 'currency',
                            data: total,
                            currencyCode: getMerchantCurrency
                          })
                        }}
                      </div>
                    </div>
                    <div class="deposit-graph-container">
                      <StatisticsChart
                        v-if="chartData.length !== 0"
                        :chart-data="chartData" :interval-type="Interval.DAY"
                        :statistics-type="Metric.DEPOSITS"
                      />
                    </div>
                  </div>
                  <div class="statistics-footer">
                    {{ $t('deposits.deposit_q4_footer') }}
                  </div>
                </div>
                <div class="statistics-container" v-else>
                  <div class="data-container">
                    <div class="box loading-shimmer"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="sub-data">
              <div class="deposit-statistics-container">
                <div class="deposit-data-container" v-if="!onTheWayDepositLoading">
                  <div class="deposit-main-container">
                    <div class="deposit-sub-data-container">
                      <div class="title-container">
                        <div class="statistics-title">
                          {{ $t('deposits.on_the_way_to_your_bank_account') }}
                        </div>
                      </div>
                      <div class="statistics-amount">
                        {{
                          formatter({
                            type: 'currency',
                            data: fundsOnTheWay,
                            currencyCode: getMerchantCurrency
                          })
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="statistics-footer">
                    {{ $t('deposits.on_the_way_to_your_bank_account_footer') }}
                  </div>
                </div>
                <div class="statistics-container" v-else>
                  <div class="data-container">
                    <div class="box loading-shimmer"></div>
                  </div>
                </div>
              </div>
              <div class="deposit-statistics-container" v-if="getActivePayfac === 'ADYEN'">
                <div class="deposit-data-container" v-if="!fundsOnHoldDepositLoading">
                  <div class="deposit-main-container">
                    <div class="deposit-sub-data-container">
                      <div class="title-container">
                        <div class="funds-in-reserve">
                          <div class="statistics-title">
                            <c-link href="https://www.chargebee.com/payments-fs/" target="_blank">
                              {{ $t('deposits.funds_in_reserve') }}
                            </c-link>
                          </div>
                          <ToolTip
                            placement="top-start"
                            :content="getChargebackReserveInfo + '<br>' + getRefundReserveInfo"
                            :no-wrap="true"
                            :arrow="false"
                          >
                            <img src="@/app/assets/images/info-outline.svg" alt="info icon" />
                          </ToolTip>
                        </div>
                        <div class="add-fund" v-if="!isAdminMode && getCountry === 'USA'" @click="navigateToAddFunds">
                          <c-icon name="plus" :size="12" />
                          <span>{{ $t('addFundsToReserve.actions.addFunds') }}</span>
                        </div>
                      </div>
                      <div class="statistics-amount">
                        {{
                          formatter({
                            type: 'currency',
                            data: fundsInReserve,
                            currencyCode: getMerchantCurrency
                          })
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="statistics-footer">
                    {{ $t('deposits.funds_in_reserve_footer') }}
                  </div>
                </div>
                <div class="statistics-container" v-else>
                  <div class="data-container">
                    <div class="box loading-shimmer"></div>
                  </div>
                </div>
              </div>
              <div class="deposit-statistics-container" v-else>
                <div class="deposit-data-container" v-if="!fundsOnHoldDepositLoading">
                  <div class="deposit-main-container">
                    <div class="deposit-sub-data-container">
                      <div class="title-container">
                        <div class="statistics-title">
                          {{ $t('deposits.funds_on_hold') }}
                        </div>
                      </div>
                      <div class="statistics-amount">
                        {{
                          formatter({
                            type: 'currency',
                            data: fundsOnHold,
                            currencyCode: getMerchantCurrency
                          })
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="statistics-footer">
                    {{ $t('deposits.funds_on_hold_footer') }}
                  </div>
                </div>
                <div class="statistics-container" v-else>
                  <div class="data-container">
                    <div class="box loading-shimmer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sub-data-container">
            <div class="section-header">
              {{ $t('deposits.estimated_future_deposits') }}
            </div>
            <div class="sub-data">
              <div class="deposit-statistics-container">
                <div class="deposit-data-container" v-if="!fundsOnHoldDepositLoading">
                  <div class="nav">
                    <div
                      class="nav-item"
                      v-for="tab in tabs"
                      :key="tab.name"
                      :class="{'nav-item-active': tab.name === currentTab, [tab.name]: true}"
                      @click="() => switchTab(tab.name)"
                      :id="getElementId('tab-' + tab.name)"
                    >
                      <span>{{ $t('deposits.tabs.' + getEstimatedTabs(tab.name)) }}</span>
                    </div>
                  </div>
                  <div class="date-of-transactions-info-row" v-if="currentFutureDeposit.transactionTimeline.first_transaction_date">
                    <div>
                      {{ $t('deposits.date_of_transactions') }}
                    </div>
                    <div>
                      : {{ formatTransactionsTimelineForASettlement(currentFutureDeposit.transactionTimeline, 'zonedDate') }}
                    </div>
                  </div>
                  <div class="data-row">
                    <div class="data-name">
                      {{ $t('deposits.total_amount') }}
                    </div>
                    <div class="data-value">
                      {{
                        formatter({type: 'currency', data: currentFutureDeposit.totalAmount, currencyCode: getMerchantCurrency})
                      }}
                    </div>
                  </div>
                  <div class="data-row">
                    <div class="data-name">
                      {{ $t('deposits.deductions') }}
                    </div>
                    <div class="data-value">
                      {{ formatter({type: 'currency', data: currentFutureDeposit.totalFee, currencyCode: getMerchantCurrency}) }}
                    </div>
                  </div>
                  <hr class="custom-hr" />
                  <div class="data-row">
                    <div class="data-name">
                      {{ $t('deposits.deposit_amount') }}
                    </div>
                    <div class="data-value data-value-total">
                      {{
                        formatter({
                          type: 'currency',
                          data: currentFutureDeposit.totalAmount - currentFutureDeposit.totalFee,
                          currencyCode: getMerchantCurrency
                        })
                      }}
                    </div>
                  </div>
                  <div class="estimate-footer">
                    <c-icon name="alert-outline" :size="12" />
                    <div class="estimate-footer-text">
                      <span>
                        {{ $t('deposits.estimated_future_deposits_footer') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="statistics-container" v-else>
                  <div class="data-container">
                    <div class="box loading-shimmer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="section-header">
          {{ $t('deposits.activity') }}
        </div>

        <TableFilterOption
          :disabled="loading.all"
          table-label="Deposits"
          v-bind="tableFilterOptionConfig"
          @childApplyFilter="applyFilter"
          @childClearFilter="applyFilter"
          :date-filter-duration="12"
          :enable-filters="['dateFilter', 'statusFilter', 'amountFilter', 'depositAmountFilter']"
          ref="tableFilterOptionComponent"
        />

        <Search
          placeholder="Search deposits by ID"
          @search="searchDeposits"
          class="search-container"
        />

        <div class="header-container">
          <div class="settlement-header-count">
            <div v-if="count!==0" class="showing-count-top" :class="{'hide-loader': loading.all || settlements.length===0}">
              {{ $t('deposits.showing') }} <b class="pagination"> {{ settlementsCount }} </b>
            </div>
            <div v-else class="showing-count-top" :class="{'hide-loader': loading.all}">
              {{ $t('deposits.noDepositsFound') }}
            </div>
            <div class="loader" :class="{'hide-loader': !loading.all}"></div>
          </div>
          <export-button
            :disabled="loading.all || settlements.length === 0 || searchQuery !== '' || isAdminMode"
            :loading="loading.export"
            :id="getElementId('export')"
            :create-export="createExport"
            :tooltip-enabled="!(searchQuery ==='' && settlements.length>0) && !isAdminMode"
            :tooltip-content="tooltipContent()"
          />
        </div>

        <TableView
          :data="settlementsData"
          :loading="loading.all"
          :all-items-loaded="allItemsLoaded"
          :infinite-scroll="searchQuery === ''"
          class="deposits-table"
          entity="deposits"
          :columns="getColumns"
          :column-right-align="[
            'total_amount',
            'deductions',
            'deposit_amount',
          ]"
          :tooltips="[
            'date_of_transactions',
            'deductions'
          ]"
          @clearFilter="clearFilterFromEmptyPlaceholder"
          :clickable-rows="true"
          :fixed-first-column="false"
          @row-clicked="rowClicked"
        >
          <template #deposit="prop">
            <div class="deposit-id-column">
              <div class="status-tag-container">
                <div
                  class="status"
                  :class="{
                    'progress-status': getMerchantSettlementDeposit(prop.item).status === 'PENDING',
                    'success-status': getMerchantSettlementDeposit(prop.item).status === 'SUCCEEDED',
                    'failure-status': getMerchantSettlementDeposit(prop.item).status === 'FAILED',
                    'canceled-status': getMerchantSettlementDeposit(prop.item).status === 'CANCELED',
                    'na-status': getMerchantSettlementDeposit(prop.item).status === undefined,
                  }"
                >
                  {{
                    getMerchantSettlementDeposit(prop.item).status
                      ? $t('deposits.status.' + getMerchantSettlementDeposit(prop.item).status)
                      : $t('deposits.status.NOT_AVAILABLE')
                  }}
                </div>
              </div>

              <div class="date-and-txid">
                <div class="date_box">
                  <ToolTip
                    placement="top"
                    :content="formatter({type: 'timeWithZone', data: prop.item.settled_at})"
                    :dismiss-on-click="true"
                  >
                    {{ formatter({type: 'zonedDate', data: prop.item.settled_at}) }}
                  </ToolTip>
                </div>
                <div class="txid">
                  {{ getMerchantSettlementDeposit(prop.item).id }}
                </div>
              </div>
            </div>
          </template>
          <template #date_of_transactions="prop">
            <ToolTip
              v-if="formatTransactionsTimelineForASettlement(prop.item.transactions_timeline, 'timeWithZone')"
              placement="top"
              :content="formatTransactionsTimelineForASettlement(prop.item.transactions_timeline, 'timeWithZone')"
              :dismiss-on-click="true"
              :no-wrap="true"
            >
              {{ formatTransactionsTimelineForASettlement(prop.item.transactions_timeline, 'zonedDate') }}
            </ToolTip>
            <div class="empty-placeholder" v-else>
              –
            </div>
          </template>
          <template #type="prop">
            <div v-if="getMerchantSettlementDeposit(prop.item).amount">
              {{ $t('deposits.type.' + getMerchantSettlementDeposit(prop.item).type) }}
            </div>
            <div class="empty-placeholder" v-else>
              —
            </div>
          </template>
          <template #total_amount="prop">
            <div class="amount-container fs-mask">
              <div class="amount">
                {{ formatter({type: 'currency', data: prop.item.total_amount, currencyCode: prop.item.currency}) }}
              </div>
              <div class="currency_code">
                {{ prop.item.currency }}
              </div>
            </div>
          </template>
          <template #deductions="prop">
            <div class="amount-container fs-mask" v-if="prop.item.total_fee !== null">
              <div class="amount">
                {{ formatter({type: 'currency', data: prop.item.total_fee, currencyCode: prop.item.currency}) }}
              </div>
              <div class="currency_code">
                {{ prop.item.currency }}
              </div>
            </div>
            <div class="empty-placeholder" v-else>
              —
            </div>
          </template>
          <template #deposit_amount="prop">
            <div class="amount-container fs-mask">
              <div class="amount" v-if="getMerchantDepositAmount(prop.item)">
                {{
                  formatter({
                    type: 'currency',
                    data: getMerchantDepositAmount(prop.item),
                    currencyCode: getMerchantSettlementDeposit(prop.item).currency,
                  })
                }}
              </div>
              <div class="currency_code" v-if="getMerchantDepositAmount(prop.item)">
                {{ prop.item.currency }}
              </div>
              <div class="empty-placeholder" v-else>
                —
              </div>
            </div>
          </template>
        </TableView>

        <PortalFooter />
      </div>
    </div>
    <ModalPopup :show="showSubmitPopup">
      <div slot="body">
        <ExportsPopUp
          :on-cancel="() => {showSubmitPopup = false}"
        />
      </div>
    </ModalPopup>
    <ModalPopup class="deposit-schedule-modal" :show="showDepositScheduleModal">
      <div slot="body">
        <DepositSchedule
          :on-cancel="() => {showDepositScheduleModal = false}"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import {mapActions, mapGetters} from "vuex";
import Formatter from "@/app/utils/common/functions/formatter";
import settlements from "@/api/settlements";
import TableView from "@/components/common/TableView";
import TableFilterOption from "@/components/common/TableFilterOption";
import {
  DepositSubType,
  SettlementStatus,
  SortBy,
  Sort, Metric, Interval, DepositStatus, ResourceType
} from "@/api/paymentApi";
import Search from "@/components/common/Search";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import StatisticsChart from "@/components/common/StatisticsChart.vue";
import StatisticApi from "@/api/statistics";
import ModalPopup from "@/components/common/ModalPopup";
import ExportsPopUp from "@/components/exports/ExportsPopUp";
import {navigateTo, navigateWithParam} from "@/router";
import SearchBar from "@/components/SearchBar";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import {DateFilters, PendoPrefixes} from "@/app/utils/common/constants";
import EventIcon from "@/components/icons/EventIcon.vue";
import DepositSchedule from "@/views/DepositSchedule.vue";
import ExportButton from "@/components/common/ExportButton.vue";
import {exportMixin} from "@/mixin/exportMixin";
import {RouteConstants} from "@/router/routeConstants";
import {
  getQuarter,
  firstDateOfQuarter,
  getDateTimeFromObject,
  getTimezoneName, getDateTimeFromObjectToUTCToJSDate, getDatePeriodFromFilter
} from "@/app/utils/common/functions/dateUtils";
import {balanceAccountMixin} from "@/mixin/balanceAccountMixin";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: 'DepositView',
  components: {
    ToolTip, ExportButton, DepositSchedule, EventIcon, SearchBar, Search, TableView, SidebarNav,
    TableFilterOption, StatisticsChart, ModalPopup, ExportsPopUp, PortalFooter},
  mixins: [portalCheckMixin, exportMixin, balanceAccountMixin],
  data: () => {
    return {
      showDepositScheduleModal: false,
      settlements: [],
      offsetId: null,
      listElm: null,
      pageSize: 15,
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      settlementStatuses: [SettlementStatus.APPROVED],
      allItemsLoaded: false,
      loading: {
        all: false,
        export: false,
      },
      sidebarNavCollapsed: false,
      count: 0,
      searchQuery: "",
      searchResults: [],
      depositStatusFilter: [
        {
          name: "SUCCEEDED",
        },
        {
          name: "FAILED",
        },
        {
          name: "PENDING",
        },
      ],
      typeFilter: [
        {
          name: "CREDIT",
        },
        {
          name: "DEBIT",
        },
      ],
      totalAmountFilter: {
        gt: null,
        gte: null,
        lt: null,
        lte: null,
      },
      depositAmountFilter: {
        gt: null,
        gte: null,
        lt: null,
        lte: null,
      },
      depositStatus: [],
      depositType: [],
      depositSubType: [],
      tableFilterOptionConfig: {},
      todayFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      total: 0,
      chartData: [],
      fundsOnTheWay: 0,
      fundsOnHold: 0,
      fundsInReserve: 0,
      tabs: [
        {
          name: "TODAY",
        },
        {
          name: "TOMORROW",
        },
      ],
      currentTab: 'TODAY',
      futureDeposit: {
        totalAmountToday: 0,
        totalFeeToday: 0,
        transactionTimelineToday: {
          first_transaction_date: undefined,
          last_transaction_date: undefined
        },
        totalAmountTomorrow: 0,
        totalFeeTomorrow: 0,
        transactionTimelineTomorrow: {
          first_transaction_date: undefined,
          last_transaction_date: undefined
        }
      },
      currentFutureDeposit: {
        totalAmount: 0,
        totalFee: 0,
        transactionTimeline: {
          first_transaction_date: undefined,
          last_transaction_date: undefined
        }
      },
      quarterDepositLoading: true,
      onTheWayDepositLoading: true,
      fundsOnHoldDepositLoading: true,
      futureEstimateDepositLoading: true,
      showSubmitPopup: false,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'isAdminMode', 'getActivePayfac', 'getMerchantCurrency']),
    ...mapGetters('merchantBalanceReserve', [
        'getCurrentAmountInRefundReserve',
        'getCurrentAmountInChargebackReserve',
        'getPendingAmountToRefundReserve',
        'getPendingAmountToChargebackReserve'
    ]),
    ...mapGetters('businessAddress', ['getCountry']),
    getColumns() {
      let columns = {
        'deposit': 'deposits.columns.deposit',
        'date_of_transactions': 'deposits.columns.date_of_transactions',
        'total_amount': 'deposits.columns.total_amount',
        'deductions': 'deposits.columns.deductions',
        'deposit_amount': 'deposits.columns.deposit_amount',
      }
      if (this.getActivePayfac === "ADYEN") {
        columns.deductions = "deposits.columns.deductions.adyen"
      }
      return columns;
    },
    settlementsCount() {
      return this.settlements.length + " / " + this.count;
    },
    settlementsData() {
      return this.searchQuery === "" ? this.settlements : this.searchResults;
    },
    Metric() {
      return Metric
    },
    Interval() {
      return Interval
    },
    getQuarterInfo() {
      return "Deposits - Q" + getQuarter() + " " + new Date().getFullYear();
    },
    getChargebackReserveInfo() {
      let chargebackReserveInfo = "Chargeback Reserve = "
          + Formatter.format({ type: 'currency', data: this.getCurrentAmountInChargebackReserve, currencyCode: this.getMerchantCurrency});
      if (this.getPendingAmountToChargebackReserve !== 0  && this.getPendingAmountToChargebackReserve !== null) {
        chargebackReserveInfo += " + "
            + Formatter.format({ type: 'currency', data: this.getPendingAmountToChargebackReserve, currencyCode: this.getMerchantCurrency})
            + " (Pending)"
      }
      return chargebackReserveInfo;
    },
    getRefundReserveInfo() {
      let refundReserveInfo =  "Refund Reserve = "
          + Formatter.format({ type: 'currency', data: this.getCurrentAmountInRefundReserve, currencyCode: this.getMerchantCurrency})
      if (this.getPendingAmountToRefundReserve !== 0  && this.getPendingAmountToRefundReserve !== null) {
        refundReserveInfo += " + "
            + Formatter.format({ type: 'currency', data: this.getPendingAmountToRefundReserve, currencyCode: this.getMerchantCurrency})
            + " (Pending)"
      }
      return refundReserveInfo;
    }
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    window.cbStorage.setItem('dateFilter', DateFilters.MONTH_TO_DATE);
    ({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate } =
        getDatePeriodFromFilter(DateFilters.MONTH_TO_DATE))

    this.listElm = document.querySelector('#infinite-list');
    this.listElm?.addEventListener('scroll', () => {
      if ((this.listElm.scrollTop + this.listElm.clientHeight + 1 >= this.listElm.scrollHeight) && this.listElm.scrollTop !== 0 && !this.loading.all) {
        this.loadMore();
      }
    });

    this.tableFilterOptionConfig = {
      statusFilterOption: this.depositStatusFilter,
      typeFilterOption: this.typeFilter,
    }

    this.loadMore();
    this.loadData();
    this.getFundsOnTheWay();
    if (this.getActivePayfac === 'ADYEN') {
      await this.getReserveBalance();
      this.fundsInReserve = this.getCurrentAmountInRefundReserve + this.getCurrentAmountInChargebackReserve;
    } else {
      this.getFundsOnHold();
    }

    this.getEstimatedFutureFunds(SettlementStatus.AWAITINGAPPROVAL).then(response => {
      this.fundsOnHoldDepositLoading = true;
      this.futureDeposit.totalAmountToday = response[0]
      this.futureDeposit.totalFeeToday = response[1]
      this.futureDeposit.transactionTimelineToday = response[2];
      this.currentFutureDeposit.totalAmount = this.futureDeposit.totalAmountToday
      this.currentFutureDeposit.totalFee = this.futureDeposit.totalFeeToday
      this.currentFutureDeposit.transactionTimeline = this.futureDeposit.transactionTimelineToday;
      this.fundsOnHoldDepositLoading = false;
    });

    this.getEstimatedFutureFunds(SettlementStatus.PENDING).then(response => {
      this.fundsOnHoldDepositLoading = true;
      this.futureDeposit.totalAmountTomorrow = response[0]
      this.futureDeposit.totalFeeTomorrow = response[1]
      this.futureDeposit.transactionTimelineTomorrow = response[2];
      this.fundsOnHoldDepositLoading = false;
    });
  },
  methods: {
    formatTransactionsTimelineForASettlement: Formatter.formatTransactionsTimelineForASettlement,
    ...mapActions('app', ['updateAppState']),
    ...mapActions('merchantBalanceReserve', ['updateBalanceReserveState']),
    formatter: Formatter.format,
    setHourOfTheDay: Formatter.setHourOfTheDay,
    rowClicked: function (id, data) {
      navigateWithParam(RouteConstants.DEPOSIT_DETAILS, {settlementId: id, data}, this.$route.query)
    },
    navigateToAddFunds() {
      navigateTo(RouteConstants.ADD_FUNDS, true, this.$route.query);
    },
    loadData() {
      this.quarterDepositLoading = true;
      const startDateOfQuarter = firstDateOfQuarter();
      const endDateOfQuarter = startDateOfQuarter.endOf('quarter')
      StatisticApi.fetchMerchantDashboardData(this.getMerchantId, "DEPOSITS",
          true, Interval.DAY, getTimezoneName(), startDateOfQuarter.toUTC().toJSDate(),
          endDateOfQuarter.toUTC().toJSDate()).then(response => {
        this.chartData = response.data;
        this.total = response.total;
        this.quarterDepositLoading = false;
      });
    },
    getMerchantSettlementDeposit(settlement) {
      return settlement.deposits.find(d => d.subType === DepositSubType.MERCHANT) || {}
    },
    getMerchantDepositAmount(settlement) {
      return this.getMerchantSettlementDeposit(settlement).type === 'DEBIT' ?
          this.getMerchantSettlementDeposit(settlement).amount*(-1) : this.getMerchantSettlementDeposit(settlement).amount;
    },
    getCommonQueryParams() {
      return {
        cursor: this.offsetId,
        size: this.pageSize,
        sort_by: SortBy.ID,
        sort: Sort.DESC,
        start_date: this.dateFilter.startDate,
        end_date: this.dateFilter.endDate,
        settled_after: undefined,
        settled_before: undefined,
        amount_gt: this.totalAmountFilter.gt,
        amount_gte: this.totalAmountFilter.gte,
        amount_lt: this.totalAmountFilter.lt,
        amount_lte: this.totalAmountFilter.lte,
        deposit_amount_gt: this.depositAmountFilter.gt,
        deposit_amount_gte: this.depositAmountFilter.gte,
        deposit_amount_lt: this.depositAmountFilter.lt,
        deposit_amount_lte: this.depositAmountFilter.lte,
        settlement_status: this.settlementStatuses,
        deposit_status: this.depositStatus,
        deposit_type: this.depositType,
        deposit_sub_type: this.depositSubType,
      }
    },
    loadMore() {
      if (this.allItemsLoaded === true || this.searchQuery !== '') return;

      this.loading.all = true;

      let params = this.getCommonQueryParams();
      settlements.listMerchantSettlements(
          this.getMerchantId,
          params.cursor,
          params.size,
          params.sort_by,
          params.sort,
          params.start_date,
          params.end_date,
          params.settled_after,
          params.settled_before,
          params.amount_gt,
          params.amount_gte,
          params.amount_lt,
          params.amount_lte,
          params.deposit_amount_gt,
          params.deposit_amount_gte,
          params.deposit_amount_lt,
          params.deposit_amount_lte,
          params.settlement_status,
          params.deposit_status,
          params.deposit_type,
          params.deposit_sub_type).then(response => {
        this.offsetId = response.cursor;
        this.count = response.count;
        this.settlements = this.settlements.concat(response.settlements)

        if (response.settlements.length < this.pageSize) {
          this.allItemsLoaded = true;
        }
        this.loading.all = false;
      });
    },
    applyFilter: function (filter) {
      this.settlements = []
      this.searchResults = [];
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
      this.settlementStatuses = [SettlementStatus.APPROVED];
      this.depositStatus = filter.statusFilter;
      this.depositType = filter.typeFilter;
      this.depositSubType = filter.depositSubType;
      this.totalAmountFilter = filter.amountFilter;
      this.depositAmountFilter = filter.depositAmountFilter;
      this.dateFilter.startDate = filter.dateFilterValue.startDate;
      this.dateFilter.endDate = filter.dateFilterValue.endDate;
      this.loadMore(true)
      this.listElm.scrollTo(0, 0);
    },
    searchDeposits: function (value) {
      this.searchQuery = value;
      this.searchResults = this.settlements.filter(
          x => this.getMerchantSettlementDeposit(x).id.toLowerCase().includes(value.toLowerCase()))
    },
    clearFilterFromEmptyPlaceholder: function () {
      this.$refs.tableFilterOptionComponent.clearFilter();
    },
    getFundsOnTheWay: function () {
      this.onTheWayDepositLoading = true;
      let fundStartDate = getDateTimeFromObject().plus({weeks: -2}).toUTC().toJSDate();
      let fundEndDate = getDateTimeFromObjectToUTCToJSDate();
      settlements.listMerchantSettlements(
          this.getMerchantId, null, 100, SortBy.ID, Sort.DESC,
          fundStartDate, fundEndDate, undefined, undefined,
          null, null, null, null,
          null, null, null, null,
          [SettlementStatus.APPROVED], [DepositStatus.PENDING],
          null, [DepositSubType.MERCHANT]).then(response => {
        response.settlements.forEach(item => {
          this.fundsOnTheWay += item.net_amount;
        });
        this.onTheWayDepositLoading = false;
      });

    },
    getFundsOnHold: function () {
      this.fundsOnHoldDepositLoading = true;
      settlements.listMerchantSettlements(
          this.getMerchantId, null, 100, SortBy.ID, Sort.DESC,
          undefined, undefined, undefined, undefined,
          null, null, null, null,
          null, null, null, null,
          [SettlementStatus.APPROVED], [DepositStatus.FAILED],
          null, [DepositSubType.MERCHANT]).then(response => {
        response.settlements.forEach(item => {
          this.fundsOnHold += item.net_amount;
        });
        this.fundsOnHoldDepositLoading = false;
      });
    },
    getEstimatedFutureFunds: async function (settlementStatus) {
      let amount = 0;
      let fee = 0;
      return settlements.listMerchantSettlements(
          this.getMerchantId, null, 100, SortBy.ID, Sort.DESC,
          undefined, undefined, undefined, undefined, null, null,
          null, null, null, null, null,
          null, [settlementStatus], null, null, null)
          .then(response => {
        response.settlements.forEach(item => {
          amount += item.total_amount;
          fee += item.total_fee;
        });
        const transactionsTimeline = {
          first_transaction_date : response.settlements[response.settlements.length-1]?.transactions_timeline?.first_transaction_date,
          last_transaction_date : response.settlements[0]?.transactions_timeline?.last_transaction_date
        }
        return [amount, fee, transactionsTimeline];
      });
    },
    switchTab: function (nextTab) {
      if (this.currentTab === nextTab) {
        return
      }
      switch (nextTab) {
        case "TODAY":
          this.currentFutureDeposit.totalAmount = this.futureDeposit.totalAmountToday
          this.currentFutureDeposit.totalFee = this.futureDeposit.totalFeeToday
          this.currentFutureDeposit.transactionTimeline = this.futureDeposit.transactionTimelineToday;
          break;
        case "TOMORROW":
          this.currentFutureDeposit.totalAmount = this.futureDeposit.totalAmountTomorrow
          this.currentFutureDeposit.totalFee = this.futureDeposit.totalFeeTomorrow
          this.currentFutureDeposit.transactionTimeline = this.futureDeposit.transactionTimelineTomorrow;
          break;
      }
      this.currentTab = nextTab
    },
    getDateFormat: function (tab) {
      if (tab === 'TODAY') {
        return this.formatter({type: 'zonedDate', data: getDateTimeFromObject().plus({days: 1}).toISODate()})
      }
      return this.formatter({type: 'zonedDate', data: getDateTimeFromObject().plus({days: 2}).toISODate()})
    },
    getEstimatedTabs: function (tab) {
      if (tab === 'TODAY') {
        return "NEXT";
      }
      return "UPCOMING";
    },
    tooltipContent() {
      if (this.searchQuery !== '') {
        return "Clear search to export deposits";
      }
      if (this.settlements.length === 0) {
        return "No deposits to export";
      }
    },
    getCreateExportParams() {
      return {
        resource_type: ResourceType.DEPOSIT,
        deposit_filter: {
          ...this.getCommonQueryParams(),
          cursor: undefined,
          size: undefined,
          requested_from: "Deposits",
        },
      };
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase();
    }
  },
}

</script>

<style lang="scss">

.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;

  & .deposit-schedule-modal {
    & .modal-container {
      max-width: 448px;
      max-height: 788px;

      & .footer {
        padding: 0;
      }
    }
  }
}

.settlements-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .settlements-sub-container {
    width: 100%;
    max-width: 1360px;
  }

  & .settlements-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 20px 0 8px 0;

    & .settlements-page-header-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: $neutral_1000;
      text-align: left;
    }

    & .view-deposit-schedule {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $primary_600;
      font-size: 12px;
      font-weight: $weight-bold;
    }

    & .view-deposit-schedule:hover {
      cursor: pointer;
    }

  }

  & .section-header {
    font-style: normal;
    font-weight: $weight_medium;
    font-size: 16px;
    line-height: 30px;
    color: $neutral_600;
    text-align: left;
    padding: 0 0 16px 0;
  }

  & .search-container{
    width: 320px;
    margin-bottom: 16px;
  }

  & .table-header-description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral_600;
    text-align: left;
    padding: 0 0 24px 0;
  }

  & .deposit-overview-data {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;

    & .sub-data-container {
      display: flex;
      flex-direction: column;
      flex: 6;

      & .sub-data {
        display: flex;
        flex-direction: row;
        flex: 1;

        & .deposit-statistics-container:nth-child(2) {
          margin-left: 16px;
        }

        & .deposit-statistics-container {
          display: flex;
          flex: 1;
          position: relative;


          & .deposit-data-container {
            background: #FFFFFF;
            border-radius: 6px;
            padding: 20px;
            text-align: left;
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;

            & .nav {
              display: flex;
              flex-direction: row;
              font-size: 14px;
              font-weight: $weight_bold;
              background-color: transparent;
              padding-left: 0;
              border-bottom: 1px solid #D4D4E8;
              margin-bottom: 16px;
              margin-top: -5px;

              &:after {
                content: '';
                height: 2px;
                background-color: $color_white;
              }

              & .nav-item {
                display: flex;
                cursor: pointer;
                align-items: start;
                margin-right: 16px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;

                @media screen and (min-width: 801px) {
                  padding: 12px 16px;
                }

                @media screen and (max-width: 800px) {
                  padding: 10px 16px;
                }

                &:hover {
                  background-color: $primary_fill_light;
                }

                &.disabled:hover {
                  background-color: $color-white;
                  cursor: not-allowed;
                }
              }

              & .nav-item-active {
                color: $primary_600;
                border-bottom: 2px solid $primary_600;

                & svg {
                  margin-right: 10px;
                  color: $primary_600;
                }
              }
            }
            & .date-of-transactions-info-row {
              display: flex;
              flex-direction: row;
              font-style: normal;
              font-weight: $weight_normal;
              font-size: 11px;
              line-height: 16px;
              color: $neutral_400;
            }

            & .data-row {
              display: flex;
              flex-direction: row;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              margin: 6px 0;

              & .data-name {
                display: flex;
                flex: 1;
                text-align: left;
                color: $neutral_400;
              }

              & .data-value {
                display: flex;
                flex: 1;
                text-align: right;
                justify-content: right;
                color: $neutral_800;
              }

              & .data-value-total {
                font-weight: 600;
              }
            }

            & .estimate-footer {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: $neutral_800;
              display: flex;
              align-items: flex-start;
              height: 100%;
              gap: 4px;
              padding-top: 20px;

              & svg {
                vertical-align: text-top;
                width: 20px;
                height: 18px;
              }

              & .estimate-footer-text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              }
            }

            & .custom-hr {
              border: 0.5px solid $neutral_200;
              margin: 6px 0 6px 0;
            }

            & .data-row:nth-child(2) {
              margin-top: 10px;
            }

          }

          & .statistics-footer {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: $neutral_600;
            margin-top: 5px;
          }

          & .deposit-main-container {
            display: flex;
            flex-direction: row;


            & .deposit-sub-data-container {
              display: flex;
              flex-direction: column;
              flex: 3;
              min-width: 130px;

              & .title-container {
                display: flex;
                flex-direction: row;
                height: fit-content;
                align-items: center;
                justify-content: space-between;

                & .funds-in-reserve {
                  display: flex;
                  flex-direction: row;
                  height: fit-content;
                  align-items: center;
                }

                & .statistics-title {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  color: $neutral_400;
                  margin-right: 5px;

                  & .no-border {
                    border: none !important;
                    padding: 0;
                  }
                }

                & .add-fund {
                  display: flex;
                  align-items: center;
                  color: $primary_600;
                  font-size: 12px;
                  gap: 4px;
                  font-weight: $weight-bold;
                }

                & .add-fund:hover {
                  cursor: pointer;
                }
              }
            }

            & .deposit-graph-container {
              display: flex;
              width: 100%;
              flex: 10;
              padding-left: 20px;

              & .chart-holder {
                width: 100%;

                & .chart-wrapper {
                  width: 100%;
                  height: 60px;

                  & .echarts {
                    display: flex;
                    flex: 1;
                    min-width: 500px;

                    & :first-child {
                      display: flex;
                      flex: 1;

                      & :first-child {
                        display: flex;
                        flex: 1;
                      }
                    }
                  }
                }
              }
            }

            & .statistics-amount {
              font-style: normal;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: $neutral_800;
              margin-top: 8px;
            }
          }
        }
      }

      & .sub-data:nth-child(2) {
        margin-bottom: 16px;
      }
    }

    & .sub-data-container:nth-child(2) {
      flex: 4;
      padding-left: 32px;

      & .sub-data:nth-child(2) {
        margin-bottom: 0px;

        & .deposit-statistics-container:nth-child(1) {
          min-width: 260px;
        }
      }
    }
  }

  &.settlements-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  & .action-header {
    background-color: $color_white;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 80px;
    justify-content: left;
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
    flex-direction: row;

    & .transaction-count {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_800;
      align-self: center;
    }
  }

  .header-container {
    background-color: $color_white;
    width: 100%;
    height: fit-content;
    border: 1px solid $neutral_100;
    border-radius: 6px 6px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 15px 25px 5px 25px;

    & .settlement-header-count {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      text-align: left;
      align-self: center;
      padding-bottom: 10px;
      display: flex;

      & .showing-count-top {
        display: inline-block;
        justify-content: left;

        & .pagination {
          font-weight: $weight_bold;
        }
      }

      & .loader {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 3px solid $neutral_100;
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        justify-content: center;
      }


      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      .hide-loader {
        display: none;
        visibility: hidden;
      }

    }

    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      padding: 0;

      & img {
        margin-right: 5px;
      }
    }
  }

  & .deposit-id-column {
    display: flex;
    flex-direction: row;

    & .status-tag-container {
      display: flex;
      text-align: left;
      min-width: 70px;
      flex-direction: row;
      justify-content: space-between;
    }

    & .status {
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 10px;
      height: fit-content;
    }

    & .success-status {
      background-color: $success_100;
      color: $success_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    & .failure-status {
      background-color: $danger_50;
      color: $danger_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    & .canceled-status {
      background-color: $warn_50;
      color: $warn_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
    }

    & .progress-status {
      background-color: $primary_100;
      color: $primary_800;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    & .na-status {
      background-color: $neutral_50;
      color: $neutral_600;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    & .date-and-txid {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-left: 8px;

      & .date_box {
        font-size: 14px;
        font-weight: $weight-medium;
        line-height: 20px;
        color: $neutral_800;
        display: flex;
        flex-direction: row;

        & .amount {
          margin-right: 5px;
        }

        & .currency_code {
          color: $neutral_400;
          font-size: 14px;
        }
      }

      & .txid {
        font-weight: $weight_normal;
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;

      }
    }
  }

  & .type-column {
    display: flex;
    min-height: 60px;
    align-items: center;

    & .type {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-medium;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      justify-content: start;

      & .empty-placeholder {
        color: #D4D4E8;
        padding-left: 20px;
      }

      & .amount-container {
        justify-content: right;
        display: flex;
        flex-direction: row;
        padding-right: 20%;
        padding-left: 20px;
        width: 100%;

        & .empty-placeholder {
          color: #D4D4E8;
          padding-right: 30px;
        }

        & .amount {
          margin-right: 5px;
        }

        & .currency_code {
          color: #666678;
          font-size: 14px;
        }
      }
    }
  }
}
</style>