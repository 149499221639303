<template>
  <div class="cs-header">
    <div class="cs-header__container">
      <div class="cs-header__main">
        <div class="cs-header__detail">
          <div class="cs-header__title" v-if="isMigrating">
            {{ $t('migration.headerTitle') }}
          </div>
          <div class="cs-header__title" v-else>
            {{ $t('onboarding.title') }}
          </div>
          <div class="cs-header__merchant_name">
            {{ getMerchantName }}
          </div>
        </div>
      </div>
      <div class="cs-header__aside">
        <div class="cs-header__doables">
          <div class="cs-header__doable">
            <c-button
              @click="() => showDismissConfirmation = true"
              :disabled="saveAndCloseLoading"
              size="large"
            >
              {{ $t('global.action.dismiss') }}
            </c-button>
          </div>
          <div class="cs-header__doable" v-if="!isMigrating">
            <c-button
              @click="saveAsDraft"
              :loading="saveAndCloseLoading"
              size="large"
            >
              {{ $t('onboarding.saveAsDraft') }}
            </c-button>
          </div>
          <div class="cs-header__doable">
            <ToolTip :enabled="!enableSubmitButton" :content="$t('onboarding.submitDisabled')" placement="bottom-end">
              <c-button
                :disabled="!enableSubmitButton"
                @click="onboardMerchant"
                variant="primary"
                size="large"
              >
                {{ $t('global.action.submit') }}
              </c-button>
            </ToolTip>
          </div>
        </div>
      </div>
    </div>
    <c-modal
      :control-variable="showDismissConfirmation"
      :primary-action="$t('global.action.confirm')"
      :secondary-action="$t('global.action.cancel')"
      :heading="$t('dismissConfirm.title')"
      variant="danger"
      @primary="openCallbackUrl"
      @close="() => showDismissConfirmation = false"
      :description="$t('dismissConfirm.description')"
    />
    <ModalPopup :show="showSubmitPopup">
      <div slot="body">
        <TermsAndConditions
          :on-cancel="() => {showSubmitPopup = false}"
          :is-migrating="isMigrating"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TermsAndConditions from "@/components/onboarding/TermsAndConditions.vue";
import ModalPopup from "@/components/common/ModalPopup.vue";
import {saveMerchantAsDraft} from "@/service/merchantService";
import {CallbackParams} from "@/app/utils/common/constants";
import FullStoryHelper from '@/app/utils/fullstory';
import {TERMS_AND_CONDITIONS_POPUP} from '@/app/utils/fullstory/constants';
import {callbackApplication} from "@/app/utils/common/functions/functions";
import ToolTip from "@/components/common/ToolTip.vue";
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "OnboardingHeader",
  components: {ToolTip, ModalPopup, TermsAndConditions},
  props: {
    isMigrating: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    showDismissConfirmation: false,
    showSubmitPopup: false,
    saveAndCloseLoading: false
  }),
  computed: {
    ...mapGetters('app', [
        'getCallbackUrl', 'getMerchantName', 'getMerchantId',
        'getDraftMerchantId', 'getAutoSavedDraftMerchantId']),
    ...mapGetters('businessProfile', {isBusinessProfileValid: 'isValid'}),
    ...mapGetters('businessAddress', {isBusinessAddressValid: 'isValid'}),
    ...mapGetters('businessStructure', {isBusinessStructureValid: 'isValid'}),
    ...mapGetters('stakeholdersDetails', {isStakeholdersDetailsValid: 'isValid'}),
    ...mapGetters('additionalUnderwritingData', {isAdditionalUnderwritingDataValid: 'isValid'}),
    ...mapGetters('bankAccountForPayouts', {isBankAccountForPayoutsValid: 'isValid'}),
    enableSubmitButton() {
      return this.isBusinessProfileValid &&
          this.isBusinessAddressValid &&
          this.isBusinessStructureValid &&
          this.isStakeholdersDetailsValid &&
          this.isAdditionalUnderwritingDataValid &&
          this.isBankAccountForPayoutsValid
    }
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    ...mapActions('onboarding', ['setAutoSaveStatus']),
    onboardMerchant() {
      this.showSubmitPopup = true;
      FullStoryHelper.event(TERMS_AND_CONDITIONS_POPUP);
    },
    saveAsDraft() {
      this.saveAndCloseLoading = true;
      this.setAutoSaveStatus(false);
      saveMerchantAsDraft().then(response => {
        this.$success(this.$t('global.toast.savedAsDraft'))
        this.updateAppState({key: 'draftMerchantId', value: response.draft_id})
        const url = new URL(this.getCallbackUrl);
        url.searchParams.append(CallbackParams.draftMerchantId, response.draft_id);
        window.open(url, "_self")
      }).catch(err => {
        this.saveAndCloseLoading = false;
        this.$danger(this.$t('errors.submitOnboardingFailed') + " - " + err.source?.detail)
      })
    },
    openCallbackUrl() {
      if (this.isMigrating) {
        navigateTo(RouteConstants.DASHBOARD);
        return;
      }
      callbackApplication(this.getCallbackUrl, this.getMerchantId, this.getAutoSavedDraftMerchantId);
    }
  },
}
</script>
<style lang="scss">
.cs-header {
  border-bottom: 1px solid #D1D5DB;
  background-color: $color_white;

  &__ {
    &container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (min-width: 801px) {
        flex-direction: row
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        flex-direction: column;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &main {
      display: flex;
      align-items: center;
      margin-left: 30px;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    &aside {
      padding-left: 40px;
    }

    &doable {
      &s {
        margin-right: 30px;
        display: flex;
        flex-wrap: wrap;
      }

      font-weight: $weight_medium;
      margin-left: 8px;
      margin-bottom: 8px;

    }

    &brand {
      position: relative;
      margin-right: 26px;
    }

    &divider {
      width: 1px;
      background-color: $divider_color;
      min-height: 40px;
    }

    &logo {
      position: relative;
      font-size: 0;
      @include c-box(46px);
      background-image: url('~@/app/assets/images/brand/cv-logomask-primary.svg');
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        @include c-box(26px);
        background-image: url('~@/app/assets/images/brand/cv-logo-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }

    &detail {
      padding-left: 16px;
      position: relative;
      min-height: 40px; // TODO
      display: flex;
      flex-direction: column;
      justify-content: left;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &title {
      margin-bottom: 4px;
      font-weight: $weight_bolder;
      text-align: left;

      @media screen and (min-width: 801px) {
        font-size: 30px;
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        font-size: 24px;
      }

      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }

    &merchant_name {
      font-size: 14px;
      width: fit-content;
      color: $neutral_400;
      font-weight: $weight_bold;
    }
  }
}
</style>