<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask
      id="mask0_12066_101329" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
      width="24" height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_12066_101329)">
      <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#374049" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>