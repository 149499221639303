<template>
  <div class="consumer-details" v-if="dispute">
    <div class="consumer-details-title">
      Customer Details
    </div>
    <div class="consumer-details-content">
      <InputField
        label="Name"
        :value="dispute.customer_name"
        id="consumer-name"
        data-testid="consumer-name"
        :set-value="(event) => updateName(event.target.value)"
      />

      <InputField
        label="Email"
        :value="dispute.customer_email"
        id="consumer-email"
        data-testid="consumer-email"
        :set-value="(event) => updateEmail(event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import InputField from "@/components/common/InputField";

export default {
  name: "DisputeConsumerInput",
  components: {InputField},
  props: {
    dispute: {
      type: Object,
      required: true,
    },
    updateName: {
      type: Function,
      required: true
    },
    updateEmail: {
      type: Function,
      required: true
    }
  },
}
</script>
<style lang="scss">
.consumer-details {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;

  & .consumer-details-title {
    font-size: 16px;
    font-weight: $weight_bold;
  }

  & .consumer-details-content {
    display: flex;
    gap: 16px;
    & .field {
      flex: 1;
    }
  }
}
</style>