<template>
  <div class="disputes-overview">
    <div class="title">
      Overview
    </div>
    <div v-if="shouldShowWarning()" class="warning">
      <img src="@/app/assets/images/info-outline-blue.svg" alt="info-outline-blue" />
      <div class="warning-text">
        {{ adjustmentTransferMessage }}
      </div>
    </div>
    <div class="fields" v-if="dispute">
      <div class="column">
        <FieldValue
          :field-value="dispute.reason"
          :field-message="dispute.message"
          :field-name="$t('fieldNames.reason')"
          :tooltip="$t('disputes.tooltips.reason')"
        />
        <FieldValue
          :field-value="null"
          :field-name="$t('fieldNames.chargebackFee')"
          :tooltip="$t('disputes.tooltips.chargebackFee')"
        />
        <FieldValue
          :field-value="formatDateWithTimezone(dispute.occurred_at.toString()) + ' ' + $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()})"
          :field-name="$t('fieldNames.disputeInitiated')"
        />
      </div>
      <div class="column">
        <FieldValue
          class-name="respond-by"
          :field-value="formatDateWithTimezone(dispute.respond_by.toString()) + ' ' + $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()})"
          :field-name="$t('fieldNames.respondBy')"
          v-if="dispute.status !== 'WON' && dispute.status !== 'LOST'"
          :tooltip="$t('disputes.tooltips.respondBy')"
        />
        <FieldValue
          class-name="last-updated"
          :field-value="formatDateWithTimezone(dispute.updated_at.toString()) + ' ' + $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()})"
          :field-name="$t('fieldNames.lastUpdated')"
          v-if="dispute.status === 'WON' || dispute.status === 'LOST'"
        />
        <FieldValue
          :field-value="getConsumerName()"
          :field-name="$t('fieldNames.consumerName')"
        >
          <span v-if="getConsumerName()"> {{ getConsumerName() }}</span>
          <span v-else class="empty-placeholder">—</span>
        </FieldValue>
        <FieldValue
          class-name="consumer-email"
          :field-value="dispute.consumer.email"
          :field-name="$t('fieldNames.consumerEmail')"
        >
          <a v-if="getConsumerEmailLink()" :href="getConsumerEmailLink()" :id="getElementId('consumer_email')">
            {{ dispute.consumer.email }}
          </a>
          <span v-else class="empty-placeholder">—</span>
        </FieldValue>
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import {DisputeResponseDto, TransactionResponseDto} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import {getName} from "@/app/utils/common/functions/functions";
import { getUtcTimezoneDifference } from "@/app/utils/common/functions/dateUtils";
import {PendoPrefixes} from "@/app/utils/common/constants";

export default {
  name: "DisputeOverview",
  components: {FieldValue},
  props: {
    dispute: {
      type: DisputeResponseDto,
      required: true,
    },
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
  },
  computed: {
    adjustmentTransferMessage() {
      return this.$t('disputes.message.' + this.dispute.type + "." + this.dispute.status)
    }
  },
  methods: {
    getUtcTimezoneDifference,
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date});
    },
    getConsumerFullNameFromDispute() {
      return getName(this.dispute.consumer.first_name, this.dispute.consumer.last_name)
    },
    getConsumerNameFromPaymentInstrument() {
      return this.transaction.payment_instrument.card?.card_holder_name || this.transaction.payment_instrument.bank_account?.name;
    },
    getConsumerName() {
      return this.getConsumerFullNameFromDispute() || this.getConsumerNameFromPaymentInstrument();
    },
    shouldShowWarning() {
      return !(this.dispute.type === 'INQUIRY' && (this.dispute.status === 'WON' || this.dispute.status === 'ACCEPTED'));
    },
    getConsumerEmailLink() {
      if (this.dispute.consumer.email) {
        return `mailto:${this.dispute.consumer.email}`;
      } else {
        return null;
      }
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">
.disputes-overview {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .warning {
    margin-top: 12px;
    display: flex;
    background: $info_50;
    border: 0.5px solid $info_100;
    border-radius: 4px;
    padding: 6px;
    gap: 10px;
    color: $primary_600;
    line-height: 18px;

    & .warning-text {
      color: $neutral-800;
      height: 18px;
      font-size: 12px;
    }
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;

      & .empty-placeholder {
        color: $neutral_300;
      }
    }

    & .consumer-email {
      & a {
        text-decoration: none;
        color: $primary_600;
        font-weight: $weight_medium;
      }
    }

    & .respond-by {
      & .field-value {
        color: $danger_600;
      }
    }
  }
}
</style>