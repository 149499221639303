<template>
  <svg width="37" height="28" viewBox="0 0 37 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.66683 27.3327C2.75016 27.3327 1.96572 27.0066 1.3135 26.3543C0.660163 25.701 0.333496 24.916 0.333496 23.9993V3.99935C0.333496 3.08268 0.660163 2.29824 1.3135 1.64602C1.96572 0.992682 2.75016 0.666016 3.66683 0.666016H13.6668L17.0002 3.99935H30.3335C31.2502 3.99935 32.0352 4.32602 32.6885 4.97935C33.3407 5.63157 33.6668 6.41602 33.6668 7.33268H15.6252L12.2918 3.99935H3.66683V23.9993L7.66683 10.666H36.1668L31.8752 24.9577C31.6529 25.6799 31.2435 26.2566 30.6468 26.6877C30.0491 27.1177 29.3891 27.3327 28.6668 27.3327H3.66683ZM7.16683 23.9993H28.6668L31.6668 13.9993H10.1668L7.16683 23.9993Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'FolderIcon',
}
</script>