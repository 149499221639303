var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('SidebarNav',{on:{"update:sidebarNavCollapsed":function($event){_vm.sidebarNavCollapsed = $event}}}),_c('SearchBar',{attrs:{"sidebar-nav-collapsed":_vm.sidebarNavCollapsed}}),_c('div',{staticClass:"exports-container",class:( _obj = {}, _obj['exports-container-sidebar-collapse'] = _vm.sidebarNavCollapsed, _obj )},[_c('div',{staticClass:"exports-sub-container"},[_c('div',{staticClass:"table-header"},[_vm._v(" "+_vm._s(_vm.$t('exports.title'))+" ")]),_c('div',{staticClass:"table-sub-header"},[_vm._v(" "+_vm._s(_vm.$t('exports.sub_title'))+" ")]),_c('div',{staticClass:"count-container"},[(_vm.count!==0)?_c('div',{staticClass:"showing-count",class:{'hide-loader': _vm.loading.all || _vm.exports.length===0}},[_vm._v(" "+_vm._s(_vm.$t('exports.showing'))+" "),_c('b',{staticClass:"pagination"},[_vm._v(_vm._s(_vm.countLabel))])]):_c('div',{staticClass:"showing-count",class:{'hide-loader': _vm.loading.all}},[_vm._v(" "+_vm._s(_vm.$t('exports.noExportsFound'))+" ")]),_c('div',{staticClass:"loader",class:{'hide-loader': !_vm.loading.all}})]),_c('TableView',{staticClass:"exports-table",attrs:{"entity":"Exports","data":_vm.exports,"loading":_vm.loading.all,"all-items-loaded":_vm.allItemsLoaded,"infinite-scroll":true,"fixed-first-column":false,"columns":{
          'requested': 'exports.columns.requested',
          'expires': 'exports.columns.expires',
          'status': 'exports.columns.status',
          'requested_from': 'exports.columns.requested_from',
          'download_link': 'exports.columns.download_link',
        },"column-right-align":[]},scopedSlots:_vm._u([{key:"requested",fn:function(prop){return [_c('div',{staticClass:"export-requested-column"},[_vm._v(" "+_vm._s(_vm.formatter({type: 'zonedDateWithTime', data: prop.item.created_at}))+" ")])]}},{key:"expires",fn:function(prop){return [_c('ToolTip',{attrs:{"placement":"top","content":_vm.formatter({type: 'timeWithZone', data: prop.item.expires_at}),"enabled":prop.item.expires_at}},[(prop.item.expires_at)?_c('div',{staticClass:"expires_at"},[_vm._v(" "+_vm._s(_vm.formatter({type: 'zonedDate', data: prop.item.expires_at}))+" ")]):_c('div',{staticClass:"empty-placeholder"},[_vm._v(" — ")])])]}},{key:"status",fn:function(prop){return [_c('div',{staticClass:"export-column"},[_c('div',{staticClass:"status-tag-container"},[_c('div',{staticClass:"status",class:{
                  'success-status': prop.item.status === 'COMPLETED',
                  'failure-status': prop.item.status === 'FAILED',
                  'progress-status': prop.item.status === 'REQUESTED' || prop.item.status === 'INITIATED'
                }},[_vm._v(" "+_vm._s(_vm.$t('exports.status.' + prop.item.status))+" ")])])])]}},{key:"requested_from",fn:function(prop){return [_vm._v(" "+_vm._s(prop.item.requested_from)+" ")]}},{key:"download_link",fn:function(prop){return [_c('div',{staticClass:"download-column",class:prop.item.status === 'COMPLETED' ? 'completed':'not-completed'},[_c('c-button',{attrs:{"variant":"primary","disabled":prop.item.status !== 'COMPLETED' || _vm.isAdminMode,"loading":_vm.loading.download === prop.item.id},on:{"click":function($event){return _vm.downloadExports(prop.item.id)}}},[_c('ToolTip',{attrs:{"enabled":prop.item.status !== 'COMPLETED' && !_vm.isAdminMode,"placement":"top","content":_vm.tooltipContent(prop.item.status)}},[_c('svg',{staticClass:"download-icon",attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"currentColor","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.99999 11.3327C1.63332 11.3327 1.31955 11.2022 1.05866 10.9413C0.797323 10.68 0.666656 10.366 0.666656 9.99935V7.99935H1.99999V9.99935H9.99999V7.99935H11.3333V9.99935C11.3333 10.366 11.2029 10.68 10.942 10.9413C10.6807 11.2022 10.3667 11.3327 9.99999 11.3327H1.99999ZM5.99999 8.66602L2.66666 5.33268L3.59999 4.36602L5.33332 6.09935V0.666016H6.66666V6.09935L8.39999 4.36602L9.33332 5.33268L5.99999 8.66602Z","fill":"currentColor"}})]),_vm._v(" "+_vm._s(_vm.$t('exports.download'))+" ")])],1)],1)]}}])}),_c('PortalFooter')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }