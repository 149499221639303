import {ExportStatus} from "@/api/paymentApi";
import exports from "@/api/exports";

export const exportMixin = {
    methods: {
        createExport() {
            this.loading.export = true;
            exports.createExport(this.getMerchantId, this.getCreateExportParams())
                .then(response => {
                    this.loading.export = false;
                    if (response.status !== ExportStatus.FAILED) {
                        this.showSubmitPopup = true;
                    }
                })
                .catch(err => {
                    this.loading.export = false;
                    this.$danger(err.source.title);
                });
        },
    },
};
