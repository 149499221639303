<template>
  <div class="settlement-breakdown">
    <div class="title">
      <div class="breakdown">
        Breakdown
      </div>
      <c-button
        @click="downloadPDF"
        variant="primary-outline"
        :id="getElementId('export')"
      >
        <DownloadsIcon />
        {{ "Download Report (.PDF)" }}
      </c-button>
    </div>
    <VueHtml2Pdf
      :show-layout="false"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="'Settlement_breakdown_' + settlement.id"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <ExportableSettlementBreakdown :settlement="settlement" />
      </section>
    </VueHtml2Pdf>
  </div>
</template>

<script>
import {SettlementDto} from "@/api/paymentApi";
import {mapGetters} from "vuex";
import DownloadsIcon from "@/components/icons/DownloadsIcon";
import VueHtml2Pdf from 'vue-html2pdf';
import ExportableSettlementBreakdown from "@/components/settlements/ExportableSettlementBreakdown";
import {PendoPrefixes} from "@/app/utils/common/constants";

export default {
  name: "SettlementBreakdown",
  components: {ExportableSettlementBreakdown, DownloadsIcon, VueHtml2Pdf},
  props: {
    settlement: {
      type: SettlementDto,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
  },
  methods: {
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf()
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">
.settlement-breakdown {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      background-color: #e5f3fa;
      border: 1px solid #8ccce6;
      color: #2d6a8a;
      padding-left: 12px;
      padding-right: 12px;

      & svg{
        margin-right: 5px;
      }
    }

    & .c-button__label {
      padding: 0;
    }

    & .c-button:hover {
      color: #2d6a8a;
      background-color: #b5def0;
    }

  }

  & .vue-html2pdf {
    position: relative;
    & .layout-container {
      position: initial !important;
      width: 100% !important;
      height: 100% !important;
      background: none !important;
    }

    & .show-in-pdf-only {
      display: none;
    }
  }
}
</style>