export const settlementHolidaysList = {
    NewYearsDay: "New Year’s Day",
    MartinLutherKingDay: "Martin Luther King Day",
    PresidentsHoliday: "Presidents' Day",
    MemorialDay:"Memorial Day",
    Juneteenth: "Juneteenth",
    IndependenceDay: "Independence Day",
    LaborDay: "Labor Day",
    VeteransDay: "Veterans' Day",
    ThanksgivingAndTheDayAfter: "Thanksgiving and the day after",
    ChristmasEveAndChristmasDay: "Christmas Eve and Christmas Day",
    NewYearsEve: "New Year's Eve"
}

export default settlementHolidaysList;

