<template>
  <div
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="drop"
    @change="selectedFile"
    class="upload-box"
    :class="{'active-dropzone': active, 'uploaded': fileUpload, 'disable-interaction': disableInteraction}"
  >
    <i18n v-if="!fileUpload" path="uploadDocument.fileUploadText" tag="div" class="upload-box-heading">
      <template #upload>
        <label :for="uploadType" class="custom-file-upload">
          {{ $t('uploadDocument.upload') }}
        </label>
        <input type="file" :class="uploadType" :id="uploadType" accept=".jpg, .jpeg, .png, .tiff, .pdf" />
      </template>
    </i18n>
    <div v-else class="uploaded-header-container">
      <div class="uploaded-header">
        {{ dropzoneFile.name }}
      </div>
      <div class="remove-file" @click="removeFile" v-if="!disableInteraction">
        x
      </div>
      <div class="upload-in-progress" v-if="uploadInProgress">
        Uploading...
      </div>
    </div>
    <hr
      v-if="fileUpload"
      class="progress-bar-file"
      :class="{'error-file':Boolean(fileUploadErrorMessage)}"
      :style="{width: `${uploadPercentage}%`, display: uploadPercentage ? 'block' : 'none'}"
    />
    <div v-if="!fileUpload" class="upload-box-sub">
      {{ $t('uploadDocument.maxSize') }}
    </div>
    <div v-if="!fileUpload" class="upload-box-sub">
      {{ $t('uploadDocument.support1') }}
    </div>
    <div v-if="!fileUpload" class="upload-box-sub">
      {{ $t('uploadDocument.support2') }}
    </div>
    <div v-else class="uploaded-sub">
      Size: {{ fileSize }} {{ fileNotation }}
    </div>
    <div v-if="Boolean(fileUploadErrorMessage) && fileUpload" class="upload-error-container">
      <img src="@/app/assets/images/error.svg" alt="error!" />
      <div class="upload-error">
        {{ fileUploadErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import cbClient from "@/app/utils/common/core/cb-client";
import {Api} from "@/app/utils/common/constants";
import {FileUploadStatus, ResourceType} from "@/api/paymentApi";
import files from "@/api/files";
import axios from "axios";
import {
  convertFileSizeToNearestUnit,
} from "@/app/utils/common/functions/fileUtils";


export default {
  name: "UploadBox",
  props: {
    uploadType: {
      type: String,
      required: true
    },
    fileError: Boolean,
    uploadComplete: {
      type: Function,
      required: true
    },
    failureCode: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    active: false,
    dropzoneFile: "",
    fileName: "",
    fileSize: 0,
    fileNotation: "",
    fileUpload: false,
    fileUploadErrorMessage: "",
    uploadPercentage: 0,
    disableInteraction: false,
    uploadInProgress: false,
    uploadCancelToken: null,
    fileId: null,
  }),
  computed: {
    ...mapGetters('app', ['getMerchantId', 'getSessionPayfac']),
    ...mapGetters('onboarding', ['getMerchantUploadedDocuments']),
  },
  methods: {
    ...mapActions('onboarding', ['clearMerchantUploadedDocuments']),
    toggleActive() {
      this.active = !this.active;
    },
    drop(e) {
      this.toggleActive();
      this.dropzoneFile = e.dataTransfer.files[0];
      this.fileProcess(e);
    },
    selectedFile() {
      this.dropzoneFile = document.querySelector(`.${this.uploadType}`).files[0];
      this.fileProcess();
    },
    removeFile() {
      this.fileUpload = false;
      this.dropzoneFile = "";
      this.fileUploadErrorMessage = null;
      this.clearMerchantUploadedDocuments([]);
    },
    setFileSize(sizeInBytes) {
      let {fileSize, fileNotation} = convertFileSizeToNearestUnit(sizeInBytes)
      this.fileSize = fileSize;
      this.fileNotation = fileNotation;
    },
    validateFileName() {
      if (!["jpeg", "jpg", "png", "tiff", "pdf"].some(s => this.fileName.endsWith(s))) {
        this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.unsupportedFormat');
        return;
      }
      if(this.fileName.length > 50) {
        this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.fileNameSize');
        return;
      }
      const FILE_NAME_REGEX = /^[a-zA-Z0-9_\- ]+(\.[a-zA-Z0-9_\- ]+)?$/;
      if (!FILE_NAME_REGEX.test(this.fileName)) {
        this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.fileName');
      }
    },
    validateFileSize(sizeInBytes) {
      let sizeInKb = sizeInBytes / 1000;
      let sizeInMb = sizeInBytes / 1000000;
      if (this.fileName?.endsWith("pdf")) {
        if (!(sizeInKb >= 1 && sizeInMb <= 10)) {
          this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.pdfFileSize', {size: this.fileSize + this.fileNotation})
        }
        return;
      }

      if (!(sizeInKb >= 100 && sizeInMb <= 10)) {
        this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.fileSize', {size: this.fileSize + this.fileNotation});
      }
    },
    async fileProcess() {
      this.fileUpload = true;
      this.dropzoneFile = new File([this.dropzoneFile], this.dropzoneFile.name.toLowerCase(), {type: this.dropzoneFile.type});
      this.fileName = this.dropzoneFile.name;
      const size = this.dropzoneFile.size;
      this.setFileSize(size);
      this.validateFileName();
      this.validateFileSize(size)
      if (this.fileUploadErrorMessage) {
        return
      }
      this.disableInteraction = true;
      this.uploadInProgress = true;

      switch (this.getSessionPayfac) {
        case "ADYEN":
            try {
              let preSignedUrl = await this.getPreSignedUrl(this.fileName);
              this.fileId = preSignedUrl.id;
              this.uploadCancelToken = axios.CancelToken.source();
              await this.uploadFile(preSignedUrl);
              await this.addToMerchantUploadedDocuments(this.fileId, FileUploadStatus.REQUIRESUPLOAD, this.uploadType, this.failureCode.split('-')[0]);
              this.uploadComplete()
              this.uploadInProgress = false
              this.disableInteraction = false
          } catch (e) {
              this.disableInteraction = false
              if (e.status === 413) {
                this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.sizeExceeded');
              } else {
                this.fileUploadErrorMessage = e?.source?.detail || this.$t('uploadDocument.fileUploadErrors.fileUploadFailed');
              }
              this.uploadInProgress = false
          }
          break;
        case "FINIX":
          cbClient.postMultipart(Api.uploadApi(this.getMerchantId), null, {
                file_type: this.uploadType,
                file: this.dropzoneFile,
              }, {},
              {
                onUploadProgress: function (progressEvent) {
                  this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(this),
              },
          ).then(() => {
            this.uploadComplete()
            this.uploadInProgress = false
            this.disableInteraction = false
          }).catch((err) => {
            this.disableInteraction = false
            if (err.status === 413) {
              this.fileUploadErrorMessage = this.$t('uploadDocument.fileUploadErrors.sizeExceeded');
            } else {
              this.fileUploadErrorMessage = err?.source?.detail || this.$t('uploadDocument.fileUploadErrors.fileUploadFailed')
            }
            this.uploadInProgress = false
          });
          break;

      }
    },
    uploadFile: async function (preSignedUrl) {
      await axios.put(preSignedUrl.url, this.dropzoneFile, {
        headers: {'Content-Type': 'application/octet-stream'},
        cancelToken: this.uploadCancelToken.token,
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        }.bind(this),
      });
    },
    getPreSignedUrl: async function (fileName) {
      return await files.getPreSignedURLForFileUpload(this.getMerchantId, {
        resource_type: ResourceType.MERCHANT,
        resource_id: this.getMerchantId,
        file_name: fileName,
      });
    },
    addToMerchantUploadedDocuments: async function (id, fileStatus, fileType, reference) {
      let existingDocuments = this.getMerchantUploadedDocuments.slice(); // Make a shallow copy of the array
      existingDocuments.push({ id:id, status: fileStatus, file_type: fileType, reference: reference});
      await this.clearMerchantUploadedDocuments(existingDocuments);
    }
  },
}
</script>

<style lang="scss">

.upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px dashed $neutral_400;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 28px 50px;
  transition: .3s ease all;
  min-height: 168px;
  position: relative;
  cursor: pointer;
  max-width: 320px;
}

.upload-box-heading {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $neutral_800;
  margin: 15px 0;
  transition: .3s ease all;
}

input[type="file"] {
  opacity: 0.0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.active-dropzone {
  background: lightgrey;
  border: 1px dashed #000000;
  color: #ffffff;
}

.disable-interaction {
  pointer-events: none;
  cursor: not-allowed;
}

.custom-file-upload {
  text-decoration: underline;
  color: #346DEC;
  cursor: pointer;
}

.upload-box-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: $neutral_400;
  display: flex;
}

.uploaded-header-container {
  display: flex;
  flex-direction: row;
  width: 70%;
  padding: 5px 5px 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: space-between;
}

.uploaded-header {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: $neutral_800;
  margin: 15px 0;
  width: 75%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.remove-file {
  color: $neutral_400;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
.upload-in-progress {
  color: $neutral_400;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  font-style: italic;
}

.uploaded-sub {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $neutral_400;
  margin: 5px 0;
  width: 100%;
}

.uploaded {
  padding: 28px 10px;
  transition: .3s ease all;
}

.progress-bar-file {
  border: 2px solid #10B981;
  align-self: start;
}

.upload-error-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.upload-error {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #EF4444;
  margin-left: 10px;
}

.error-file {
  border: 2px solid #EF4444;
}
</style>