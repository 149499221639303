<template>
  <div class="settlement-info" v-if="settlement !== null">
    <div class="line1">
      <div class="transaction-type">
        <div class="icon">
          <DepositIcon />
        </div>
        {{ debitOrCredit() }}
      </div>
    </div>

    <div class="line2">
      <div class="amount" v-if="getDepositAmount()">
        <div class="value">
          {{ getDepositAmount() }}
        </div>
        <div class="currency-code">
          {{ settlement.currency }}
        </div>
      </div>
      <DepositStatusTag :settlement="settlement" />
    </div>

    <div class="divider"></div>

    <div class="row">
      <FieldValue
        :field-name="$t('fieldNames.depositId')"
        :field-value="getMerchantSettlementDeposit(settlement).id"
        :show-copy-value="true"
      />
    </div>
  </div>
</template>
<script>
import Formatter from "@/app/utils/common/functions/formatter";
import FieldValue from "@/components/common/FieldValue.vue";
import {DepositSubType, SettlementDto} from "@/api/paymentApi";
import DepositIcon from "@/components/icons/DepositIcon";
import DepositStatusTag from "@/components/settlements/DepositStatusTag";

export default {
  name: "TransactionInfo",
  components: {DepositStatusTag, DepositIcon, FieldValue},
  props: {
    settlement: {
      type: SettlementDto,
      required: true,
    },
  },
  methods: {
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    debitOrCredit() {
      return this.getMerchantDepositAmount() < 0 ? "DEBIT" : "CREDIT";
    },
    getAmount() {
      return this.format('currency', this.settlement.net_amount, "", this.settlement.currency)
    },
    getMerchantSettlementDeposit(settlement) {
      return settlement.deposits.find(d => d.subType === DepositSubType.MERCHANT) || {}
    },
    getMerchantDepositAmount() {
      return this.getMerchantSettlementDeposit(this.settlement).type === 'DEBIT'
          ? this.getMerchantSettlementDeposit(this.settlement).amount * (-1)
          : this.getMerchantSettlementDeposit(this.settlement).amount;
    },
    getDepositAmount() {
      if (this.getMerchantDepositAmount() === undefined) {
        return null;
      }
      return Formatter.format({
        type: 'currency',
        data: this.getMerchantDepositAmount(),
        currencyCode: this.settlement.currency,
      })
    },
  },
}
</script>
<style lang="scss">

.settlement-info {

  & .line2 {
    display: flex;
    align-items: center;
    margin-top: 9px;

    & .empty-placeholder {
      color: #D4D4E8;
    }

    & .amount {
      font-size: 24px;
      display: flex;
      align-items: center;
      margin-right: 12px;

      & .value {
        font-weight: $weight_bold;
        margin-right: 4px;
      }

      & .currency_code {
        font-weight: $weight_normal;
      }
    }
  }

  & .field {
    width: fit-content;
    text-align: left;

    & .field-label {
      font-size: 12px;
      color: #64648C;
      margin-bottom: 4px;
    }

    & .field-value {
      & .icon {
        cursor: pointer;
      }
    }
  }

  & .divider {
    width: 100%;
    background: #ECECF8;
    height: 1px;
    margin-top: 16px;
  }

  & .row {
    display: flex;
    justify-content: space-between;
    & div {
      flex: 1;
    }
  }

  & .line1 {
    display: flex;

    & .info-icon {
      margin-left: 8px;
    }

    & .transaction-type {
      background-color: $neutral_50;
      width: fit-content;
      border: 1px solid $neutral_200;
      border-radius: 4px;
      padding: 1px 4px;
      font-size: 12px;
      font-weight: $weight_bold;
      display: flex;
      align-items: center;
      color: $neutral_800;
      line-height: 18px;
      gap: 5px;

      & .icon {
        display: flex;
        align-items: center;

        & svg {
          color: $neutral_600;
          width: 20px;
          height: 20px;
        }
      }
    }
  }


}
</style>


