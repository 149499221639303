var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.complianceForm != null)?_c('div',{staticClass:"status",class:{
    'incomplete-status': _vm.complianceForm.compliance_form_status === 'INCOMPLETE',
    'complete-status': _vm.complianceForm.compliance_form_status === 'COMPLETE',
    'overdue-status': _vm.complianceForm.compliance_form_status === 'OVERDUE',
    'invalid-status': _vm.complianceForm.compliance_form_status === 'INVALID',
    'expired-status': _vm.complianceForm.compliance_form_status === 'EXPIRED',
    'progress-status': _vm.inProgressStatus
  }},[_vm._v(" "+_vm._s(_vm.getComplianceFormStatus)+" ")]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }