import { render, staticRenderFns } from "./DownloadsIcon.vue?vue&type=template&id=03fb1a44&"
import script from "./DownloadsIcon.vue?vue&type=script&lang=js&"
export * from "./DownloadsIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports