<template>
  <svg width="18" height="20" viewBox="0 0 18 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8001 2.80039C1.8001 1.47491 2.87461 0.400391 4.2001 0.400391H13.8001C15.1256 0.400391 16.2001 1.47491 16.2001 2.80039V17.2004C16.8628 17.2004 17.4001 17.7376 17.4001 18.4004C17.4001 19.0631 16.8628 19.6004 16.2001 19.6004H12.4001C11.8478 19.6004 11.4001 19.1527 11.4001 18.6004V16.0004C11.4001 15.3376 10.8628 14.8004 10.2001 14.8004H7.8001C7.13736 14.8004 6.6001 15.3376 6.6001 16.0004V18.6004C6.6001 19.1527 6.15238 19.6004 5.6001 19.6004H1.8001C1.13736 19.6004 0.600098 19.0631 0.600098 18.4004C0.600098 17.7376 1.13736 17.2004 1.8001 17.2004V2.80039ZM5.4001 4.00039H7.8001V6.40039H5.4001V4.00039ZM7.8001 8.80039H5.4001V11.2004H7.8001V8.80039ZM10.2001 4.00039H12.6001V6.40039H10.2001V4.00039ZM12.6001 8.80039H10.2001V11.2004H12.6001V8.80039Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'OfficeBuildingIcon',
}
</script>