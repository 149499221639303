import {CallbackParams} from "@/app/utils/common/constants";
import axios from "axios";
import * as Sentry from "@sentry/vue";

export const epochToyyyyMMdd = (epoch) => {
  return msToyyyyMMdd(epoch * 1000)
}

export const msToyyyyMMdd = (ms) => {
  const d = new Date(ms);
  const year = d.getFullYear();
  const month = ("00" + (d.getMonth() + 1)).slice(-2);
  const date = ("00" + d.getDate()).slice(-2);
  return `${year}-${month}-${date}`;
}

// validates to yyyy-mm-dd
export const isValidDate = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!dateString.match(regEx)) return false;  // Invalid format
  const date = new Date(dateString);
  const dateInMs = date.getTime();
  if(!dateInMs && dateInMs !== 0) return false; // NaN value, Invalid date
  return date.toISOString().startsWith(dateString);
}

export const formatPhoneNumber = (phone) => {
  if (phone === undefined || phone === null) return phone;
  const x = phone.toString()
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

  if (x === undefined) return phone;
  if (!x[2]) {
    return x[1];
  } else {
    const firstPart = `(${x[1]}) `;
    const secondPart = x[2];
    const thirdPart = x[3] ? `-${x[3]}` : "";
    return firstPart + secondPart + thirdPart;
  }
};

export const unFormatPhoneNumber = (phone) => {
  if (phone === undefined || phone === null) return phone;
  return phone.replace("(", "")
      .replace(")", "")
      .replace(" ", "")
      .replace("-", "");
}

export const formatZip = (zip) => {
  if (zip === undefined || zip === null) return zip;
  const x = zip.toString()
      .replace(/\D/g, "")
      .match(/(\d{0,5})(\d{0,4})/);

  if (x === undefined) return zip;
  if (!x[2]) {
    return x[1];
  } else {
    return `${x[1]}-${x[2]}`
  }
}

export const formatEin = (ein) => {
  if (ein === undefined || ein === null) return ein;
  const x = ein.toString()
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,7})/);

  if (x === undefined) return ein;
  if (!x[2]) {
    return x[1];
  } else {
    return `${x[1]}-${x[2]}`
  }
}

export const unHyphenify = (value) => {
  if (value === undefined || value === null) return value;
  return value.replaceAll('-', '');
}
export const maskTaxId = (taxId, item) => {
  if (item?.ownerType === undefined || item.ownerType.value !== 'ENTITY') {
    return "xxx-xx-" + taxId.slice(-4);
  }
  return "xx-xxx" + taxId.slice(-4);
}

export const isIPad = () => {
  return !!(/iPad/.exec(navigator.userAgent));
};

export const isIPhone = () => {
  return !!(/iPhone/.exec(navigator.userAgent));
};

const generateApplicationCallbackUrl = (callbackUrl, merchantId, draftMerchantId) => {
    const url = new URL(callbackUrl);
    if (merchantId) {
        url.searchParams.append(CallbackParams.merchantId, merchantId);
    }
    if (draftMerchantId) {
        url.searchParams.append(CallbackParams.draftMerchantId, draftMerchantId);
    }
    Sentry.captureMessage(url.toString());
    return url.toString()
}

export const callbackApplication = (callbackUrl, merchantId, draftMerchantId) => {
  window.open(generateApplicationCallbackUrl(callbackUrl, merchantId, draftMerchantId), "_self")
}

export const callbackApplicationAjax = (callbackUrl, merchantId, draftMerchantId) => {
    axios.get(generateApplicationCallbackUrl(callbackUrl, merchantId, draftMerchantId))
}

export function deleteEmptyAttributes(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

export function getName(firstName, lastName) {
  if (!firstName && !lastName) {
    return null
  }
  return (firstName || '') + ' ' + (lastName || '')
}

export function isNullOrEmpty(obj): boolean {
  if (obj === null || obj === undefined) return true
  if (typeof obj === "string") {
    return obj === "";
  }
  if (typeof obj === "object") {
    return Object.keys(obj).length === 0;
  }
  return false;
}