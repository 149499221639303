<template>
  <div class="aud-container">
    <div class="header">
      {{ $t('additionalUnderwritingData.title') }}
    </div>
    <div class="subtitle">
      {{ $t('additionalUnderwritingData.subtitle') }}
    </div>
    <div class="separator"></div>

    <InputField
      :label="$t('additionalUnderwritingData.billingDescriptor.label')"
      :value="billingDescriptor.value"
      :error="errors.billingDescriptor"
      :set-value="setBillingDescriptor"
      id="billing-descriptor"
      :hint="$t('additionalUnderwritingData.billingDescriptor.instruction',
                {min: getMin(billingDescriptor.apiPath), max: getMax(billingDescriptor.apiPath)})"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import InputField from "@/components/common/InputField";
import validations from "@/app/validators/api/openapi";

export default {
  name: 'AdditionalUnderwritingData',
  components: {InputField},
  computed: {
    ...mapState({
      api: state => state.additionalUnderwritingData.api,
      errors: state => state.additionalUnderwritingData.errors,
      billingDescriptor: state => state.additionalUnderwritingData.data.billingDescriptor,
    }),
  },
  methods: {
    ...mapActions('additionalUnderwritingData', {
      setBillingDescriptor: 'updateBillingDescriptor',
    }),
    getMax(key) {
      return validations[this.api][key].length.maximum;
    },
    getMin(key) {
      return validations[this.api][key].length.minimum;
    },
  }
}
</script>

<style lang="scss" scoped>
.aud-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $primary_text;
    text-align: left;
    margin-bottom: 8px;
  }

  & .subtitle {
    font-size: 12px;
    color: $neutral_600;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }


  & .refund-row {
    display: flex;
    width: 100%;
    gap: 25px;
    justify-content: space-between;

    & .select-field {
      flex: 0 0 48%;
    }
  }
  #transaction-amount {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    & .field {
      width: inherit;
      min-width: 270px;
      max-width: 400px;
      flex: 0 1 48%;
    }
  }
}


</style>

<style src="../../../node_modules/v-calendar/lib/v-calendar.min.css"/>