<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle v-if="stepBullet" cx="10" cy="10" r="8" fill="#D6DADF" />
    <circle v-if="stepBullet" cx="10" cy="10" r="4" fill="#2D6A8A" />
    <circle v-if="!stepBullet" cx="10" cy="10" r="4" fill="#D6DADF" />
  </svg>
</template>

<script>
export default {
  name: "BulletIcon",
  props: {
    stepBullet: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>