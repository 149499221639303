<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.9333 12H1.06667C0.477563 12 0 12.4776 0 13.0667V26.6907C0 27.2798 0.477563 27.7573 1.06667 27.7573H38.9333C39.5224 27.7573 40 27.2798 40 26.6907V13.0667C40 12.4776 39.5224 12 38.9333 12Z" fill="#00425F" />
    <path d="M9.25335 25.3334C12.4198 25.3334 14.9867 22.7665 14.9867 19.6C14.9867 16.4336 12.4198 13.8667 9.25335 13.8667C6.08692 13.8667 3.52002 16.4336 3.52002 19.6C3.52002 22.7665 6.08692 25.3334 9.25335 25.3334Z" fill="#EE312A" />
    <path d="M9.25338 22.0461C7.97168 19.1407 7.03177 16.4268 7.03177 16.4268H5.06671C5.06671 16.4268 6.26275 19.9103 8.48436 24.2668H10.0224C12.244 19.9103 13.44 16.4268 13.44 16.4268H11.475C11.475 16.4268 10.5351 19.1407 9.25338 22.0461Z" fill="white" />
    <path d="M36.6461 21.6822H32.8168C32.8168 21.6822 32.9019 22.969 34.6038 22.969C35.4547 22.969 36.3057 22.7114 36.3057 22.7114L36.4759 24.0837C36.4759 24.0837 35.6249 24.4267 34.4336 24.4267C32.7317 24.4267 31.2 23.5691 31.2 21.1677C31.2 19.2808 32.3913 18.0801 34.0932 18.0801C36.6461 18.0801 36.8163 20.6531 36.6461 21.6822ZM34.0081 19.2808C32.9019 19.2808 32.8168 20.4815 32.8168 20.4815H35.1994C35.1994 20.4815 35.1144 19.2808 34.0081 19.2808Z" fill="white" />
    <path d="M23.4248 19.6599L23.68 18.2949C23.68 18.2949 21.7089 17.6951 20.1067 18.8067V24.2666H21.8084L21.8082 19.8305C22.4888 19.3186 23.4248 19.6599 23.4248 19.6599Z" fill="white" />
    <path d="M18.7794 21.6822H14.9502C14.9502 21.6822 15.0353 22.969 16.7372 22.969C17.5881 22.969 18.439 22.7114 18.439 22.7114L18.6092 24.0837C18.6092 24.0837 17.7583 24.4267 16.567 24.4267C14.8651 24.4267 13.3334 23.5691 13.3334 21.1677C13.3334 19.2808 14.5247 18.0801 16.2266 18.0801C18.7794 18.0801 18.9496 20.6531 18.7794 21.6822ZM16.1415 19.2808C15.0353 19.2808 14.9502 20.4815 14.9502 20.4815H17.3328C17.3328 20.4815 17.2478 19.2808 16.1415 19.2808Z" fill="white" />
    <path d="M27.5733 22.2192C27.0428 20.9286 26.6148 19.5981 26.2933 18.2402L24.5867 18.2405C24.5867 18.2405 25.44 21.5361 26.8908 24.2669H28.2558C29.7067 21.5361 30.56 18.2411 30.56 18.2411H28.8534C28.5318 19.5987 28.1038 20.9288 27.5733 22.2192Z" fill="white" />
  </svg>
</template>
<script>
export default {
  name: 'VerveIcon',
}
</script>