<template>
  <div id="app">
    <keep-alive include="DisputeView,TransactionView,DepositView,AuthorizationView">
      <router-view v-if="!loading" :key="$route.fullPath" @preLoaderStatusMessage="(obj) => preLoaderStatus = obj" />
    </keep-alive>
    <AppPreLoader 
      :show-pre-loader="loading || !preLoaderTimeout || preLoaderStatus.keepPreLoaderOpen"
      :loading-message="preLoaderStatus.loadingMessage"
    />
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {getAndStoreSession} from "@/service/sessionService";
import {navigateTo} from "@/router";
import SentryWrapper from '@/app/utils/sentry';
import FullStoryHelper from '@/app/utils/fullstory';
import PendoHelper from '@/app/utils/pendo';
import { UI_INITIALIZE } from '@/app/utils/fullstory/constants';
import {isNullOrEmpty} from "@/app/utils/common/functions/functions";
import {CbStorage} from "@/app/utils/common/constants";
import {RouteConstants} from "@/router/routeConstants";
import AppPreLoader from "@/components/common/AppPreLoader.vue";

export default {
  name: "App",
  components: {AppPreLoader},
  data: () => ({
    loading: true,
    preLoaderTimeout: false,
    preLoaderStatus: {loadingMessage: null, keepPreLoaderOpen: false},
  }),
  computed: {
    ...mapGetters('app', ['getMerchantId', 'getExternalId', 'getMerchantName']),
  },
  async mounted() {
    this.isAdminViewPage();
    setTimeout(() => {
      this.preLoaderTimeout = true;
    }, 2000);
    if (this.shouldSkipSessionSetup()) {
      this.loading = false;
      return;
    }
    this.storeClientIpAddress();

    let hashParams = this.parseRouterHash();
    this.storeTokenFromParams(hashParams);

    try{
      await getAndStoreSession()
      this.loading = false
      if (!isNullOrEmpty(hashParams)) {
        await this.$router.replace({hash: '', query: this.$route.query});
      }
      this.initializeTools();
    }
    catch (e) {
      if (e.name !== 'NavigationDuplicated') {
        this.gotoErrorPage();
      }
    }
  },
  methods: {
    ...mapActions('app', ['storeClientIpAddress']),
    storeTokenFromParams(hashParams) {
      let token = hashParams.token;
      if (token) {
        window.cbStorage.setItem(CbStorage.JwtToken, token)
      } else {
        token = window.cbStorage.getItem(CbStorage.JwtToken);
      }

      if(!token) {
        this.gotoErrorPage();
      }
    },
    shouldSkipSessionSetup() {
      return this.$route.name === RouteConstants.ERROR
          || this.$route.name === RouteConstants.DEFAULT
          || this.$route.name === RouteConstants.DUPLICATE
          || this.$route.path.startsWith("/admin")
          || this.$route.query.adminMode === "true";
    },
    initializeTools() {
      if(process.env.VUE_APP_BUILD_ENV === "local") {
        return;
      }
      SentryWrapper.init({
        config: {
          dsn: "https://56cdb78d79f7483890409e9ed32d12d6@o252526.ingest.sentry.io/6491904",
          environment: process.env.VUE_APP_BUILD_ENV,
          merchantId: this.getMerchantId,
          externalId: this.getExternalId,
          merchantName: this.getMerchantName
        }
      });

      FullStoryHelper.enable({
        merchantId: this.getMerchantId,
        externalId: this.getExternalId,
        merchantName: this.getMerchantName
      });
      FullStoryHelper.event(UI_INITIALIZE);
      const pendoToken = process.env.VUE_APP_PENDO_TOKEN;
      PendoHelper.init(this.getExternalId, process.env.VUE_APP_BUILD_ENV, pendoToken);
    },
    gotoErrorPage() {
      this.loading = false
      if(this.$route.name !== RouteConstants.ERROR) {
        navigateTo(RouteConstants.ERROR);
      }
    },
    parseRouterHash() {
      const parsedParams = {};
      this.$route.hash?.split('&')
        .map(part => part.replace(/^#/, ''))
        .forEach(param => {
          const parts = param.split('=');
          parsedParams[parts[0]] = decodeURIComponent(parts[1]);
        });
      return parsedParams;
    },
    isAdminViewPage() {
      //This is for keeping the pre-loader on motion with status message on Admin page
      if(this.$route.path != null && this.$route.path.startsWith("/admin")) {
        this.preLoaderStatus = {loadingMessage: this.$t('landing.loggingIn'), keepPreLoaderOpen: true}
      }
    }
  }
}
</script>
<style lang="scss">
.c-modal {
  & .c-modal__doable{
    & .c-button, .c-button__label {
      display: inline-block;
      margin: 0;
      padding: 0 !important;
      text-align: center;
    }
  }
}
</style>

