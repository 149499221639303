<template>
  <div class="bap-container">
    <div class="header-add-bank-acc" v-if="addBankAccount">
      {{ $t('bankAccountForPayouts.action.addBankAccount') }}
    </div>
    <div v-else>
      <div class="header">
        {{ $t('bankAccountForPayouts.title') }}
      </div>
      <div class="subtitle">
        {{ $t('bankAccountForPayouts.subtitle') }}
      </div>
    </div>
    <div class="separator"></div>


    <InputField
      v-if="shouldShowApiField('name')"
      :label="$t('bankAccountForPayouts.accountHolderName.label')"
      :value="name"
      id="account-holder-name"
      data-testid="account-holder-name"
      :set-value="setName"
      :error="errors.name"
      :hint="`${$t('bankAccountForPayouts.accountHolderName.instruction')}`"
    />
    <InputField
      :label="$t('bankAccountForPayouts.' + accountNumberField + '.label')"
      :value="getCountrySpecificBankAccountApiFields[accountNumberField].value"
      type="password"
      id="account-number"
      data-testid="account-number"
      :error="errors[accountNumberField]"
      :set-value="(event) => setCountrySpecificBankField({'field': accountNumberField, event})"
      class="fs-mask"
      v-if="!readOnly && (shouldShowApiField('accountNumber') || shouldShowApiField('iban'))"
    />
    <InputField
      :label="$t('bankAccountForPayouts.reenterAccountNumber.' + accountNumberField + '.label')"
      :value="reenterAccountNumber"
      :error="errors.reenterAccountNumber"
      :block-copy-paste="true"
      id="reenter-account-number"
      data-testid="reenter-account-number"
      :set-value="setReenterAccountNumber"
      class="fs-mask"
      v-if="!readOnly && shouldShowApiField('reenterAccountNumber')"
    />
    <InputField
      :label="$t('bankAccountForPayouts.' + bankCodeField + '.label')"
      :value="getCountrySpecificBankAccountApiFields[bankCodeField].value"
      :set-value="(event) => setCountrySpecificBankField({'field': bankCodeField, event})"
      id="bank-code"
      data-testid="bank-code"
      :error="errors[bankCodeField]"
      :hint="$t('bankAccountForPayouts.' + bankCodeField + '.instruction')"
      class="fs-mask"
      v-if="!readOnly && (shouldShowApiField('bankCode') || shouldShowApiField('sortCode'))"
    />

    <div v-if="shouldShowApiField('accountType')" id="account-type">
      <SelectField
        :label="$t('bankAccountForPayouts.accountType.label')"
        :searchable="false"
        :clearable="true"
        :value="accountType"
        :options="getAccountTypes()"
        :error="errors.accountType"
        select-classname="account-type-selection"
        data-testid="account-type-selection"
        :on-update="setAccountType"
        :hint="$t('bankAccountForPayouts.accountType.instruction')"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import SelectField from "@/components/common/SelectField.vue";
import InputField from "@/components/common/InputField";

export default {
  name: 'BankAccountForPayout',
  components: {SelectField, InputField},
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    addBankAccount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters('bankAccountForPayouts', ['getAccountTypeError', 'getCountrySpecificBankAccountApiFields', 'shouldShowApiField']),
    ...mapGetters('businessAddress', ['getCountry']),
    ...mapState({
      errors: state => state.bankAccountForPayouts.errors,
      name: state => state.bankAccountForPayouts.data.name.value,
      nameApiPath: state => state.bankAccountForPayouts.data.name.apiPath,
      accountType: state => state.bankAccountForPayouts.data.accountType.value,
      reenterAccountNumber: state => state.bankAccountForPayouts.data.reenterAccountNumber.value,
    }),
    accountNumberField() {
      return this.shouldShowApiField('accountNumber') ? 'accountNumber' : 'iban';
    },
    bankCodeField() {
      return this.shouldShowApiField('bankCode') ? 'bankCode' : 'sortCode';
    },
  },
  mounted() {
    if (this.addBankAccount) {
      this.resetName(null);
      this.resetCountrySpecificBankField({'field': 'accountNumber','value': null});
      this.resetCountrySpecificBankField({'field': 'iban','value': null});
      this.resetCountrySpecificBankField({'field': 'bankCode','value': null});
      this.resetCountrySpecificBankField({'field': 'sortCode','value': null});
      this.resetAccountType(null);
      this.resetReenterAccountNumber(null);
    }
  },
  methods: {
    ...mapActions('bankAccountForPayouts', {
      setName: 'updateName',
      setAccountType: 'updateAccountType',
      setReenterAccountNumber: 'updateReenterAccountNumber',
      setCountrySpecificBankField : 'updateCountrySpecificBankField'
    }),
    ...mapMutations('bankAccountForPayouts', {
      resetName: 'updateName',
      resetAccountType: 'updateAccountType',
      resetReenterAccountNumber: 'updateReenterAccountNumber',
      resetCountrySpecificBankField: 'updateCountrySpecificBankField'
    }),
    getAccountTypes: () => ([
      {
        displayText: "Savings",
        value: "SAVINGS"
      },
      {
        displayText: "Checking",
        value: "CHECKING"
      }
    ]),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.bap-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $primary_text;
    text-align: left;
    margin-bottom: 8px;
  }

  & .header-add-bank-acc {
    font-size: 20px;
    font-weight: $weight_medium;
    color: $neutral_800;
    margin-bottom: 0;
    text-align: left
  }

  & .subtitle {
    font-size: 12px;
    color: $neutral_600;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }

  #transaction-amount {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    & .field {
      width: inherit;
      min-width: 270px;
      max-width: 400px;
      flex: 0 1 48%;
    }
  }

  #account-type {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    & .field {
      width: inherit;
      min-width: 270px;
      max-width: 400px;
      flex: 0 1 58%;
    }
  }
}

</style>

<style src="../../../node_modules/v-calendar/lib/v-calendar.min.css"/>