<template>
  <div class="add-funds-initiated-overview">
    <div class="add-funds-initiated-header">
      <img src="@/app/assets/images/green-tick.svg" alt="green-tick-icon" />
      <span> {{ $t('transactions.transactionInitiated') }}</span>
    </div>
    <div class="add-funds-initiated-data-container">
      <div class="add-funds-initiated-disclaimer">
        {{ $t('transactions.message.TRANSACTION_INITIATED') }}
      </div>
      <FieldValue
        class-name="add-funds-initiated-transaction-view"
        :field-name="$t('fieldNames.transactionId')"
        :field-value="transactionId"
        :show-copy-value="true"
      />
    </div>
    <div class="buttons-container">
      <c-button variant="action" @click="navigateTo('transaction_details')">
        {{ $t('transactions.actions.seeTransactionDetails') }}
      </c-button>
      <c-button variant="primary" @click="onDismiss">
        {{ $t('global.action.okay') }}
      </c-button>
    </div>
  </div>
</template>

<script>

import {navigateWithParam} from "@/router";
import FieldValue from "@/components/common/FieldValue.vue";

export default {
  name: 'AddFundsInitiatedPopup',
  components: {FieldValue},
  props: {
    transactionId: {
      type: String,
      required: true
    },
    onDismiss: {
      type: Function,
      required: true
    }
  },
  methods: {
    navigateTo(route) {
      navigateWithParam(route, {transactionId: this.transactionId},
          this.$route.query);
    },
  }
}
</script>

<style lang="scss">

.add-funds-initiated-overview {
  padding: 24px 32px;
  text-align: left;

  & .add-funds-initiated-header {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: 20px;
    font-weight: $weight_medium;
    color: $neutral_800;
    margin-bottom: 24px;
  }

  & .add-funds-initiated-data-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .add-funds-initiated-disclaimer {
      font-size: 12px;
      font-weight: $weight_normal;
      color: $neutral_800;
    }
    & .add-funds-initiated-transaction-view {
      & .field {
        display: flex;
        align-items: start;
        & .field-value {
          font-size: 14px;
          color: $neutral_800;
        }
        & .field-label {
          display: block;
          font-size: 14px;
          font-weight: $weight_normal;
          margin-right: 8px;
        }
      }
    }
  }

  & .buttons-container {
    margin-top: 40px;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 24px;

    & .c-button {
      margin-bottom: 0 !important;
    }

    & .c-button__label {
      padding: 0;
    }

    & .c-button--primary {
      margin-bottom: 0;
      width: 140px;
    }

    & .c-button--action:disabled {
      color: $neutral_300;
    }
  }
}
</style>