class CbStorage {
    localStorageEnabled: boolean;
    
    constructor() {
        try {
          window.localStorage.getItem("check");
          this.localStorageEnabled = true;
          return;
        } catch (error) {
          this.localStorageEnabled = false;
        }
    }

    setItem(key: string, value: any) {
        if (this.localStorageEnabled && value) {
          try {
            window.localStorage.setItem(key, value);
          } catch (error) {
            this.localStorageEnabled = false;
          }
        }
      }
    
    getItem(key: string) {
        if (this.localStorageEnabled) {
            return window.localStorage.getItem(key);
        }
    }

    clear() {
        window.localStorage.clear()
    }

    removeItem(key: string) {
        if (this.localStorageEnabled) {
            window.localStorage.removeItem(key);
        }
    }
}

export default new CbStorage();