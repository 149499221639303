const MccCodes = [
    {"value":"0742", "displayText":"0742 - Veterinary Services"},
    {"value":"0763", "displayText":"0763 - Agricultural Co-operatives"},
    {"value":"0780", "displayText":"0780 - Landscaping and Horticultural Services"},
    {"value":"1520", "displayText":"1520 - General Contractors – Residential and Commercial"},
    {"value":"1711", "displayText":"1711 - Heating, Plumbing, and Air Conditioning Contractors"},
    {"value":"1731", "displayText":"1731 - Electrical Contractors"},
    {"value":"1740", "displayText":"1740 - Masonry, Stonework, Tile Setting, Plastering and Insulation Contractors"},
    {"value":"1750", "displayText":"1750 - Carpentry Contractors"},
    {"value":"1761", "displayText":"1761 - Roofing, Siding, and Sheet Metal Work Contractors"},
    {"value":"1771", "displayText":"1771 - Concrete Work Contractors"},
    {"value":"1799", "displayText":"1799 - Special Trade Contractors (Not Elsewhere Classified)"},
    {"value":"2741", "displayText":"2741 - Miscellaneous Publishing and Printing"},
    {"value":"2791", "displayText":"2791 - Typesetting, Plate Making and Related Services"},
    {"value":"2842", "displayText":"2842 - Specialty Cleaning, Polishing and Sanitation Preparations"},    
    {"value":"4011", "displayText":"4011 - Railroads"},
    {"value":"4111", "displayText":"4111 - Local and Suburban Commuter Passenger Transportation, Including Ferries"},
    {"value":"4112", "displayText":"4112 - Passenger Railways"},
    {"value":"4119", "displayText":"4119 - Ambulance Services"},
    {"value":"4121", "displayText":"4121 - Taxicabs and Limousines"},
    {"value":"4131", "displayText":"4131 - Bus Lines"},
    {"value":"4214", "displayText":"4214 - Motor Freight Carriers and Trucking – Local and Long Distance, Moving and Storage Companies, and Local Delivery Services"},
    {"value":"4215", "displayText":"4215 - Courier Services – Air and Ground, and Freight Forwarders"},
    {"value":"4225", "displayText":"4225 - Public Warehousing and Storage – Farm Products, Refrigerated Goods, Household Goods, and Storage"},
    {"value":"4411", "displayText":"4411 - Steamship and Cruise Lines"},
    {"value":"4457", "displayText":"4457 - Boat Rentals and Leasing"},
    {"value":"4468", "displayText":"4468 - Marinas, Marine Service, and Supplies"},
    {"value":"4511", "displayText":"4511 - Airlines and Air Carriers (Not Elsewhere Classified)"},
    {"value":"4582", "displayText":"4582 - Airports, Flying Fields, and Airport Terminals"},
    {"value":"4722", "displayText":"4722 - Travel Agencies and Tour Operators"},
    {"value":"4723", "displayText":"4723 - Package Tour Operators – Germany Only"},
    {"value":"4784", "displayText":"4784 - Tolls and Bridge Fees"},
    {"value":"4789", "displayText":"4789 - Transportation Services (Not Elsewhere Classified)"},
    {"value":"4812", "displayText":"4812 - Telecommunication Equipment and Telephone Sales"},
    {"value":"4814", "displayText":"4814 - Telecommunication Services, including Local and Long Distance Calls, Credit Card Calls, Calls Through Use of Magnetic-Stripe-Reading Telephones, and Fax Services"},
    {"value":"4816", "displayText":"4816 - Computer Network/Information Services"},
    {"value":"4821", "displayText":"4821 - Telegraph Services"},
    {"value":"4829", "displayText":"4829 - Money Transfer"},
    {"value":"4899", "displayText":"4899 - Cable, Satellite and Other Pay Television/Radio/Streaming Services"},
    {"value":"4900", "displayText":"4900 - Utilities – Electric, Gas, Water, and Sanitary"},
    {"value":"5013", "displayText":"5013 - Motor Vehicle Supplies and New Parts"},
    {"value":"5021", "displayText":"5021 - Office and Commercial Furniture ..."},
    {"value":"5039", "displayText":"5039 - Construction Materials (Not Elsewhere Classified)"},
    {"value":"5044", "displayText":"5044 - Photographic, Photocopy, Microfilm Equipment and Supplies"},
    {"value":"5045", "displayText":"5045 - Computers and Computer Peripheral Equipment and Software"},
    {"value":"5046", "displayText":"5046 - Commercial Equipment (Not Elsewhere Classified)"},
    {"value":"5047", "displayText":"5047 - Medical, Dental, Ophthalmic and Hospital Equipment and Supplies"},
    {"value":"5051", "displayText":"5051 - Metal Service Centers and Offices"},
    {"value":"5065", "displayText":"5065 - Electrical Parts and Equipment"},
    {"value":"5072", "displayText":"5072 - Hardware, Equipment and Supplies"},
    {"value":"5074", "displayText":"5074 - Plumbing and Heating Equipment and Supplies"},
    {"value":"5085", "displayText":"5085 - Industrial Supplies (Not Elsewhere Classified)"},
    {"value":"5094", "displayText":"5094 - Precious Stones and Metals, Watches and Jewelry"},
    {"value":"5099", "displayText":"5099 - Durable Goods (Not Elsewhere Classified)"},
    {"value":"5111", "displayText":"5111 - Stationery, Office Supplies, Printing and Writing Paper"},
    {"value":"5122", "displayText":"5122 - Drugs, Drug Proprietaries, and Druggist Sundries"},
    {"value":"5131", "displayText":"5131 - Piece Goods, Notions, and Other Dry Goods"},
    {"value":"5137", "displayText":"5137 - Men's, Women's, and Children's Uniforms and Commercial Clothing"},
    {"value":"5139", "displayText":"5139 - Commercial Footwear"},
    {"value":"5169", "displayText":"5169 - Chemicals and Allied Products (Not Elsewhere Classified)"},
    {"value":"5172", "displayText":"5172 - Petroleum and Petroleum Products"},
    {"value":"5192", "displayText":"5192 - Books, Periodicals and Newspapers"},
    {"value":"5193", "displayText":"5193 - Florists Supplies, Nursery Stock and Flowers"},
    {"value":"5198", "displayText":"5198 - Paints, Varnishes and Supplies"},
    {"value":"5199", "displayText":"5199 - Nondurable Goods (Not Elsewhere Classified)"},
    {"value":"5200", "displayText":"5200 - Home Supply Warehouse Stores"},
    {"value":"5211", "displayText":"5211 - Lumber and Building Materials Stores"},
    {"value":"5231", "displayText":"5231 - Glass, Paint, and Wallpaper Stores"},
    {"value":"5251", "displayText":"5251 - Hardware Stores"},
    {"value":"5261", "displayText":"5261 - Nurseries and Lawn and Garden Supply Stores"},
    {"value":"5262", "displayText":"5262 - Marketplaces"},
    {"value":"5300", "displayText":"5300 - Wholesale Clubs"},
    {"value":"5309", "displayText":"5309 - Duty Free Stores"},
    {"value":"5310", "displayText":"5310 - Discount Stores"},
    {"value":"5311", "displayText":"5311 - Department Stores"},
    {"value":"5331", "displayText":"5331 - Variety Stores"},
    {"value":"5399", "displayText":"5399 - Miscellaneous General Merchandise"},
    {"value":"5411", "displayText":"5411 - Grocery Stores and Supermarkets"},
    {"value":"5422", "displayText":"5422 - Freezer and Locker Meat Provisioners"},
    {"value":"5441", "displayText":"5441 - Candy, Nut, and Confectionery Stores"},
    {"value":"5451", "displayText":"5451 - Dairy Products Stores"},
    {"value":"5462", "displayText":"5462 - Bakeries"},
    {"value":"5499", "displayText":"5499 - Miscellaneous Food Stores – Convenience Stores and Specialty Markets"},
    {"value":"5511", "displayText":"5511 - Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing"},
    {"value":"5521", "displayText":"5521 - Car and Truck Dealers (Used Only) Sales, Service, Repairs, Parts, and Leasing"},
    {"value":"5532", "displayText":"5532 - Automotive Tire Stores"},
    {"value":"5533", "displayText":"5533 - Automotive Parts and Accessories Stores"},
    {"value":"5541", "displayText":"5541 - Service Stations (With or without Ancillary Services)"},
    {"value":"5542", "displayText":"5542 - Automated Fuel Dispensers"},
    {"value":"5551", "displayText":"5551 - Boat Dealers"},
    {"value":"5552", "displayText":"5552 - Electric Vehicle Charging"},
    {"value":"5571", "displayText":"5571 - Motorcycle Shops and Dealers"},
    {"value":"5592", "displayText":"5592 - Motor Homes Dealers"},
    {"value":"5598", "displayText":"5598 - Snowmobile Dealers"},
    {"value":"5599", "displayText":"5599 - Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers (Not Elsewhere Classified)"},
    {"value":"5611", "displayText":"5611 - Men's and Boys' Clothing and Accessories Stores"},
    {"value":"5621", "displayText":"5621 - Women's Ready-To-Wear Stores .."},
    {"value":"5631", "displayText":"5631 - Women's Accessory and Specialty Shops"},
    {"value":"5641", "displayText":"5641 - Children's and Infants' Wear Stores"},
    {"value":"5651", "displayText":"5651 - Family Clothing Stores"},
    {"value":"5655", "displayText":"5655 - Sports and Riding Apparel Stores"},
    {"value":"5661", "displayText":"5661 - Shoe Stores"},
    {"value":"5681", "displayText":"5681 - Furriers and Fur Shops"},
    {"value":"5691", "displayText":"5691 - Men's and Women's Clothing Stores"},
    {"value":"5697", "displayText":"5697 - Tailors, Seamstresses, Mending, and Alterations"},
    {"value":"5698", "displayText":"5698 - Wig and Toupee Stores"},
    {"value":"5699", "displayText":"5699 - Miscellaneous Apparel and Accessory Shops"},
    {"value":"5712", "displayText":"5712 - Furniture, Home Furnishings, and Equipment Stores, Except Appliances"},
    {"value":"5713", "displayText":"5713 - Floor Covering Stores"},
    {"value":"5714", "displayText":"5714 - Drapery, Window Covering, and Upholstery Stores"},
    {"value":"5718", "displayText":"5718 - Fireplace, Fireplace Screens and Accessories Stores"},
    {"value":"5719", "displayText":"5719 - Miscellaneous Home Furnishing Specialty Stores"},
    {"value":"5722", "displayText":"5722 - Household Appliance Stores"},
    {"value":"5732", "displayText":"5732 - Electronics Stores"},
    {"value":"5733", "displayText":"5733 - Music Stores – Musical Instruments, Pianos, and Sheet Music"},
    {"value":"5734", "displayText":"5734 - Computer Software Stores"},
    {"value":"5735", "displayText":"5735 - Record Stores"},
    {"value":"5811", "displayText":"5811 - Caterers"},
    {"value":"5812", "displayText":"5812 - Eating Places and Restaurants"},
    {"value":"5813", "displayText":"5813 - Drinking Places (Alcoholic Beverages) – Bars, Taverns, Nightclubs, Cocktail Lounges, and Discotheques"},
    {"value":"5814", "displayText":"5814 - Fast Food Restaurants"},
    {"value":"5815", "displayText":"5815 - Digital Goods Media – Books, Movies, Digital artwork/images, Music"},
    {"value":"5816", "displayText":"5816 - Digital Goods – Games"},
    {"value":"5817", "displayText":"5817 - Digital Goods – Applications (Excludes Games)"},
    {"value":"5818", "displayText":"5818 - Digital Goods – Large Digital Goods Merchant"},
    {"value":"5912", "displayText":"5912 - Drug Stores and Pharmacies"},
    {"value":"5921", "displayText":"5921 - Package Stores – Beer, Wine, and Liquor"},
    {"value":"5931", "displayText":"5931 - Used Merchandise and Secondhand Stores"},
    {"value":"5932", "displayText":"5932 - Antique Shops – Sales, Repairs, and Restoration Services"},
    {"value":"5933", "displayText":"5933 - Pawn Shops"},
    {"value":"5935", "displayText":"5935 - Wrecking and Salvage Yards"},
    {"value":"5937", "displayText":"5937 - Antique Reproductions"},
    {"value":"5940", "displayText":"5940 - Bicycle Shops – Sales and Service"},
    {"value":"5941", "displayText":"5941 - Sporting Goods Stores"},
    {"value":"5942", "displayText":"5942 - Book Stores"},
    {"value":"5943", "displayText":"5943 - Stationery Stores, Office and School Supply Stores"},
    {"value":"5944", "displayText":"5944 - Jewelry Stores, Watches, Clocks, and Silverware Stores"},
    {"value":"5945", "displayText":"5945 - Hobby, Toy, and Game Shops"},
    {"value":"5946", "displayText":"5946 - Camera and Photographic Supply Stores"},
    {"value":"5947", "displayText":"5947 - Gift, Card, Novelty and Souvenir Shops"},
    {"value":"5948", "displayText":"5948 - Luggage and Leather Goods Stores"},
    {"value":"5949", "displayText":"5949 - Sewing, Needlework, Fabric and Piece Goods Stores"},
    {"value":"5950", "displayText":"5950 - Glassware/Crystal Stores"},
    {"value":"5960", "displayText":"5960 - Direct Marketing – Insurance Services"},
    {"value":"5962", "displayText":"5962 - Direct Marketing – Travel-Related Arrangement Services"},
    {"value":"5963", "displayText":"5963 - Door-To-Door Sales"},
    {"value":"5964", "displayText":"5964 - Direct Marketing – Catalog Merchant"},
    {"value":"5965", "displayText":"5965 - Direct Marketing – Combination Catalog and Retail Merchant"},
    {"value":"5966", "displayText":"5966 - Direct Marketing – Outbound Telemarketing Merchant"},
    {"value":"5967", "displayText":"5967 - Direct Marketing – Inbound Teleservices Merchant"},
    {"value":"5968", "displayText":"5968 - Direct Marketing – Continuity/Subscription Merchant"},
    {"value":"5969", "displayText":"5969 - Direct Marketing – Other Direct Marketers (Not Elsewhere Classified)"},
    {"value":"5970", "displayText":"5970 - Artist's Supply and Craft Shops"},
    {"value":"5971", "displayText":"5971 - Art Dealers and Galleries"},
    {"value":"5972", "displayText":"5972 - Stamp and Coin Stores"},
    {"value":"5973", "displayText":"5973 - Religious Goods Stores"},
    {"value":"5975", "displayText":"5975 - Hearing Aids – Sales, Service, and Supply"},
    {"value":"5976", "displayText":"5976 - Orthopedic Goods – Prosthetic Devices"},
    {"value":"5977", "displayText":"5977 - Cosmetic Stores"},
    {"value":"5978", "displayText":"5978 - Typewriter Stores – Sales, Rentals, and Service"},
    {"value":"5983", "displayText":"5983 - Fuel Dealers – Fuel Oil, Wood, Coal, and Liquefied Petroleum"},
    {"value":"5992", "displayText":"5992 - Florists"},
    {"value":"5993", "displayText":"5993 - Cigar Stores and Stands"},
    {"value":"5994", "displayText":"5994 - News Dealers and Newsstands"},
    {"value":"5995", "displayText":"5995 - Pet Shops, Pet Foods and Supplies Stores"},
    {"value":"5996", "displayText":"5996 - Swimming Pools – Sales and Service"},
    {"value":"5997", "displayText":"5997 - Electric Razor Stores – Sales and Service"},
    {"value":"5998", "displayText":"5998 - Tent and Awning Shops"},
    {"value":"5999", "displayText":"5999 - Miscellaneous and Specialty Retail Shops"},
    {"value":"6010", "displayText":"6010 - Financial Institutions – Manual Cash Disbursements"},
    {"value":"6011", "displayText":"6011 - Financial Institutions – Automated Cash Disbursements"},
    {"value":"6012", "displayText":"6012 - Financial Institutions – Merchandise, Services, and Debt Repayment"},
    {"value":"6051", "displayText":"6051 - Non-Financial Institutions – Foreign Currency, Non-Fiat Currency (for example: Cryptocurrency), Money Orders (Not Money Transfer), Account Funding (not Stored Value Load), Travelers Cheques, and Debt Repayment"},
    {"value":"6211", "displayText":"6211 - Security Brokers/Dealers"},
    {"value":"6300", "displayText":"6300 - Insurance Sales, Underwriting, and Premiums"},
    {"value":"6513", "displayText":"6513 - Real Estate Agents and Managers"},
    {"value":"6540", "displayText":"6540 - Non-Financial Institutions – Stored Value Card Purchase/Load"},
    {"value":"7011", "displayText":"7011 - Lodging – Hotels, Motels, Resorts, Central Reservation Services (Not Elsewhere Classified)"},
    {"value":"7012", "displayText":"7012 - Timeshares"},
    {"value":"7032", "displayText":"7032 - Sporting and Recreational Camps"},
    {"value":"7033", "displayText":"7033 - Trailer Parks and Campgrounds"},
    {"value":"7210", "displayText":"7210 - Laundry, Cleaning, and Garment Services"},
    {"value":"7211", "displayText":"7211 - Laundries – Family and Commercial"},
    {"value":"7216", "displayText":"7216 - Dry Cleaners"},
    {"value":"7217", "displayText":"7217 - Carpet and Upholstery Cleaning"},
    {"value":"7221", "displayText":"7221 - Photographic Studios"},
    {"value":"7230", "displayText":"7230 - Beauty and Barber Shops"},
    {"value":"7251", "displayText":"7251 - Shoe Repair Shops, Shoe Shine Parlors, and Hat Cleaning Shops"},
    {"value":"7261", "displayText":"7261 - Funeral Services and Crematories"},
    {"value":"7273", "displayText":"7273 - Dating Services"},
    {"value":"7276", "displayText":"7276 - Tax Preparation Services"},
    {"value":"7277", "displayText":"7277 - Counseling Services – Debt, Marriage, and Personal"},
    {"value":"7278", "displayText":"7278 - Buying and Shopping Services and Clubs"},
    {"value":"7296", "displayText":"7296 - Clothing Rental – Costumes, Uniforms, Formal Wear"},
    {"value":"7297", "displayText":"7297 - Massage Parlors"},
    {"value":"7298", "displayText":"7298 - Health and Beauty Spas"},
    {"value":"7299", "displayText":"7299 - Miscellaneous Personal Services (Not Elsewhere Classified)"},
    {"value":"7311", "displayText":"7311 - Advertising Services"},
    {"value":"7321", "displayText":"7321 - Consumer Credit Reporting Agencies"},
    {"value":"7333", "displayText":"7333 - Commercial Photography, Art, and Graphics"},
    {"value":"7338", "displayText":"7338 - Quick Copy, Reproduction, and Blueprinting Services"},
    {"value":"7339", "displayText":"7339 - Stenographic and Secretarial Support"},
    {"value":"7342", "displayText":"7342 - Exterminating and Disinfecting Services"},
    {"value":"7349", "displayText":"7349 - Cleaning, Maintenance, and Janitorial Services"},
    {"value":"7361", "displayText":"7361 - Employment Agencies and Temporary Help Services"},
    {"value":"7372", "displayText":"7372 - Computer Programming, Data Processing, and Integrated Systems Design Services"},
    {"value":"7375", "displayText":"7375 - Information Retrieval Services"},
    {"value":"7379", "displayText":"7379 - Computer Maintenance, Repair and Services (Not Elsewhere Classified)"},
    {"value":"7392", "displayText":"7392 - Management, Consulting, and Public Relations Services"},
    {"value":"7393", "displayText":"7393 - Detective Agencies, Protective Services, and Security Services, including Armored Cars, and Guard Dogs"},
    {"value":"7394", "displayText":"7394 - Equipment, Tool, Furniture, and Appliance Rental and Leasing"},
    {"value":"7395", "displayText":"7395 - Photofinishing Laboratories and Photo Developing"},
    {"value":"7399", "displayText":"7399 - Business Services (Not Elsewhere Classified)"},
    {"value":"7512", "displayText":"7512 - Automobile Rental Agency"},
    {"value":"7513", "displayText":"7513 - Truck and Utility Trailer Rentals"},
    {"value":"7519", "displayText":"7519 - Motor Home and Recreational Vehicle Rentals"},
    {"value":"7523", "displayText":"7523 - Parking Lots, Parking Meters and Garages"},
    {"value":"7531", "displayText":"7531 - Automotive Body Repair Shops"},
    {"value":"7534", "displayText":"7534 - Tire Retreading and Repair Shops"},
    {"value":"7535", "displayText":"7535 - Automotive Paint Shops"},
    {"value":"7538", "displayText":"7538 - Automotive Service Shops (Non-Dealer)"},
    {"value":"7542", "displayText":"7542 - Car Washes"},
    {"value":"7549", "displayText":"7549 - Towing Services"},
    {"value":"7622", "displayText":"7622 - Electronics Repair Shops"},
    {"value":"7623", "displayText":"7623 - Air Conditioning and Refrigeration Repair Shops"},
    {"value":"7629", "displayText":"7629 - Electrical and Small Appliance Repair Shops"},
    {"value":"7631", "displayText":"7631 - Watch, Clock and Jewelry Repair"},
    {"value":"7641", "displayText":"7641 - Furniture – Reupholstery, Repair, and Refinishing"},
    {"value":"7692", "displayText":"7692 - Welding Services"},
    {"value":"7699", "displayText":"7699 - Miscellaneous Repair Shops and Related Services"},
    {"value":"7800", "displayText":"7800 - Government-Owned Lotteries (US Region only)"},
    {"value":"7801", "displayText":"7801 - Government Licensed On-Line Casinos (On-Line Gambling) (US Region only)"},
    {"value":"7802", "displayText":"7802 - Government-Licensed Horse/Dog Racing (US Region only)"},
    {"value":"7829", "displayText":"7829 - Motion Picture and Video Tape Production and Distribution"},
    {"value":"7832", "displayText":"7832 - Motion Picture Theaters"},
    {"value":"7841", "displayText":"7841 - DVD/Video Tape Rental Stores"},
    {"value":"7911", "displayText":"7911 - Dance Halls, Studios and Schools"},
    {"value":"7922", "displayText":"7922 - Ticket Agencies and Theatrical Producers (Except Motion Pictures)"},
    {"value":"7929", "displayText":"7929 - Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)"},
    {"value":"7932", "displayText":"7932 - Billiard and Pool Establishments"},
    {"value":"7933", "displayText":"7933 - Bowling Alleys"},
    {"value":"7941", "displayText":"7941 - Commercial Sports, Professional Sports Clubs, Athletic Fields, and Sports Promoters"},
    {"value":"7991", "displayText":"7991 - Tourist Attractions and Exhibits"},
    {"value":"7992", "displayText":"7992 - Public Golf Courses"},
    {"value":"7993", "displayText":"7993 - Video Amusement Game Supplies"},
    {"value":"7994", "displayText":"7994 - Video Game Arcades/Establishments"},
    {"value":"7995", "displayText":"7995 - Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, Wagers at Race Tracks and games of chance to win prizes of monetary value"},
    {"value":"7996", "displayText":"7996 - Amusement Parks, Circuses, Carnivals, and Fortune Tellers"},
    {"value":"7997", "displayText":"7997 - Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses"},
    {"value":"7998", "displayText":"7998 - Aquariums, Seaquariums, Dolphinariums, and Zoos"},
    {"value":"7999", "displayText":"7999 - Recreation Services (Not Elsewhere Classified)"},
    {"value":"8011", "displayText":"8011 - Doctors and Physicians (Not Elsewhere Classified)"},
    {"value":"8021", "displayText":"8021 - Dentists and Orthodontists"},
    {"value":"8031", "displayText":"8031 - Osteopaths"},
    {"value":"8041", "displayText":"8041 - Chiropractors"},
    {"value":"8042", "displayText":"8042 - Optometrists and Ophthalmologists"},
    {"value":"8043", "displayText":"8043 - Opticians, Optical Goods, and Eyeglasses"},
    {"value":"8049", "displayText":"8049 - Podiatrists and Chiropodists"},
    {"value":"8050", "displayText":"8050 - Nursing and Personal Care Facilities"},
    {"value":"8062", "displayText":"8062 - Hospitals"},
    {"value":"8071", "displayText":"8071 - Medical and Dental Laboratories"},
    {"value":"8099", "displayText":"8099 - Medical Services and Health Practitioners (Not Elsewhere Classified)"},
    {"value":"8111", "displayText":"8111 - Legal Services and Attorneys"},
    {"value":"8211", "displayText":"8211 - Elementary and Secondary Schools"},
    {"value":"8220", "displayText":"8220 - Colleges, Universities, Professional Schools, and Junior Colleges"},
    {"value":"8241", "displayText":"8241 - Correspondence Schools"},
    {"value":"8244", "displayText":"8244 - Business and Secretarial Schools"},
    {"value":"8249", "displayText":"8249 - Vocational and Trade Schools"},
    {"value":"8299", "displayText":"8299 - Schools and Educational Services (Not Elsewhere Classified)"},
    {"value":"8351", "displayText":"8351 - Child Care Services"},
    {"value":"8398", "displayText":"8398 - Charitable Social Service Organizations"},
    {"value":"8641", "displayText":"8641 - Civic, Social, and Fraternal Associations"},
    {"value":"8651", "displayText":"8651 - Political Organizations"},
    {"value":"8661", "displayText":"8661 - Religious Organizations"},
    {"value":"8675", "displayText":"8675 - Automobile Associations"},
    {"value":"8699", "displayText":"8699 - Membership Organizations (Not Elsewhere Classified)"},
    {"value":"8734", "displayText":"8734 - Testing Laboratories (Non-Medical Testing)"},
    {"value":"8911", "displayText":"8911 - Architectural, Engineering, and Surveying Services"},
    {"value":"8931", "displayText":"8931 - Accounting, Auditing, and Bookkeeping Services"},
    {"value":"8999", "displayText":"8999 - Professional Services (Not Elsewhere Classified)"},
    {"value":"9211", "displayText":"9211 - Court Costs, Including Alimony and Child Support"},
    {"value":"9222", "displayText":"9222 - Fines"},
    {"value":"9223", "displayText":"9223 - Bail and Bond Payments"},
    {"value":"9311", "displayText":"9311 - Tax Payments"},
    {"value":"9399", "displayText":"9399 - Government Services (Not Elsewhere Classified)"},
    {"value":"9402", "displayText":"9402 - Postal Services – Government Only"},
    {"value":"9405", "displayText":"9405 - U.S. Federal Government Agencies or Departments"},
    {"value":"9406", "displayText":"9406 - Government-Owned Lotteries (Non-U.S. region)"}
]
export default MccCodes;
