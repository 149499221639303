<template>
  <svg
    width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
    v-if="status !== 'IDEAL'"
  >
    <rect
      x="20" y="20" width="20" height="20" rx="10"
      transform="rotate(-180 20 20)"
      :fill="status === 'SUCCESS' ? success50: error50"
    />
    <path
      fill-rule="evenodd" clip-rule="evenodd" d="M6.53033 6.75C6.53033 6.33579 6.86612 6 7.28033 6L13.2907 6C13.705
    6 14.0407 6.33579 14.0407 6.75L14.0407 12.7604C14.0407 13.1746 13.705 13.5104 13.2907 13.5104C12.8765 13.5104
    12.5407 13.1746 12.5407 12.7604L12.5407 8.56066L7.28033 13.8211C6.98744 14.114 6.51256 14.114 6.21967
    13.8211C5.92678 13.5282 5.92678 13.0533 6.21967 12.7604L11.4801 7.5L7.28033 7.5C6.86612 7.5 6.53033 7.16421
    6.53033 6.75Z" :fill="status === 'SUCCESS' ? success800: error800" v-if="change === 'INCREASE'"
    />
    <path
      fill-rule="evenodd" clip-rule="evenodd" d="M13.75 6.53033C14.1642 6.53033 14.5 6.86612 14.5
    7.28033V13.2907C14.5 13.705 14.1642 14.0407 13.75 14.0407H7.73959C7.32538 14.0407 6.98959 13.705 6.98959
    13.2907C6.98959 12.8765 7.32538 12.5407 7.73959 12.5407H11.9393L6.67893 7.28033C6.38604 6.98744 6.38604
    6.51256 6.67893 6.21967C6.97182 5.92678 7.4467 5.92678 7.73959 6.21967L13 11.4801V7.28033C13 6.86612 13.3358
    6.53033 13.75 6.53033Z" :fill="status === 'SUCCESS' ? success800: error800" v-if="change === 'DECREASE'"
    />
  </svg>
</template>

<script>

import {DANGER_50, DANGER_800, SUCCESS_50, SUCCESS_800} from "@chargebee/cookie-tokens";

export default {
  name: 'StatisticsIcon',
  props: {
    change: {
      type: String,
      required: false,
      default: "INCREASE"
    },
    status: {
      type: String,
      required: false,
      default: "SUCCESS"
    },
  },
  data: function () {
    return {
      success50: SUCCESS_50,
      success800: SUCCESS_800,
      error50: DANGER_50,
      error800: DANGER_800
    }
  }
}
</script>