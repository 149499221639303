import {PayfacErrorCodeMapper} from "@/app/utils/common/functions/payfac-error-code-mapper";
import {RouteConstants} from "@/router/routeConstants";

export class AdyenErrorCodeMapper implements PayfacErrorCodeMapper {
    ErrorRouteConstants = {
        ...RouteConstants,
        ["1_300"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["1_310"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["1_311"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["1_312"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["1_316"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_101"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_102"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_103"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_104"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_105"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_106"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_107"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_108"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_109"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_110"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_112"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_120"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_121"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_122"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_138"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_139"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_152"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_153"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_154"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_155"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_156"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_170"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_171"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_172"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_173"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_174"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_175"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_179"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_180"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_181"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_182"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
        ["2_183"]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,

        ["1_700"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["1_701"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_113"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_114"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_115"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_116"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_135"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_140"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_141"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_142"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_143"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_144"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_145"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_146"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        ["2_147"]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,

        ["1_500"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["1_507"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["1_514"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["1_515"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["1_516"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_117"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_118"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_123"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_124"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_125"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_132"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_133"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_148"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_149"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_150"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_151"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_158"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,

        ["1_600"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
        ["2_189"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,

        ["1_901"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["1_902"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["1_905"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_136"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_159"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_160"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_164"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_166"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_176"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_177"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        ["2_178"]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,

        ["1_102"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_301"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_302"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_303"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_304"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_305"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_306"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_307"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_308"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_309"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_313"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_314"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_315"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_501"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_502"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_503"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_504"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_505"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_506"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_508"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_510"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_511"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_512"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_513"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_601"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_602"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_703"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_704"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_705"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_900"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_903"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["1_904"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_126"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_127"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_128"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_129"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_130"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_131"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_134"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_137"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_157"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        ["2_184"]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,

    };

    DocumentTypePages = {
        IDENTIFICATION_CARD: ["IDENTIFICATION_CARD_FRONT", "IDENTIFICATION_CARD_BACK"],
        PASSPORT: ["PASSPORT"],
        DRIVER_LICENSE: ["DRIVERS_LICENSE_FRONT", "DRIVERS_LICENSE_BACK"],
        TAX_DOCUMENT: ["TAX_DOCUMENT"],
        VAT_DOCUMENT: ["VAT_DOCUMENT"],
        BUSINESS_REGISTRATION: ["BUSINESS_REGISTRATION"],
        BUSINESS_ADDRESS_VERIFICATION: ["BUSINESS_ADDRESS_VERIFICATION"],
        BANK_STATEMENT: ["BANK_STATEMENT"],
        CONSTITUTIONAL_DOCUMENT: ["CONSTITUTIONAL_DOCUMENT"],
        INDUSTRY_PROOF: ["INDUSTRY_PROOF"],
        OTHER: ["OTHER"],
    }

    DocumentTypes = {
        IDENTIFICATION_CARD: "IDENTIFICATION_CARD",
        PASSPORT: "PASSPORT",
        DRIVER_LICENSE: "DRIVER_LICENSE",
        TAX_DOCUMENT: "TAX_DOCUMENT",
        VAT_DOCUMENT: "VAT_DOCUMENT",
        BUSINESS_REGISTRATION: "BUSINESS_REGISTRATION",
        BUSINESS_ADDRESS_VERIFICATION: "BUSINESS_ADDRESS_VERIFICATION",
        BANK_STATEMENT: "BANK_STATEMENT",
        CONSTITUTIONAL_DOCUMENT: "CONSTITUTIONAL_DOCUMENT",
        INDUSTRY_PROOF: "INDUSTRY_PROOF",
        OTHER: "OTHER",
    }

    ErrorCodeToDocumentType = {
        "1_102":
            [this.DocumentTypes.OTHER],
        "1_301":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_302":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_303":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_304":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_305":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_306":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_307":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_308":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_309":
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        "1_313":
            [this.DocumentTypes.TAX_DOCUMENT],
        "1_314":
            [this.DocumentTypes.TAX_DOCUMENT],
        "1_315":
            [this.DocumentTypes.TAX_DOCUMENT],
        "1_501":
            [this.DocumentTypes.BUSINESS_REGISTRATION],
        "1_502":
            [this.DocumentTypes.BUSINESS_REGISTRATION],
        "1_503":
            [this.DocumentTypes.BUSINESS_REGISTRATION, this.DocumentTypes.TAX_DOCUMENT],
        "1_504":
            [this.DocumentTypes.BUSINESS_REGISTRATION, this.DocumentTypes.TAX_DOCUMENT],
        "1_505":
            [this.DocumentTypes.BUSINESS_ADDRESS_VERIFICATION],
        "1_506":
            [this.DocumentTypes.BUSINESS_ADDRESS_VERIFICATION],
        "1_508":
            [this.DocumentTypes.BUSINESS_REGISTRATION],
        "1_510":
            [this.DocumentTypes.BUSINESS_REGISTRATION],
        "1_511":
            [this.DocumentTypes.BUSINESS_REGISTRATION],
        "1_512":
            [this.DocumentTypes.VAT_DOCUMENT],
        "1_513":
            [this.DocumentTypes.VAT_DOCUMENT],
        "1_601":
            [this.DocumentTypes.CONSTITUTIONAL_DOCUMENT],
        "1_602":
            [this.DocumentTypes.CONSTITUTIONAL_DOCUMENT],
        "2_184":
            [this.DocumentTypes.CONSTITUTIONAL_DOCUMENT],
        "1_703":
            [this.DocumentTypes.BANK_STATEMENT],
        "1_704":
            [this.DocumentTypes.BANK_STATEMENT],
        "1_705":
            [this.DocumentTypes.BANK_STATEMENT],
        "1_900":
            [this.DocumentTypes.INDUSTRY_PROOF],
        "1_903":
            [this.DocumentTypes.INDUSTRY_PROOF],
        "1_904":
            [this.DocumentTypes.INDUSTRY_PROOF],
        "2_126":
            [this.DocumentTypes.OTHER],
        "2_127":
            [this.DocumentTypes.DRIVER_LICENSE],
        "2_128":
            [this.DocumentTypes.DRIVER_LICENSE],
        "2_129":
            [this.DocumentTypes.DRIVER_LICENSE],
        "2_130":
            [this.DocumentTypes.IDENTIFICATION_CARD],
        "2_131":
            [this.DocumentTypes.IDENTIFICATION_CARD],
        "2_134":
            [this.DocumentTypes.VAT_DOCUMENT],
        "2_137":
            [this.DocumentTypes.BUSINESS_REGISTRATION],
        "2_157":
            [this.DocumentTypes.PASSPORT],

    }

    public getNavigationForErrorCode = (errorCode) => {
        return {
            name: this.ErrorRouteConstants[this.getRawErrorCode(errorCode)],
            query: {errorCode},
        };
    }

    public cannotOnboardErrorCodes = () => {
        return [
            "1_100",
            "1_101",
        ]
    }

    public rejectionDueToOwnerInfo = () => {
        return [
            "1_300",
            "1_310",
            "1_311",
            "1_312",
            "1_316",
            "2_101",
            "2_102",
            "2_103",
            "2_104",
            "2_105",
            "2_106",
            "2_107",
            "2_108",
            "2_109",
            "2_110",
            "2_112",
            "2_120",
            "2_121",
            "2_122",
            "2_138",
            "2_139",
            "2_152",
            "2_153",
            "2_154",
            "2_155",
            "2_156",
            "2_170",
            "2_171",
            "2_172",
            "2_173",
            "2_174",
            "2_175",
            "2_179",
            "2_180",
            "2_181",
            "2_182",
            "2_183",
            "2_189",
        ]
    }

    public rejectionDueToBusinessStructure = () => {
        return [
            "1_901",
            "1_902",
            "1_905",
            "2_136",
            "2_159",
            "2_160",
            "2_164",
            "2_166",
            "2_176",
            "2_177",
            "2_178",
        ]
    }

    public rejectionDueToBusinessInformation = () => {
        return [
            "1_500",
            "1_507",
            "1_514",
            "1_515",
            "1_516",
            "2_117",
            "2_118",
            "2_123",
            "2_124",
            "2_125",
            "2_132",
            "2_133",
            "2_148",
            "2_149",
            "2_150",
            "2_151",
            "2_158",
            "1_600",
            "2_189",
        ]
    }

    public rejectionDueToBankAccount = () => {
        return [
            "1_700",
            "1_701",
            "2_113",
            "2_114",
            "2_115",
            "2_116",
            "2_135",
            "2_140",
            "2_141",
            "2_142",
            "2_143",
            "2_144",
            "2_145",
            "2_146",
            "2_147",
        ]
    }

    public errorCodesRequiringFileUpload = () => {
        return [
            "1_102",
            "1_301",
            "1_302",
            "1_303",
            "1_304",
            "1_305",
            "1_306",
            "1_307",
            "1_308",
            "1_309",
            "1_313",
            "1_314",
            "1_315",
            "1_501",
            "1_502",
            "1_503",
            "1_504",
            "1_505",
            "1_506",
            "1_508",
            "1_510",
            "1_511",
            "1_512",
            "1_513",
            "1_601",
            "1_602",
            "1_703",
            "1_704",
            "1_705",
            "1_900",
            "1_903",
            "1_904",
            "2_126",
            "2_127",
            "2_128",
            "2_129",
            "2_130",
            "2_131",
            "2_134",
            "2_137",
            "2_157",
            "2_184",
        ]
    }

    public getRawErrorCode = (errorCode) => {
        return errorCode.split('-')[1];
    }

    public getErrorCodeFixed = (errorCode) => {
        return [errorCode];
    }
}