import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import OnboardingIndex from '@/views/OnboardingIndex.vue'
import OnboardingSuccess from '@/views/OnboardingSuccess.vue'
import OnboardingView from '@/views/OnboardingView.vue'
import OnboardingStatusView from '@/views/OnboardingStatusView.vue'
import EditOwnerInfoView from '@/views/EditOwnerInfoView.vue'
import EditBusinessStructureView from '@/views/EditBusinessStructureView.vue'
import EditBankAccountView from '@/views/EditBankAccountView.vue'
import EditBusinessInformationView from '@/views/EditBusinessInformationView.vue'
import UploadDocumentView from '@/views/UploadDocumentView.vue'
import TransactionView from '@/views/TransactionView.vue'
import ExportView from '@/views/ExportView.vue'
import DisputeDetailsView from '@/views/DisputeDetailsView.vue'
import TransactionDetailsView from '@/views/TransactionDetailsView.vue'
import DepositDetailsView from '@/views/DepositDetailsView.vue'
import ComplianceFormDetailsView from "@/views/ComplianceFormDetailsView.vue";
import DashboardView from '@/views/DashboardView.vue'
import DepositView from '@/views/DepositView.vue'
import DisputeView from '@/views/DisputeView.vue'
import DisputeResponseView from '@/views/DisputeResponseView.vue'
import ErrorView from '@/views/ErrorView.vue'
import AdminView from "@/views/AdminView.vue"
import DuplicateTab from '@/views/DuplicateTab.vue'
import Logout from '@/views/Logout.vue'
import NotificationView from '@/views/NotificationView.vue'
import ProfileView from "@/views/ProfileView.vue";
import ReportView from "@/views/ReportView.vue";
import {RouteConstants} from "@/router/routeConstants";
import AuthorizationView from "@/views/AuthorizationView.vue";
import AuthorizationDetailsView from "@/views/AuthorizationDetailsView.vue";
import AddFundsToReserveView from "@/views/AddFundsToReserveView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: RouteConstants.LOGIN,
        component: Login,
    },
    {
        path: '/admin',
        name: RouteConstants.ADMIN,
        component: AdminView,
    },
    {
        path: '/profile',
        name: RouteConstants.PROFILE,
        component: ProfileView,
    },
    {
        path: '/onboarding',
        component: OnboardingIndex,
        children: [
            {
                path: '',
                name: RouteConstants.ONBOARDING,
                component: OnboardingView,
            },
            {
                path: 'status',
                name: RouteConstants.ONBOARDING_STATUS,
                component: OnboardingStatusView,
            },
            {
                path: 'edit_owner_info',
                name: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,
                component: EditOwnerInfoView,
            },
            {
                path: 'edit_bank_account',
                name: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
                component: EditBankAccountView,
            },
            {
                path: 'edit_business_structure',
                name: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
                component: EditBusinessStructureView,
            },
            {
                path: 'edit_business_information',
                name: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,
                component: EditBusinessInformationView,
            },
            {
                path: 'upload_documents',
                name: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
                component: UploadDocumentView,
            },
            {
                path: 'success',
                name: RouteConstants.ONBOARDING_SUCCESS,
                component: OnboardingSuccess,
            },
        ],
    },
    {
        path: '/notifications',
        name: RouteConstants.NOTIFICATIONS,
        component: NotificationView,
    },
    {
        path: '/payment_profile',
        name: RouteConstants.PAYMENT_PROFILE,
        component: OnboardingView,
    },
    {
        path: '/dashboard',
        name: RouteConstants.DASHBOARD,
        component: DashboardView,
    },
    {
        path: '/transactions',
        name: RouteConstants.TRANSACTIONS,
        component: TransactionView,
    },
    {
        path: '/transactions/:transactionId',
        name: RouteConstants.TRANSACTION_DETAILS,
        component: TransactionDetailsView,
    },
    {
        path: '/deposits/:settlementId',
        name: RouteConstants.DEPOSIT_DETAILS,
        component: DepositDetailsView,
    },
    {
        path: '/compliance_forms/:complianceFormId',
        name: RouteConstants.COMPLIANCE_FORM_DETAILS,
        component: ComplianceFormDetailsView,
    },
    {
        path: '/authorizations',
        name: RouteConstants.AUTHORIZATIONS,
        component: AuthorizationView,
    },
    {
        path: '/authorizations/:authorizationId',
        name: RouteConstants.AUTHORIZATION_DETAILS,
        component: AuthorizationDetailsView,
    },
    {
        path: "/exports",
        name: RouteConstants.EXPORTS,
        component: ExportView
    },
    {
        path: "/reports",
        name: RouteConstants.REPORTS,
        component: ReportView
    },
    {
        path: '/disputes',
        name: RouteConstants.DISPUTES,
        component: DisputeView,
    },
    {
        path: '/disputes/:disputeId',
        name: RouteConstants.DISPUTE_DETAILS,
        component: DisputeDetailsView,
    },
    {
        path: '/disputes/:disputeId/response',
        name: RouteConstants.DISPUTE_RESPONSE,
        component: DisputeResponseView,
    },
    {
        path: "/deposits",
        name: RouteConstants.DEPOSITS,
        component: DepositView
    },
    {
        path: "/add_funds/reserves",
        name: RouteConstants.ADD_FUNDS,
        component: AddFundsToReserveView
    },
    {
        path: '/error',
        name: RouteConstants.ERROR,
        component: ErrorView,
    },
    {
        path: '/logout',
        name: RouteConstants.LOGOUT,
        component: Logout,
    },
    {
        path: '/duplicate',
        name: RouteConstants.DUPLICATE,
        component: DuplicateTab,
    },
    {
        path: '/:pathMatch(.*)*',
        name: RouteConstants.DEFAULT,
        component: ErrorView,
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

export const navigateTo = (routeName, push, query) => {
    if(push) {
        router.push({name: routeName, query})
    } else {
        router.replace({name: routeName, query})
    }
}

export const goBack = () => {
    router.go(-1)
}

export const navigateWithParam = (routeName, params, query) => {
    router.push({ name: routeName, params, query})
}

export default router
