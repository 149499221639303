<template>
  <div class="compliance-form-attestation">
    <div class="title" v-if="complianceForm != null">
      <div class="attestation">
        Attestation
      </div>
      <c-button
        v-if="!complianceForm.attestation_details.is_accepted"
        variant="secondary"
        size="large"
        :disabled="fileStatusNull"
        @click="openComplianceFormResponseModal"
      >
        {{ $t('profile.actions.attestComplianceForm') }}
      </c-button>
    </div>
    <div
      class="fields"
      v-if="!inProgressOrCompleteStatus"
    >
      <div class="column">
        <FieldValue
          class-name="unsigned-form"
          :field-value="complianceForm.unsigned_file_id === null ? null : (complianceForm.unsigned_file_id + '.pdf')"
          :field-name="$t('fieldNames.unsignedForm')"
          :on-click="openComplianceFormResponseModal"
        />
      </div>
    </div>
    <div class="fields" v-else>
      <div class="column">
        <FieldValue
          class-name="attested-by"
          :field-value="complianceForm.attestation_details.name"
          :field-name="$t('fieldNames.attestedBy')"
        />
        <FieldValue
          class-name="attest-title"
          :field-value="complianceForm.attestation_details.title"
          :field-name="$t('fieldNames.title')"
        />
      </div>
      <div class="column">
        <FieldValue
          v-if="complianceForm.compliance_form_status === 'COMPLETE'"
          class-name="attested-form"
          :field-value="complianceForm.signed_file_id !== null ? complianceForm.signed_file_id + '.pdf' : 'In Progress...'"
          :field-name="$t('fieldNames.attestedForm')"
          :on-click="complianceForm.signed_file_id !== null ? openComplianceFormResponseModal : ''"
        />
        <FieldValue
          v-else
          class-name="unsigned-form"
          :field-value="complianceForm.unsigned_file_id + '.pdf'"
          :field-name="$t('fieldNames.unsignedForm')"
          :on-click="openComplianceFormResponseModal"
        />
        <FieldValue
          class="attested-date"
          :field-value="formatDateWithTimezone(complianceForm.attestation_details.signed_at.toString())"
          :field-name="$t('fieldNames.attestedDate')"
        />
      </div>
    </div>
    <ModalPopup class="response-modal" :show="openResponseModal">
      <div slot="body">
        <ComplianceFormResponse
          :on-attest="onAttestFromComplianceFormResponse"
          :on-dismiss="() => openResponseModal = false"
          :compliance-form="complianceForm"
          :in-progress-or-complete-status="inProgressOrCompleteStatus"
        />
      </div>
    </ModalPopup>
    <ModalPopup class="attest-modal" :show="openAttestModal">
      <div slot="body">
        <AttestComplianceForm
          v-if="openAttestModal === true"
          :compliance-form="complianceForm"
          :on-dismiss="() => {openAttestModal = false}"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import FieldValue from "@/components/common/FieldValue.vue";
import { ComplianceFormResponseDto } from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import ModalPopup from "@/components/common/ModalPopup.vue";
import ComplianceFormResponse from "@/components/complianceforms/ComplianceFormResponse.vue";
import AttestComplianceForm from "@/components/complianceforms/AttestComplianceForm.vue";
export default {
  name: 'ComplianceFormAttestation',
  components: { AttestComplianceForm, ComplianceFormResponse, FieldValue, ModalPopup },
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true,
    },
    inProgressOrCompleteStatus: {
      type: Boolean,
      required: true,
    },
  },
  data: function() {
    return {
      openResponseModal: false,
      openAttestModal: false
    }
  },
  computed: {
    fileStatusNull() {
      if(this.complianceForm.compliance_form_status === 'COMPLETE') {
        return this.complianceForm.signed_file_id === null;
      }else if (this.complianceForm.compliance_form_status === 'INCOMPLETE') {
        return this.complianceForm.unsigned_file_id === null;
      }
      return false;
    },
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    openComplianceFormResponseModal() {
      this.openResponseModal = true;
    },
    onAttestFromComplianceFormResponse() {
      this.openResponseModal = false;
      this.openAttestModal = true;
    },
  }
};
</script>

<style lang="scss">

.compliance-form-attestation {

  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight_bold;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & button {
      border-color: $primary_400;
      margin-bottom: 0;
      & .c-button__label {
        padding: 0;
        font-weight: $weight_bold;
      }

      @media screen and (max-width: 700px) {
        margin-left: 5px;
        font-size: 10px;
        overflow: hidden;
        text-align: left;
      }
    }
  }

  & .fields {
    display: flex;

    & .column {
      display: flex;
      width: 385px;
      padding-right: 0px;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      overflow: auto;

      & .unsigned-form {

        & .field-value {
          color: $primary_600;
          font-weight: $weight_medium;
        }
      }

      .attested-form {

        & .field-value {
          color: $primary_600;
          font-weight: $weight_medium;
        }
      }
    }
  }

  & .attest-modal {
    & .modal-container {
      max-width: 500px;

      & .footer {
        padding: 0;
      }
    }
  }

  & .response-modal {
    & .modal-container {
      max-width: 900px;

      & .footer {
        padding: 0;
      }
    }
  }
}
</style>
