<template>
  <div class="mcc-not-supported-container">
    <div class="header">
      <div class="title-container">
        <div class="icon-container">
          <c-icon name="alert" :size="24" />
        </div>
        <div class="content-container">
          <div class="title">
            {{ $t('MCCNotSupported.title') }}
          </div>
          <div class="subtitle">
            {{ $t('MCCNotSupported.subtitle') }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <c-button
        @click="closePopup"
      >
        {{ $t('global.action.close') }}
      </c-button>
    </div>
  </div>
</template>
<script>

export default {
  name: "MCCNotSupported",
  props: {
    onCancel: {
      type: Function,
      required: true
    },
  },
  methods: {
    closePopup() {
      this.onCancel();
    }
  }
}
</script>

<style lang="scss">


.mcc-not-supported-container {
  padding: 30px 30px 0px 30px;
  color: $subtitle-text;
  font-size: 14px;

  & .buttons {
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: end;
    font-weight: $weight_bold;
    background-color: $color-white;
  }

  & .header {
    display: flow-root;
    justify-content: start;
    position: sticky;
    color: $text-color;
    top: 0;
    background-color: $color-white;
    border-bottom: 0px !important;
    z-index: 99;

    & .title-container {
      text-align: left;
      display: flex;

      & .icon-container {
        flex: 10;
      }

      & .content-container {
        flex: 90;

        & .title {
          font-size: 16px;
          font-weight: $weight_bold;
          color: $text-color;
          margin-bottom: 8px;
        }

        & .subtitle {
          font-weight: $weight_normal;
          font-size: 12px;
          color: $text-color;
        }
      }
    }
  }
}


</style>