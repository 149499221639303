<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.83333 12.1667H9.66667V10.5H3.83333V12.1667ZM3.83333 8.83333H12.1667V7.16667H3.83333V8.83333ZM3.83333 5.5H12.1667V3.83333H3.83333V5.5ZM2.16667 15.5C1.70833 15.5 1.31583 15.3369 0.989167 15.0108C0.663055 14.6842 0.5 14.2917 0.5 13.8333V2.16667C0.5 1.70833 0.663055 1.31583 0.989167 0.989167C1.31583 0.663055 1.70833 0.5 2.16667 0.5H13.8333C14.2917 0.5 14.6842 0.663055 15.0108 0.989167C15.3369 1.31583 15.5 1.70833 15.5 2.16667V13.8333C15.5 14.2917 15.3369 14.6842 15.0108 15.0108C14.6842 15.3369 14.2917 15.5 13.8333 15.5H2.16667ZM2.16667 13.8333H13.8333V2.16667H2.16667V13.8333Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'FormIcon',
}
</script>