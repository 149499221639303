/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import {Tabs} from "@/app/utils/common/constants";
import Vue from "vue";

export const onboardingDefaultState = {
    currentTab: Tabs.businessProfile,
    autoSave: true,
    merchantUploadedDocuments: [],
};

const getters = {
    getAutoSaveStatus: (state) => state.autoSave,
    getMerchantUploadedDocuments: state => state.merchantUploadedDocuments,
}

const actions = {
    setCurrentTab({commit}, tab) {
        commit('setCurrentTab', tab)
    },
    setAutoSaveStatus({commit}, value) {
        commit('setAutoSaveStatus', value)
    },
    async clearMerchantUploadedDocuments({commit}, documents) {
        commit('clearMerchantUploadedDocuments', documents)
    },
    validateAllFields({dispatch}) {
        dispatch('businessProfile/validateAllFields', null, {root: true})
        dispatch('businessAddress/validateAllFields', null, {root: true})
        dispatch('businessStructure/validateAllFields', null, {root: true})
        dispatch('stakeholdersDetails/validateAllFields', null, {root: true})
        dispatch('additionalUnderwritingData/validateAllFields', null, {root: true})
        dispatch('bankAccountForPayouts/validateAllFields', null, {root: true})
    }
};

const mutations = {
    setAutoSaveStatus(state, value) {
        Vue.set(state, 'autoSave', value);
    },
    setCurrentTab(state, tab) {
        state.currentTab = tab
    },
    clearMerchantUploadedDocuments(state, documents): void {
        state.merchantUploadedDocuments = documents;
    }
};

export const onboarding = {
    namespaced: true,
    state:onboardingDefaultState,
    actions,
    getters,
    mutations
};