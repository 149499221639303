<template>
  <div class="footer" data-html2canvas-ignore="true">
    <c-link
      href="https://www.chargebee.com/docs/2.0/cb_payments.html"
      target="_blank"
      class="footer-link"
      :id="getElementId('help_doc')"
    >
      {{ $t('transactions.footer.helpDocumentation') }}
    </c-link>
    <c-link
      href="mailto:support@chargebee.com"
      target="_blank"
      class="footer-link"
      :id="getElementId('support')"
    >
      {{ $t('transactions.footer.support') }}
    </c-link>
    <c-link
      href="https://www.chargebee.com/payments-tou/"
      target="_blank"
      class="footer-link"
      :id="getElementId('legal_privacy_policy')"
    >
      {{ $t('transactions.footer.legalPrivacyPolicy') }}
    </c-link>
  </div>
</template>

<script>
import {PendoPrefixes} from "@/app/utils/common/constants";

export default {
  name: "PortalFooter",
  methods: {
    getElementId(id) {
      return PendoPrefixes.CBpayFooter + id;
    }
  }
}
</script>

<style lang="scss">
.footer {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  justify-content: left;

  & .footer-link {
    font-size: 12px;
    margin-right: 30px;
    font-weight: 500;
  }
}
</style>