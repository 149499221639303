<template>
  <div class="bs-container">
    <slot>
      <div class="header">
        {{ $t('businessStructure.title') }}
      </div>
      <div class="subtitle">
        {{ $t('businessStructure.subtitle') }}
      </div>
      <div class="separator"></div>
    </slot>
    <div id="business-and-ownership-type">
      <div class="field select-field">
        <c-label :label="$t('businessStructure.typeOfBusiness.label')" />

        <c-select
          autofocus
          :searchable="false"
          :clearable="true"
          id="type-of-business-select"
          :options="getTypesOfBusiness()"
          @update="checkAndSetTypeOfBusiness"
          :value="getTypeOfBusiness"
          class="c-flex-grow-1 type-of-business-select"
        >
          <template #option="options">
            <div class="type-of-business-option">
              <div>{{ options.option.displayText }}</div>
              <ToolTip
                placement="right"
                :content="options.option.description"
                v-if="options.option.description"
              >
                <img src="@/app/assets/images/info.svg" alt="Chargebee Info Icon" />
              </ToolTip>
            </div>
          </template>
        </c-select>

        <c-inline-error
          v-if="Boolean(getErrors.typeOfBusiness)"
          :message="getErrors.typeOfBusiness"
        />
        <div class="hint" v-if="!Boolean(getErrors.typeOfBusiness)">
          {{ $t('businessStructure.typeOfBusiness.instruction') }}
        </div>
      </div>

      <SelectField
        :label="$t('businessStructure.ownershipType.label')"
        :searchable="false"
        :clearable="true"
        :options="getOwnershipTypeList()"
        :value="getOwnershipType"
        :on-update="setOwnershipType"
        :error="getErrors.ownershipType"
        :disabled="disableOwnershipTypeSelect"
        select-classname="ownership-select"
        data-testid="ownership-select"
        :hint="$t('businessStructure.ownershipType.instruction')"
      />
    </div>

    <InputField
      v-for="field in getApplicableCountrySpecificApiFields"
      :label="$t(getCountrySpecificApiFields[field].label[getCountry] || getCountrySpecificApiFields[field].label.default)"
      :key="field"
      :value="getCountrySpecificApiFields[field].value"
      :set-value="(event) => setCountrySpecificField({field, event})"
      :error="getErrors[field]"
      :id="field"
      :data-testid="field"
      :hint="$t(getCountrySpecificApiFields[field].instruction[getCountry] || getCountrySpecificApiFields[field].instruction.default)"
      class="fs-mask"
    />

    <div id="inc-date-and-mcc">
      <DateInput
        :label="$t('businessStructure.incorporationDate.label')"
        id="incorporation-date"
        data-testid="incorporation-date"
        :value="getFormattedDate(getIncorporationDate)"
        :set-value="setIncorporationDate"
        :error="getErrors.incorporationDate"
        :hint="$t('businessStructure.incorporationDate.instruction')"
      />

      <div class="field select-field">
        <c-label :label="$t('businessStructure.merchantCategoryCode.label')" />
        <c-select
          autofocus
          :searchable="true"
          :clearable="true"
          :options="getMccCodes()"
          @update="validateMerchantCategoryCode"
          :value="getMerchantCategoryCode"
          class="c-flex-grow-1 mcc-select"
        >
          <template #option="options">
            <div class="mcc-option">
              <div>{{ getMccDisplayText(options.option.displayText) }}</div>
              <div>{{ options.option.value }}</div>
            </div>
          </template>
        </c-select>

        <c-inline-error
          v-if="Boolean(getErrors.merchantCategoryCode)"
          :message="getErrors.merchantCategoryCode"
        />
        <div class="hint" v-if="!Boolean(getErrors.merchantCategoryCode)">
          <i18n path="businessStructure.merchantCategoryCode.instruction" tag="div">
            <template #whatIsMcc>
              <c-link href="https://www.chargebee.com/docs/2.0/cb_payments_faq.html" target="_blank">
                {{ $t('businessStructure.merchantCategoryCode.whatIsMcc') }}
              </c-link>
            </template>
          </i18n>
        </div>
      </div>
    </div>

    <ModalPopup :show="showMCCNotSupportedPopup">
      <div slot="body">
        <MCCNotSupported
          :on-cancel="cancelUnsupportedMCCPopup"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import TypesOfBusinessList from "@/data/type-of-business-list.ts"
import SelectField from "@/components/common/SelectField";
import InputField from "@/components/common/InputField";
import OwnershipTypeList from "@/data/ownership-type-list";
import MccCodes from "@/data/mcc-codes";
import validations from "@/app/validators/api/openapi";
import DateInput from "@/components/common/DateInput";
import {BusinessType, OwnershipType} from "@/api/paymentApi";
import {unsupportedMccCodesList} from "@/data/unsupported-mcc-codes";
import ModalPopup from "@/components/common/ModalPopup.vue";
import MCCNotSupported from "@/components/onboarding/MCCNotSupported.vue";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: 'BusinessStructure',
  components: {ToolTip, MCCNotSupported, ModalPopup, SelectField, InputField, DateInput},
  data: function () {
    return {
      showMCCNotSupportedPopup: false,
    }
  },
  computed: {
    ...mapGetters('businessStructure', [
        'getTypeOfBusiness',
        'getOwnershipType',
        'getIncorporationDate',
        'getMerchantCategoryCode',
        'getErrors',
        'getCountrySpecificApiFields',
        'shouldShowApiField'
    ]),
    ...mapGetters('businessAddress', ['getCountry']),
    ...mapGetters('app', ["getPayfac"]),
    getApplicableCountrySpecificApiFields() {
      return Object.keys(this.getCountrySpecificApiFields).filter(field => {
        return this.shouldShowApiField(field)
      })
    },
    ...mapGetters('app', ["getSessionPayfac"]),
    disableOwnershipTypeSelect() {
      return this.getTypeOfBusiness === BusinessType.INDIVIDUALSOLEPROPRIETOR
              || this.getTypeOfBusiness === BusinessType.GOVERNMENTENTITY
              || this.getTypeOfBusiness === BusinessType.TAXEXEMPT;
    }
  },
  methods: {
    ...mapActions('businessStructure', {
      setTypeOfBusiness: 'updateTypeOfBusiness',
      setOwnershipType: 'updateOwnershipType',
      setEin: 'updateEin',
      setIncorporationDate: 'updateIncorporationDate',
      setMerchantCategoryCode: 'updateMerchantCategoryCode',
      setCountrySpecificField: 'updateCountrySpecificField'
    }),
    getMax: key => validations.createMerchant[key].length.maximum,
    getTypesOfBusiness: () => TypesOfBusinessList,
    getOwnershipTypeList: () => OwnershipTypeList,
    getMccCodes: () => MccCodes,
    getMccDisplayText: (textWithCode) => {
      return textWithCode.split(" - ")[1]
    },
    getFormattedDate: (date) => {
      if(!date) return null;
      return date.replace(/(\d{4})-(\d\d)-(\d\d)/, "$2/$3/$1");
    },
    checkAndSetTypeOfBusiness(businessType) {
      if(businessType === BusinessType.INDIVIDUALSOLEPROPRIETOR) {
        this.setOwnershipType(OwnershipType.PRIVATE);
      } else if(businessType === BusinessType.GOVERNMENTENTITY || businessType === BusinessType.TAXEXEMPT) {
        this.setOwnershipType(OwnershipType.PUBLIC)
      }
      this.setTypeOfBusiness(businessType);
    },
    validateMerchantCategoryCode(code) {
      if (unsupportedMccCodesList(this.getSessionPayfac).find( e => e === code) !== undefined)
        this.showMCCNotSupportedPopup = true

      this.setMerchantCategoryCode(code);
    },
    cancelUnsupportedMCCPopup() {
      this.showMCCNotSupportedPopup = false
      this.setMerchantCategoryCode(null)
    }
  }
}
</script>

<style lang="scss" >
.bs-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $primary_text;
    text-align: left;
    margin-bottom: 8px;
  }

  & .subtitle {
    font-size: 12px;
    color: $neutral_600;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }

  #business-and-ownership-type {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;

    & .type-of-business-select {
      & .c-select__item .c-tooltip{
        visibility: hidden;
      }

      & .c-select__item:hover .c-tooltip{
        visibility: visible;
        text-align: left;
      }
      & .type-of-business-option {
        display: flex;
        justify-content: space-between;
      }
    }

    & .field {
      flex: 1;
      width: inherit;
    }
  }

  #inc-date-and-mcc {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    & .mcc-option {
      display: flex;
      justify-content: space-between;
      gap: 30px;
    }

    & .field {
      flex: 1;
      width: 300px;

      & .c-dropdown {
        width: 100%;
      }
    }
  }
}

</style>

<style src="../../../node_modules/v-calendar/lib/v-calendar.min.css"/>