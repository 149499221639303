<template>
  <TableView
    :data="disputes"
    :loading="loading"
    :infinite-scroll="infiniteScroll"
    :all-items-loaded="allItemsLoaded"
    class="disputes-table"
    entity="disputes"
    :columns="columns"
    :column-right-align="[]"
    @clearFilter="$emit('clearFilter')"
    :clickable-rows="true"
    :fixed-first-column="false"
    @row-clicked="rowClicked"
  >
    <template #dispute="prop">
      <div class="dispute-column">
        <div class="status-tag-container">
          <div
            class="status"
            :class="{
              'pending-status': prop.item.status === 'PENDING' || prop.item.status === 'INQUIRY' ,
              'won-status': prop.item.status === 'WON',
              'lost-status': prop.item.status === 'LOST' || prop.item.status === 'ACCEPTED',
              'responded-status': prop.item.status === 'RESPONDED'
            }"
          >
            {{ $t('disputes.status.' + prop.item.status) }}
          </div>
        </div>
        <div class="amount-and-txid">
          <div class="amount_box">
            <div class="amount">
              {{ formatter({type : "currency", data: prop.item.amount, currencyCode: prop.item.currency}) }}
            </div>
            <div class="currency_code">
              {{ prop.item.currency }}
            </div>
          </div>
          <div class="dispute-id">
            {{ prop.item.id }}
          </div>
        </div>
      </div>
    </template>
    <template #customer_info="prop">
      <div
        class="customer-info-column fs-mask" v-if="prop.item.consumer.first_name
          || prop.item.consumer.last_name || prop.item.consumer.email"
      >
        <div class="name-and-email">
          <div class="name">
            <div v-if="prop.item.consumer.first_name || prop.item.consumer.last_name">
              {{ prop.item.consumer.first_name }} {{ prop.item.consumer.last_name }}
            </div>
            <div v-else class="empty-placeholder">
              —
            </div>
          </div>
          <div class="email">
            <div v-if="prop.item.consumer.email">
              {{ prop.item.consumer.email }}
            </div>
            <div v-else class="empty-placeholder">
              —
            </div>
          </div>
        </div>
      </div>
      <div class="empty-placeholder" v-else>
        —
      </div>
    </template>
    <template #dispute_type="prop">
      {{ $t('disputes.type.' + prop.item.type) }}
    </template>
    <template #payment_method="prop">
      <ToolTip
        placement="top"
        :content="getPaymentMethodTooltip(prop.item.payment_instrument)"
        :dismiss-on-click="true"
      >
        <div class="payment-method-column">
          <component
            v-if="prop.item.payment_instrument.type === 'card'"
            class="card-logo"
            :is="cardLogoForCardBrand(prop.item.payment_instrument.card.brand)"
          />
          <img
            v-else
            class="card-logo"
            src="@/app/assets/images/account_balance.svg"
            alt="Chargebee ACH bank account Icon"
          />
          <div class="payment-method">
            {{ prop.item.payment_instrument.type === 'card'
              ? "**** " + prop.item.payment_instrument.card.last_four
              : $t('paymentInstrument.' + prop.item.payment_instrument.bank_account.type.replace('_', '').toLowerCase()) }}
          </div>
        </div>
      </ToolTip>
    </template>
    <template #initiated="prop">
      <ToolTip
        placement="top"
        :content="formatter({type : 'timeWithZone', data: prop.item.occurred_at})"
        :dismiss-on-click="true"
      >
        {{ formatter({type : 'zonedDate', data: prop.item.occurred_at}) }}
      </ToolTip>
    </template>
    <template #respond_by="prop">
      <ToolTip
        placement="top"
        :content="formatter({type : 'timeWithZone', data: prop.item.respond_by})"
        :dismiss-on-click="true"
      >
        {{ formatter({type : 'zonedDate', data: prop.item.respond_by}) }}
      </ToolTip>
    </template>
    <template #responded="prop">
      <ToolTip
        placement="top"
        :content="formatter({type : 'timeWithZone', data: prop.item.responded_at})"
        :dismiss-on-click="true"
      >
        <span v-if="prop.item.responded_at">{{ formatter({type : 'zonedDate', data: prop.item.responded_at}) }}</span>
        <span class="empty-placeholder" v-else>
          —
        </span>
      </ToolTip>
    </template>
    <template #last_updated="prop">
      <ToolTip
        placement="top"
        :content="formatter({type : 'timeWithZone', data: prop.item.updated_at})"
        :dismiss-on-click="true"
      >
        <span v-if="prop.item.updated_at">{{ formatter({type : 'zonedDate', data: prop.item.updated_at}) }}</span>
        <span class="empty-placeholder" v-else>
          —
        </span>
      </ToolTip>
    </template>
    <template #reason="prop">
      <ToolTip
        placement="top"
        :content="prop.item.reason"
        :dismiss-on-click="true"
        :enabled="prop.item.reason!==null"
      >
        <div class="error_message" v-if="prop.item.reason">
          {{ $t('disputes.reason.' + prop.item.reason) }}
        </div>
        <div class="empty-placeholder" v-else>
          —
        </div>
      </ToolTip>
    </template>
  </TableView>
</template>

<script>

import Formatter from "@/app/utils/common/functions/formatter";
import TableView from "@/components/common/TableView";
import {navigateWithParam} from "@/router";
import {cardBrandLogo} from "@/app/utils/common/helpers/cardBrandLogo";
import {RouteConstants} from "@/router/routeConstants";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: 'DisputeTable',
  components: {ToolTip, TableView},
  props: {
    disputes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    infiniteScroll: {
      type: Boolean,
      required: true,
    },
    allItemsLoaded: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    }
  },
  data: () => {
    return {}
  },
  computed: {
    isAdmin() {
      return String(this.$router.currentRoute.query.adminMode) === "true"
    },
  },
  methods: {
    formatter: Formatter.format,
    rowClicked: function (id, data) {
      navigateWithParam(RouteConstants.DISPUTE_DETAILS, {disputeId: id, data}, this.$route.query)
    },
    cardLogoForCardBrand(cardBrand) {
      return cardBrandLogo[cardBrand] || cardBrandLogo['UNKNOWN'];
    },
    getPaymentMethodTooltip(paymentInstrument) {
      return paymentInstrument.type === 'card' ?
          this.$t('cards.brands.' + paymentInstrument.card.brand)
          : this.$t('paymentInstrument.transfer' + paymentInstrument.bank_account.type.replace('_', '').toLowerCase());
    }
  },
}

</script>

<style lang="scss">

.disputes-table {

  & .empty-placeholder {
    color: $neutral_400;
  }

  & .customer-info-column {
    display: flex;
    font-weight: $weight-normal;

    & .name-and-email {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      & .name {
        font-size: 14px;
        line-height: 20px;
        color: $neutral_1000;
      }

      & .email {
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  & .dispute-column {
    display: flex;
    flex-direction: row;

    & .status-tag-container {
      display: flex;
      text-align: left;
      min-width: 70px;
      flex-direction: row;
      justify-content: space-between;
    }

    & .status {
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 10px;
      height: fit-content;
      line-height: 16px;
      font-weight: 500;
      font-style: normal;
    }

    & .won-status {
      background-color: $success_100;
      color: $success_800;
    }

    & .responded-status {
      background-color: $neutral_100;
      color: $neutral_800;
    }

    & .lost-status {
      background-color: $danger_50;
      color: $danger_800;
    }

    & .pending-status {
      background-color: $warn_50;
      color: $warn_800;
    }

    & .amount-and-txid {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-left: 8px;

      & .amount_box {
        font-size: 14px;
        font-weight: $weight-medium;
        line-height: 20px;
        color: $neutral_1000;
        display: flex;
        flex-direction: row;

        & .amount {
          margin-right: 5px;
        }
      }

      & .dispute-id {
        font-weight: $weight_normal;
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;

      }
    }
  }
}
</style>