import {validateField} from "@/app/utils/common/functions/validation";
import allValidations from "@/app/validators/api/customValidations";
// import store from "@/store/index";

function getValidationSpec(api, apiPath) {
    return allValidations[api][apiPath];
}

/**
 * This method is invoked, when next button or submit button is clicked.
 * This method will validate all the forms and generate error messages that can be displayed on UI.
 * @param commit
 * @param api
 * @param country
 * @param data
 * @returns {boolean}
 */
export const validateAndGenerateError = (commit, api, country, data) => {
    let hasErrors = false;
    Object.keys(data).forEach(key => {
        const validationSpec = getValidationSpecForField(api, data, key, country);
        const errors = validateField(data[key].value, validationSpec);
        if (errors) {
            commit('setErrors', {key, errors})
        }
        hasErrors = hasErrors || Boolean(errors.length)
    })
    return !hasErrors;
}

/**
 * This method is invoked whenever a field is updated, to keep track of whether a field contains valid value.
 * This sets 'valid' flag in the specific api field in vuex store.
 * @param commit
 * @param api
 * @param data
 * @param country
 * @param key
 */
export const validateAndSetValid = (commit, api, data, country, key) => {
    const validationSpec = getValidationSpecForField(api, data, key, country);
    const errors = validateField(data[key].value, validationSpec);
    let action = errors.length ? 'setInvalid' : 'setValid';
    commit(action, key)
}


function getValidationSpecForField(api, data, key, country) {
    const countrySpecificValidationSpec = data[key].validationSpec;
    return countrySpecificValidationSpec ? countrySpecificValidationSpec[country] : getValidationSpec(api, data[key].apiPath);
}