/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import {validateAndGenerateError, validateAndSetValid} from "@/store/validationFromStore";
import {copyFromApiResponse, copyFromErrorResponse} from '@/store/storeMappers'
import {OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";


export const additionalUnderwritingDataDefaultState: OnboardingSectionData = {
    api: "createMerchant",
    errors: {},
    data: {
        billingDescriptor: {
            apiPath: "processing_info.statement_descriptor",
            value: null,
            valid: false
        },
    }
};

const getters = {
    isValid: (state) => Object.keys(state.data).every(key => state.data[key].valid)
}

const actions = {
    validate({commit, state, rootGetters}) {
        commit('resetErrors')
        return validateAndGenerateError(commit, state.api, rootGetters["businessAddress/getCountry"], state.data);
    },
    validateField({commit, state, rootGetters}, key) {
        validateAndSetValid(commit, state.api, state.data, rootGetters["businessAddress/getCountry"], key)
    },
    validateAllFields({state, dispatch}) {
        Object.keys(state.data).forEach(key => dispatch('validateField', key))
    },
    resetErrors({commit}) {
        commit('resetErrors');
    },
    setErrors({commit}, {key, errors}) {
        commit('setErrors', {key, errors});
    },
    updateBillingDescriptor({commit, dispatch}, event) {
        commit('updateBillingDescriptor', event.target.value);
        dispatch('validateField', 'billingDescriptor');
    },
    copyFromFieldErrorsResponse({commit}, subErrors) {
        commit('copyFromFieldErrorsResponse', subErrors)
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('copyFromMerchantApiResponse', merchantApiResponse)
    },
};

const mutations = {
    resetErrors(state): void {
        state.errors = {}
    },
    setErrors(state, {key, errors}): void {
        state.errors[key] = errors;
    },
    setInvalid(state, key) {
        state.data[key].valid = false;
    },
    setValid(state, key) {
        state.data[key].valid = true;
    },
    updateBillingDescriptor(state, billingDescriptor: string): void {
        state.data.billingDescriptor.value = billingDescriptor;
        delete state.errors.billingDescriptor;
    },
    copyFromFieldErrorsResponse: function (state, subErrors): void {
        state.errors = copyFromErrorResponse(subErrors, state.data)
    },
    copyFromMerchantApiResponse(state, merchantApiResponse): void {
        state.data = copyFromApiResponse(merchantApiResponse, state.data);
    }
};

export const additionalUnderwritingData = {
    namespaced: true,
    state: additionalUnderwritingDataDefaultState,
    getters,
    actions,
    mutations
};