export const FULL_STORY_OPTIONS = {
    debug: false,
    host: 'fullstory.com',
    orgId: 'A7EM7',
    namespace: 'FS',
  };
  
  export const SENTRY_ORG_NAME = 'cb-leap-ui';
  export const UI_INITIALIZE = "CBPAYMENTS_UI_INITIALIZE";
  export const BUSINESS_PROFILE_PAGE = "CBPAYMENTS_BUSINESS_PROFILE_PAGE";
  export const OPERATIONS_OWNERSHIP_PAGE = "CBPAYMENTS_OPERATIONS_OWNERSHIP_PAGE";
  export const TRANSACTION_SETTLEMENT_PAGE = "CBPAYMENTS_TRANSACTION_SETTLEMENT_PAGE";
  export const TERMS_AND_CONDITIONS_POPUP = "CBPAYMENTS_TERMS_AND_CONDITIONS_POPUP";
  export const MERCHANT_ONBOARD_SUBMIT = "CBPAYMENTS_MERCHANT_ONBOARD_SUBMIT";
  export const MERCHANT_MIGRATED_SUBMIT = "CBPAYMENTS_MERCHANT_MIGRATED_SUBMIT";
  export const MERCHANT_CREATED_SUCCESS = "CBPAYMENTS_MERCHANT_CREATED_SUCCESS";
  export const MERCHANT_MIGRATED_INITIATED = "CBPAYMENTS_MERCHANT_MIGRATED_INITIATED";
  export const MERCHANT_CREATED_FAILED = "CBPAYMENTS_MERCHANT_CREATED_FAILED";
  export const MERCHANT_MIGRATED_FAILED = "CBPAYMENTS_MERCHANT_MIGRATED_FAILED";
  export const MERCHANT_CREATED_SUCCESS_PAGE = "CBPAYMENTS_MERCHANT_CREATED_SUCCESS_PAGE";
  export const ONBOARDING_STATUS_PAGE = "CBPAYMENTS_ONBOARDING_STATUS_PAGE";
  export const EDIT_OWNER_INFO_PAGE = "CBPAYMENTS_EDIT_OWNER_INFO_PAGE";
  export const EDIT_BUSINESS_STRUCTURE_PAGE = "CBPAYMENTS_EDIT_BUSINESS_STRUCTURE_PAGE";
  export const EDIT_BANK_ACCOUNT_PAGE = "CBPAYMENTS_EDIT_BANK_ACCOUNT_PAGE";
  export const EDIT_BUSINESS_INFORMATION_PAGE = "CBPAYMENTS_EDIT_BUSINESS_INFORMATION_PAGE";
  export const UPLOAD_DOCUMENTS_PAGE = "CBPAYMENTS_UPLOAD_DOCUMENTS_PAGE";