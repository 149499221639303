<template>
  <div class="table-of-contents">
    <div class="title">
      {{ $t('tableOfContent.onThisPage') }}
    </div>
    <ul>
      <li class="section" v-for="section in sections" :key="section.id">
        <div class="section-member" @click="scrollToSection(section.id)" v-if="section.show === true">
          {{ section.title }}
        </div>
      </li>
    </ul>
  </div>
</template>


<script>

export default {
  name: "TableOfContents",
  props: {
    sections: {
      type: Array,
      required: true,
      default: undefined
    }
  },
  methods: {
    scrollToSection(id) {
      const element = document.getElementById(id);
      if (element != null) {
        element.scrollIntoView(
          {
            behavior: 'smooth',
            block: 'start',
          }
        );
        element.animate([
          { backgroundColor: 'initial' }, // Initial color
          { backgroundColor: '#eaecee' }  // Target color
        ], {
          duration: 1000,
          iterations: 1
        });
      }
    },
  }
}
</script>

<style lang="scss">

.table-of-contents {
  max-width: 250px;
  padding: 20px;

  ul {
    list-style: none;
  }

  & .title {
    font-style: normal;
    font-weight: $weight_bold;
    font-size: 12px;
    line-height: 18px;
    color: $neutral_400;
    text-align: left;
  }

  & .section {
    font-style: normal;
    font-weight: $weight_medium;
    font-size: 14px;
    line-height: 20px;
    color: $neutral_800;
    text-align: left;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}
</style>