import TransactionsIcon from "@/components/icons/TransactionsIcon.vue";
import DashboardIcon from "@/components/icons/DashboardIcon.vue";
import DepositIcon from "@/components/icons/DepositIcon.vue";
import DisputeIcon from "@/components/icons/DisputeIcon.vue";
import DownloadsIcon from "@/components/icons/DownloadsIcon.vue";
import NotificationsIcon from "@/components/icons/NotificationsIcon.vue";
import PersonIcon from "@/components/icons/PaymentProfileIcon.vue";

export const SidebarNavOptions = [
    {
        name: "dashboard",
        icon: DashboardIcon,
        route: "dashboard"
    },
    {
        name: "transactions",
        icon: TransactionsIcon,
        child:
            [
                {
                    parent: "transactions",
                    name: "payments",
                    route: "transactions",
                    childRoutes: ["transaction_details"]
                },
                {
                    parent: "transactions",
                    name: "authorizations",
                    route: "authorizations",
                    childRoutes: ["authorization_details"]
                }
            ]
    },
    {
        name: "deposits",
        icon: DepositIcon,
        route: "deposits",
        childRoutes: ["deposit_details"]
    },
    {
        name: "disputes",
        icon: DisputeIcon,
        route: "disputes",
        childRoutes: ["dispute_details"]
    },
    {
        name: "downloads",
        icon: DownloadsIcon,
        child:
            [
                {
                    parent: "downloads",
                    name: "reports",
                    route: "reports",
                },
                {
                    parent: "downloads",
                    name: "exports",
                    route: "exports",
                }
            ]
    },
    {
        name: "notifications",
        icon: NotificationsIcon,
        route: "notifications"
    },
    {
        name: "profile",
        icon: PersonIcon,
        route: "profile",
        childRoutes: ["compliance_form_details"]
    }
]