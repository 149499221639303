/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/

import Vue from "vue";

export const disputesDefaultState = {
    disputes: []
};

const disputesGetters = {
    getDisputes : state => state.disputes
}

const actions = {
    setDisputes({commit}, disputes) {
        commit('setDisputes', disputes);
    },
    updateDispute({commit}, {id, dispute}) {
        commit('updateDispute', {id, dispute})
    }
};

const mutations = {
    setDisputes(state, disputes): void {
        state.disputes = disputes;
    },
    updateDispute(state, {id, dispute}): void {
        const index = state.disputes.findIndex(x => x.id === id)
        if (index !== -1) {
            Vue.set(state.disputes, index, dispute)
        }
    }
};

export const disputes = {
    namespaced: true,
    state:disputesDefaultState,
    getters:disputesGetters,
    actions,
    mutations
};