import i18n from '@/i18n'
import {isValidDate} from "@/app/utils/common/functions/functions";


export const validateField = (value: string, validationSpecs?: Record<string, any>): string => {
    if (!validationSpecs) {
        return ""
    }
    const allResults: string[] = []
    Object.keys(validationSpecs).forEach(spec => {
        const validator = getValidator(spec);
        const result = validator(value, validationSpecs[spec]);
        if (result !== undefined && result !== null) {
            allResults.push(result)
        }
    })
    return allResults.join(". ")
};


const allowBlankValidator = () => {
    //do nothing
}

const mandatoryFieldValidator = (value, spec) => {
    if (value === null || value === undefined || value === "") {
        return i18n.t(`errors.${spec.message}`)
    }
}

export const lengthValidator = (value, spec) => {
    if (value === null || value === undefined) {
        return
    }

    if (spec.maximum === spec.minimum && value.length !== spec.maximum) {
        return i18n.t(`errors.validation_error.string.length.exact`, {num: spec.maximum})
    }
    if (value.length > spec.maximum) {
        return i18n.t(`errors.${spec.messages.max}`, {max: spec.maximum})
    }

    if (value.length < spec.minimum) {
        return i18n.t(`errors.${spec.messages.min}`, {min: spec.minimum})
    }
}

const enumValidator = (value, spec) => {
    if (value === null || value === undefined) {
        return
    }
    if (!spec.values.includes(value)) {
        return i18n.t(`errors.${spec.message}`)
    }
}

const strictIntValidator = (value, spec) => {
    if (value === null || value === undefined) {
        return
    }

    if (!(/^-?\d+$/.test(value))) {
        return i18n.t(`errors.${spec.message}`)
    }
}

const dateFormatValidator = (value, spec) => {
    if (value === null || value === undefined) {
        return
    }

    if (!isValidDate(value)) {
        return i18n.t(`errors.${spec.message}`)
    }
}

const emailValidator = (value, spec) => {
    if (value === null || value === undefined) {
        return
    }
    if (value.length <= 2) {
        return i18n.t(`errors.${spec.message}`)
    }
    if (value.indexOf("@") == -1) {
        return i18n.t(`errors.${spec.message}`)
    }

    const parts = value.split("@");
    const dot = parts[1].indexOf(".");
    const dotSplits = parts[1].split(".");
    const dotCount = dotSplits.length - 1;

    if (dot == -1 || dot < 2 || dotCount > 2) {
        return i18n.t(`errors.${spec.message}`)
    }

    for (const element of dotSplits) {
        if (element.length == 0) {
            return i18n.t(`errors.${spec.message}`)
        }
    }
}

const rangeValidator = (value, spec) => {
    if (value === null || value === undefined || value === "") {
        return
    }

    if (value > spec.maximum) {
        return i18n.t(`errors.${spec.messages.max}`, {max: spec.maximum})
    }
    if (value < spec.minimum) {
        return i18n.t(`errors.${spec.messages.min}`, {min: spec.minimum})
    }
};

const formatValidator = (value, spec) => {
    if (value === null || value === undefined || value === "") {
        return
    }

    if (!new RegExp(spec.with).test(value)) {
        return i18n.t(`errors.${spec.message}`)
    }
};

const regexValidator = (value, spec) => {
    if (value === null || value === undefined || value === "") {
        return
    }

    if (!new RegExp(spec.with).test(value)) {
        return i18n.t(`errors.${spec.message}`, {format: spec.format})
    }
};
const getValidator = (name) => {

    const validators = {
        presence: mandatoryFieldValidator,
        length: lengthValidator,
        allow_blank: allowBlankValidator,
        enum: enumValidator,
        strictInt: strictIntValidator,
        dateFormat: dateFormatValidator,
        email: emailValidator,
        range: rangeValidator,
        format: formatValidator,
        regex: regexValidator,
    }

    return validators[name];
}