<template>
  <div class="compliance-form-audit-logs">
    <div class="title">
      Audit Logs
    </div>

    <div class="fields" v-if="complianceForm">
      <div class="column">
        <FieldValue
          v-if="inProgressOrCompleteStatus"
          class-name="attested-on"
          :field-value="formatDateWithTimezone(complianceForm.attestation_details.signed_at.toString())"
          :field-name="$t('fieldNames.auditLogAttestedOn')"
        />
        <FieldValue
          class-name="created-on"
          :field-value="formatDateWithTimezone(complianceForm.created_at.toString())"
          :field-name="$t('fieldNames.auditLogCreatedOn')"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { ComplianceFormResponseDto } from "@/api/paymentApi";
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";

export default {
  name: 'ComplianceFormAuditLogs',
  components: { FieldValue },
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true,
    },
    inProgressOrCompleteStatus: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type:'zonedDateWithTime', data: date})
    }
  }
}
</script>

<style lang="scss">

.compliance-form-audit-logs {

  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
    line-height: 30px;
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;

      & .field-label {
        color: $neutral_800;
        font-size: 14px;
      }

      & .field-value {
        color: $neutral_600;
        font-size: 12px;
      }
    }
  }
}
</style>