<template>
  <TableView
    :data="complianceforms"
    :loading="loading"
    :all-items-loaded="allItemsLoaded"
    :infinite-scroll="infiniteScroll"
    :fixed-first-column="false"
    class="compliance-forms-table"
    entity="compliance-forms"
    :columns="columns"
    :column-right-align="[]"
    :clickable-rows="clickableRows"
    @row-clicked="rowClicked"
  >
    <template #id="prop">
      <div class="id-column">
        {{ prop.item.id }}
      </div>
    </template>
    <template #created_on="prop">
      <ToolTip
        placement="top"
        :content="formatter({type : 'timeWithZone', data: prop.item.created_at})"
        :dismiss-on-click="true"
      >
        <div class="created-on">
          {{ formatter({type : 'zonedDate', data: prop.item.created_at}) }}
        </div>
      </ToolTip>
    </template>
    <template #form_type="prop">
      <ToolTip
        placement="top"
        :content="prop.item.compliance_form_type"
        :dismiss-on-click="true"
        :enabled="prop.item.compliance_form_type!==null"
      >
        {{ getComplianceFormType(prop.item.compliance_form_type) }}
      </ToolTip>
    </template>
    <template #due_on="prop">
      <div class="empty-column" v-if="inProgressOrCompleteStatus(prop.item)">
        {{ '-' }}
      </div>

      <ToolTip
        placement="top"
        v-else
        :content="formatter({type : 'timeWithZone', data: prop.item.due_at})"
        dismiss-on-click="true"
      >
        {{ formatter({type : 'zonedDate', data: prop.item.due_at}) }}
      </ToolTip>
    </template>
    <template #valid_until="prop">
      <div class="empty-column" v-if="prop.item.valid_until == null">
        {{ '-' }}
      </div>

      <ToolTip
        placement="top"
        :content="formatter({type : 'timeWithZone', data: prop.item.valid_until})"
        :dismiss-on-click="true"
      >
        {{ formatter({type : 'zonedDate', data: prop.item.valid_until}) }}
      </ToolTip>
    </template>
    <template #status="prop">
      <div class="status-tag-container">
        <ComplianceFormStatusTag :compliance-form="prop.item" />
      </div>
    </template>
  </TableView>
</template>

<script>

import TableView from "@/components/common/TableView.vue";
import { mapGetters } from "vuex";
import Formatter from "@/app/utils/common/functions/formatter";
import { navigateWithParam} from "@/router";
import ComplianceFormStatusTag from "@/components/complianceforms/ComplianceFormStatusTag.vue";
import {RouteConstants} from "@/router/routeConstants";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: 'ComplianceFormTable',
  components: { ComplianceFormStatusTag, TableView, ToolTip},
  props: {
    complianceforms: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    allItemsLoaded: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Object,
      required: true,
    },
    infiniteScroll: {
      type: Boolean,
      required: true,
    }
  },
  data: () => {
    return {
      clickableRows: true
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
  },
  methods: {
    formatter: Formatter.format,
    rowClicked: function(id, data) {
      navigateWithParam(RouteConstants.COMPLIANCE_FORM_DETAILS, {complianceFormId: id, data}, this.$route.query);
    },
    getComplianceFormType(data) {
      return data.replace(/_/g, ' ');
    },
    inProgressOrCompleteStatus(complianceForm) {
      return (complianceForm.compliance_form_status === 'INCOMPLETE' &&
          complianceForm.attestation_details.is_accepted) ||
        (complianceForm.compliance_form_status === 'COMPLETE');
    },
  },
}

</script>

<style lang = "scss">

.compliance-forms-table {

  & .type-column {
    display: flex;

    & .type {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_1000;
      width: 100%;
      justify-content: start;

      & .empty-place-holder {
        color: $neutral_400
      }
    }
  }

  & .id-column {
    display: flex;
    text-align: left;
    padding: 16px 0px;
    font-weight: $weight_normal;
    color: $neutral_800;
    font-size: 14px;
    line-height: 20px;
  }

  & .created-on {
    display: flex;
    text-align: left;
    font-weight: $weight_normal;
    color: $neutral_800;
    font-size: 14px;
    line-height: 20px;
  }

  & .status-tag-container {
    display: flex;
    text-align: left;
    min-width: 70px;
    flex-direction: row;
    justify-content: space-between;
  }

  & .empty-column {
    text-align: left;
  }
}

</style>