<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.1207 12.7451H15.853V27.6008H24.1207V12.7451Z" fill="#FF5F00" />
    <path d="M16.378 20.1732C16.378 17.286 17.7166 14.5301 19.9738 12.7454C15.8793 9.517 9.92129 10.2257 6.69294 14.3464C3.49084 18.4409 4.1995 24.3726 8.32024 27.601C11.7586 30.3044 16.5617 30.3044 20 27.601C17.7166 25.8162 16.378 23.0603 16.378 20.1732Z" fill="#EB001B" />
    <path d="M35.2756 20.1732C35.2756 25.3963 31.0499 29.622 25.8268 29.622C23.7008 29.622 21.6535 28.9133 20 27.601C24.0945 24.3726 24.8031 18.4409 21.5748 14.3202C21.1024 13.7427 20.5774 13.1915 20 12.7454C24.0945 9.517 30.0525 10.2257 33.2546 14.3464C34.5669 15.9999 35.2756 18.0472 35.2756 20.1732Z" fill="#F79E1B" />
  </svg>
</template>
<script>
export default{
  name: "MastercardIcon"
}
</script>