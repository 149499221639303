<template>
  <div class="dispute-details-form">
    <div class="dispute-details-title">
      Details
    </div>
    <div class="dispute-details-content">
      <div class="row">
        <DateInput
          label="Purchase Date"
          :value="disputeResponse.service_details.purchase_date"
          id="purchase-date"
          :error="error.purchase_date"
          :set-value="(date) => updateDisputeDetail('purchase_date', date)"
          :show-mandatory="true"
          :use-milliseconds="false"
        />
        <DateInput
          label="Service Date"
          :value="disputeResponse.service_details.service_date"
          id="service-date"
          :error="error.service_date"
          :set-value="(date) => updateDisputeDetail('service_date', date)"
          :show-mandatory="true"
          :use-milliseconds="false"
        />
      </div>

      <InputField
        label="Service Type"
        :value="disputeResponse.service_details.type_of_service"
        :set-value="(event) => updateDisputeDetail('type_of_service', event.target.value)"
        id="shipping-tracking-number"
        :error="error.type_of_service"
        :show-mandatory="true"
      />
      
      <InputTextAreaField
        label="Service description"
        :value="disputeResponse.service_details.description"
        id="purchase-details"
        data-testid="purchase-details"
        :error="error.description"
        hint="Specify the goods or services that were purchased. Include links, where relevant."
        :set-value="(value) =>updateDisputeDetail('description', value)"
        :show-mandatory="true"
      />
      <InputTextAreaField
        label="Refund Policy Details"
        :value="disputeResponse.service_details.refund_policy_details"
        id="refund-policy-details"
        data-testid="refund-policy-details"
        :error="error.refund_policy_details"
        hint="Specify how the refund policy does not apply to this purchase. Include links, where relevant."
        :set-value="(value) => updateDisputeDetail('refund_policy_details', value)"
        :show-mandatory="true"
      />
    </div>
  </div>
</template>

<script>
import InputTextAreaField from "@/components/common/InputTextAreaField";
import InputField from "@/components/common/InputField";
import DateInput from "@/components/common/DateInput";

export default {
  name: "DisputeDetailsServicesInput",
  components: {InputField, InputTextAreaField, DateInput},
  props: {
    disputeResponse: {
      type: Object,
      required: true
    },
    updateDisputeDetail: {
      type: Function,
      required: true
    },
    error: {
      type: Object,
      required: true
    }
  },
}
</script>
<style lang="scss">
.dispute-details-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 20px;

  & .dispute-details-title {
    font-size: 16px;
    font-weight: $weight_bold;
  }

  & .row {
    display: flex;
    gap: 20px;
    & .field {
      flex: 1;

      & .c-dropdown {
        width: 100%;
      }
    }
  }

}
</style>