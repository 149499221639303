<template>
  <div class="choose-application">
    <table aria-describedby="Show applications">
      <tr>
        <th>Application name</th>
      </tr>
      <tr
        v-for="application in applications"
        :key="application.name"
        @click="chooseApplication(application);"
        class="app-row"
      >
        <td> {{ application.name }}</td>
        <td class="select">
          Select
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import Applications from "@/data/applications";
import {mapActions} from "vuex";

export default {
  name: "ChooseApplication",
  computed: {
    applications() {
      return Applications[process.env.VUE_APP_BUILD_ENV];
    }
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    chooseApplication(application) {
      this.updateAppState({
        key: 'recentlySelectedMerchants',
        value: [],
      })
      this.updateAppState({key: 'applicationName', value: application.name})
    }
  }
}
</script>

<style lang="scss">
.choose-application {
  & .title {
    font-size: 14px;
    margin-top: 8px;
    font-weight: $weight-bold;
    color: $primary_text_dark;
  }

  & table {
    margin-top: 50px;
    border: 1px solid #E5E7EB;
    border-radius: 10px;

    text-align: left;
    border-spacing:0;
    th {
      color: $subtitle_text;
      font-size: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      padding: 20px 100px;
    }
    td {
      padding: 20px 100px;
    }
    tr:nth-child(even) {
      background-color: #F9FAFB;
    }

    & .select {
      font-weight: $weight-normal;
      color: $primary-600;
    }

    & .app-row {
      cursor: pointer;
      &:hover {
        background-color: $primary-50;
      }
    }
  }
}
</style>