<template>
  <div class="choose-merchant">
    <div class="loading-container" v-if="loading">
      <div class="loader"></div>
      Fetching merchants...
    </div>
    <div v-else>
      <div class="title-and-input">
        <div class="title-container">
          <div class="select-merchant">
            <div class="select-merchant-label">
              Select a Merchant
            </div>
            <div @click="closePopup" v-if="showClosePopupButton">
              <img class="search-clear-icon" src="@/app/assets/images/search_clear.svg" alt="search-clear" />
            </div>
          </div>
          <div class="switch-application">
            <span class="application-name">Application: {{ getApplicationName }}</span>
            <span class="switch-link" @click="navigateToHome">Switch</span>
          </div>
        </div>
      </div>
      <Search
        placeholder="Search merchant"
        @search="searchMerchant"
        id="search-merchant"
        class="search-container"
      />
      <div class="table-title" v-if="merchants.length !== 0">
        {{ tableTitle }}
      </div>
      <div class="table-container" v-if="merchants.length !== 0">
        <table aria-describedby="Show merchants" class="merchants-table">
          <tr>
            <th>Legal Business Name</th>
            <th>Merchant Category Code</th>
            <th>Payfac</th>
          </tr>
          <tr
            v-for="merchant in merchants"
            :key="merchant.id"
            @click="chooseMerchant(merchant);"
            class="app-row"
            :class="{'selectable': getMerchantId !== merchant.id}"
          >
            <td>
              {{ merchant.business_info.name }}
            </td>
            <td>
              {{ getMccName(merchant.business_info.mcc) }}
            </td>
            <td>
              {{ merchant.payfac }}
            </td>
            <td :class="{select: getMerchantId !== merchant.id}">
              {{ getMerchantId === merchant.id ? "Current Merchant" : "Select" }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Applications from "@/data/applications";
import {mapActions, mapGetters} from "vuex";
import merchants from "@/api/merchants";
import {navigateTo} from "@/router";
import Search from "@/components/common/Search";
import mccCodes from "@/data/mcc-codes";
import {RouteConstants} from "@/router/routeConstants";
import {getAndStoreMerchantConfiguration} from "@/service/sessionService";
import {storeMerchantDetails} from "@/service/saveInStore";

export default {
  name: "ChooseMerchant",
  components: {Search},
  props: {
    showClosePopupButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      searchQuery: "",
      searchResults: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters('app', ['getRecentlySelectedMerchants', 'getMerchants', 'getApplicationName', 'getMerchantId']),
    applications() {
      return Applications[process.env.VUE_APP_BUILD_ENV];
    },
    merchants() {
      return this.searchQuery === ""
          ? this.recentlySelectedMerchants : this.searchResults;
    },
    recentlySelectedMerchants() {
      return this.getRecentlySelectedMerchants.map(x => this.getMerchants.find(m => m.id === x)) || []
    },
    tableTitle() {
      return this.searchQuery === ""
          ? "Recently searched by you" : "Search results";
    }
  },
  async mounted() {
    try {
      const merchantsList = await merchants.listMerchants(undefined, 100);
      this.loading = false;
      this.updateAppState({key: 'merchants', value: merchantsList.merchants})
    } catch (err) {
      navigateTo(RouteConstants.ERROR)
    }
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    ...mapGetters('app', ['isAdminMode']),
    closePopup() {
      this.$emit('closePopup');
    },
    getMccName(code) {
      return mccCodes.find(x => x.value === code)?.displayText;
    },
    searchMerchant(searchTerm) {
      const searchPrefix = "Q:";
      const searchKeyValueSeparator = "=";
      this.searchQuery = searchTerm;
      if (searchTerm && searchTerm.startsWith(searchPrefix)) {
        let query = searchTerm.split(searchPrefix)[1];
        if (query && query.includes(searchKeyValueSeparator)) {
          let [searchKey, expectedValue] = query.split(searchKeyValueSeparator);
          if (searchKey && expectedValue) {
            this.searchResults = this.getMerchants.filter(merchantEntry => {
              let actualValue = searchKey.split('.').reduce((filteredResult, field) => filteredResult?.[field], merchantEntry);
              return actualValue && actualValue.toLowerCase().includes(expectedValue.toLowerCase());
            });
            return;
          } 
        }
      }
      this.searchResults = this.getMerchants
          .filter(x => x.business_info.name.toLowerCase().includes(searchTerm.toLowerCase()))
    },
    navigateToHome() {
      this.updateAppState({key: "applicationName", value: null})
      this.updateAppState({key: "merchantId", value: null})
      this.updateAppState({key: "merchantName", value: null})
      navigateTo(RouteConstants.ADMIN)
    },
    async chooseMerchant(merchant) {
      storeMerchantDetails(merchant);
      this.updateAppState({key: 'merchantId', value: merchant.id});
      this.updateAppState({key: 'payfac', value: merchant.payfac});
      this.updateAppState({
        key: 'recentlySelectedMerchants',
        value: [...new Set([...this.getRecentlySelectedMerchants, merchant.id])],
      });
      await getAndStoreMerchantConfiguration(this.getMerchantId)
      navigateTo(RouteConstants.DASHBOARD, true, {
        adminMode: true,
        applicationName: this.getApplicationName,
        merchantId: merchant.id,
        merchantName: merchant.business_info.name
      })
    },
  },
}

</script>

<style lang="scss">
.choose-merchant {
  width: 600px;

  & .select-merchant {
    display: flex;
    width: 100%;

    & .select-merchant-label {

      & .label {
        color: $subtitle-text;
        font-weight: $weight-normal;
      }

      flex: 1;
      flex-basis: 95%;
      font-size: 16px;
      font-weight: $weight-bold;
      text-align: left;
    }

    & .search-clear-icon {
      cursor: pointer;
      display: block;
    }
  }

  & .switch-application {
    & .switch-link {
      margin-left: 20px;
      color: $primary-600;
      cursor: pointer;
    }
  }

  & .title-container {
    align-items: start;
    margin-top: 50px;
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .title {
      font-size: 16px;
      font-weight: $weight-bold;
      color: $primary_text_dark;
      text-align: left;
    }
  }

  & .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 3px solid #D4D4E8;
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  & .search-container{
    width: 600px;
  }

  & .table-title {
    margin-top: 40px;
    color: $primary_text_dark;
    font-size: 14px;
    font-weight: $weight_medium;
    text-align: left;
    margin-bottom: 12px;
  }

  & .table-container {
    height: 320px;
    overflow: scroll;
    border: 1px solid #E5E7EB;
    border-radius: 10px;
    border-spacing: 0;
  }

  & table {

    text-align: left;
    width: 100%;

    th {
      color: $subtitle_text;
      font-size: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      padding: 20px;
      font-weight: $weight-medium;
    }

    td {
      padding: 20px;
      justify-content: center;
    }

    td:last-child{
      text-align: center;
    }

    td:first-child {
      word-break: break-word;
    }

    tr:nth-child(even) {
      background-color: #F9FAFB;
    }

    & .select {
      font-weight: $weight-normal;
      color: $primary-600;
    }

    & .app-row.selectable {
      cursor: pointer;

      &:hover {
        background-color: $primary_50;
      }
    }
  }
}
</style>