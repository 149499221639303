<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_19327_26504" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
      width="20" height="20"
    >
      <rect width="20" height="20" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_19327_26504)">
      <path d="M4.16667 17.5C3.70833 17.5 3.31583 17.3369 2.98917 17.0108C2.66306 16.6842 2.5 16.2917 2.5 15.8333V4.16667C2.5 3.70833 2.66306 3.31583 2.98917 2.98917C3.31583 2.66306 3.70833 2.5 4.16667 2.5H10V4.16667H4.16667V15.8333H15.8333V10H17.5V15.8333C17.5 16.2917 17.3369 16.6842 17.0108 17.0108C16.6842 17.3369 16.2917 17.5 15.8333 17.5H4.16667ZM8.08333 13.0833L6.91667 11.9167L14.6667 4.16667H11.6667V2.5H17.5V8.33333H15.8333V5.33333L8.08333 13.0833Z" fill="currentColor" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LinkIcon',
}
</script>