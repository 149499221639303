<template>
  <div class="funds-reserve-details-card-overview">
    <div class="funds-reserve-header">
      <div class="funds-reserve-title">
        <span>{{ fundReserveName }} </span>
      </div>
      <div class="funds-reserve-balance-details">
        <span>{{ $t('addFundsToReserve.labels.balance') }}</span>
        <div class="funds-reserve-balance">
          {{ formatter({
            type: "currency",
            data: remainingFundsInReserve,
            currencyCode: getMerchantCurrency})
          }}
        </div>
      </div>
    </div>
    <div v-if="incomingFundsToReserve" class="incoming-funds-balance-details">
      <span>{{ $t('addFundsToReserve.labels.incomingFunds') }}</span>
      <div class="incoming-funds-balance">
        {{ formatter({
          type: "currency",
          data: incomingFundsToReserve,
          currencyCode: getMerchantCurrency})
        }}
      </div>
      <div class="incoming-funds-balance-status">
        <span>{{ $t('addFundsToReserve.labels.Pending') }}</span>
      </div>
    </div>
    <div class="reserve-funds-info-panel">
      <c-icon name="information" :size="15" />
      <div class="reserve-funds-info-text">
        {{ infoForFundsReserve }}
      </div>
    </div>

    <InputField
      label="Amount"
      id="amount"
      :append-slot="getMerchantCurrency"
      prepend-slot="$"
      type="number"
      :value="enteredAmount"
      :set-value="setValue"
      :hint="hint"
      :error="error"
    />
  </div>
</template>

<script>

import Formatter from "@/app/utils/common/functions/formatter";
import InputField from "@/components/common/InputField.vue";
import { mapGetters } from "vuex";

export default {
  name: "FundsReserveDetailsCard",
  components: {InputField},
  props: {
    fundReserveName: {
      type: String,
      required: true
    },
    remainingFundsInReserve: {
      type: Number,
      required: true,
    },
    incomingFundsToReserve: {
      type: Number,
      required: true
    },
    infoForFundsReserve: {
      type: String,
      required: true
    },
    enteredAmountFromStore: {
      type: Number,
      required: false,
      default: null
    },
    hint: {
      type: String,
      required: false,
      default: undefined
    },
    error: {
      type: String,
      required: true,
    }
  },
  data: function() {
    return {
      enteredAmount: null
    }
  },
  computed:{
    ...mapGetters('app', ['getMerchantCurrency'])
  },
  mounted() {
    if (this.enteredAmountFromStore !== 0 &&  this.enteredAmountFromStore !== null
        && this.enteredAmountFromStore !== undefined) {
      this.enteredAmount = this.enteredAmountFromStore.toString();
    }
  },
  methods: {
    formatter: Formatter.format,
    setValue(event) {
      this.enteredAmount = event.target.value;
      this.$emit('enteredAmount', this.enteredAmount);
    }
  }
}
</script>

<style lang="scss" scoped>
.funds-reserve-details-card-overview{
  margin-top: 12px;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid $neutral_100;

  & .funds-reserve-header {
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    & .funds-reserve-title {
      color: $neutral_800;
      font-size: 16px;
      font-weight: $weight_bold;
    }

    & .funds-reserve-balance-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      font-weight: $weight_normal;
      color: $neutral_600;

      & .funds-reserve-balance {
        margin-left: 4px;
      }
    }
  }

  & .incoming-funds-balance-details {
    display: flex;
    align-items: center;
    color: $neutral_600;
    font-size: 14px;
    font-weight: $weight_normal;
    margin-top: 16px;

    & .incoming-funds-balance {
      margin-left: 4px;
    }

    & .incoming-funds-balance-status {
      margin-left: 4px;
      font-size: 12px;
      padding: 2px 10px;
      font-weight: $weight_medium;
      border-radius: 10px;
      background-color: $primary_100;
      color: $primary_800;
    }
  }

  & .reserve-funds-info-panel {
    display: flex;
    align-items: start;
    gap: 10px;
    margin-top: 16px;

    & .c-icon {
      width: 18px;
      color: $info_400;
    }

    & .reserve-funds-info-text {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: $weight_normal;
      color: $info_600;
    }

  }
}
</style>