<template>
  <div class="chart-holder">
    <div class="chart-wrapper">
      <chart :option="chartOptionsLine" id="charts-custom" />
    </div>
    <div v-if="chartOptionsLine && chartOptionsLine.xAxis.data.length > 0" class="axis-label-container">
      <div class="left-label">
        {{ leftLabel() }}
      </div>
      <div class="right-label">
        {{ rightLabel() }}
      </div>
    </div>
  </div>
</template>

<script>

import {graphic} from "echarts";
import {Interval, Metric} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import {NEUTRAL_50, PRIMARY_400} from "@chargebee/cookie-tokens";
import {getTimezoneName} from "@/app/utils/common/functions/dateUtils";
import { mapGetters } from "vuex";

export default {
  name: "StatisticsChart",
  props: {
    chartData: {
      type: Array,
      required: true
    },
    intervalType: {
      type: String,
      required: false,
      default: Interval.DAY
    },
    statisticsType: {
      type: String,
      required: true,
    }
  },
  data: () => {
    return {
      chartOptionsLine: undefined,
      time: ["1 AM", "2 AM ", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM",
        "1 PM", "2 PM ", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM", "12 AM"],
    }
  },
  computed:{
    ...mapGetters('app', ['getMerchantCurrency'])
  },
  mounted() {
    this.setData(this.getMerchantCurrency);
    if (this.intervalType === Interval.HOUR) {
      this.setupAxisDataForHourInterval();
    } else {
      this.setupAxisDataForDayInterval();
    }
  },
  methods: {
    setData(merchantCurrency) {
      this.chartOptionsLine = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#FFFFFF',
          extraCssText: 'border: 1px solid #ECECF8; border-radius: 4px;',
          formatter: function (params) {
            let x = params[0].name;
            let type = x.substr(0, x.indexOf('-'));
            x = x.substr(x.indexOf('-') + 1);
            if(x === '11 PM - 12 AM') x = '11 PM - 11:59 PM'
            let dashboardMetricCurrencyFormat = [Metric.GROSSVOLUME, Metric.DEPOSITS, Metric.REFUNDS];
            let dashboardMetricPercentageFormat = [Metric.CHARGEBACKRATE, Metric.PAYMENTACCEPTANCERATE];
            let y = params[0].value;
            if (dashboardMetricCurrencyFormat.includes(type)) {
              y = Formatter.format({type : "currency", data:  y, currencyCode: merchantCurrency});
            } else if (dashboardMetricPercentageFormat.includes(type)) {
              y = y + "%";
            }
            return '<div style="color: #64648C;">' + x + '</div> <div style="color: #141428;">' + y + '</div>'
          },
        },
        grid: {
          top: "5%", bottom: "5%", right: "1%", left: "1%", height: "auto", width: "auto",
        },
        animationDuration: 500,
        xAxis: {
          data: [],
          splitLine: {
            show: true,
            interval: 0,
            lineStyle: {
              color: NEUTRAL_50
            }
          },
          axisTick: {
            alignWithLabel: true,
            show: false
          },
          axisLabel: {
            show: false,
            interval: 1,
          },
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          show: false
        },
        series: {
          type: "line",
          smooth: true,
          stack: "a",
          showSymbol: false,
          symbol: "circle",
          data: [],
          lineStyle: {
            color: PRIMARY_400,
            width: 2,
          },
          areaStyle: {
            color: graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: NEUTRAL_50
              },
              {
                offset: 1,
                color: NEUTRAL_50
              }])
          }
        },
        color: ["#127ac2"]
      }
    },
    setupAxisDataForDayInterval() {
      const xAxisData = [];
      const yAxisData = [];
      this.chartData.forEach(item => {
        let x = new Date(item.time);
        let y = item.value;
        xAxisData.push(this.statisticsType + "-" + x.toLocaleString('en-US', {
              month: 'long',
              timeZone: getTimezoneName()
            }).slice(0, 3) + " "
            + new Date(x.toLocaleString('en-US', {timeZone: getTimezoneName()})).getDate());
        yAxisData.push(y);
      });
      this.chartOptionsLine.xAxis.data = xAxisData;
      this.chartOptionsLine.series.data = yAxisData;

      let interval = 1;
      if (this.intervalType !== Interval.HOUR) {
        interval = 10;
      }
      this.chartOptionsLine.xAxis.splitLine.interval = (xAxisData.length) / interval;
    },
    setupAxisDataForHourInterval() {
      const xAxisData = [];
      const yAxisData = [];
      for (let i = 0; i < 24; i++) {
        yAxisData.push(0);
        xAxisData.push(this.statisticsType + "-" + this.time[(i + 23) % 24] + " - " + this.time[i]);
      }
      this.chartData.forEach((item) => {
        let y = item.value;
        if(item.time !== undefined) {
          let x = new Date(item.time.toLocaleString('en-US', {month: 'long', timeZone: getTimezoneName()}));
          yAxisData[new Date(x).getHours()] += y;
        }
      });
      this.chartOptionsLine.xAxis.data = xAxisData;
      this.chartOptionsLine.series.data = yAxisData;
    },
    leftLabel() {
      const leftLabel = this.chartOptionsLine.xAxis.data[0].substr(this.chartOptionsLine.xAxis.data[0].indexOf('-') + 1)
      return leftLabel === "Inv NaN" ? "" : leftLabel;
    },
    rightLabel(){
      const length = this.chartOptionsLine.xAxis.data.length;
      const rightLabel = this.chartOptionsLine.xAxis.data[length - 1].substr(this.chartOptionsLine.xAxis.data[length - 1].indexOf('-') + 1);
      if(rightLabel === "Inv NaN") return "";
      return rightLabel === "11 PM - 12 AM" ? "11 PM - 11:59 PM" : rightLabel ;
    }
  }
};
</script>

<style lang="scss">
.axis-label-container {
  display: flex;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;

  & .left-label {
    flex: 1;
    text-align: start;
  }

  & .right-label {
    flex: 1;
    text-align: end;
  }
}

.chart-wrapper {
  width: 100%;
  height: 120px;

  & .echarts {
    width: inherit;

    & :first-child {
      width: inherit;

      & :first-child {
        width: inherit;
      }
    }
  }
}
</style>