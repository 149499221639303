<template>
  <div class="beneficiaries-container">
    <div
      class="beneficiary-item"
      v-for="item in filteredBeneficiaries"
      :key="item.index"
    >
      <div class="column-name">
        <div class="beneficiary-name">
          {{ item.beneficiary.firstName.value }}&nbsp;{{ item.beneficiary.lastName.value }}
        </div>
        <div class="beneficiary-email fs-mask">
          <img src="@/app/assets/images/email.svg" alt="Chargebee Email Icon" />
          {{ item.beneficiary.emailAddress.value }}
        </div>
      </div>
      <div class="column-id">
        <div class="ownership-percentage">
          {{ $t('beneficiary.ownership') }} -
          {{ item.beneficiary.ownershipPercentage.value }}%
          <span id="pipe" v-if="!item.isControlPerson && item.beneficiary.businessName.value"> | </span>
          <span id="business-name" v-if="!item.isControlPerson"> {{ item.beneficiary.businessName.value }}</span>
        </div>
        <div class="tax-id fs-mask">
          {{ $t('beneficiary.taxId') }} -
          {{ maskTaxId(item.beneficiary.taxId.value, item.beneficiary) }}
        </div>
      </div>
      <div class="column-dots dots">
        <c-popover
          :arrow="false"
          v-bind="openOptions"
          :primary-action="(item.isControlPerson || readOnly) ? null : $t('global.action.delete')"
          :secondary-action="$t('global.action.edit')"
          @primary="() => deleteOwner(item.index)"
          @cancel="() => editAction(item.index)"
        >
          <c-tag class="c-cursor-pointer" slot="trigger">
            <c-icon name="dots-horizontal" size="16" />
          </c-tag>
        </c-popover>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {maskTaxId} from "@/app/utils/common/functions/functions";

export default {
  name: "BeneficiariesList",
  props: {
    editAction: {
      type: Function,
      required: true
    },
    deleteAction: {
      type: Function,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false
    },
    showControlPerson: Boolean,
    showAdditionalOwners: Boolean,
  },
  data: () => ({
    openOptions: false,
    blockOwnerDelete: false
  }),
  computed: {
    ...mapGetters('stakeholdersDetails', [
      'isBeneficiaryValid',
      'getBeneficiaries',
    ]),
    // preserve index while filtering. Because currently beneficiaries are uniquely identified by their index
    filteredBeneficiaries() {
      return this.getBeneficiaries
          .map((x, index) => ({index, beneficiary: x, isControlPerson: index === 0}))
          .filter((x) => this.isBeneficiaryValid(x.index))
          .filter(x => {
            if (this.showControlPerson) {
              return x.isControlPerson
            }

            if (this.showAdditionalOwners) {
              return !x.isControlPerson
            }
          })
    },
  },
  methods: {
    maskTaxId: maskTaxId,
    deleteOwner(i) {
        this.deleteAction(i);
    }
  },
}
</script>

<style lang="scss">
.beneficiaries-container {
  width: 100%;
  margin-bottom: 20px;

  & .beneficiary-item {

    border-top: 1px solid #E5E7EB;

    &:first-child {
      border: none;
    }

    & .column-name {
      flex: 1 1 50%;
    }

    & .column-id {
      flex: 1 1 45%;
    }

    & .column-dots {
      flex: 1 1 5%;
    }

    & .beneficiary-item-column {
      flex: 1;
    }

    display: flex;
    justify-content: space-between;
    padding: 20px;

    & .beneficiary-name {
      color: $primary-600;
      font-size: 14px;
      font-weight: $weight_medium;
      margin-bottom: 8px;
    }

    & .beneficiary-email {
      color: $subtitle-text;
      font-size: 14px;
      display: flex;
      gap: 20px;
    }

    & .ownership-percentage {
      margin-bottom: 8px;

      & #pipe {
        color: $subtitle-text;
        margin-left: 10px;
        margin-right: 10px;
      }

      & #business-name {
        text-overflow: ellipsis;
        overflow: hidden;
        overflow-wrap: anywhere;
      }
    }

    & .tax-id {
      color: $subtitle-text;
    }

    & .dots {

      & .c-cursor-pointer {
        border: none;
        background: none;
      }

      & .c-popover__footer {
        display: flex;
        flex-direction: column;
        margin-top: 0;
      }

      & .c-popover__wrap {
        min-width: 150px;
        padding: 0;

        display: flex;
        flex-direction: column;
        margin-top: 10px;

        & button {
          background: none;
          width: 100%;
          border: none;
          box-shadow: none;
          border-radius: 0;
          padding: 16px;

          &:hover {
            background-color: $primary_fill_light;
          }

          & .c-button__label {
            display: block;
            text-align: left;
          }
        }

        & .c-button--secondary {
          color: $color_black;
        }

        & .c-button--primary {
          color: $color_red;
        }

        & .c-button--primary:hover {
          background-color: #FEF2F2;
        }

        & button.disabled {
          color: $disablea_text;
          opacity: 0.7;
          &:hover {
            background: none;
          }
        }
      }
    }
  }
}
</style>