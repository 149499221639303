<template>
  <div class="settlement-overview">
    <div class="title">
      Overview
    </div>

    <div class="warning" v-if="getFee() === null">
      <span class="icon"><img src="@/app/assets/images/amber-exclamation.svg" alt="Warning" /></span>
      <span>We did not deduct a fee amount, in part or in whole, from this deposit. We will create a separate debit entry for it.</span>
    </div>

    <div class="fields" v-if="settlement">
      <div class="column">
        <FieldValue
          :field-value="formatTransactionsTimelineForASettlement(settlement.transactions_timeline, 'zonedDate')"
          :field-name="$t('fieldNames.transactionsDate')"
        />
        <FieldValue
          :field-value="formatDateWithTimezone(settlement.settled_at.toString())"
          :field-name="$t('fieldNames.depositDate')"
        />
      </div>
      <div class="column">
        <FieldValue
          :field-value="getTotalAmount()"
          :field-name="$t('fieldNames.totalAmount')"
        />
        <FieldValue
          :field-value="getFee()"
          :field-name="$t('fieldNames.deductions')"
        />
        <FieldValue
          :field-value="getDepositAmount()"
          :field-name="$t('fieldNames.depositAmount')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";
import {DepositSubType, SettlementDto} from "@/api/paymentApi";

export default {
  name: "DepositOverview",
  components: {FieldValue},
  props: {
    settlement: {
      type: SettlementDto,
      required: true,
    },
  },
  data: function () {
    return {}
  },
  methods: {
    formatTransactionsTimelineForASettlement: Formatter.formatTransactionsTimelineForASettlement,
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    getFee() {
      let fee = this.settlement.total_fee;
      if (fee === undefined || fee === null) {
        return null;
      }
      return this.format('currency', fee, '', this.settlement.currency) + " " + this.settlement.currency
    },
    getTotalAmount() {
      return Formatter.format({
        type: 'currency',
        data: this.settlement.total_amount,
        currencyCode: this.settlement.currency,
      }) + " " + this.settlement.currency
    },
    getMerchantSettlementDeposit(settlement) {
      return settlement.deposits.find(d => d.subType === DepositSubType.MERCHANT) || {}
    },
    getMerchantDepositAmount() {
      return this.getMerchantSettlementDeposit(this.settlement).type === 'DEBIT'
          ? this.getMerchantSettlementDeposit(this.settlement).amount * (-1)
          : this.getMerchantSettlementDeposit(this.settlement).amount;
    },
    getDepositAmount() {
      if (this.getMerchantDepositAmount() === undefined) {
        return null;
      }
      return Formatter.format({
        type: 'currency',
        data: this.getMerchantDepositAmount(),
        currencyCode: this.settlement.currency,
      }) + " " + this.settlement.currency
    },
  },
}
</script>

<style lang="scss">
.settlement-overview {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .warning {
    margin-top: 12px;
    color: $warn_800;
    display: flex;
    font-size: 12px;
    text-align: left;

    & .icon {
      margin-right: 4px;
    }
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }

    & .consumer-email {
      & a {
        text-decoration: none;
      }
    }

    & .source-transaction {
      color: $primary_600;
      cursor: pointer;
    }

    & .respond-by {
      & .field-value {
        color: #DE3618;
      }
    }
  }
}
</style>