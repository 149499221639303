import {getDraftMerchant, getMerchant, getMerchantConfiguration, getMerchantFiles} from "@/service/merchantService";
import {storeAppDetails, storeMerchantConfiguration, storeMerchantDetails} from "@/service/saveInStore";
import sessionsApi from '@/api/sessions'

export const getAndStoreSession = async (token) => {
    const session = await sessionsApi.fetchSession(token);
    storeAppDetails(session)
    if (session.merchant_id) {
        await getAndStoreMerchantAndConfiguration(session.merchant_id)
    } else if (session.onboarding_draft_id) {
        await getAndStoreDraftMerchant(session.onboarding_draft_id)
    }
    return session;
}

async function getAndStoreDraftMerchant(draftMerchantId) {
    const draftResponse: any = await getDraftMerchant(draftMerchantId)
    storeMerchantDetails(draftResponse)
    return draftResponse;
}

export async function getAndStoreMerchant(merchantId) {
    const merchantResponse: any = await getMerchant(merchantId)
    storeMerchantDetails(merchantResponse)
    return merchantResponse;
}

export async function getAndStoreMerchantAndConfiguration(merchantId) {
    await getAndStoreMerchant(merchantId);
    await getAndStoreMerchantConfiguration(merchantId);
}

export async function getAndStoreMerchantConfiguration(merchantId) {
    const configurationResponse = await getMerchantConfiguration(merchantId)
    storeMerchantConfiguration(configurationResponse)
    return configurationResponse;
}

export const getAndStoreMerchantFiles = async (merchantId) => {
    const merchantFileResponse: any = await getMerchantFiles(merchantId)
    return merchantFileResponse;
}
