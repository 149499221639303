<template>
  <div class="onboarding-success">
    <AppHeader />
    <div class="content">
      <img src="../app/assets/images/onboarding-success.svg" alt="Chargebee Success Icon" />

      <div class="title">
        {{ $t('applicationSuccess.title') }}
      </div>
      <div class="description">
        {{ $t('applicationSuccess.description') }}
      </div>
      <div class="help-title">
        {{ $t('applicationSuccess.helpAndSupport') }}
      </div>
      <div class="help">
        <i18n path="applicationSuccess.contact" tag="div">
          <template #email>
            <c-link
              href="mailto:support@chargebee.com"
              target="_blank"
            >
              {{ $t('applicationSuccess.email') }}
            </c-link>
          </template>
          <template #faq>
            <c-link
              href="https://www.chargebee.com/docs/2.0/cb_payments_faq.html"
              target="_blank"
            >
              {{ $t('applicationSuccess.faq') }}
            </c-link>
          </template>
        </i18n>
      </div>
      <div class="redirect-instruction">
        Redirecting automatically to Chargebee in <span class="countdown">{{ countDown }} seconds</span>. Do not close or refresh this page.
      </div>
      <c-button variant="primary" @click="redirectToApplication">
        {{ $t('applicationSuccess.goBack') }}
      </c-button>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/onboarding/AppHeader";
import {mapGetters} from "vuex";
import {callbackApplication} from "@/app/utils/common/functions/functions";
import {navigateTo} from "@/router";
import FullStoryHelper from '@/app/utils/fullstory';
import { MERCHANT_CREATED_SUCCESS_PAGE } from '@/app/utils/fullstory/constants';
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "OnboardingSuccessView",
  components: {AppHeader},
  data: function () {
    return {
      countDown: 1
    }
  },
  computed: {
    ...mapGetters('app', ['getCallbackUrl', 'getMerchantId', 'getDraftMerchantId']),

  },
  created () {
    this.$info("Redirecting to Chargebee in 2 seconds. Please do not close the window.")
    this.countDownTimer()
  },
  mounted() {
    FullStoryHelper.event(MERCHANT_CREATED_SUCCESS_PAGE);
    window.onpopstate = () => {
      navigateTo(RouteConstants.ONBOARDING_SUCCESS)
    };
  },
  methods: {
    redirectToApplication() {
      callbackApplication(this.getCallbackUrl, this.getMerchantId, this.getDraftMerchantId)
    },
    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.redirectToApplication()
      }
    }
  },
}
</script>

<style lang="scss">
.onboarding-success {
  & .content {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
  }

  & img {
    margin-top: 50px;
    height: 200px;
  }

  & .title {
    font-size: 24px;
    margin-top: 40px;
    font-weight: $weight-bold;
  }

  & .description {
    font-size: 14px;
    margin-top: 8px;
    color: $subtitle-text;
  }

  & .help-title {
    font-size: 16px;
    margin-top: 40px;
    font-weight: $weight-bold;
  }

  & .help {
    font-size: 14px;
    margin-top: 8px;
    color: $subtitle-text;
  }

  & .redirect-instruction {
    margin-top: 20px;
  }

  & .countdown {
    font-weight: $weight-bold;
  }
  & button {
    width: 220px;
    margin-top: 10px;
  }
}
</style>