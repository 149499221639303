<template>
  <svg
    version="1.1" id="Shape_1_1_" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="20px" height="12.5px" viewBox="0 0 375 234"
    enable-background="new 0 0 20 12.5" xml:space="preserve"
  >
    <g id="Shape_1">
      <g>
        <path
          fill="#434A54" d="M347.479-0.006H27.53c-15.201,0-27.524,12.273-27.524,27.413v22.082h374.997V27.407
          C375.003,12.268,362.68-0.006,347.479-0.006z M0.006,206.588c0,15.139,12.323,27.413,27.524,27.413h319.949
          c15.201,0,27.524-12.274,27.524-27.413V95.785H0.006V206.588z M278.669,132.945h54.743v17.361h-54.743V132.945z M42.209,132.945
          h121.26v17.361H42.209V132.945z M42.209,169.038h65.753v17.361H42.209V169.038z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'UnknownCardIcon',
}
</script>