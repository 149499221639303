<template>
  <div class="add-beneficiary-container">
    <div class="header">
      <slot>
        <div class="title-container">
          <div class="title">
            {{ title }}
          </div>
          <div class="subtitle">
            {{ subtitle }}
          </div>
        </div>
        <div class="buttons-container">
          <c-button variant="primary" @click="addBeneficiary" v-if="!readOnly" :disabled="getErrors.ownershipPercentage">
            {{ editMode ? $t('global.action.save') : $t('global.action.add') }}
          </c-button>
          <c-button variant="action" @click="dismiss" v-if="!editMode">
            {{ $t('global.action.dismiss') }}
          </c-button>
          <c-button variant="secondary" @click="dismiss" v-if="readOnly">
            {{ $t('global.action.dismiss') }}
          </c-button>
        </div>
      </slot>
    </div>
    <div class="personal-details">
      <div id="business_owner">
        <SelectField
          :label="$t('addBeneficiary.businessOwnerType')"
          v-if="!isControlPerson"
          :searchable="false"
          :clearable="false"
          :value="getBeneficialOwnerType(i).value"
          :options="BeneficialOwnerTypes"
          :on-update="(value) => setOwnerType({i, value})"
          select-classname="owner-type-select"
          :error="getErrors.ownerType"
        />

        <InputField
          :label="$t('addBeneficiary.businessName')"
          id="business-name"
          v-if="!isControlPerson && getBeneficialOwnerType(i).value === 'ENTITY'"
          :value="getBusinessName(i).value"
          :set-value="(event) => setBusinessName({i, event})"
          :error="getErrors.businessName"
        />
      </div>
      <div class="sub-header">
        {{ personDetailsTitle }}
      </div>
      <div class="sub-header-instruction">
        {{ personDetailsInstructions }}
      </div>
      <div id="personal-details-form">
        <InputField
          :label="$t('addBeneficiary.firstName')"
          id="first-name"
          :value="getFirstName(i).value"
          :set-value="(event) => setFirstName({i, event})"
          :error="getErrors.firstName"
          :max-length="getMax(getFirstName(i).apiPath)"
        />
        <InputField
          :label="$t('addBeneficiary.lastName')"
          :value="getLastName(i).value"
          id="last-name"
          :set-value="(event) => setLastName({i, event})"
          :error="getErrors.lastName"
          :max-length="getMax(getLastName(i).apiPath)"
        />
        <InputField
          :label="$t('addBeneficiary.jobTitle')"
          :value="getJobTitle(i).value"
          id="job-title"
          :set-value="(event) => setJobTitle({i, event})"
          :error="getErrors.jobTitle"
        />

        <DateInput
          :label="$t('addBeneficiary.dateOfBirth')"
          :value="getFormattedDate(getDateOfBirth(i).value)"
          id="date-of-birth"
          :set-value="(date) => setDateOfBirth({i, value: date})"
          :error="getErrors.dateOfBirth"
          :max-date="maxDateOfBirth"
          class="fs-mask"
        />

        <InputField
          :label="$t('addBeneficiary.emailAddress')"
          id="email"
          :value="getEmailAddress(i).value"
          :set-value="(event) => setEmailAddress({i, event})"
          :error="getErrors.emailAddress"
          class="fs-mask"
        />

        <PhoneInput
          :label="$t('addBeneficiary.phoneNumber')"
          :value="getPhoneNumber(i).formattedValue"
          id="phone-number"
          :disable-country-code="false"
          :country-code="getPhoneCountryCode(i)"
          :set-value="(countryCode, value) => setPhoneNumber({i, countryCode, value})"
          :error="getErrors.phoneNumber"
          class="fs-mask"
        />
      </div>
      <Address
        :getters="{
          getAddressLine1: () => getAddressLine1(i),
          getAddressLine2: () => getAddressLine2(i),
          getAddressCity: () => getAddressCity(i),
          getAddressState: () => getAddressState(i),
          getAddressZip: () => getAddressZip(i),
          getAddressCountry: () => getAddressCountry(i),
          getErrors: getErrors,
          getApi: getApi
        }"
        :setters="{
          setAddressLine1: (event) => setAddressLine1({i, event}),
          setAddressLine2: (event) => setAddressLine2({i, event}),
          setAddressCity: (event) => setAddressCity({i, event}),
          setAddressState: (value) => setAddressState({i, value}),
          setAddressZip: (event) => setAddressZip({i, event}),
          setAddressCountry: (value) => setAddressCountry({i, value})
        }"
      >
        <div slot="header" class="address-header">
          {{ $t('address.title') }}
        </div>
        <div class="subtitle">
          {{ $t('address.subtitle') }}
        </div>
        <div class="separator"></div>
      </Address>
    </div>

    <div class="ownership-container">
      <div class="sub-header">
        {{ $t('addBeneficiary.ownership') }}
      </div>
      <div id="ownership">
        <InputField
          :label="ownershipPercentage"
          id="ownership-percentage"
          append-slot="%"
          :value="getOwnershipPercentage(i).value"
          :set-value="(event) => setOwnershipPercentage({i, event})"
          :error="getErrors.ownershipPercentage"
          :hint="$t('addBeneficiary.ownershipPercentage.instruction',
                    isControlPerson ? controlPersonPercentageOwnership : additionalOwnerPercentageOwnership)"
        />

        <InputField
          :label="taxId(i)"
          id="tax-id"
          :value="getTaxId(i).value"
          :set-value="(event) => setTaxId({i, event})"
          :error="getErrors.taxId"
          :max-length="getMaxLengthForTaxId(i)"
          :hint="taxIdInstruction(i)"
          class="fs-mask"
        />
      </div>
    </div>
  </div>
</template>
<script>
import InputField from "@/components/common/InputField";
import PhoneInput from "@/components/common/PhoneInput";
import DateInput from "@/components/common/DateInput";
import { mapActions, mapGetters } from "vuex";
import validations from "@/app/validators/api/openapi";
import Address from "@/components/onboarding/Address";
import {
  additionalOwnerPercentageOwnership,
  controlPersonPercentageOwnership,
} from "@/store/modules/onboarding/stakeholderDetails";
import {EventBus} from "@/events/event-bus";
import {FormEvents} from "@/app/utils/common/constants";
import SelectField from "@/components/common/SelectField";
import BeneficialOwnerTypes from "@/data/beneficial-owner-types";

export default {
  name: "AddBeneficiary",
  components: {SelectField, Address, DateInput, PhoneInput, InputField},
  props: {
    i: {
      type: Number,
      required: true
    },
    isControlPerson: Boolean,
    close: {
      type: Function,
      required: true
    },
    onDismiss: {
      type: Function,
      required: false,
      default: null
    },
    editMode: Boolean,
    readOnly: {
      type: Boolean,
      required: false
    }
  },
  data: () => {
    return {
      additionalOwnerPercentageOwnership,
      controlPersonPercentageOwnership,
      BeneficialOwnerTypes
    }
  },
  computed: {
    title() {
      let section = `${this.isControlPerson ? 'controlPersonDetails' : 'beneficiaryDetails'}`;
      return this.$t(`businessOwnership.${section}.add.title`);
    },
    subtitle() {
      let section = `${this.isControlPerson ? 'controlPersonDetails' : 'beneficiaryDetails'}`;
      return this.$t(`businessOwnership.${section}.add.subtitle`);
    },
    personDetailsTitle() {
      return this.isControlPerson ? this.$t('addBeneficiary.personalDetails') :
          this.$t('addBeneficiary.beneficialOwnerDetails');
    },
    personDetailsInstructions() {
      return this.isControlPerson ? this.$t('addBeneficiary.personalDetailsInstructions') :
          this.$t('addBeneficiary.beneficialOwnerDetailsInstructions');
    },
    ownershipPercentage() {
      let section = `${this.isControlPerson ? 'controlPersonDetails' : 'beneficiaryDetails'}`;
      return this.$t(`businessOwnership.${section}.add.ownershipPercentage`);
    },
    maxDateOfBirth() {
      let date = new Date();
      date.setFullYear( date.getFullYear() - 18 );
      return date;
    },
    ...mapGetters('stakeholdersDetails', [
      'getBeneficialOwnerType',
      'getBusinessName',
      'getAddressLine1',
      'getAddressLine2',
      'getAddressCity',
      'getAddressState',
      'getAddressZip',
      'getAddressCountry',
      'getFirstName',
      'getLastName',
      'getJobTitle',
      'getDateOfBirth',
      'getEmailAddress',
      'getPhoneNumber',
      'getOwnershipPercentage',
      'getTaxId',
      'getApi',
      'getErrors',
    ]),
  },
  mounted() {
    EventBus.$on(FormEvents.ENTER, this.addBeneficiary)
  },
  methods: {
    ...mapActions('stakeholdersDetails', [
      'setOwnerType',
      'setBusinessName',
      'setFirstName',
      'setLastName',
      'setJobTitle',
      'setDateOfBirth',
      'setEmailAddress',
      'setPhoneNumber',
      'setAddressLine1',
      'setAddressLine2',
      'setAddressCity',
      'setAddressState',
      'setAddressZip',
      'setAddressCountry',
      'setOwnershipPercentage',
      'setTaxId',
      'setRegistrationNumber',
      'setVatNumber',
      'validate',
      'clearErrors',
    ]),
    getMax: function (key) {
      return validations[this.getApi][key].length?.maximum
    },
    getMin: function (key) {
      return validations[this.getApi][key].length?.minimum
    },
    getMaxValue: function (key) {
      return validations[this.getApi][key].range?.maximum
    },
    getMinValue: function (key) {
      return validations[this.getApi][key].range?.minimum
    },
    addBeneficiary: function () {
      Promise.all([this.validate(this.i)])
          .then(values => {
            if (values.some(x => !x)) {
              this.$danger(this.$t('errors.validationErrorToastSave'))
            } else {
              this.close()
            }
          })

    },
    dismiss: function () {
      this.clearErrors()
      this.onDismiss();
      this.close();
    },
    taxId(i) {
      if (this.isControlPerson) {
        return this.$t('addBeneficiary.taxId.label')
      }
      const label = this.getBeneficialOwnerType(i).value === 'ENTITY' ? "einAbbr" : "ssnAbbr"
      return this.$t(`addBeneficiary.taxId.${label}`);
    },
    taxIdInstruction(i) {
      if (this.isControlPerson) {
        return this.$t('addBeneficiary.taxId.instruction')
      }
      const label = this.getBeneficialOwnerType(i).value === 'ENTITY' ? "ein" : "ssn"
      return this.$t(`addBeneficiary.taxId.${label}`);
    },
    getMaxLengthForTaxId(i) {
      if (this.getBeneficialOwnerType(i) !== undefined && this.getBeneficialOwnerType(i).value === 'ENTITY') {
        return 10;
      }
      return 15;
    },
    getFormattedDate: (date) => {
      if (date === null) return null;
      return date.replace(/(\d{4})-(\d\d)-(\d\d)/, "$2/$3/$1");
    },
    getPhoneCountryCode(i) {
      if (this.getPhoneNumber(i).value && this.getPhoneNumber(i).value.includes(" ")) {
        return this.getPhoneNumber(i).value?.split(" ")[0];
      }
      return null;
    }
  },
}
</script>

<style lang="scss">
.add-beneficiary-container {
  padding-right: 40px;
  padding-left: 40px;
  padding-bottom: 40px;

  & .header {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    position: sticky;
    top: 0;
    padding-top: 40px;
    padding-bottom: 16px;
    background-color: $color-white;
    border-bottom: 1px solid #D1D5DB;
    z-index: 99;

    & .title-container {
      display: flex;
      flex-direction: column;

      & .title {
        font-size: 24px;
        font-weight: $weight_bold;
        margin-bottom: 8px;
      }

      & .subtitle {
        font-size: 12px;
        color: $subtitle_text;
      }
    }

    & .buttons-container {
      display: flex;
      flex-direction: column;

      button {
        width: 150px;
      }

      & .c-button--action {
        color: $primary_text_dark;
      }
    }
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 5px;
    width: 100%;
  }

  & .field {
    margin-top: 16px;
  }

  & .sub-header {
    margin-top: 25px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: $weight_bolder;
  }

  .sub-header-instruction {
    color: $subtitle-text;
    font-size: 12px;
  }

  & #business_owner {
    display: flex;
    gap: 20px;
    margin-top: 12px;
    margin-bottom: 32px;
    & .field {
      width: 300px;

    }
  }

  & #personal-details-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .field {
      width: 300px;

      & .c-dropdown {
        width: 100%;
      }
    }
  }

  & .address-container {
    & .address-header {
      font-size: 16px;
      font-weight: $weight_bold;
      margin-top: 30px;
      margin-bottom: 8px;
    }
  }

  & #ownership {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .field {
      width: 300px;
    }
  }
}


</style>