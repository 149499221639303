<template>
  <div class="disputes-transaction-info">
    <div class="title">
      Transaction
    </div>

    <div class="fields">
      <div class="column">
        <FieldValue
          :field-value="transaction.id" 
          :field-name="$t('fieldNames.transaction')"
          :on-click="navigateToTransactionDetails"
          :id="getElementId('parent_transaction')"
        />
        <FieldValue
          :field-value="formatDateWithTimezone(transaction.created_at.toString()) + ' ' + $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()})"
          :field-name="$t('fieldNames.transactionDate')"
        />
      </div>
      <div class="column">
        <FieldValue
          :field-value="format('currency', dispute.amount, '', dispute.currency) + ' ' + dispute.currency "
          :field-name="$t('fieldNames.amount')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import {DisputeResponseDto, TransactionResponseDto} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import {navigateWithParam} from "@/router";
import {getUtcTimezoneDifference} from "@/app/utils/common/functions/dateUtils";
import {PendoPrefixes} from "@/app/utils/common/constants";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "DisputeTransactionInfo",
  components: {FieldValue},
  props: {
    dispute: {
      type: DisputeResponseDto,
      required: true,
    },
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
  },
  methods: {
    getUtcTimezoneDifference,
    navigateToTransactionDetails() {
      navigateWithParam(RouteConstants.TRANSACTION_DETAILS, {
        transactionId: this.transaction.id,
        data: this.transaction
      }, this.$route.query)
    },
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date});
    },
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">
.disputes-transaction-info {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }
  }
}
</style>