<template>
  <div class="settlements-holiday-container">
    <div class="settlements-holiday-header-container">
      <div class="settlements-holiday-page-header">
        <div class="deposit-schedule-page-title">
          {{ $t('deposits.deposit_schedule') }}
        </div>
        <div class="close-button" @click="onCancel">
          <CloseIcon />
        </div>
      </div>
      <div class="settlements-holiday-info">
        <div class="settlements-info">
          {{ $t('deposits.deposit_schedule_settlement_info') }}
        </div>
        <div class="settlements-info">
          {{
            $t('deposits.deposit_schedule_settlement_additional_business_days')
          }}
        </div>
      </div>
    </div>
    <div class="settlements-holiday-content-table">
      <TableView
        :data="getHolidays"
        :columns="getColumns"
        :infinite-scroll="infiniteScroll"
        :all-items-loaded="allItemsLoaded"
        :loading="loading"
        :clickable-rows="false"
        entity="settlements-holidays"
      >
        <template #holidays="prop">
          <div class="holiday-column">
            <span>{{ prop.item.holidays }}</span>
          </div>
        </template>
      </TableView>
    </div>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue';
import { settlementHolidaysList } from '@/data/settlement-holidays';
import TableView from '@/components/common/TableView.vue';
export default {
  name: 'DepositSchedule',
  components: { TableView, CloseIcon },
  props: {
    onCancel: {
      type: Function,
      required: true,
    },
  },
  data: () => {
    return {
      loading: false,
      allItemsLoaded: false,
      infiniteScroll: false
    };
  },
  computed: {
    getColumns() {
      let column = {};
      column['holidays'] = 'deposits.columns.holidays';
      return column;
    },
    getHolidays() {
      let holidays = [];
      for (let settlementHolidaysListKey of Object.values(
        settlementHolidaysList
      )) {
        holidays.push({ holidays: settlementHolidaysListKey });
      }
      return holidays;
    },
  },
};
</script>

<style lang="scss">
.settlements-holiday-container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & .settlements-holiday-header-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    & .settlements-holiday-page-header {
      display: flex;
      justify-content: space-between;

      & .deposit-schedule-page-title {
        font-weight: $weight_medium;
        color: $neutral_800;
        font-size: 18px;
      }

      & .close-button:hover {
        cursor: pointer;
      }
    }

    & .settlements-holiday-info {
      display: flex;
      flex-direction: column;
      gap: 24px;

      & .settlements-info {
        text-align: left;
        font-size: 14px;
        font-weight: $weight-normal;
        color: $neutral-600;
      }
    }
  }

  & .settlements-holiday-content-table {
    & .table-container {
      border: none;

    }

    & .holiday-column {
      display: flex;
      height: 44px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      color: $neutral_800;
      font-size: 14px;
      font-style: normal;
      font-weight: $weight_normal;
      line-height: 20px;
      text-align: left;
    }
  }
}
</style>