<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="sales-container"
      :class="{['sales-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="sales-sub-container">
        <div class="table-header">
          {{ $t('transactions.title') }}
        </div>

        <div class="nav">
          <div
            class="nav-item"
            v-for="tab in tabs"
            :key="tab.name"
            :class="{'nav-item-active': tab.name === currentTab, [tab.name]: true}"
            @click="() => switchTab(tab.name)"
            :id="getElementId('tab-' + tab.name)"
          >
            <span>{{ $t('transactions.tabs.' + tab.name) }}</span>
          </div>
        </div>

        <TableFilterOption
          :disabled="loading.all"
          :table-label="currentTab === 'REFUND' ? 'Refunds' : 'PaymentsAndRefunds'"
          :current-tab="currentTab"
          :date-filter-duration="12"
          v-bind="tableFilterOptionConfig"
          :enable-filters="['dateFilter', 'statusFilter', 'typeFilter', 'paymentMethodTypeFilter', 'amountFilter']"
          ref="tableFilterOptionComponent"
          @childApplyFilter="applyFilter"
          @childClearFilter="applyFilter"
        />

        <Search
          :placeholder="$t('transactions.search')"
          @search="searchTransactions"
          class="search-container"
        />

        <div class="header-container">
          <div class="transaction-header-count">
            <div v-if="count!==0" class="showing-count-top" :class="{'hide-loader': loading.all || sales.length===0}">
              {{ $t('transactions.showing') }} <b class="pagination">{{ transactionsCount }}</b>
            </div>
            <div v-else class="showing-count-top" :class="{'hide-loader': loading.all}">
              {{ $t('transactions.noTransactionsFound') }}
            </div>
            <div class="loader" :class="{'hide-loader': !loading.all}"></div>
          </div>
          <export-button
            :disabled="loading.all || sales.length === 0 || searchQuery !== '' || isAdminMode"
            :loading="loading.export"
            :create-export="createExport"
            :id="getElementId('export')"
            :tooltip-enabled="!(searchQuery ==='' && sales.length>0) && !isAdminMode"
            :tooltip-content="tooltipContent()"
          />
        </div>

        <TableView
          :data="transactions"
          :loading="loading.all"
          :infinite-scroll="searchQuery === ''"
          class="transaction-table"
          :all-items-loaded="allItemsLoaded"
          :columns="getColumns"
          :column-right-align="['net_amount', 'deductions']"
          :tooltips="['deductions']"
          entity="payments"
          @row-clicked="rowClicked"
          :fixed-first-column="false"
          :clickable-rows="true"
          @clearFilter="clearFilterFromEmptyPlaceholder"
        >
          <template #transaction="prop">
            <div class="transaction-column">
              <div class="status-tag-container">
                <div
                  class="status"
                  :class="{
                    'success-status': ['SUCCESS', 'REFUNDED'].includes(prop.item.state),
                    'returned-status': prop.item.state === 'RETURNED',
                    'failure-status': ['FAILED', 'NOT_REFUNDED'].includes(prop.item.state),
                    'canceled-status': prop.item.state === 'CANCELED',
                    'progress-status':
                      !['SUCCESS', 'FAILED', 'CANCELED', 'NOT_REFUNDED', 'REFUNDED', 'RETURNED'].includes(prop.item.state)
                  }"
                >
                  {{ $t('transactions.status.' + prop.item.state) }}
                </div>
              </div>
              <div class="amount-and-txid">
                <div class="amount_box">
                  <div class="amount" v-if="isNegativeAmountType(prop.item.type)">
                    {{ formatter({type: 'currency', data: prop.item.amount*(-1), currencyCode: prop.item.currency}) }}
                  </div>
                  <div class="amount" v-else>
                    {{ formatter({type:'currency', data: prop.item.amount, currencyCode: prop.item.currency}) }}
                  </div>
                  <div class="currency_code">
                    {{ prop.item.currency }}
                  </div>
                  <div v-if="isDunningAttempt(prop.item)" class="dunning-tag-container">
                    <div class="dunning-status">
                      {{ $t('transactions.tags.dunning') }}
                    </div>
                  </div>
                </div>
                <div class="txid">
                  {{ prop.item.id }}
                </div>
              </div>
            </div>
          </template>
          <template #customer_info="prop">
            <div
              class="customer-info-column fs-mask" v-if="prop.item.consumer && ((prop.item.consumer.first_name
                && prop.item.consumer.last_name) || prop.item.consumer.email)"
            >
              <div class="name-and-email">
                <div class="name">
                  <div v-if="prop.item.consumer.first_name || prop.item.consumer.last_name">
                    {{ prop.item.consumer.first_name }} {{ prop.item.consumer.last_name }}
                  </div>
                  <div v-else class="empty-placeholder">
                    —
                  </div>
                </div>
                <div class="email">
                  <div v-if="prop.item.consumer.email">
                    {{ prop.item.consumer.email }}
                  </div>
                  <div v-else class="empty-placeholder">
                    —
                  </div>
                </div>
              </div>
            </div>
            <div class="empty-placeholder" v-else>
              —
            </div>
          </template>
          <template #type="prop">
            {{ getTransactionTypeDisplayName(prop.item.type) }}
          </template>
          <template #payment_method="prop">
            <div v-if="prop.item.payment_instrument.type === null" class="payment-method-column">
              <div class="empty-placeholder">
                —
              </div>
            </div>
            <ToolTip
              v-else
              placement="top"
              :content="getPaymentMethodTooltip(prop.item.payment_instrument)"
              :dismiss-on-click="true"
            >
              <div class="payment-method-column">
                <component
                  v-if="prop.item.payment_instrument.type === 'card'"
                  class="card-logo"
                  :is="cardLogoForCardBrand(prop.item.payment_instrument.card.brand)"
                />
                <img
                  v-else
                  class="card-logo"
                  src="@/app/assets/images/account_balance.svg"
                  alt="Chargebee ACH bank account Icon"
                />
                <div class="payment-method">
                  {{ prop.item.payment_instrument.type === 'card'
                    ? "**** " + prop.item.payment_instrument.card.last_four
                    : $t('paymentInstrument.' + prop.item.payment_instrument.bank_account.type.replace('_', '').toLowerCase()) }}
                </div>
              </div>
            </ToolTip>
          </template>
          <template #deductions="prop">
            <div class="amount-container fs-mask">
              <div class="amount fee" v-if="prop.item.fee != null">
                {{ formatter({type: 'currency', data: prop.item.amount-prop.item.net_amount, currencyCode: prop.item.currency}) }}
              </div>
              <div class="currency_code" v-if="prop.item.fee != null">
                {{ prop.item.currency }}
              </div>
              <div class="empty-placeholder" v-else>
                —
              </div>
            </div>
          </template>
          <template #net_amount="prop">
            <div class="amount-container fs-mask">
              <div class="empty-placeholder" v-if="prop.item.state === 'FAILED'">
                —
              </div>
              <div class="amount" v-else-if="isNegativeAmountType(prop.item.type)">
                {{ formatter({type:'currency', data: prop.item.amount*(-1), currencyCode: prop.item.currency}) }}
              </div>
              <div class="amount" v-else>
                {{ formatter({type:'currency', data: prop.item.net_amount, currencyCode: prop.item.currency}) }}
              </div>
              <div class="currency_code" v-if="prop.item.state !== 'FAILED'">
                {{ prop.item.currency }}
              </div>
            </div>
          </template>
          <template #settlement="prop">
            <ToolTip
              placement="top"
              :content="formatter({type:'timeWithZone', data: prop.item.created_at})"
              :dismiss-on-click="true"
            >
              {{ formatter({type:'zonedDate', data: prop.item.created_at}) }}
            </ToolTip>
          </template>
          <template #declined_error="prop">
            <ToolTip
              placement="top"
              :content="prop.item.error_reason"
              :dismiss-on-click="true"
              :enabled="prop.item.error_reason!==null"
            >
              <div class="error_message" v-if="prop.item.error_reason">
                {{ prop.item.error_reason }}
              </div>
              <div class="empty-placeholder" v-else>
                —
              </div>
            </ToolTip>
          </template>
        </TableView>

        <PortalFooter />
      </div>
    </div>
    <ModalPopup :show="showSubmitPopup">
      <div slot="body">
        <ExportsPopUp
          :on-cancel="() => {showSubmitPopup = false}"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import {mapActions, mapGetters} from "vuex";
import Formatter from "@/app/utils/common/functions/formatter";
import {deleteEmptyAttributes} from "@/app/utils/common/functions/functions";
import {
  SortBy,
  Sort,
  TransactionType,
  ResourceType, SaleStatus, RefundStatus
} from "@/api/paymentApi";
import transactions from "@/api/transactions";
import TableView from "@/components/common/TableView";
import TableFilterOption from "@/components/common/TableFilterOption";
import sales from "@/api/sales";
import {mapRefundToTransaction, mapSaleToTransaction} from "@/service/transactionService";
import refunds from "@/api/refunds";
import Search from "@/components/common/Search";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {Features, getFeatureToggle} from "@/featureToggles";
import ModalPopup from "@/components/common/ModalPopup";
import ExportsPopUp from "@/components/exports/ExportsPopUp";
import {navigateWithParam} from "@/router";
import SearchBar from "@/components/SearchBar";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import {DateFilters, PendoPrefixes} from "@/app/utils/common/constants";
import ExportButton from "@/components/common/ExportButton.vue";
import {exportMixin} from "@/mixin/exportMixin";
import {isNegativeAmountType, getTransactionTypeDisplayName} from "@/app/utils/common/functions/transaction-util";
import {cardBrandLogo} from "@/app/utils/common/helpers/cardBrandLogo";
import {RouteConstants} from "@/router/routeConstants";
import ToolTip from "@/components/common/ToolTip.vue";
import {
  getDatePeriodFromFilter,
} from "@/app/utils/common/functions/dateUtils";

export default {
  name: 'TransactionView',
  components: {
    ToolTip,
    ExportButton,
    SearchBar, Search, TableView, SidebarNav, TableFilterOption, ModalPopup, ExportsPopUp, PortalFooter},
  mixins: [portalCheckMixin, exportMixin],
  data: () => {
    return {
      sales: [],
      offsetId: null,
      listElm: null,
      pageSize: 15,
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      selectedStatus: [],
      selectedType: [],
      paymentMethodFilter: [],
      amountFilter: {
        gt: null,
        gte: null,
        lt: null,
        lte: null,
      },
      allItemsLoaded: false,
      loading: {
        all: false,
        export: false
      },
      sidebarNavCollapsed: false,
      currentTab: "ALL",
      count: 0,
      searchQuery: "",
      searchResults: [],
      tabs: [
        {
          name: "ALL",
        },
        {
          name: "REFUND",
        },
        {
          name: "SUCCESS",
        },
        {
          name: "FAILED",
        },
      ],
      tableFilterOptionConfig: {},
      showSubmitPopup: false,
      parentTransactions: new Set()
    }
  },
  computed: {
    TransactionType() {
      return TransactionType
    },
    ...mapGetters('app', ['getMerchantId', 'isAdminMode']),
    getColumns() {
      let columns = {
        'transaction': 'transactions.columns.transaction',
        'settlement': 'transactions.columns.settlement',
        'customer_info': 'transactions.columns.customer_info',
        'type': 'transactions.columns.type',
        'payment_method': 'transactions.columns.payment_method',
        'deductions': this.currentTab !== "REFUND" && getFeatureToggle(Features.FEES)
            ? 'transactions.columns.deductions' : null,
        'net_amount': 'transactions.columns.net_amount',
        'declined_error': this.currentTab !== "SUCCESS" ? 'transactions.columns.declined_error' : null,
      };

      return deleteEmptyAttributes(columns)
    },
    transactionsCount() {
      return this.sales.length + " / " + this.count;
    },
    transactions() {
      return this.searchQuery === "" ? this.sales : this.searchResults;
    },
  },
  mounted() {
    window.cbStorage.setItem('dateFilter', DateFilters.MONTH_TO_DATE);
    ({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate } =
        getDatePeriodFromFilter(DateFilters.MONTH_TO_DATE))

    if (window.cbStorage.getItem("cb_payments_transaction_current_tab")) {
      this.currentTab = window.cbStorage.getItem("cb_payments_transaction_current_tab")
    }

    this.selectedStatus = this.getSelectedStatus(this.currentTab);
    this.selectedType = this.getSelectedType(this.currentTab);

    this.listElm = document.querySelector('#infinite-list');
    this.listElm?.addEventListener('scroll', () => {
      if ((this.listElm.scrollTop + this.listElm.clientHeight + 0.5 >= this.listElm.scrollHeight) && this.listElm.scrollTop !== 0 && !this.loading.all) {
        this.loadMore();
      }
    });

    this.loadMore();
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    formatter: Formatter.format,
    setHourOfTheDay: Formatter.setHourOfTheDay,
    isNegativeAmountType,
    getTransactionTypeDisplayName,
    rowClicked: function (id, data) {
      navigateWithParam(RouteConstants.TRANSACTION_DETAILS, {transactionId: id, data}, {
        ...this.$route.query,
        consumerId: data.consumer?.id
      })
    },
    getTransactionType() {
      return (this.currentTab === "REFUND") ? TransactionType.REFUND : TransactionType.DEBIT;
    },
    getSaleStatusFromCurrentTab: function () {
      switch (this.currentTab){
        case "SUCCESS": return [SaleStatus.SUCCEEDED]
        case "FAILED": return [SaleStatus.FAILED]
        default: return []
      }
    },
    getRefundStatusFromCurrentTab: function () {
      if (this.currentTab === "REFUND") {
        return [RefundStatus.SUCCEEDED, RefundStatus.FAILED]
      } else {
        return [];
      }
    },
    setOffsetAndCount: function (obj) {
      let {cursor, count} = obj;
      this.offsetId = cursor
      this.count = count;
    },
    loadSales: function () {
      let params = this.getCommonQueryParams();
      return sales.listMerchantSales(
          this.getMerchantId,
          params.cursor,
          params.size,
          params.sort_by,
          params.sort,
          params.start_date,
          params.end_date,
          params.type,
          this.getSaleStatusFromCurrentTab(),
          params.payment_method_type,
          params.amount_gt,
          params.amount_gte,
          params.amount_lt,
          params.amount_lte)
          .then(response => {
            this.setOffsetAndCount(response);
            const latestAttempts = this.removeDunningAttempts(response.sales.map(s => mapSaleToTransaction(s)));
            this.sales = this.sales.concat(latestAttempts)
            if (response.sales.length < this.pageSize) {
              this.allItemsLoaded = true;
            }
            this.loading.all = false;
          })
          .catch(err => {
            this.$danger(err.source?.title)
          })
    },
    loadRefunds: function () {
      let params = this.getCommonQueryParams();
      return refunds.listMerchantRefunds(
          this.getMerchantId,
          params.cursor,
          params.size,
          params.sort_by,
          params.sort,
          params.start_date,
          params.end_date,
          this.getRefundStatusFromCurrentTab(),
          params.type,
          params.payment_method_type,
          params.amount_gt,
          params.amount_gte,
          params.amount_lt,
          params.amount_lte)
          .then(response => {
            this.setOffsetAndCount(response);
            this.sales = this.sales.concat(response.refunds.map(r => mapRefundToTransaction(r)))
            if (response.refunds.length < this.pageSize) {
              this.allItemsLoaded = true;
            }
            this.loading.all = false;
          })
          .catch(err => {
            this.$danger(err.source?.title)
          })
    },
    loadTransactions: function () {
      let params = this.getCommonQueryParams();
      transactions.listMerchantTransactions(
          this.getMerchantId,
          params.cursor,
          params.size,
          params.sort_by,
          params.sort,
          params.start_date,
          params.end_date,
          params.type,
          params.status,
          params.payment_method_type,
          params.amount_gt,
          params.amount_gte,
          params.amount_lt,
          params.amount_lte)
          .then(response => {
            this.setOffsetAndCount(response);
            const latestAttempts = this.removeDunningAttempts(response.transactions);
            this.sales = this.sales.concat(latestAttempts)
            if (response.transactions.length < this.pageSize) {
              this.allItemsLoaded = true;
            }
            this.loading.all = false;
          }).catch(err => {
        this.$danger(err.source?.title)
      })
    },
    removeDunningAttempts: function(transactions) {
      let filteredTransactions = []
      for(const transaction of transactions) {
        if(transaction.type !== TransactionType.DEBIT) {
          filteredTransactions.push(transaction);
          continue;
        }
        if (transaction.tags?.is_dunning_attempt) {
          if(transaction.tags?.parent_transaction_id && !this.parentTransactions.has(transaction.tags.parent_transaction_id)) {
            filteredTransactions.push(transaction);
            this.parentTransactions.add(transaction.tags.parent_transaction_id);
          }
        } else if (!this.parentTransactions.has(transaction.id)) {
          filteredTransactions.push(transaction);
          this.parentTransactions.add(transaction.id);
        }
      }
      return filteredTransactions;
    },
    isDunningAttempt(transaction) {
      return transaction.tags?.is_dunning_attempt;
    },
    getCommonQueryParams() {
      return {
        cursor: this.offsetId,
        size: this.pageSize,
        sort_by: SortBy.ID,
        sort: Sort.DESC,
        start_date: this.dateFilter.startDate, 
        end_date: this.dateFilter.endDate,
        type: this.selectedType,
        status: this.selectedStatus,
        payment_method_type: this.paymentMethodFilter,
        amount_gt: this.amountFilter.gt,
        amount_gte: this.amountFilter.gte > this.amountFilter.lte ? this.amountFilter.lte : this.amountFilter.gte,
        amount_lt: this.amountFilter.lt,
        amount_lte: this.amountFilter.gte < this.amountFilter.lte ? this.amountFilter.lte : this.amountFilter.gte
      }
    },
    getCreateExportParams() {
      let v1;
      if (this.currentTab === "ALL") {
        v1 = {
          resource_type: ResourceType.TRANSACTION,
          transaction_filter: {
            ...this.getCommonQueryParams(),
            cursor: undefined,
            size: undefined,
            status: this.selectedStatus,
            requested_from: "Transactions"
          }
        }
      }
      else if(this.currentTab === "REFUND") {
        v1 = {
          resource_type: ResourceType.REFUND,
          refund_filter: {
            ...this.getCommonQueryParams(),
            cursor: undefined,
            size: undefined,
            status: this.getRefundStatusFromCurrentTab(),
            requested_from: "Transactions"
          }
        }
      }
      else {
        v1 = {
          resource_type: ResourceType.SALE,
          sale_filter: {
            ...this.getCommonQueryParams(),
            cursor: undefined,
            size: undefined,
            status: this.getSaleStatusFromCurrentTab(),
            requested_from: "Transactions"
          }
        }
      }

      return v1;
    },
    tooltipContent() {
      if(this.searchQuery !== '') {
        return this.$t('transactions.searchExport');
      }
      if(this.sales.length === 0) {
        return this.$t('transactions.noExport');
      }
    },
    async loadMore(forceLoad=false) {
      if ((this.allItemsLoaded === true || this.searchQuery !== '') && !forceLoad) return;
      this.loading.all = true;

      let load = {
        ["ALL"]: this.loadTransactions,
        ["SUCCESS"]: this.loadSales,
        ["FAILED"]: this.loadSales,
        ["REFUND"]: this.loadRefunds
      }
      await load[this.currentTab]();
      this.searchTransactions(this.searchQuery);
    },
    switchTab: function (nextTab) {
      if (this.currentTab === nextTab) {
        return
      }
      this.parentTransactions.clear();
      this.sales = [];
      this.searchResults = [];
      this.currentTab = nextTab
      window.cbStorage.setItem("cb_payments_transaction_current_tab", this.currentTab)
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
      this.selectedStatus = this.getSelectedStatus(nextTab);
      this.selectedType = this.getSelectedType(nextTab);
      this.loadMore(true)
      this.listElm?.scrollTo(0, 0);
    },
    getSelectedStatus(nextTab) {
      switch (nextTab) {
        case "REFUND":
          return ["SUCCESS", "FAILED"]
        case "SUCCESS":
        case "FAILED":
          return [nextTab]
        case "ALL":
        default:
          return [];
      }
    },
    getSelectedType(nextTab) {
      switch (nextTab) {
        case "REFUND":
          return ["REFUND", "ACH_RETURN", "PAYMENT_RETURN", "PAYMENT_REVERSAL"]
        case "SUCCESS":
        case "FAILED":
          return ["DEBIT"]
        case "ALL":
        default:
          return [];
      }
    },
    applyFilter: function (filter) {
      this.sales = [];
      this.searchResults = [];
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
      this.parentTransactions.clear();
      this.dateFilter.startDate = filter.dateFilterValue.startDate;
      this.dateFilter.endDate = filter.dateFilterValue.endDate;
      this.selectedStatus = filter.statusFilter;
      this.selectedType =  filter.typeFilter;
      this.paymentMethodFilter = filter.paymentMethodFilter;
      this.amountFilter = filter.amountFilter;
      this.loadMore(true)
      this.listElm.scrollTo(0, 0);
    },
    searchTransactions: function(value) {
      this.searchQuery = value;
      this.searchResults = this.sales.filter(x => x.id.toLowerCase().includes(value.toLowerCase()));
    },
    clearFilterFromEmptyPlaceholder: function () {
      this.$refs.tableFilterOptionComponent.clearFilter();
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase();
    },
    cardLogoForCardBrand(cardBrand) {
      return cardBrandLogo[cardBrand] || cardBrandLogo['UNKNOWN'];
    },
    getPaymentMethodTooltip(paymentInstrument) {
      return paymentInstrument.type === 'card' ?
          this.$t('cards.brands.' + paymentInstrument.card.brand)
          : this.$t('paymentInstrument.transfer' + + paymentInstrument.bank_account.type.replace('_', '').toLowerCase());
    }
  },
}

</script>

<style lang="scss">

.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.sales-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .sales-sub-container{
    max-width: 1360px;
    width: 100%;
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: $neutral_1000;
    text-align: left;
    padding: 20px 0;
  }

  & .nav {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: $weight_bold;
    background-color: transparent;
    padding-left: 0;
    border-bottom: 1px solid $neutral_200;
    margin-bottom: 16px;

    &:after {
      content: '';
      height: 2px;
      background-color: $color_white;
    }

    & .nav-item {
      color: $neutral_600;
      display: flex;
      cursor: pointer;
      align-items: start;
      margin-right: 16px;

      @media screen and (min-width: 801px) {
        padding: 12px 16px;
      }

      @media screen and (max-width: 800px) {
        padding: 10px 16px;
      }

      &:hover {
        background-color: $primary_fill_light;
      }

      &.disabled:hover {
        background-color: $color-white;
        cursor: not-allowed;
      }
    }

    & .nav-item-active {
      color: $primary_600;
      border-bottom: 2px solid $primary_400;

      & svg {
        margin-right: 10px;
        color: $primary_text;
      }
    }
  }

  &.sales-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  & .action-header {
    background-color: $color_white;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 80px;
    justify-content: left;
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
    flex-direction: row;

    & .transaction-count {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_800;
      align-self: center;
    }
  }

  .header-container {
    background-color: $color_white;
    width: 100%;
    height: fit-content;
    border: 1px solid $neutral_100;
    border-radius: 6px 6px 0px 0px ;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 15px 25px 5px 25px;

    & .transaction-header-count{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      text-align: left;
      align-self: center;
      padding-bottom: 10px;
      display: flex;

      & .showing-count-top {
        display: inline-block;
        justify-content: left;

        & .pagination {
          font-weight: $weight_bold;
        }
      }

      & .loader {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 3px solid $neutral_100;
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        justify-content: center;
      }


      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      .hide-loader {
        display: none;
        visibility: hidden;
      }

    }

    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      padding: 0;

      & img{
        margin-right: 5px;
      }
    }
  }

  & .transaction-column {
    display: flex;
    flex-direction: row;

    & .status-tag-container {
      display: flex;
      text-align: left;
      min-width: 70px;
      flex-direction: row;
      justify-content: space-between;
    }

    & .status {
      font-size: 12px;
      padding: 2px 10px;
      border-radius: 10px;
      height: fit-content;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    & .success-status {
      background-color: $success_100;
      color: $success_600;
    }

    & .returned-status {
      background-color: $danger_50;
      color: $danger_800;
    }

    & .failure-status {
      background-color: $danger_50;
      color: $danger_800;
    }

    & .canceled-status {
      background-color: $warn_50;
      color: $warn_800;
    }

    & .progress-status {
      background-color: $primary_100;
      color: $primary_800;
    }

    & .amount-and-txid {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      margin-left: 12px;

      & .amount_box {
        font-size: 14px;
        font-weight: $weight-medium;
        line-height: 20px;
        color: $neutral_800;
        display: flex;
        flex-direction: row;

        & .dunning-tag-container {
          display: flex;
          text-align: left;
          min-width: 70px;
          flex-direction: row;
          margin-left: 4px;

          & .dunning-status {
            font-size: 12px;
            padding: 2px 10px;
            border-radius: 10px;
            height: fit-content;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            background-color: $neutral_400;
            color: $base_white;
          }
        }

        & .amount {
          margin-right: 5px;
          font-weight: $weight_bold;
        }

        & .currency_code {
          color: $neutral_400;
          font-size: 14px;
        }
      }

      & .txid {
        font-weight: $weight_normal;
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;

      }
    }
  }

  & .customer-info-column {
    display: flex;

    & .name-and-email {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;

      & .name {
        font-size: 14px;
        font-weight: $weight-normal;
        line-height: 20px;
        color: $neutral_800;
      }

      & .email {
        font-weight: $weight_normal;
        color: $neutral_400;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  & .type-column {
    display: flex;
    align-items: center;
    min-height: 60px;

    & .type {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      justify-content: start;

      & .empty-placeholder {
        color: $neutral_300;

        &.amount-container {
          padding-right: 30px;
        }
      }

      & .no-cust-records-placeholder {
        color: $neutral_300;
        padding-left: 20px;
      }

      & .error_message {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      & .amount-container {
        justify-content: right;
        display: flex;
        flex-direction: row;
        padding-right: 20%;
        padding-left: 20px;
        width: 100%;

        & .amount {
          margin-right: 5px;
        }

        & .currency_code {
          color: $neutral_400;
          font-size: 14px;
        }
      }
    }
  }

  & .payment-method-column {
    display: flex;

    & .payment-method {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_800;
    }

    & .card-logo {
      align-self: center;
      margin-right: 7px;
    }
  }

  & .search-container{
    width: 320px;
    margin-bottom: 16px;
  }
}
</style>