<template>
  <div class="rejection-action-card">
    <div class="left">
      <img src="../../app/assets/images/orang-exclamation-triangle.svg" alt="Chargebee Warning Icon" />
      <div class="title-container">
        <div class="title">
          {{ title() }}
        </div>
        <div class="sub-title">
          {{ description() }}
        </div>
      </div>
    </div>
    <div class="right">
      <c-button variant="action" @click="onClick">
        {{ actionLabel() }}
      </c-button>
    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";
import router from "@/router";

export default {
  name: "RejectionActionCard",
  props: {
    failureCode: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      personName: "",
      activeBeneficiaryIndex: 0,
    }
  },
  computed: {
    ...mapGetters('stakeholdersDetails', ['getControlPersonName']),
    ...mapGetters('app', ["getPayfacErrorCodeMapper", "getSessionPayfac"]),
    ...mapGetters('stakeholdersDetails', ["getAdditionalBeneficiaries",
      "getFirstName", "getLastName"]),
  },
  mounted() {
    if (this.getSessionPayfac === 'ADYEN' && this.getAdditionalBeneficiaries.length !== 0 &&
        this.getAdditionalBeneficiaries.map(x => x['idAtPayfac'].value)
        .indexOf(this.failureCode.split('-')[0]) !== -1) {
      this.activeBeneficiaryIndex = this.getAdditionalBeneficiaries.map(x => x['idAtPayfac'].value)
          .indexOf(this.failureCode.split('-')[0]) + 1;
    }
    this.personName = this.getFirstName(this.activeBeneficiaryIndex).value
        + " " + this.getLastName(this.activeBeneficiaryIndex).value;
  },
  methods: {
    onClick() {
      let route = this.getPayfacErrorCodeMapper.getNavigationForErrorCode(this.failureCode);
      route.query = {...route.query, migration: this.getIsMigrating}
      router.push(route);
    },
    getRawErrorCode() {
      return this.getPayfacErrorCodeMapper.getRawErrorCode(this.failureCode);
    },
    title() {
      return this.$t('rejectionAction.title.' + this.getRawErrorCode(), {name: this.personName})
    },
    description() {
      return this.$t('rejectionAction.description.' + this.getRawErrorCode());
    },
    actionLabel() {
      return this.$t('rejectionAction.actionLabel.' + this.getRawErrorCode());
    }
  }
}
</script>

<style lang="scss">
.rejection-action-card {
  background-color: #FFF7ED;
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 12px;

  & .title {
    font-size: 14px;
    font-weight: $weight-medium;
    line-height: 24px;
  }

  & .sub-title {
    font-size: 12px;
    color: $subtitle-text;
    line-height: 24px;
  }


  & .left {
    display: flex;
    flex-direction: row;

    & img {
      width: 15.17px;
      height: 13.92px;
      margin: 5px;
      margin-right: 14px;
    }
  }
  & .right {
    & button {
      color: $color_red;
      font-weight: $weight-medium;
      & span {
        padding: 8px;
        border-radius: 4px;
        background-color: $color_white;
      }
    }
  }
}

</style>