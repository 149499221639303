<template>
  <div class="compliance-form-detail">
    <div class="line1" v-if="complianceForm != null">
      <div class="compliance-form-type">
        <div class="icon">
          <ComplianceFormIcon />
        </div>
        {{ 'SAQ A' }}
      </div>
    </div>

    <div class="line2" v-if="complianceForm != null">
      <div class="form-type">
        {{ getComplianceFormType }}
      </div>
      <ComplianceFormStatusTag :compliance-form="complianceForm" />
    </div>

    <div class="divider" v-if="complianceForm != null"></div>

    <FieldValue
      class-name="id"
      :field-name="$t('fieldNames.id')"
      v-if="complianceForm != null"
      :field-value="complianceForm.id"
      :show-copy-value="true"
    />
  </div>
</template>

<script>
import ComplianceFormIcon from "@/components/icons/ComplianceFormIcon.vue";
import ComplianceFormStatusTag from "@/components/complianceforms/ComplianceFormStatusTag.vue";
import { ComplianceFormResponseDto } from "@/api/paymentApi";
import FieldValue from "@/components/common/FieldValue.vue";
export default {
  name: 'ComplianceFormDetail',
  components: { ComplianceFormStatusTag, ComplianceFormIcon, FieldValue},
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true,
    },
  },
  computed: {
    getComplianceFormType() {
      return this.complianceForm.compliance_form_type.replace(/_/g, " ");
    }
  },
}
</script>

<style lang="scss">

.compliance-form-detail {

  & .shimmer-container {
    & div {
      margin-bottom: 8px;
    }
  }

  & .loading-shimmer {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    border-radius: 10px;
  }

  & .size-120-24 {
    width: 120px;
    height: 24px;
  }

  & .size-100-36 {
    width: 100px;
    height: 36px;
  }

  & .size-50-24 {
    width: 50px;
    height: 24px;
  }

  & .size-50-16 {
    width: 50px;
    height: 16px;
  }

  & .size-100-24 {
    width: 100px;
    height: 24px;
  }

  & .line2 {
    display: flex;
    align-items: center;
    margin-top: 9px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    & .form-type {
      color: $neutral_800;
      font-weight: $weight_bold;
      line-height: 36px;
      font-size: 24px;
      margin-right: 12px;
    }
  }

  & .field {
    width: fit-content;
    text-align: left;

    & .field-label {
      color: $neutral_600;
      line-height: 18px;
    }

    & .field-value {
      & .icon {
        cursor: pointer;
      }
    }
  }

  & .divider {
    width: 100%;
    background: $neutral_100;
    height: 1px;
    margin-top: 12px;
  }

  & .line1 {
    display: flex;

    & .compliance-form-type {
      color: $neutral_800;
      background-color: $neutral_50;
      width: fit-content;
      border: 1px solid $neutral_200;
      border-radius: 4px;
      padding: 2px;
      font-size: 12px;
      font-weight: $weight_bold;
      display: flex;
      align-items: center;

      & .icon {
        display: flex;
        align-items: center;
        margin-right: 5px;

        & svg {
          color: $neutral_600;
        }
      }
    }
  }


}
</style>