<template>
  <div class="empty-placeholder-container">
    <NoResultsIcon class="empty-placeholder-noResult-icon" />
    <div class="empty-placeholder-content">
      <div class="empty-placeholder-msg1">
        {{ heading() }}
      </div>
      <div class="empty-placeholder-msg2">
        {{ description() }}
      </div>
    </div>
  </div>
</template>

<script>
import {navigateTo} from "@/router";
import NoResultsIcon from "@/components/icons/NoResultsIcon";
import {RouteConstants} from "@/router/routeConstants";


export default {
  name: "EmptyTablePlaceholder",
  components: {NoResultsIcon},
  props: {
    entity: {
      type: String,
      required: true,
    }
  },
  methods: {
    configureChargebee() {
      navigateTo(RouteConstants.ONBOARDING_STATUS, true)
    },
    heading() {
      if(this.entity === "Exports") {
        return this.$t('exports.noExportsFound');
      }
      if(this.entity === "compliance-forms") {
        return this.$t('profile.noComplianceFormYet');
      }
      else {
        return this.$t('emptyPlaceHolder.'+this.entity+'.noPaymentsFound');
      }
    },
    description() {
      if(this.entity === "Exports") {
        return this.$t('exports.noExportsYetDescription');
      }
      if(this.entity === "compliance-forms") {
        return this.$t('profile.noComplianceFormYetDescription');
      }
      else {
        return this.$t('emptyPlaceHolder.noPaymentsFoundDescription');
      }
    },
  }
}
</script>
<style lang="scss">
.empty-placeholder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100%;
  & svg {
    color: $neutral_400;
  }

  & .empty-placeholder-noResult-icon {
    align-self: flex-start;
    width: 40px;
    height: 40px;
  }

  & .empty-placeholder-content {
    position: relative;
    color: $neutral_400;
    & .empty-placeholder-msg1 {
      display: flex;
      padding: 8px 0px;
      width: 100%;
      font-weight: $weight_medium;
      line-height: 24px;
      font-size: 16px;
      color: $neutral_800;
    }

    & .empty-placeholder-msg2 {
      text-align: left;
      float: left;
      font-size: 12px;
      padding-bottom: 15px;
      font-weight: $weight_normal;
      line-height: 18px;
    }
  }

  & .c-popover {
    width: 35%;

    & .c-cursor-pointer {
      width: 85%;
      height: 30px;
      background: none;
      cursor: pointer;
      border-color: #A8CBFF;

      &:hover {
        background-color: $primary_fill_light;
      }
    }
  }

  & .app-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding: 4px;
    cursor: pointer !important;

    & .app-title-container {
      display: flex;
      align-items: center;

      & .app-title {
        margin-left: -3px;
        font-size: 14px;
        color: #0052CC;
      }
    }
  }

}
</style>