<template>
  <div class="disputes-physical-goods-info">
    <div class="fields">
      <div class="column">
        <FieldValue
          :field-value="evidence.physical_goods_details.shipping_tracking_number"
          :field-name="$t('fieldNames.shippingTrackingNumber')"
        />
        <FieldValue
          :field-value="evidence.physical_goods_details.purchase_details"
          :field-name="$t('fieldNames.purchaseDetails')"
        />
      </div>
      <div class="column">
        <FieldValue
          :field-value="evidence.physical_goods_details.delivery_partner"
          :field-name="$t('fieldNames.deliveryPartner')"
        />
        <FieldValue
          :field-value="evidence.physical_goods_details.refund_policy_details"
          :field-name="$t('fieldNames.refundPolicyDetails')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";

export default {
  name: "DisputeViewPhysicalGoods",
  components: {FieldValue},
  props: {
    evidence: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style lang="scss">
.disputes-physical-goods-info {

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }
  }
}
</style>