// CommonMixin.js
import { mapGetters } from 'vuex';
import Formatter from "@/app/utils/common/functions/formatter";
import {formatRelativeTime} from "@/app/utils/common/functions/dateUtils";
import {navigateWithParam} from "@/router";
import {LocalStorageKeys, PendoPrefixes} from "@/app/utils/common/constants";
import _ from 'lodash';

export const notificationMixin = {
    data: () => ({
        alreadyReadIds: localStorage.getItem(LocalStorageKeys.READ_IDS) || []
    }),
    computed: {
        ...mapGetters('app', ['getTimezone']),
        title() {
            return this.$t(`notifications.${this.currentTab.toLowerCase()}.${this.audit.event.toLowerCase()}_title` , {
                amount: Formatter.format({ type: "currency", data: this.getAmount(this.audit.event), currencyCode: "USD" })
            });
        },
        isRead() {
            if(this.audit.resource_type === "DISPUTE") {
                let needsAttention = JSON.parse(this.audit.content).status !== 'WON' && JSON.parse(this.audit.content).status !== 'LOST';
                return !needsAttention || this.alreadyReadIds.includes(this.audit.id);
            }
            return this.alreadyReadIds.includes(this.audit.id);
        },
        showUnreadMarker() {
            return !this.isRead && this.currentTab === 'TODO'
        },
        description() {
            return this.$t(`notifications.${this.currentTab.toLowerCase()}.${this.audit.event.toLowerCase()}_desc`, {
                amount: Formatter.format({ type: "currency", data: this.getAmount(this.audit.event), currencyCode: "USD" })
            });
        }
    },
    methods: {
        handleMarkAsRead() {
            if(this.alreadyReadIds.includes(this.audit.id)) return;
            this.alreadyReadIds = _.concat(this.alreadyReadIds, this.audit.id);
            localStorage.setItem(LocalStorageKeys.READ_IDS, this.alreadyReadIds);
        },
        openDetails(route) {
            navigateWithParam(route, { resourceId: this.audit.resource_id }, this.$route.query);
        },
        dismiss(auditId) {
            this.hideAudit(auditId);
        },
        getAmount(event) {
            if (event === "SETTLEMENT_APPROVED") {
                return JSON.parse(this.audit.content).net_amount;
            } else {
                return JSON.parse(this.audit.content).amount;
            }
        },
        relativeDate(createdAt) {
            // if (this.currentTab === 'EVENTS') need to check this
            //     return Formatter.format({type: 'zonedDateWithTime', data: createdAt, zone: this.getTimezone})
            // empty-commit
            return formatRelativeTime(createdAt, new Date());
        },
        getElementId(id) {
            return PendoPrefixes.CBpay + this.$route.name + '-' + id;
        },
    }
};
