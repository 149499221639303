<template>
  <div class="status-home">
    <div class="top-fixed">
      <AppHeader
        :secondary-action-label="!getIsMigrating ? $t('onboarding.goBack') : $t('migration.goBack')"
        :secondary-action="() => goBackToApplication()"
        :app-header="getIsMigrating ? 'migration.headerTitle' : 'onboarding.title'"
      />
    </div>
    <div class="status-container">
      <div class="status-box">
        <StatusProgression :status="getStatus" :failure-codes="getErrorCodesToBeFixed" />
      </div>
      <i18n path="status.assistance" tag="div" class="title-description">
        <template #faqPage>
          <c-link href="https://www.chargebee.com/docs/2.0/cb_payments_faq.html" target="_blank">
            {{ $t('status.faqPage') }}
          </c-link>
        </template>
        <template #documentationPage>
          <c-link href="https://www.chargebee.com/docs/2.0/cb_payments.html" target="_blank">
            {{ $t('status.documentationPage') }}
          </c-link>
        </template>
        <template #supportEmail>
          <c-link href="mailto:support@chargebee.com" target="_blank">
            {{ $t('status.supportEmail') }}
          </c-link>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/onboarding/AppHeader";
import StatusProgression from "@/components/onboarding/StatusProgression";
import {OnboardingStatus} from "@/app/utils/common/constants";
import {mapGetters} from "vuex";
import {callbackApplication} from "@/app/utils/common/functions/functions";
import FullStoryHelper from '@/app/utils/fullstory';
import { ONBOARDING_STATUS_PAGE } from '@/app/utils/fullstory/constants';
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: 'OnboardingStatusView',
  components: {
    StatusProgression,
    AppHeader
  },
  data: function () {
    return {
      
    }
  },
  computed: {
    ...mapGetters('app', ['getCallbackUrl', 'getDraftMerchantId', 'getMerchantId', 'getSessionPayfac']),
    ...mapGetters('merchantPayfacDetails', ['isEligibleForMigration', 'getOnboardingStatus',
      'getErrorCodesToBeFixed']),
    getIsMigrating() {
      return (this.$route.query.migration === true && this.isEligibleForMigration);
    },
    getStatus() {
      let onboardingStatus = this.getOnboardingStatus(this.getSessionPayfac);
      let errorCodes = this.getErrorCodesToBeFixed;
      if (onboardingStatus === OnboardingStatus.REJECTED && !errorCodes.length) {
        return OnboardingStatus.REJECTED_BUT_FIXED;
      } else {
        return onboardingStatus;
      }
    },
  },
  mounted() {
    FullStoryHelper.event(ONBOARDING_STATUS_PAGE);
  },
  methods: {
    goBackToApplication() {
      if (this.getIsMigrating) {
        navigateTo(RouteConstants.DASHBOARD, true);
        return;
      }
      callbackApplication(this.getCallbackUrl, this.getMerchantId, this.getDraftMerchantId)
    }
  }
}
</script>

<style lang="scss">
.status-home {
  display: inline-block;
  width: 100%;
  height: 100%;

  & .loader {
    font-size: 42px;
    color: $primary_text-dark;
    margin-top: 52px;
  }

  & .auth-failed {
    font-size: 42px;
    color: $primary_text-dark;
    margin-top: 52px;
  }
}


.top-fixed {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 98;
  display: inline-block;
}

.status-container {
  height: 100%;
  padding: 28px;
  background-color: $primary-fill_light;


  & .status-box {
    background-color: $color-white;
    max-width: 1000px;
    margin: auto;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
  }

  & .title-description {
    margin: auto;
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 12px;
    max-width: 1000px;
    text-align: left;
  }
}


</style>
