<template>
  <div class="sidebar-container">
    <div
      class="sidebar"
      :class="{'sidebar-collapse': sidebarNavCollapsed, 'sandbox-site': getIsSandbox}"
    >
      <PopOver
        :arrow="false"
        :secondary-action="'Billing'"
        :primary-action="'Chargebee Payments'"
        :primary-action-help-text="'Payment Solution'"
        :secondary-action-help-text="'Subscription Management'"
        @cancel="returnToBillingApp"
        @primary="() => {}"
        :id="getElementId('switcher-product')"
      >
        <template #title>
          <div class="popover-title">
            Switch to
          </div>
        </template>
        <c-tag
          class="c-cursor-pointer"
          slot="trigger"
        >
          <div class="app-switch">
            <div class="app-title-container">
              <span class="logo"></span>
              <div class="app-title" v-if="!sidebarNavCollapsed">
                Payments
              </div>
            </div>
            <img src="@/app/assets/images/dropdown.svg" alt="dropdown" v-if="!sidebarNavCollapsed" />
          </div>
        </c-tag>
      </PopOver>
      <div class="nav-items">
        <div
          class="merchant-switcher" @click="() => showSubmitPopup = true"
          v-if="isAdminMode"
        >
          {{ sidebarNavCollapsed ? getMerchantName.charAt(0).toUpperCase() : getMerchantName }}
          <RightArrowIcon />
        </div>
        <div v-if="!isAdminMode && getCbSiteName" class="merchant-details">
          <span class="merchant-site-name">
            {{ sidebarNavCollapsed ? getCbSiteName.charAt(0) + '...' : getCbSiteName }}
          </span>
          <span class="merchant-site" :class="getIsSandbox ? 'test' : 'live'">
            {{ getIsSandbox ? $t('sideNav.testSite') : $t('sideNav.liveSite') }}
          </span>
          <div class="merchant-site-url">
            {{ sidebarNavCollapsed ? '' : getCbSiteUrl() }}
          </div>
        </div>
        <div class="separator"></div>
        <div
          v-for="navOption in filteredNavOptions"
          :key="navOption.name"
        >
          <div
            class="nav-item"
            :class="{'nav-item-selected': isSelectedNav(navOption)}"
            @click="changeRoute(navOption)"
            :id="getElementId(navOption.name)"
          >
            <div class="nav-icon">
              <component :is="navOption.icon" />
            </div>
            <div
              class="nav-title"
              :class="{'nav-title-collapse': sidebarNavCollapsed}"
            >
              {{ $t('sideNav.titles.' + navOption.name) }}
            </div>
            <div v-if="navOption.child !== undefined" class="dropdown-container">
              <img
                src="@/app/assets/images/dropdown.svg" alt="dropdown"
                class="dropdown-icon"
                :class="{'dropdown-icon-rotate': !isNavCollapsed(navOption)}"
                v-if="!sidebarNavCollapsed"
              />
            </div>
          </div>
          <div 
            v-if="(navOption.child !== undefined)"
            :class="{'sub-nav-items-collapsed': isNavCollapsed(navOption)}"
          >
            <div
              v-for="subNavOption in navOption.child"
              :key="subNavOption.name"
              class="sub-nav-item"
              :class="{'sub-nav-item-selected': isSelectedNav(subNavOption)}"
              @click="changeRoute(subNavOption)"
              :id="getElementId(navOption.name + '-' + subNavOption.name)"
            >
              <div
                class="sub-nav-title"
                :class="{'sub-nav-title-collapse': sidebarNavCollapsed}"
              >
                {{ $t('sideNav.titles.' + subNavOption.name) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-bottom">
        <div class="nav-items">
          <PopOver
            :arrow="false"
          >
            <template #trigger>
              <div
                class="nav-item"
                :class="{'nav-item-selected': selectedNav.name === 'profile'}"
              >
                <div class="nav-icon">
                  <AccountIcon />
                </div>
                <div
                  class="nav-title"
                  :class="{'nav-title-collapse': sidebarNavCollapsed}"
                >
                  Account
                </div>
              </div>
            </template>

            <div class="account-menu">
              <div class="nav-item" @click="changeRoute(getNavOptions('profile'))">
                <div class="menu-icon">
                  <PaymentProfileIcon />
                </div>
                <div class="nav-title">
                  {{ 'Profile' }}
                </div>
              </div>
              <div class="nav-item sign-out" :id="getElementId('sign_out')" @click="logout">
                <div class="menu-icon">
                  <LogoutIcon />
                </div>
                <div class="nav-title">
                  {{ 'Sign Out' }}
                </div>
              </div>
            </div>
          </PopOver>
        </div>
      </div>
    </div>
    <div
      class="collapse-and-expand"
      @click="toggleSidebarNavCollapsed()"
    >
      <img class="collapse-bar" src="@/app/assets/images/vertical_line.svg" alt="dropdown" />
    </div>

    <ModalPopup :show="showSubmitPopup">
      <div slot="body">
        <ChooseMerchant
          :show-close-popup-button="true"
          :on-cancel="() => {showSubmitPopup = false}"
          @closePopup="() => {showSubmitPopup = false}"
        />
      </div>
    </ModalPopup>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {navigateTo} from "@/router";
import {SidebarNavOptions} from "@/app/utils/common/sidebarOptions";
import RightArrowIcon from "@/components/icons/RightArrowIcon";
import ModalPopup from "@/components/common/ModalPopup";
import PopOver from "@/components/common/PopOver";
import {cbAppUrl, openUrlInSameTab, getCbAppUrl} from "@/app/utils/common/functions/url-helper";
import LogoutIcon from "@/components/icons/LogoutIcon";
import ChooseMerchant from "@/components/admin/ChooseMerchant";
import {PendoPrefixes} from "@/app/utils/common/constants";
import PaymentProfileIcon from "@/components/icons/PaymentProfileIcon.vue";
import AccountIcon from "@/components/icons/AccountIcon.vue";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: 'SidebarNav',
  components: {PaymentProfileIcon, AccountIcon, ChooseMerchant, RightArrowIcon, ModalPopup, PopOver, LogoutIcon},
  data: () => {
    return {
      sidebarNavCollapsed: false,
      showSubmitPopup: false,
      selectedNav: {
        name: null,
        icon: null,
        route: null
      },
      initialCreation: {},
      navItemsCollapsed: {},
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantName', 'isAdminMode', 'hideOnboardingPage', 'getCbSiteName', 'getIsSandbox']),
    isAdmin() {
      return String(this.$router.currentRoute.query.adminMode) === "true"
    },
    filteredNavOptions() {
      return SidebarNavOptions
          .filter(x => x.name === 'notifications' ? this.isAdmin : true)
          .filter(x => x.name !== 'profile')
    },
  },
  activated() {
    // Added this boolean condition to refresh sidebar for keep-alive components
    if(this.initialCreation[this.$route.name]) {
      this.refreshNav();
    } else {
      this.initialCreation[this.$route.name] = true;
    }
  },
  created() {
    this.refreshNav();
  },
  methods: {
    getCbSiteUrl() {
      return getCbAppUrl(this.getCbSiteName).split("//")[1];
    },
    refreshNav() {
      this.updateSelectedNav();
      //mark collapsable main navs as collapsed initially
      this.navItemsCollapsed = SidebarNavOptions.filter(x => x.child)
                                  .map(x => x.name)
                                  .reduce((name, index) => {
                                    name[index]=true;
                                    return name;
                                  }, {});
      //if selected nav is a child nav, then expand it's parent nav
      if(this.selectedNav.parent) {
        this.navItemsCollapsed[this.selectedNav.parent] = false;
      }
    },
    changeRoute(navOption) {
      let {adminMode, applicationName, merchantId, merchantName, timeZone} = this.$route.query;
      let query = {adminMode, applicationName, merchantId, merchantName, timeZone};
      //if same nav
      if(navOption.name === this.selectedNav.name) {
        if(navOption.child) {
          if(this.sidebarNavCollapsed) {
            this._toggleSidebarNavCollapsed();
          }
          //expanding the current menu
          //toggle the state
          this.navItemsCollapsed[navOption.name] = !this.navItemsCollapsed[navOption.name];
          //set the correct child nav based on route
          this.updateSelectedNav();
          return;
        }

        if (navOption?.childRoutes?.includes(this.$route.name)) {
          navigateTo(navOption.route, true, query);
        }
        return;
      }

      //if nav has child
      if(navOption.child) {
        if(this.sidebarNavCollapsed) {
            this._toggleSidebarNavCollapsed();
        }

        //toggle collapse state
        this.navItemsCollapsed[navOption.name] = !this.navItemsCollapsed[navOption.name];
        
        //if the parent nav is going to collapse, then highlight the parent nav if child is selected
        if(this.navItemsCollapsed[navOption.name] 
            && this.selectedNav.parent === navOption.name) {
          this.selectedNav = SidebarNavOptions.find(x => x.name === navOption.name);
        }
        return;
      }

      //navigate
      navigateTo(navOption.route, true, query);
    },
    updateSelectedNav() {
      //find active nav name from current route
      //see if it is main nav
      let activeNav = SidebarNavOptions
          .find(x => (x.route === this.$router.currentRoute.name)
              || ((x.childRoutes || []).includes(this.$router.currentRoute.name)));
      if(!activeNav) {
        //if not main nav, check the child routes
        for (const activeNavKey of SidebarNavOptions) {
          if(activeNavKey.child) {
            activeNav = activeNavKey.child.find(x => (x.route === this.$router.currentRoute.name)
                || ((x.childRoutes || []).includes(this.$router.currentRoute.name)));
          }
          if(activeNav) {
            break;
          }
        }
      }

      if(!activeNav) {
        this.selectedNav = {}
        throw new Error("Current route not declared in sidebarOptions");
      }

      this.selectedNav = activeNav;
    },
    isSelectedNav(navOption) {
      return this.selectedNav.name === navOption.name;
    },
    isNavCollapsed(navOption) {
      return this.navItemsCollapsed[navOption.name];
    },
    returnToBillingApp() {
      window.cbStorage.clear();
      openUrlInSameTab(cbAppUrl());
    },
    _toggleSidebarNavCollapsed() {
      this.sidebarNavCollapsed = !this.sidebarNavCollapsed;
      this.$emit('update:sidebarNavCollapsed', this.sidebarNavCollapsed);
    },
    toggleSidebarNavCollapsed() {
      this._toggleSidebarNavCollapsed();
      if(!this.sidebarNavCollapsed) {
        //if expanding, keep them like a page refresh
        this.refreshNav();
      } else {
        //if collpasing
        //collapse all navs, then if child nav is selected, highlight appropriate parent nav
        let keys = Object.keys(this.navItemsCollapsed);
        keys.forEach(k => this.navItemsCollapsed[k] = true);

        if(this.selectedNav.parent) {
          this.selectedNav = SidebarNavOptions.find(x => x.name === this.selectedNav.parent);
        }
      }
      
    },
    getNavOptions(navName) {
      return SidebarNavOptions.find(x => x.name === navName);
    },
    logout() {
      navigateTo(RouteConstants.LOGOUT);
    },
    getElementId(id) {
      return PendoPrefixes.CBpayNavbar + id;
    }
  },
}
</script>
<style lang="scss">
.sidebar-container {
  margin-left: 0;
  height: 100vh;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: space-between;

  & .modal-body {
    overflow: scroll;

    & .choose-merchant {
      margin: auto;
      padding-bottom: 50px;
      height: 500px;
    }
  }

  & .collapse-and-expand {
    width: 20px;
    cursor: pointer;
    height: fit-content;
    position: relative;
    top: 40%;

    &:hover {
      & .collapse-bar {
        visibility: hidden;
      }

      & .expand {
        visibility: visible;
      }
    }

    & .collapse-bar {
      height: 48px;
      width: 2px;
    }

    & .collapse {
      height: 36px;
    }

    & .expand {
      height: 36px;
    }
  }

  & .sidebar {
    transition: all 0.2s ease-in;
    width: $sidebar_nav_width;
    background: white;
    padding: 12px;
    display: flex;
    flex-direction: column;
    overflow: scroll;

    box-shadow: 10px 10px 15px -3px #0000001A;
    border-bottom-right-radius: 15px;


    & .c-popover {
      & .c-button--secondary .action-name {
        font-weight: $weight_normal;
      }

      & .popover-title {
        width: 100%;
        text-align: left;
        margin: 20px;
        font-weight: $weight_bold;
      }
    }

    & .c-cursor-pointer {
      width: 222px;
      background: none;
      height: 50px;
      padding-right: 20px;
    }

    & .app-switch {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    & .nav-items {
      padding: 5px;
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      & .c-dropdown__item {
        display: flex;
        gap: 12px;
        font-size: 14px;
        justify-content: start;
        align-items: center;
        height: 40px;
      }
      & .nav-item {
        display: flex;
        padding: 4px;
        cursor: pointer;
        border-radius: 5px;
        height: 40px;

        & svg {
          color: $neutral_400;
          margin-left: auto;
          margin-right: 0;
          width: 100%;
        }

        &:hover {
          background-color: #F0F5F5;
        }

        & .nav-icon {
          width: 25px;
          margin-right: 12px;
          margin-left: 8px;
          display: flex;
          align-items: center;
        }

        & .nav-title {
          width: 100%;
          text-align: left;
          visibility: visible;
          transition: all 0.2s ease-out;
          display: flex;
          align-items: center;
        }

        & .dropdown-container {
          margin: auto;
          padding-right: 8px;
        }

        .dropdown-icon{
          transition: all 0.2s ease-out;
        }

        .dropdown-icon-rotate{
          transform: rotate(180deg);
          transition: all 0.2s ease-out;
        }

        & .nav-title-collapse {
          visibility: hidden;
          transition: all 0.2s ease-out;
        }

        color: $neutral_800;
        font-size: 14px;
      }

      & .nav-item-selected {
        color: $neutral_800;
        font-weight: $weight-bold;
        background-color: $primary_fill_light;

        & svg {
          color: $neutral_400;
        }
      }

      & .sub-nav-items-collapsed {
          display: none;
      }

      & .sub-nav-item {
        display: flex;
        padding: 4px;
        cursor: pointer;
        border-radius: 5px;
        height: 40px;

        &:hover {
          background-color: #F0F5F5;
        }

        & .sub-nav-title {
          padding-left: 41.5px;
          display: flex;
          align-items: center;
          width: 100%;
          text-align: left;
          visibility: visible;
          transition: all 0.2s ease-out;
        }

        & .sub-nav-title-collapse {
          visibility: hidden;
          transition: all 0.2s ease-out;
        }

        color: $neutral_800;
        font-size: 14px;
      }

      & .sub-nav-item-selected {
        color: $neutral_800;
        font-weight: $weight-bold;
        background-color: $primary_fill_light;
      }

    }

    & .nav-bottom {
      margin-top: auto;

      .c-popover__container {
        left: 244px !important;
        top: 60px !important;
      }
      .c-popover__content {
        display: flex !important;
      }

      & .account-menu {
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: start;
        align-self: stretch;
        width: 100% !important;

        & .nav-item {
          align-self: stretch;
        }

        & .sign-out {
          color: $danger_400
        }

        & .menu-icon {
          width: 25px;
          margin-right: 12px;
          margin-left: 8px;
          display: flex;
          align-items: center;
        }
      }

    }


    & .merchant-switcher {
      background: $primary-50;
      border: 1px solid $primary-200;
      padding: 13px;
      border-radius: 6px;
      color: $primary-600;
      font-size: 14px;
      font-weight: $weight-bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-align: left;
      margin-bottom: 16px;
    }

    & .merchant-details {
      background: $base_white;
      padding: 4px 12px 12px 12px;
      text-align: left;
      font-weight: $weight_medium;
      overflow: hidden;
      & .merchant-site-name {
        color: $neutral_800;
        font-size: 14px;
        max-width: 80%;
        display: inline-block;
        padding-right: 8px;
      }
      .merchant-site {
        border-radius: 2px;
        color: $base_white;
        font-weight: $weight_bolder;
        font-size: 9px;
        padding: 3px;
        display: inline-block;

        &.test {
          background: $warn_400;
        }

        &.live {
          background: $success_400;
        }
      }
      & .merchant-site-url {
        color: $neutral_600;
        font-size: 10px;
        margin-top: 6px;
      }

      .merchant-site-name,
      .merchant-site-url {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
    }

    & .separator {
      border-top: 1px solid #E6EAF0;
      margin-bottom: 10px;
    }

    & .app-title-container {
      display: flex;
      align-items: center;
      width: 100%;

      & .app-title {
        margin-left: 12px;
        font-size: 16px;
        font-weight: $weight-bold;
        visibility: visible;
      }

      & .app-title-collapse {
        visibility: hidden;
        transition: visibility 0.2s ease-in-out;
      }

      & .logo {
        position: relative;
        font-size: 0;
        @include c-box(25px);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:before {
          content: '';
          @include c-box(24px);
          background-image: url('~@/app/assets/images/brand/cv-logo-white.svg');
          background-size: contain;
          background-repeat: no-repeat;
          display: inline-block;
        }
      }
    }

    & .c-popover__footer {
      display: flex;
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 4px;
    }

    & .c-popover__wrap {
      min-width: 248px;
      padding: 0;
      display: flex;
      flex-direction: column;

      & button {
        background: none;
        width: 100%;
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding: 16px;

        &:hover {
          background-color: $primary-50;
        }

        & .c-button__label {
          display: block;
          text-align: left;
          padding-left: 16px;
        }
      }

      & .c-button--secondary {
        color: $color_black;
      }

      & .c-button--primary {
        color: $color_black;
      }

      & .c-button--primary:hover {
        background-color: $primary_50;
      }

      & .c-button--secondary:hover {
        background-color: $primary_50;
      }

      & button.disabled {
        color: $neutral_400;
        opacity: 0.7;

        &:hover {
          background: none;
        }
      }
    }

    &.sidebar-collapse {
      width: $sidebar_collapsed_nav_width;

      & .c-cursor-pointer {
        width: auto;
        padding: 16px;
      }

      & .nav-items {
        & .nav-item {
          & svg {
            width: 20px;
          }

          & .nav-icon {
            display: flex;
            width: 100%;
            margin: 12px;
          }
        }
      }
      & .nav-bottom {
        & .c-popover__container {
          left: 78px !important;
        }
      }
    }
  }

  & .sandbox-site {
    margin-top: 32px;
  }
}
</style>