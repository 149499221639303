<template>
  <div class="export-container">
    <div class="header">
      <div class="title">
        {{ $t('exports.popup.initiated') }}
      </div>
    </div>
    <div class="exports-description">
      {{ $t('exports.popup.description') }}
    </div>
    <div class="exports-info">
      <img src="@/app/assets/images/info-outline-blue.svg" alt="info-outline-blue" />
      <div class="exports-info-content">
        {{ $t('exports.popup.info_content') }}
      </div>
    </div>
    <div>
      <div class="buttons">
        <c-button
          @click="navigateToExports"
          :disabled="loading"
          size="large"
          class="navigate-exports"
        >
          {{ $t('exports.popup.goto') }}
        </c-button>
        <c-button
          @click="closePopup"
          :disabled="loading"
          variant="primary"
          size="large"
        >
          {{ $t('global.action.close') }}
        </c-button>
      </div>
    </div>
  </div>
</template>
<script>

import {navigateWithParam} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "ExportsPopUp",
  props: {
    onCancel: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      loading: false
    }
  },
  methods: {
    navigateToExports() {
      this.closePopup();
      navigateWithParam(RouteConstants.EXPORTS, {}, {...this.$route.query});
    },
    closePopup() {
      this.onCancel();
    }
  }
}
</script>

<style lang="scss">


.export-container {
  padding-right: 40px;
  padding-left: 40px;
  color: $subtitle-text;
  font-size: 14px;

  & .buttons {
    display: flex;
    position: sticky;
    bottom: 0;
    justify-content: end;
    gap: 20px;
    font-weight: $weight_bold;
    background-color: $color-white;

    & button {
      margin-top: 25px;
      margin-bottom: 10px;
    }

    & .navigate-exports {
      padding: 0px;
      color: #2D6A8A;
      background: $color-white;
      border: transparent;
    }
  }

  & .header {
    display: flex;
    justify-content: start;
    position: sticky;
    color: $text-color;
    top: 0;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: $color-white;
    z-index: 99;

    & .title {
      text-align: left;
      font-size: 24px;
      font-weight: $weight_bold;
      color: $text-color;
      margin-bottom: 8px;
    }
  }

  & .exports-description {
    text-align: left;
    margin-top: 16px;
    margin-bottom: 26px;
    color: #141428;
  }

  & .exports-info {
    display: flex;
    text-align: left;
    margin-top: 20px;
    background: $info_50;
    padding: 20px;
    border-top: 1px solid $info_100;

    & img {
      width: 16.67px;
      height: 16.67px;
    }

    & .exports-info-content {
      padding-left: 10px;
      color: $neutral-800;
    }
  }

}


</style>