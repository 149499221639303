import {PayfacErrorCodeMapper} from "@/app/utils/common/functions/payfac-error-code-mapper";
import {RouteConstants} from "@/router/routeConstants";

export class FinixErrorCodeMapper implements PayfacErrorCodeMapper {

    ErrorCodes = {
        INVALID_BANK_ACCOUNT_NUMBER: "INVALID_BANK_ACCOUNT_NUMBER",
        INVALID_BANK_ROUTING_NUMBER: "INVALID_BANK_ROUTING_NUMBER",
        INVALID_OWNER_DATA: "INVALID_OWNER_DATA",
        BANK_NOT_SUPPORTED: "BANK_NOT_SUPPORTED",
        INVALID_BANK_ACCOUNT_OWNERSHIP: "INVALID_BANK_ACCOUNT_OWNERSHIP",
        INVALID_BUSINESS_INFORMATION: "INVALID_BUSINESS_INFORMATION",
        INVALID_BUSINESS_TYPE: "INVALID_BUSINESS_TYPE",
        INVALID_TAX_ID_NUMBER: "INVALID_TAX_ID_NUMBER",
        PO_BOX_ADDRESS_NOT_SUPPORTED: "PO_BOX_ADDRESS_NOT_SUPPORTED",
        PROHIBITED_MCC: "PROHIBITED_MCC",
        INVALID_MCC: "INVALID_MCC",

        GENERIC_DECLINE: "GENERIC_DECLINE",
        DECLINED_DO_NOT_SUBMIT_AGAIN: "DECLINED_DO_NOT_SUBMIT_AGAIN",
        HIGH_RISK: "HIGH_RISK",
        FINANCIAL_HISTORY: "FINANCIAL_HISTORY",
        MULTIPLE_FACTORS: "MULTIPLE_FACTORS",
        MATCH_LIST_DECLINE: "MATCH_LIST_DECLINE",
        OFAC_DECLINE: "OFAC_DECLINE",

        ID_VERIFICATION_NEEDED: "ID_VERIFICATION_NEEDED",
        ID_VERIFICATION_FAILED: "ID_VERIFICATION_FAILED",
        BANK_VERIFICATION_NEEDED: "BANK_VERIFICATION_NEEDED",
        BANK_VERIFICATION_FAILED: "BANK_VERIFICATION_FAILED",
        TAX_ID_VERIFICATION_NEEDED: "TAX_ID_VERIFICATION_NEEDED",
        TAX_ID_VERIFICATION_FAILED: "TAX_ID_VERIFICATION_FAILED",
        INSUFFICIENT_BUSINESS_HISTORY: "INSUFFICIENT_BUSINESS_HISTORY",
    };

    ErrorRouteConstants = {
        ...RouteConstants,
        [this.ErrorCodes.INVALID_OWNER_DATA]: RouteConstants.ONBOARDING_EDIT_OWNER_INFO,

        [this.ErrorCodes.INVALID_BANK_ROUTING_NUMBER]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        [this.ErrorCodes.INVALID_BANK_ACCOUNT_NUMBER]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        [this.ErrorCodes.BANK_NOT_SUPPORTED]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,
        [this.ErrorCodes.INVALID_BANK_ACCOUNT_OWNERSHIP]: RouteConstants.ONBOARDING_EDIT_BANK_ACCOUNT,

        [this.ErrorCodes.INVALID_BUSINESS_INFORMATION]: RouteConstants.ONBOARDING_EDIT_BUSINESS_INFORMATION,

        [this.ErrorCodes.INVALID_BUSINESS_TYPE]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        [this.ErrorCodes.INVALID_TAX_ID_NUMBER]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        [this.ErrorCodes.INVALID_MCC]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        [this.ErrorCodes.PROHIBITED_MCC]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,
        [this.ErrorCodes.PO_BOX_ADDRESS_NOT_SUPPORTED]: RouteConstants.ONBOARDING_EDIT_BUSINESS_STRUCTURE,

        [this.ErrorCodes.ID_VERIFICATION_NEEDED]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        [this.ErrorCodes.ID_VERIFICATION_FAILED]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        [this.ErrorCodes.BANK_VERIFICATION_NEEDED]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        [this.ErrorCodes.BANK_VERIFICATION_FAILED]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        [this.ErrorCodes.TAX_ID_VERIFICATION_NEEDED]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        [this.ErrorCodes.TAX_ID_VERIFICATION_FAILED]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
        [this.ErrorCodes.INSUFFICIENT_BUSINESS_HISTORY]: RouteConstants.ONBOARDING_UPLOAD_DOCUMENTS,
    };

    DocumentTypePages = {
        IDENTIFICATION_CARD: ["IDENTIFICATION_CARD_FRONT", "IDENTIFICATION_CARD_BACK"],
        PASSPORT: ["PASSPORT"],
        DRIVER_LICENSE: ["DRIVERS_LICENSE_FRONT", "DRIVERS_LICENSE_BACK"],
        TAX_DOCUMENT: ["TAX_DOCUMENT"],
        BUSINESS_REGISTRATION: ["BUSINESS_REGISTRATION"],
        BUSINESS_ADDRESS_VERIFICATION: ["BUSINESS_ADDRESS_VERIFICATION"],
        BANK_STATEMENT: ["BANK_STATEMENT"],
        OTHER: ["OTHER"],
    }

    DocumentTypes = {
        IDENTIFICATION_CARD: "IDENTIFICATION_CARD",
        PASSPORT: "PASSPORT",
        DRIVER_LICENSE: "DRIVER_LICENSE",
        TAX_DOCUMENT: "TAX_DOCUMENT",
        BUSINESS_REGISTRATION: "BUSINESS_REGISTRATION",
        BUSINESS_ADDRESS_VERIFICATION: "BUSINESS_ADDRESS_VERIFICATION",
        BANK_STATEMENT: "BANK_STATEMENT",
        OTHER: "OTHER",
    }

    ErrorCodeToDocumentType = {
        ID_VERIFICATION_NEEDED:
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        ID_VERIFICATION_FAILED:
            [this.DocumentTypes.IDENTIFICATION_CARD, this.DocumentTypes.PASSPORT, this.DocumentTypes.DRIVER_LICENSE],
        BANK_VERIFICATION_NEEDED:
            [this.DocumentTypes.BANK_STATEMENT, this.DocumentTypes.OTHER],
        BANK_VERIFICATION_FAILED:
            [this.DocumentTypes.BANK_STATEMENT, this.DocumentTypes.OTHER],
        TAX_ID_VERIFICATION_NEEDED:
            [this.DocumentTypes.TAX_DOCUMENT, this.DocumentTypes.BUSINESS_REGISTRATION, this.DocumentTypes.BUSINESS_ADDRESS_VERIFICATION],
        TAX_ID_VERIFICATION_FAILED:
            [this.DocumentTypes.TAX_DOCUMENT, this.DocumentTypes.BUSINESS_REGISTRATION, this.DocumentTypes.BUSINESS_ADDRESS_VERIFICATION],
        INSUFFICIENT_BUSINESS_HISTORY:
            [this.DocumentTypes.TAX_DOCUMENT, this.DocumentTypes.BUSINESS_REGISTRATION, this.DocumentTypes.BUSINESS_ADDRESS_VERIFICATION],
    }

    public getNavigationForErrorCode = (errorCode) => {
        return {
            name: this.ErrorRouteConstants[errorCode],
            query: {errorCode},
        };
    }

    public cannotOnboardErrorCodes = () => {
        return [
            this.ErrorCodes.GENERIC_DECLINE,
            this.ErrorCodes.DECLINED_DO_NOT_SUBMIT_AGAIN,
            this.ErrorCodes.HIGH_RISK,
            this.ErrorCodes.FINANCIAL_HISTORY,
            this.ErrorCodes.MULTIPLE_FACTORS,
            this.ErrorCodes.MATCH_LIST_DECLINE,
            this.ErrorCodes.OFAC_DECLINE,
        ]
    }

    public rejectionDueToOwnerInfo = () => {
        return [
            this.ErrorCodes.INVALID_OWNER_DATA,
        ]
    }

    public rejectionDueToBusinessStructure = () => {
        return [
            this.ErrorCodes.INVALID_BUSINESS_TYPE,
            this.ErrorCodes.INVALID_TAX_ID_NUMBER,
            this.ErrorCodes.PROHIBITED_MCC,
            this.ErrorCodes.INVALID_MCC,
            this.ErrorCodes.PO_BOX_ADDRESS_NOT_SUPPORTED,
        ]
    }

    public rejectionDueToBusinessInformation = () => {
        return [
            this.ErrorCodes.INVALID_BUSINESS_INFORMATION,
        ]
    }

    public rejectionDueToBankAccount = () => {
        return [
            this.ErrorCodes.INVALID_BANK_ACCOUNT_NUMBER,
            this.ErrorCodes.INVALID_BANK_ROUTING_NUMBER,
            this.ErrorCodes.BANK_NOT_SUPPORTED,
            this.ErrorCodes.INVALID_BANK_ACCOUNT_OWNERSHIP,
        ]
    }

    public errorCodesRequiringFileUpload = () => {
        return [
            this.ErrorCodes.ID_VERIFICATION_NEEDED,
            this.ErrorCodes.ID_VERIFICATION_FAILED,
            this.ErrorCodes.BANK_VERIFICATION_NEEDED,
            this.ErrorCodes.BANK_VERIFICATION_FAILED,
            this.ErrorCodes.TAX_ID_VERIFICATION_NEEDED,
            this.ErrorCodes.TAX_ID_VERIFICATION_FAILED,
            this.ErrorCodes.INSUFFICIENT_BUSINESS_HISTORY,
        ]
    }

    public getRawErrorCode = (errorCode) => errorCode

    public getErrorCodeFixed = (errorCode) => {
        if (this.rejectionDueToBankAccount().includes(errorCode)) {
            return this.rejectionDueToBankAccount();
        }
        if (this.rejectionDueToBusinessInformation().includes(errorCode)) {
            return this.rejectionDueToBusinessInformation();
        }
        if (this.rejectionDueToBusinessStructure().includes(errorCode)) {
            return this.rejectionDueToBusinessStructure();
        }
        if (this.rejectionDueToOwnerInfo().includes(errorCode)) {
            return this.rejectionDueToOwnerInfo();
        }
        return [errorCode];
    }
}