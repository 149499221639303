import { isIPad, isIPhone } from '@/app/utils/common/functions/functions';

class UrlHelper {
  static downloadUrl(url) {
    if (isIPad() || isIPhone()) {
      window.location.href = url;
    } else {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url;
      document.body.appendChild(iframe);
    }
  }
}

export default UrlHelper;

export const fixedEncodeURIComponent = (str = "") => {
  const s = encodeURIComponent(str);
  const replacer = c => '%' + c.charCodeAt(0).toString(16);
  return s.replace(/[!'()*]/g, replacer);
};

export const urlEncodeData = (data: any) => {
  const str: string[] = [];
  for (const p in data) {
    if (data.hasOwnProperty(p) && !(data[p] === undefined || data[p] == null)) {
      if (Array.isArray(data[p])) {
        data[p].forEach(e => {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(e));
        });
      } else {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(data[p]));
      }
    }
  }
  return str.join('&');
};

const getCbAppDomain = () => {
  if (process.env.VUE_APP_BUILD_ENV == "local") {
    return "localcb.in:8080";
  } else if (process.env.VUE_APP_BUILD_ENV == "predev") {
    return "predev.in";
  } else if (process.env.VUE_APP_BUILD_ENV == "dev") {
    return "devcb.in";
  } else if (process.env.VUE_APP_BUILD_ENV == "dev-eu") {
    return "eu.devcb.in";
  } else if (process.env.VUE_APP_BUILD_ENV == "staging") {
    return "stagingcb.com";
  } else if (process.env.VUE_APP_BUILD_ENV == "prod-eu") {
    return "eu.chargebee.com"
  } else {
    return "chargebee.com"
  }
}

export const getCbAppUrl = (merchantSiteName: string) => {
  const domain = merchantSiteName + "." + getCbAppDomain();

  if (process.env.VUE_APP_BUILD_ENV == "local") {
    return "http://" + domain;
  } 
  return "https://" + domain;
}

export const cbAppUrl = () => {
  return getCbAppUrl("app");
}

export const openUrlInSameTab = (url: string) => {
  window.open(url, "_self")
}

export const openUrlInNewTab = (url: string) => {
  window.open(url, "_blank")
}

export const openChargebeeAppDetailsPage = (merchantSiteName: string, module: string, id: string) => {
  //https://apidocs.chargebee.com/docs/api/v1/advanced-features#admin_console
  //https://support.chargebee.com/support/solutions/articles/223401-can-i-construct-the-admin-console-url-for-direct-access-to-chargebee-page-from-your-application-
  const url = getCbAppUrl(merchantSiteName) + "/admin-console/" + module + "/" + id;
  openUrlInNewTab(url);
}