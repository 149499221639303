
const OwnershipTypeList = [
  {
    displayText: "Private",
    value: "PRIVATE"
  },
  {
    displayText: "Public",
    value: "PUBLIC"
  }
]

export default OwnershipTypeList;
