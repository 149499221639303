<template>
  <svg width="34" height="30" viewBox="0 0 34 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.25 29.0833L21.6667 19.5C20.7778 20.2222 19.7917 20.7639 18.7083 21.125C17.625 21.4861 16.5 21.6667 15.3333 21.6667C14.1667 21.6667 13.0556 21.4933 12 21.1467C10.9444 20.7989 9.97222 20.3056 9.08333 19.6667L11.5 17.25C12.0556 17.5833 12.6528 17.8472 13.2917 18.0417C13.9306 18.2361 14.6111 18.3333 15.3333 18.3333C17.4167 18.3333 19.1878 17.6044 20.6467 16.1467C22.1044 14.6878 22.8333 12.9167 22.8333 10.8333C22.8333 8.75 22.1044 6.97889 20.6467 5.52C19.1878 4.06222 17.4167 3.33333 15.3333 3.33333C13.4167 3.33333 11.7567 3.97889 10.3533 5.27C8.95111 6.56222 8.13889 8.16667 7.91667 10.0833L10 8L12.3333 10.3333L6.16667 16.5L0 10.3333L2.33333 8L4.58333 10.1667C4.75 7.30556 5.86111 4.89556 7.91667 2.93667C9.97222 0.978889 12.4444 0 15.3333 0C18.3611 0 20.9239 1.04833 23.0217 3.145C25.1183 5.24278 26.1667 7.80556 26.1667 10.8333C26.1667 12 25.9861 13.1389 25.625 14.25C25.2639 15.3611 24.7222 16.3333 24 17.1667L33.5833 26.75L31.25 29.0833Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'NoResultsIcon',
}
</script>