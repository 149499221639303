<template>
  <div class="checkbox-container" :class="{ active: value, inactive: !value}" @click="select">
    <div class="checkbox" :id="id">
    </div>
    <div class="option-name-desc">
      <c-label :label="optionName" />
      <div class="instruction" v-if="optionInstruction">
        {{ optionInstruction }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RadioOption",
  props: {
    id: {
      type: String,
      required: true
    },
    value: Boolean,
    optionName: {
      type: String,
      required: true
    },
    optionInstruction: {
      type: String,
      required: false,
      default: undefined
    },
    select: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss">
.checkbox-container {
  padding: 20px;
  margin-top: 10px;
  display: flex;
  align-items: start;
  justify-content: left;
  cursor: pointer;
  font-size: 22px;
  user-select: none;

  &.active {
    & .checkbox {
      width: 19px;
      height: 18px;
      border-radius: 50%;
      border: 6px solid $primary_fill;
    }
    background-color: $primary_tint;
  }

  &.inactive {
    & .checkbox {
      width: 19px;
      height: 18px;
      border-radius: 50%;
      background-color: $color_white;
      border: 1px solid $radio_border
    }
    background-color: $color_white;

    &:hover {
      background-color: $primary_fill_light;
    }

    & .option-name-desc {
      & .c-label {
        font-weight: $weight_medium;
      }
      & .instruction {
        color: $subtitle_text;
      }
    }
  }

  & .option-name-desc {
    margin-left: 18px;
    display: flex;
    flex-direction: column;
    align-items: start;

    & .instruction {
      margin-top: 4px;
      font-size: 12px;
    }
  }


}
</style>