<template>
  <div class="site-status-banner-container">
    <div class="status-banner-content">
      {{ $t('global.title.sandboxSite') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteStatusBanner',
}
</script>

<style lang="scss" scoped>
.site-status-banner-container {
  text-align: center;
  background: $warn_400;
  height: 32px;
  width: 100%;

  & .status-banner-content {
    padding-top: 6px;
    font-size: 14px;
    font-weight: $weight_normal;
    line-height: 20px;
    color: $base_white;
  }
}

</style>