<script>
export default {
  name: "ModalPopup",
  props: {
    show: Boolean,
    close: {
      type: Function,
      default: null
    }
  }
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>
          <div class="footer" v-if="close">
            <c-button
              v-if="close"
              @click="close"
              size="large"
              variant="primary"
              class="c-mt--xxl c-mb--xxl close"
            >
              {{ $t('global.action.close') }}
            </c-button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">


.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  .footer {
    width: 100%;
    display: flex;

    & button {
      margin-top: 20px;
    }

    .close {
      margin-left: auto;
      margin-right: 16px;
      margin-bottom: 16px;
      float: right;
    }
  }

  width: 95%;
  min-width: 320px;
  max-width: 700px;
  max-height: 90vh;
  margin: 0px auto;

  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow: scroll;
  background-color: $color_white;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>