<template>
  <div
    v-if="authorization !== null"
    class="authorization-status"
    :class="{
      'success-status': authorization.status === 'SUCCEEDED',
      'failure-status': authorization.status === 'FAILED',
      'canceled-status': ['CANCELED', 'UNAVAILABLE'].includes(authorization.status),
      'progress-status': authorization.status === 'PENDING'
    }"
  >
    {{ $t('authorizations.status.' + authorization.status) }}
  </div>
</template>

<script>
export default {
  name: "AuthorizationStatusTag",
  props: {
    authorization: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.authorization-status {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  height: fit-content;

  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.success-status {
    background-color: $success_100;
    color: $success_600;
  }

  &.failure-status {
    background-color: $danger_50;
    color: $danger_800;
  }

  &.canceled-status {
    background-color: $warn_50;
    color: $warn_800;
  }

  &.progress-status {
    background-color: $primary_100;
    color: $primary_800;
  }
}
</style>