import store from '@/store'
import {DateTime} from 'luxon';
import Formatter from "@/app/utils/common/functions/formatter";
import {DateFilters} from "@/app/utils/common/constants";

const timezoneOffsetDecimalPlaces = 2;
const maxMonthNameLength = 3;

export function getTimezone() {
    return store.getters["app/getTimezone"];
}

export function getTimezoneName():string {
    return getTimezone().name;
}

export function getDateTimeFromObject() {
    return DateTime.fromObject({zone: getTimezone()});
}

export function getDateTimeFromObjectToUTCToJSDate() {
    return getDateTimeFromObject().toUTC().toJSDate();
}

export function getDateTimeFromMillis(givenDate) {
    return DateTime.fromMillis(givenDate, {zone: getTimezone()});
}

export function getDateTimeFromJSDate(givenDate) {
    return DateTime.fromJSDate(givenDate, {zone: getTimezone()});
}

export function getDateTimeNow() {
    return DateTime.now().setZone(getTimezone());
}

export function getAbbreviatedMonth(date: Date) {
    return date.toLocaleString('default', {month: 'short'}).slice(0, maxMonthNameLength);
}

export function getQuarter() {
    return Math.floor(new Date().getMonth() / 3) + 1;
}

export function firstDateOfQuarter() {
    return DateTime.fromFormat(getQuarter().toString(), 'q');
}

export function setToPreviousMonthLastDay(givenDate) {
    givenDate.setDate(0);
}

export function formatRelativeTime(incomingDateString: string, now: Date): string {
    // Convert the dates to UTC
    const nowUtc = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
        now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

    const incomingDate = new Date(incomingDateString);

    const utcValue = Date.UTC(
        incomingDate.getUTCFullYear(),  // Year
        incomingDate.getUTCMonth(),  // Month (subtract 1 as it's zero-based)
        incomingDate.getUTCDate(),  // Day
        incomingDate.getUTCHours(),  // Hour
        incomingDate.getUTCMinutes(),  // Minute
        incomingDate.getUTCSeconds()   // Second
    );

    const diffInSeconds = Math.floor((nowUtc - utcValue) / 1000); // Calculate the difference in seconds

    const timeUnits = [
        { unit: 'second', threshold: 60 },
        { unit: 'minute', threshold: 3600 },
        { unit: 'hour', threshold: 86400 },
        { unit: 'day', threshold: 604800 },
        { unit: 'week', threshold: 2592000 },
        { unit: 'month', threshold: Infinity }
    ];

    let divisor = 1;
    for (const unit of timeUnits) {
        if (diffInSeconds < unit.threshold) {
            const timeValue = Math.floor(diffInSeconds / divisor);
            return `${timeValue} ${unit.unit}${timeValue !== 1 ? 's' : ''} ago`;
        }
        divisor = unit.threshold;
    }

    return '';
}

export function isGivenDateBetweenFirstAndFifth(givenDate: Date) {
    const currentDay = new Date(givenDate).getDate();

    return currentDay >= 1 && currentDay <= 5;
}

export function getMonthYearInfosFromMonthOffset(month: number) {
    const currentDate = new Date();
    for(let prevMonth= 0; prevMonth < month; prevMonth++) {
        // currentDate is mutated each time
        setToPreviousMonthLastDay(currentDate);
    }
    const shortMonth = getAbbreviatedMonth(currentDate);
    const longMonth = currentDate.toLocaleString('default', {month: 'long'});
    return {longMonth: longMonth, shortMonth: shortMonth, year: currentDate.getFullYear()};
}

export function getUtcTimezoneDifference(): string {
    const zoneTime = getDateTimeNow();

    const differenceInMinutes = zoneTime.offset;

    const hours = Math.floor(Math.abs(differenceInMinutes / 60));
    const minutes = Math.abs(differenceInMinutes % 60);

    const sign = differenceInMinutes >= 0 ? '+' : '-';

    return `${sign}${(parseTimezoneOffset(hours))}:${parseTimezoneOffset(minutes)}`;
}

export function getTodayRange (): {startDate: Date, endDate: Date} {
    return {
        startDate: Formatter.setHourOfTheDay(getDateTimeFromObject(), 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

export function getWeeksAgoRange (numberOfWeeks: number) : {startDate: Date, endDate: Date} {
    const dateTime = getDateTimeFromObject().plus({days: -(numberOfWeeks * 7)});
    return {
        startDate: Formatter.setHourOfTheDay(dateTime, 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

//plus ({month: -numberOfMonths}) will return currentDate of subtracted month if exist or last date of that month.
//so 30 days a month is considered
//e.g Feb 21 - (1 month) -> Jan 21
//e.g Mar 31 - (1 month) -> Feb 29.
export function getMonthsAgoRange (numberOfMonths : number) : {startDate: Date, endDate: Date}{
    const dateTime = getDateTimeFromObject().plus({days: -(numberOfMonths * 30)});
    return {
        startDate: Formatter.setHourOfTheDay(dateTime, 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

export function getYearsAgoRange (numberOfYears : number) : {startDate: Date, endDate: Date}{
    const dateTime = getDateTimeFromObject().plus({years: -numberOfYears});
    return {
        startDate: Formatter.setHourOfTheDay(dateTime, 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

export function getPreviousMonthRange() : {startDate: Date, endDate: Date}{
    const dateTime = getDateTimeFromObject().plus({month: -1});
    return {
        startDate: Formatter.setHourOfTheDay(dateTime.startOf('month'), 0),
        endDate: dateTime.endOf('month').toUTC().toJSDate()
    };
}

export function getMonthToDateRange() : {startDate: Date, endDate: Date}{
    const dateTime = getDateTimeFromObject().startOf('month');
    return {
        startDate: Formatter.setHourOfTheDay(dateTime, 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

export function getQuarterToDateRange() : {startDate: Date, endDate: Date}{
    const dateTime = getDateTimeFromObject().startOf('quarter');
    return {
        startDate: Formatter.setHourOfTheDay(dateTime, 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

export function getYearToDateRange() : {startDate: Date, endDate: Date}{
    const dateTime = getDateTimeFromObject().startOf('year');
    return {
        startDate: Formatter.setHourOfTheDay(dateTime, 0),
        endDate: getDateTimeFromObjectToUTCToJSDate()
    };
}

export function getDatePeriodFromFilter(dateFilter: string) : {startDate: Date, endDate: Date} {
    switch (dateFilter) {
        case DateFilters.TODAY:
            return getTodayRange();
        case DateFilters.ONE_WEEK:
            return getWeeksAgoRange(1)
        case DateFilters.ONE_MONTH:
            return getMonthsAgoRange(1)
        case DateFilters.THREE_MONTH:
            return getMonthsAgoRange(3)
        case DateFilters.ONE_YEAR:
            return getYearsAgoRange(1)
        case DateFilters.PREVIOUS_MONTH:
            return getPreviousMonthRange()
        case DateFilters.MONTH_TO_DATE:
            return getMonthToDateRange()
        case DateFilters.QUARTER_TO_DATE:
            return getQuarterToDateRange()
        case DateFilters.YEAR_TO_DATE:
            return getYearToDateRange();
        default:
            return getTodayRange();
    }
}

function parseTimezoneOffset(hours: number) {
    return hours.toString().padStart(timezoneOffsetDecimalPlaces, '0');
}

export const getStore = () => store;