<template>
  <div class="compliance-form-detail-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="compliance-form-detail-content"
      :class="{['compliance-form-detail-content-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="compliance-form-sub-detail-content">
        <div class="options-bar">
          <div class="back-to-compliance-form" @click="() => navigateTo('profile')">
            <img src="@/app/assets/images/back.svg" alt="Back to Compliance icon" />
            <span>{{ $t('profile.actions.backToCompliance') }}</span>
          </div>
        </div>
        <div class="loading-message" v-if="!complianceForm">
          Fetching compliance form...
        </div>
        <div class="main-layout-container" v-else>
          <div class="cl-dock__main">
            <div class="layout-container">
              <div class="cards">
                <div class="card-container first-card">
                  <div class="card">
                    <ComplianceFormDetail :compliance-form="complianceForm" />
                  </div>
                </div>
                <div class="card-container" id="overview">
                  <div class="card">
                    <ComplianceFormOverview :show-title="true" :show-attested-at="false" :compliance-form="complianceForm" :in-progress-or-complete-status="inProgressOrCompleteStatus" />
                  </div>
                </div>
                <div class="card-container" id="attestation">
                  <div class="card">
                    <ComplianceFormAttestation :compliance-form="complianceForm" :in-progress-or-complete-status="inProgressOrCompleteStatus" />
                  </div>
                </div>
                <div class="card-container" id="audit-logs">
                  <div class="card">
                    <ComplianceFormAuditLogs :compliance-form="complianceForm" :in-progress-or-complete-status="inProgressOrCompleteStatus" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cl-dock__sidebar">
            <TableOfContents :sections="sections" />
          </div>
        </div>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav.vue";
import SearchBar from "@/components/SearchBar.vue";
import {navigateTo} from "@/router";
import {mapActions, mapGetters} from "vuex";
import complianceForms from "@/api/complianceForms";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import ComplianceFormDetail from "@/components/complianceforms/ComplianceFormDetail.vue";
import ComplianceFormOverview from "@/components/complianceforms/ComplianceFormOverview.vue";
import ComplianceFormAttestation from "@/components/complianceforms/ComplianceFormAttestation.vue";
import ComplianceFormAuditLogs from "@/components/complianceforms/ComplianceFormAuditLogs.vue";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import { EventBus } from "@/events/event-bus";
import TableOfContents from "@/components/common/TableOfContents.vue";
import locales from "@/i18n/locales";

export default {
  name: "ComplianceFormDetailsView",
  components: {TableOfContents, ComplianceFormDetail, ComplianceFormOverview, ComplianceFormAttestation, ComplianceFormAuditLogs, PortalFooter, SearchBar, SidebarNav},
  mixins: [portalCheckMixin],
  data: function () {
    return {
      sidebarNavCollapsed: false,
      complianceForm: null,
      sections: [],
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    loading() {
      return this.complianceForm === null
    },
    inProgressOrCompleteStatus() {
      return (this.complianceForm.compliance_form_status === 'INCOMPLETE' &&
          this.complianceForm.attestation_details.is_accepted) ||
        (this.complianceForm.compliance_form_status === 'COMPLETE');
    },
  },
  mounted: async function () {
    EventBus.$on("complianceFormUpdated", this.handleComplianceFormUpdated);
    await this.storeClientIpAddress();
    let response;
    try{
      response = await complianceForms.fetchComplianceFormById(
          this.getMerchantId, this.$route.params.complianceFormId);
    } catch (e) {
      this.$danger(this.$t('profile.getComplianceFormFailed'));
      return;
    }
    this.complianceForm = response;
    window.cbStorage.setItem("cb_profile_current_tab", "COMPLIANCE");
    this.sections=[
      { id: 'overview', title: locales.en.tableOfContent.overview, show: true},
      { id: 'attestation', title: locales.en.tableOfContent.compliance.attestation, show: true },
      { id: 'audit-logs', title: locales.en.tableOfContent.compliance.auditLogs, show: true}
    ];
  },
  beforeDestroy() {
    EventBus.$off("complianceFormUpdated", this.handleComplianceFormUpdated);
  },
  methods: {
    ...mapActions('app', ['storeClientIpAddress']),
    navigateTo: function (route) {
      navigateTo(route, false, this.$route.query);
    },
    handleComplianceFormUpdated(complianceForm) {
      this.complianceForm = complianceForm;
    }
  }
}
</script>

<style lang="scss">
.compliance-form-detail-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;


  & .loading-message {
    color: $primary_text_dark;
    margin-top: 52px;
  }

  & .main-layout-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .cl-dock__main {
      width: calc(75% - 8px);
      margin-right: 32px;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    & .cl-dock__sidebar {
      top: 50px;
      position: sticky;
      border-radius: 8px;
      width: calc(25% - 12px);
      align-self: flex-start;

      @media screen and (max-width: 700px) {
        display: none;
        width: 0;
      }
    }

    & .layout-container {
      width: 100%;
      display: flex;
      flex-direction: row;

      & .cards {
        width: 100%;

        & .card-container {
          scroll-margin-top: 70px;
          background-color: #FFFFFF;
          padding: 24px;
          border-radius: 6px;
          margin-top: 24px;

          & .card {
            width: 100%;
          }
        }

        & .first-card {
          margin-top: 0;
        }
      }
    }
  }

  & .compliance-form-detail-content {
    left: 256px;
    top: 0;
    position: relative;
    width: calc(100% - 256px);
    padding: 20px 30px 30px 30px;
    transition: all 0.2s ease-in;
    justify-content: center;
    display: flex;

    & .compliance-form-sub-detail-content {
      max-width: 1360px;
      width: 100%;
    }

    &.compliance-form-detail-content-sidebar-collapse {
      left: 86px;
      width: calc(100% - 86px);
    }

    & .options-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      & .back-to-compliance-form {
        cursor: pointer;

        & span {
          margin-left: 8px;
        }
      }

      & .respond-buttons {
        display: flex;
        align-items: center;

        & button {
          margin-left: 16px;
          margin-bottom: 0;

          & .c-button__label {
            padding: 0;
          }
        }
      }
    }
  }
}
</style>