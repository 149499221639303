<template>
  <div class="dispute-header">
    <div class="icon-and-header">
      <div class="logo"></div>
      <div class="header-and-id">
        <div class="header">
          {{ header }}
        </div>
        <div v-if="addFundsView" class="sub-header">
          {{ $t('addFundsToReserve.subTitle') }}
        </div>
        <div v-else class="id">
          {{ dispute && dispute.id }}
        </div>
      </div>
    </div>
    <div class="close" @click="() => showDismissConfirmation = true">
      <img alt="close" class="close-icon" src="@/app/assets/images/close.svg" />
    </div>
    <c-modal
      :control-variable="showDismissConfirmation"
      :primary-action="$t('global.action.confirm')"
      :secondary-action="$t('global.action.cancel')"
      :heading="$t('dismissConfirm.title')"
      variant="danger"
      custom-class="dispute-dismiss"
      @primary="navigateToDispute"
      @close="() => showDismissConfirmation = false"
      :description="$t('dismissConfirm.description')"
    />
  </div>
</template>

<script>

import {DisputeResponseDto} from "@/api/paymentApi";
import {navigateTo, navigateWithParam, } from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "DisputeHeader",
  props: {
    dispute: {
      type: DisputeResponseDto,
      required: false,
      default: undefined,
    },
    header: {
      type: String,
      required: true,
    },
    addFundsView: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: function() {
    return {
      showDismissConfirmation: false
    }
  },
  methods: {
    navigateToDispute: function () {
      if (this.addFundsView) {
        navigateTo(RouteConstants.DEPOSITS, false, this.$route.query);
      } else {
        navigateWithParam(RouteConstants.DISPUTE_DETAILS, {disputeId: this.dispute.id}, this.$route.query);
      }
    },
  },
}
</script>
<style lang="scss">
.dispute-header {
  border-bottom: 1px solid #D1D5DB;
  background-color: $color_white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;

  & .close {
    padding: 12px;
    margin-right: 32px;
    cursor: pointer;
  }
  
  & img {
    height: 14px;
    width: 14px;
  }

  & .icon-and-header {
    display: flex;
    margin-left: 30px;
    margin-top: 12px;
    margin-bottom: 12px;

    & .header {
      font-size: 24px;
      font-weight: $weight_bold;
    }

    & .sub-header {
      font-size: 14px;
      font-weight: $weight_bold;
    }

    & .id {
      font-size: 14px;
      color: #64648C;
      font-weight: $weight-normal;
    }

    & .logo {
      position: relative;
      font-size: 0;
      @include c-box(46px);
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        @include c-box(46px);
        background-image: url('~@/app/assets/images/brand/cv-logo-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }

    & .header-and-id {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
}
</style>