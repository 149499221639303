<template>
  <c-button
    variant="primary-outline"
    :disabled="disabled"
    :loading="loading"
    @click="createExport"
    :id="id"
  >
    <ToolTip
      :enabled="tooltipEnabled"
      placement="top"
      :content="tooltipContent"
    >
      <svg
        width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
        class="download-icon"
      >
        <path d="M1.99999 11.3327C1.63332 11.3327 1.31955 11.2022 1.05866 10.9413C0.797323 10.68 0.666656 10.366 0.666656 9.99935V7.99935H1.99999V9.99935H9.99999V7.99935H11.3333V9.99935C11.3333 10.366 11.2029 10.68 10.942 10.9413C10.6807 11.2022 10.3667 11.3327 9.99999 11.3327H1.99999ZM5.99999 8.66602L2.66666 5.33268L3.59999 4.36602L5.33332 6.09935V0.666016H6.66666V6.09935L8.39999 4.36602L9.33332 5.33268L5.99999 8.66602Z" fill="#2D6A8A" />
      </svg>
      {{ $t('global.action.export') }}
    </ToolTip>
  </c-button>
</template>

<script>

import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: 'ExportButton',
  components: {ToolTip},
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    createExport: {
      type: Function,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    tooltipEnabled: {
      type: Boolean,
      required: true,
    },
    tooltipContent: {
      type: String,
      required: true,
    },
  }
}
</script>