<template>
  <div class="transaction-info" v-if="transaction !== null">
    <div class="line1">
      <div class="transaction-type">
        <div class="icon">
          <TransactionsIcon />
        </div>
        {{ getTransactionTypeDisplayName(transaction.type).toUpperCase() }}
      </div>
      <div v-if="!!getCbAppId()">
        <div class="view-in-chargebee" @click="openCbAppDetailsPage">
          <span>{{ $t('global.action.view_in_chargebee') }}</span>
          <LinkIcon />
        </div>
      </div>
    </div>

    <div class="line2">
      <div class="amount">
        <div class="value">
          {{ getAmount() }}
        </div>
        <div class="currency-code">
          {{ transaction.currency }}
        </div>
      </div>
      <TransactionStatusTag :transaction="transaction" />
    </div>

    <div class="divider"></div>

    <div class="row">
      <FieldValue
        :field-name="$t('fieldNames.transactionId')"
        :field-value="transaction.id" :show-copy-value="true"
      />
      <div class="border" v-if="transaction.consumer"></div>
      <FieldValue
        v-if="transaction.consumer"
        :field-name="$t('fieldNames.customer')"
        :field-value="getCustomerName()"
      >
        <span v-if="getCustomerName()"> {{ getCustomerName() }}</span>
        <span v-else class="empty-placeholder">—</span>
      </FieldValue>
    </div>
  </div>
</template>
<script>
import TransactionsIcon from "@/components/icons/TransactionsIcon";
import Formatter from "@/app/utils/common/functions/formatter";
import FieldValue from "@/components/common/FieldValue.vue";
import {TransactionResponseDto} from "@/api/paymentApi";
import TransactionStatusTag from "@/components/transactions/TransactionStatusTag";
import {getName} from "@/app/utils/common/functions/functions";
import {isNegativeAmountType, getTransactionTypeDisplayName} from "@/app/utils/common/functions/transaction-util";
import {openChargebeeAppDetailsPage} from "@/app/utils/common/functions/url-helper";
import {mapGetters} from "vuex";
import LinkIcon from "@/components/icons/LinkIcon.vue";

export default {
  name: "TransactionInfo",
  components: {LinkIcon, TransactionStatusTag, FieldValue, TransactionsIcon},
  props: {
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
  },
  computed: {
    ...mapGetters('app', ['getCbSiteName'])
  },
  methods: {
    getTransactionTypeDisplayName,
    isNegativeAmountType,
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    getCustomerFullName() {
      let firstName = this.transaction.consumer.first_name;
      let lastName = this.transaction.consumer.last_name;
      return getName(firstName, lastName);
    },
    getCustomerName() {
      return this.getCustomerFullName() || this.transaction.payment_instrument.card?.card_holder_name || this.transaction.payment_instrument.bank_account?.name;
    },
    getAmount() {
      let amount = this.transaction.amount;
      if (this.isNegativeAmountType(this.transaction.type)) {
        amount = amount * (-1);
      }
      return this.format('currency', amount, "", this.transaction.currency)
    },
    getCbAppId() {
      return this.getCbSiteName ? this.transaction.tags?.cb_transaction_id : null;
    },
    openCbAppDetailsPage() {
      let id = this.getCbAppId();
      if (id) {
        openChargebeeAppDetailsPage(this.getCbSiteName, "transactions", id);
      }
    }
  }
}
</script>
<style lang="scss">

.transaction-info {

  & .line2 {
    display: flex;
    align-items: center;
    margin-top: 9px;

    & .amount {
      font-size: 24px;
      display: flex;
      align-items: center;
      margin-right: 12px;

      & .value {
        font-weight: $weight_bold;
        margin-right: 4px;
      }

      & .currency_code {
        font-weight: $weight_normal;
      }
    }
  }

  & .field {
    width: fit-content;
    text-align: left;

    & .field-label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    & .field-value {
      & .icon {
        cursor: pointer;
      }
    }
  }

  & .divider {
    width: 100%;
    background: #ECECF8;
    height: 1px;
    margin-top: 16px;
  }

  & .row {
    display: flex;

    & .border {
      width: 1px;
      background: #CDD2DB;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 24px;
    }

    & .empty-placeholder {
      color: $neutral_300;
    }
  }

  & .line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .info-icon {
      margin-left: 8px;
    }

    & .transaction-type {
      background-color: $neutral_50;
      width: fit-content;
      border: 1px solid $neutral_100;
      border-radius: 4px;
      padding: 2px;
      font-size: 12px;
      font-weight: $weight_bold;
      display: flex;
      align-items: center;

      & .icon {
        display: flex;
        align-items: center;
        margin-right: 5px;

        & svg {
          color: #64648C;
        }
      }
    }

    & .view-in-chargebee {
      display: flex;
      align-items: center;
      gap: 4px;
      color: $primary_600;
      font-weight: $weight_medium;
    }

    & .view-in-chargebee:hover {
      cursor: pointer;
    }
  }
}
</style>