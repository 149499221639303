<template>
  <svg width="14" height="18" viewBox="0 0 14 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99998 10.6666C7.45831 10.6666 7.85081 10.5033 8.17748 10.1766C8.50359 9.85051 8.66665 9.45829 8.66665 8.99996C8.66665 8.54163 8.50359 8.14913 8.17748 7.82246C7.85081 7.49635 7.45831 7.33329 6.99998 7.33329C6.54165 7.33329 6.14942 7.49635 5.82331 7.82246C5.49665 8.14913 5.33331 8.54163 5.33331 8.99996C5.33331 9.45829 5.49665 9.85051 5.82331 10.1766C6.14942 10.5033 6.54165 10.6666 6.99998 10.6666ZM3.66665 14H10.3333V13.5208C10.3333 13.1875 10.243 12.8819 10.0625 12.6041C9.88192 12.3263 9.63192 12.118 9.31248 11.9791C8.95137 11.8263 8.57998 11.7083 8.19831 11.625C7.81609 11.5416 7.41665 11.5 6.99998 11.5C6.58331 11.5 6.18387 11.5416 5.80165 11.625C5.41998 11.7083 5.04859 11.8263 4.68748 11.9791C4.36804 12.118 4.11804 12.3263 3.93748 12.6041C3.75692 12.8819 3.66665 13.1875 3.66665 13.5208V14ZM12 17.3333H1.99998C1.54165 17.3333 1.14942 17.1702 0.823313 16.8441C0.496646 16.5175 0.333313 16.125 0.333313 15.6666V2.33329C0.333313 1.87496 0.496646 1.48246 0.823313 1.15579C1.14942 0.829681 1.54165 0.666626 1.99998 0.666626H8.66665L13.6666 5.66663V15.6666C13.6666 16.125 13.5036 16.5175 13.1775 16.8441C12.8508 17.1702 12.4583 17.3333 12 17.3333ZM12 15.6666V6.37496L7.95831 2.33329H1.99998V15.6666H12Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'PaymentProfileIcon',
}
</script>