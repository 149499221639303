<template>
  <div class="dispute-evidence-form">
    <div class="dispute-evidence-title">
      Evidence
    </div>
    <div class="dispute-evidence-content">
      <div class="banner">
        <img src="@/app/assets/images/info-outline-blue.svg" alt="info-outline-blue" />
        <div class="evidence-info">
          <div>
            Evidence can include proof of communication with the customer, proof of delivery of purchase, proof of
            usage of service, screenshots of refund policy, etc.
          </div>
        </div>
      </div>

      <div class="uploaded-files">
        <div class="uploaded-file" v-for="fileUploaded in filesUploaded" :key="fileUploaded.id">
          <div class="left">
            <div class="file-name">
              {{ fileUploaded.file.name }}
            </div>
            <div class="file-size">
              {{ getFileSizeDisplay(fileUploaded.file) }}
            </div>
          </div>
          <div class="right" @click="() => removeFile(fileUploaded.id)">
            <img alt="close" class="close-icon" src="@/app/assets/images/close.svg" />
          </div>
        </div>
      </div>

      <div class="uploading-file" v-if="fileBeingUploaded">
        <div class="content">
          <div class="left">
            <div class="file-name">
              {{ fileBeingUploaded.name }}
            </div>
            <div class="file-size">
              {{ getFileSizeDisplay(fileBeingUploaded) }}
            </div>
          </div>
          <div class="right" @click="cancelFileUploaded">
            Cancel
          </div>
        </div>
        <div class="loader" :style="{width: `${fileUploadProgress}%`, display: fileUploadProgress ? 'block' : 'none'}">
        </div>
      </div>

      <div class="error-message" v-if="error || fileBeingUploadedError">
        {{ error || fileBeingUploadedError }}
      </div>

      <div class="upload-prompt" v-if="filesUploaded.length < 8" :class="{'disable-upload': fileBeingUploaded}">
        <label class="file-select">
          <div class="select-button">
            <span>{{ $t('disputes.selectFile') }}</span>
            <input
              type="file"
              @click="(event) => fileUploadInProgress && event.preventDefault()"
              @change="handleFileChange" :model="selectedFile"
            />
          </div>
        </label>
        <div class="info">
          Format supported: PDF, JPG or TIFF
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {DisputeResponseDto, ResourceType} from "@/api/paymentApi";
import files from "@/api/files";
import axios from "axios";
import {convertFileSizeToNearestUnit, validateFile, validateFileSize} from "@/app/utils/common/functions/fileUtils";

export default {
  name: "DisputeEvidenceUpload",
  props: {
    dispute: {
      type: DisputeResponseDto,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
    updateDisputeDetail: {
      type: Function,
      required: true,
    },
  },
  data: function () {
    return {
      selectedFile: '',
      fileUploadInProgress: false,
      fileBeingUploaded: null,
      fileBeingUploadedError: null,
      fileUploadProgress: null,
      filesUploaded: [],
      uploadCancelToken: null,
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
  },
  methods: {
    cancelFileUploaded(e) {
      this.uploadCancelToken.cancel();
      this.resetCurrentFileUpload(e);
      this.fileBeingUploadedError = null
    },
    removeFile(id) {
      this.filesUploaded = this.filesUploaded.filter(x => x.id !== id)
      this.updateDisputeDetail(this.filesUploaded.map(x => x.id))
    },
    getFileSizeDisplay(file) {
      let {fileSize, fileNotation} = convertFileSizeToNearestUnit(file.size)
      return fileSize + fileNotation
    },
    resetCurrentFileUpload: function (e) {
      this.selectedFile = ''
      this.fileBeingUploaded = null
      e.target.value = '';
    },
    uploadFile: async function (preSignedUrl) {
      await axios.put(preSignedUrl.url, this.fileBeingUploaded, {
        headers: {'Content-Type': this.fileBeingUploaded.type},
        cancelToken: this.uploadCancelToken.token,
        onUploadProgress: function (progressEvent) {
          this.fileUploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }.bind(this),
      });
    },
    getPreSignedUrl: async function (fileName) {
      return await files.getPreSignedURLForFileUpload(this.getMerchantId, {
        resource_type: ResourceType.DISPUTE,
        resource_id: this.dispute.id,
        file_name: fileName,
      });
    },
    async handleFileChange(e) {
      this.updateDisputeDetail(this.filesUploaded.map(x => x.id))
      this.fileUploadInProgress = true;
      this.fileBeingUploadedError = null;

      this.$emit('input', e.target.files[0])
      this.fileBeingUploaded = new File([e.target.files[0]], e.target.files[0].name, {type: e.target.files[0].type});

      let fileName = this.fileBeingUploaded.name;
      let fileSize = this.fileBeingUploaded.size

      this.fileBeingUploadedError = validateFile(fileName, this.fileBeingUploaded.type) 
      || validateFileSize(this.fileBeingUploaded.type, fileSize);
      if (this.fileBeingUploadedError) {
        this.fileUploadInProgress = false
        this.resetCurrentFileUpload(e);
        return
      }

      try {
        let preSignedUrl = await this.getPreSignedUrl(fileName);
        this.uploadCancelToken = axios.CancelToken.source();
        await this.uploadFile(preSignedUrl);
        this.fileUploadInProgress = false
        this.filesUploaded.push({file: this.fileBeingUploaded, id: preSignedUrl.id})
        this.updateDisputeDetail(this.filesUploaded.map(x => x.id))
      } catch (e) {
        this.fileUploadInProgress = false
        this.fileBeingUploadedError = "Uploading file failed. Please try again."
      }
      this.resetCurrentFileUpload(e);
    },
  },
}
</script>
<style lang="scss">
.dispute-evidence-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;

  & .dispute-evidence-title {
    font-size: 16px;
    font-weight: $weight_bold;
  }

  & .submit-button {
    width: 200px;
  }

  & .fee-info {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    margin-top: 60px;
    margin-bottom: 16px;
    color: #64648C;
  }

  & .info {
    margin-top: 12px;
    font-size: 12px;
    color: #374049;
  }

  & .upload-button {
    font-size: 14px;
    font-weight: $weight_bold;
    width: 200px;
    margin-top: 16px;
  }

  & .dispute-evidence-content {

    & .uploading-file {
      padding: 12px 20px;
      border: 0.5px solid $primary_600;
      border-radius: 4px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & .content {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      & .loader {
        height: 4px;
        background: $primary_600;
      }

      & .file-name {
        font-size: 14px;
        line-height: 20px;
        font-weight: $weight-medium;
      }

      & .file-size {
        font-size: 14px;
        color: #6B7280;
        line-height: 20px;
      }

      & .right {
        align-items: center;
        display: flex;
        cursor: pointer;
        padding: 20px;
      }
    }

    & .uploaded-file {
      padding: 12px 20px;
      border: 0.5px solid #0052CC;
      border-radius: 4px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      & .file-name {
        font-size: 14px;
        line-height: 20px;
        font-weight: $weight-medium;
      }

      & .file-size {
        font-size: 14px;
        color: #6B7280;
        line-height: 20px;
      }

      & .right {
        align-items: center;
        display: flex;
        cursor: pointer;
        padding: 20px;

        & img {
          width: 14px;
        }
      }
    }

    & .error-message {
      margin-top: 12px;
      padding: 12px 20px;
      background-color: #FFEBEB;
    }

    & .upload-prompt {
      margin-top: 12px;

      &.disable-upload {
        cursor: not-allowed;

        & .select-button {
          cursor: not-allowed;
          background-color: $disable_fill;

          & input {
            cursor: not-allowed;
          }
        }
      }

      & .file-select {
        display: flex;
        width: fit-content;

        & .select-button {
          background-color: #e5f3fa;
          color: #2d6a8a;
          padding: 10px 12px 10px 12px;
          border-radius: 6px;
          border: solid 1px #8ccce6;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
          position: relative;
          gap: 10px;
        }

        & .select-button:hover {
          background-color: #b5def0;
        }
      }
    }

    & .banner {
      display: flex;
      align-items: start;
      background: $info_50;
      border: 0.5px solid $info_100;
      border-radius: 4px;
      padding: 12px;
      gap: 10px;
      color: $primary_600;
      line-height: 18px;
      margin-top: 12px;

      & .evidence-info {
        color: $neutral-800;
        display: flex;
        flex-direction: column;

        & .evidence-info-link {
          font-weight: $weight-bold;
        }
      }
    }
  }


}
</style>