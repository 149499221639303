export const timezoneAbbreviationsMapping = {
    'Pacific/Pago_Pago': 'SST',
    'Pacific/Samoa': 'SST',
    'Pacific/Niue': 'NUT',
    'US/Samoa': 'SST',
    'Pacific/Midway': 'SST',
    'Pacific/Honolulu': 'HST',
    'Pacific/Rarotonga': 'CKT',
    'Pacific/Tahiti': 'TAHT',
    'Pacific/Johnston': 'HST',
    'US/Hawaii': 'HST',
    'Pacific/Marquesas': 'MART',
    'Pacific/Gambier': 'GAMT',
    'America/Atka': 'HDT',
    'America/Adak': 'HDT',
    'US/Aleutian': 'HDT',
    'US/Alaska': 'AKDT',
    'America/Juneau': 'AKDT',
    'America/Metlakatla': 'AKDT',
    'America/Yakutat': 'AKDT',
    'Pacific/Pitcairn': 'PST',
    'America/Sitka': 'AKDT',
    'America/Anchorage': 'AKDT',
    'America/Nome': 'AKDT',
    'Canada/Yukon': 'PDT',
    'US/Arizona': 'MST',
    'America/Dawson_Creek': 'MST',
    'Canada/Pacific': 'PDT',
    'PST8PDT': 'PDT',
    'America/Dawson': 'PDT',
    'Mexico/BajaNorte': 'PDT',
    'America/Tijuana': 'PDT',
    'America/Creston': 'MST',
    'America/Hermosillo': 'MST',
    'America/Santa_Isabel': 'PDT',
    'America/Vancouver': 'PDT',
    'America/Ensenada': 'PDT',
    'America/Phoenix': 'MST',
    'America/Whitehorse': 'PDT',
    'America/Fort_Nelson': 'MST',
    'America/Los_Angeles': 'PDT',
    'US/Pacific': 'PDT',
    'America/El_Salvador': 'CST',
    'America/Guatemala': 'CST',
    'America/Belize': 'CST',
    'America/Managua': 'CST',
    'America/Tegucigalpa': 'CST',
    'Mexico/BajaSur': 'MDT',
    'America/Regina': 'CST',
    'America/Denver': 'MDT',
    'Pacific/Galapagos': 'GALT',
    'America/Yellowknife': 'MDT',
    'America/Swift_Current': 'CST',
    'America/Inuvik': 'MDT',
    'America/Mazatlan': 'MDT',
    'America/Boise': 'MDT',
    'America/Costa_Rica': 'CST',
    'MST7MDT': 'MDT',
    'America/Chihuahua': 'MDT',
    'America/Ojinaga': 'MDT',
    'US/Mountain': 'MDT',
    'America/Edmonton': 'MDT',
    'Canada/Mountain': 'MDT',
    'America/Cambridge_Bay': 'MDT',
    'Navajo': 'MDT',
    'Canada/Saskatchewan': 'CST',
    'America/Shiprock': 'MDT',
    'America/Panama': 'EST',
    'America/Chicago': 'CDT',
    'America/Eirunepe': 'ACT',
    'Pacific/Easter': 'EASST',
    'Mexico/General': 'CDT',
    'America/Porto_Acre': 'ACT',
    'America/Guayaquil': 'ECT',
    'America/Rankin_Inlet': 'CDT',
    'US/Central': 'CDT',
    'America/Rainy_River': 'CDT',
    'America/Indiana/Knox': 'CDT',
    'America/North_Dakota/Beulah': 'CDT',
    'America/Monterrey': 'CDT',
    'America/Jamaica': 'EST',
    'America/Atikokan': 'EST',
    'America/Coral_Harbour': 'EST',
    'America/North_Dakota/Center': 'CDT',
    'America/Cayman': 'EST',
    'America/Indiana/Tell_City': 'CDT',
    'Chile/EasterIsland': 'EASST',
    'America/Mexico_City': 'CDT',
    'America/Matamoros': 'CDT',
    'CST6CDT': 'CDT',
    'America/Knox_IN': 'CDT',
    'America/Bogota': 'COT',
    'America/Menominee': 'CDT',
    'America/Resolute': 'CDT',
    'Canada/Central': 'CDT',
    'Brazil/Acre': 'ACT',
    'America/Cancun': 'EST',
    'America/Lima': 'PET',
    'America/Bahia_Banderas': 'CDT',
    'US/Indiana-Starke': 'CDT',
    'America/Rio_Branco': 'ACT',
    'Jamaica': 'EST',
    'America/Merida': 'CDT',
    'America/North_Dakota/New_Salem': 'CDT',
    'America/Winnipeg': 'CDT',
    'America/Cuiaba': 'AMT',
    'America/Marigot': 'AST',
    'America/Indiana/Petersburg': 'EDT',
    'America/Grand_Turk': 'EDT',
    'Cuba': 'CDT',
    'America/Manaus': 'AMT',
    'America/Fort_Wayne': 'EDT',
    'America/St_Thomas': 'AST',
    'America/Anguilla': 'AST',
    'America/Havana': 'CDT',
    'US/Michigan': 'EDT',
    'America/Barbados': 'AST',
    'America/Louisville': 'EDT',
    'America/Curacao': 'AST',
    'America/Guyana': 'GYT',
    'America/Martinique': 'AST',
    'America/Puerto_Rico': 'AST',
    'America/Port_of_Spain': 'AST',
    'America/Indiana/Vevay': 'EDT',
    'America/Indiana/Vincennes': 'EDT',
    'America/Kralendijk': 'AST',
    'America/Antigua': 'AST',
    'America/Indianapolis': 'EDT',
    'America/Iqaluit': 'EDT',
    'America/St_Vincent': 'AST',
    'America/Kentucky/Louisville': 'EDT',
    'America/Dominica': 'AST',
    'EST5EDT': 'EDT',
    'America/Nassau': 'EDT',
    'America/Kentucky/Monticello': 'EDT',
    'Brazil/West': 'AMT',
    'America/Aruba': 'AST',
    'America/Indiana/Indianapolis': 'EDT',
    'America/La_Paz': 'BOT',
    'America/Thunder_Bay': 'EDT',
    'America/Indiana/Marengo': 'EDT',
    'America/Blanc-Sablon': 'AST',
    'America/Santo_Domingo': 'AST',
    'US/Eastern': 'EDT',
    'Canada/Eastern': 'EDT',
    'America/Port-au-Prince': 'EDT',
    'America/St_Barthelemy': 'AST',
    'America/Nipigon': 'EDT',
    'US/East-Indiana': 'EDT',
    'America/St_Lucia': 'AST',
    'America/Montserrat': 'AST',
    'America/Lower_Princes': 'AST',
    'America/Detroit': 'EDT',
    'America/Tortola': 'AST',
    'America/Porto_Velho': 'AMT',
    'America/Campo_Grande': 'AMT',
    'America/Virgin': 'AST',
    'America/Pangnirtung': 'EDT',
    'America/Montreal': 'EDT',
    'America/Indiana/Winamac': 'EDT',
    'America/Boa_Vista': 'AMT',
    'America/Grenada': 'AST',
    'America/New_York': 'EDT',
    'America/St_Kitts': 'AST',
    'America/Caracas': 'VET',
    'America/Guadeloupe': 'AST',
    'America/Toronto': 'EDT',
    'Chile/Continental': 'CLST',
    'America/Argentina/Catamarca': 'ART',
    'Canada/Atlantic': 'ADT',
    'America/Argentina/Cordoba': 'ART',
    'America/Araguaina': 'BRT',
    'America/Argentina/Salta': 'ART',
    'America/Montevideo': 'UYT',
    'Brazil/East': 'BRT',
    'America/Argentina/Mendoza': 'ART',
    'America/Argentina/Rio_Gallegos': 'ART',
    'America/Catamarca': 'ART',
    'America/Cordoba': 'ART',
    'America/Sao_Paulo': 'BRT',
    'America/Argentina/Jujuy': 'ART',
    'America/Cayenne': 'GFT',
    'America/Recife': 'BRT',
    'America/Buenos_Aires': 'ART',
    'America/Paramaribo': 'SRT',
    'America/Moncton': 'ADT',
    'America/Mendoza': 'ART',
    'America/Santarem': 'BRT',
    'America/Asuncion': 'PYST',
    'Atlantic/Bermuda': 'ADT',
    'America/Maceio': 'BRT',
    'Atlantic/Stanley': 'FKT',
    'America/Halifax': 'ADT',
    'Antarctica/Rothera': 'ROTT',
    'America/Argentina/San_Luis': 'ART',
    'America/Santiago': 'CLST',
    'America/Argentina/Ushuaia': 'ART',
    'Antarctica/Palmer': 'CLT',
    'America/Punta_Arenas': 'America/Punta_Arenas',
    'America/Glace_Bay': 'ADT',
    'America/Fortaleza': 'BRT',
    'America/Thule': 'ADT',
    'America/Argentina/La_Rioja': 'ART',
    'America/Belem': 'BRT',
    'America/Jujuy': 'ART',
    'America/Bahia': 'BRT',
    'America/Goose_Bay': 'ADT',
    'America/Argentina/San_Juan': 'ART',
    'America/Argentina/ComodRivadavia': 'ART',
    'America/Argentina/Tucuman': 'ART',
    'America/Rosario': 'ART',
    'America/Argentina/Buenos_Aires': 'ART',
    'America/St_Johns': 'NDT',
    'Canada/Newfoundland': 'NDT',
    'America/Miquelon': 'PMDT',
    'America/Godthab': 'WGST',
    'America/Noronha': 'FNT',
    'Brazil/DeNoronha': 'FNT',
    'Atlantic/South_Georgia': 'GST',
    'Atlantic/Cape_Verde': 'CVT',
    'GMT': 'GMT',
    'Atlantic/St_Helena': 'GMT',
    'Africa/Banjul': 'GMT',
    'Africa/Freetown': 'GMT',
    'Africa/Bamako': 'GMT',
    'Africa/Conakry': 'GMT',
    'Universal': 'UTC',
    'Africa/Nouakchott': 'GMT',
    'UTC': 'UTC',
    'Atlantic/Azores': 'AZOST',
    'Africa/Abidjan': 'GMT',
    'Africa/Accra': 'GMT',
    'GMT0': 'GMT',
    'Zulu': 'UTC',
    'Africa/Ouagadougou': 'GMT',
    'Atlantic/Reykjavik': 'GMT',
    'Iceland': 'GMT',
    'Africa/Lome': 'GMT',
    'Greenwich': 'GMT',
    'America/Danmarkshavn': 'GMT',
    'Africa/Dakar': 'GMT',
    'America/Scoresbysund': 'EGST',
    'Africa/Bissau': 'GMT',
    'Africa/Timbuktu': 'GMT',
    'UCT': 'UTC',
    'Africa/Monrovia': 'GMT',
    'Europe/London': 'BST',
    'Europe/Jersey': 'BST',
    'Europe/Guernsey': 'BST',
    'Europe/Isle_of_Man': 'BST',
    'Africa/Tunis': 'CET',
    'Africa/Malabo': 'WAT',
    'GB-Eire': 'BST',
    'Africa/Lagos': 'WAT',
    'Africa/Algiers': 'CET',
    'GB': 'BST',
    'Portugal': 'WEST',
    'Africa/Sao_Tome': 'WAT',
    'Africa/Ndjamena': 'WAT',
    'Atlantic/Faeroe': 'WEST',
    'Eire': 'IST',
    'Atlantic/Faroe': 'WEST',
    'Europe/Dublin': 'IST',
    'Africa/Libreville': 'WAT',
    'Africa/El_Aaiun': 'WET',
    'Africa/Douala': 'WAT',
    'Africa/Brazzaville': 'WAT',
    'Africa/Porto-Novo': 'WAT',
    'Atlantic/Madeira': 'WEST',
    'Europe/Lisbon': 'WEST',
    'Atlantic/Canary': 'WEST',
    'Africa/Casablanca': 'WET',
    'Europe/Belfast': 'BST',
    'Africa/Luanda': 'WAT',
    'Africa/Kinshasa': 'WAT',
    'Africa/Bangui': 'WAT',
    'WET': 'WEST',
    'Africa/Niamey': 'WAT',
    'Africa/Cairo': 'EET',
    'Africa/Mbabane': 'SAST',
    'Europe/Brussels': 'CEST',
    'Europe/Warsaw': 'CEST',
    'CET': 'CEST',
    'Europe/Luxembourg': 'CEST',
    'Libya': 'EET',
    'Africa/Kigali': 'CAT',
    'Africa/Tripoli': 'EET',
    'Europe/Kaliningrad': 'EET',
    'Africa/Windhoek': 'CAT',
    'Europe/Malta': 'CEST',
    'Europe/Busingen': 'CEST',
    'Europe/Skopje': 'CEST',
    'Europe/Sarajevo': 'CEST',
    'Europe/Rome': 'CEST',
    'Europe/Zurich': 'CEST',
    'Europe/Gibraltar': 'CEST',
    'Africa/Lubumbashi': 'CAT',
    'Europe/Vaduz': 'CEST',
    'Europe/Ljubljana': 'CEST',
    'Europe/Berlin': 'CEST',
    'Europe/Stockholm': 'CEST',
    'Europe/Budapest': 'CEST',
    'Europe/Zagreb': 'CEST',
    'Europe/Paris': 'CEST',
    'Africa/Ceuta': 'CEST',
    'Europe/Prague': 'CEST',
    'Antarctica/Troll': 'CEST',
    'Africa/Gaborone': 'CAT',
    'Europe/Copenhagen': 'CEST',
    'Europe/Vienna': 'CEST',
    'Europe/Tirane': 'CEST',
    'MET': 'MEST',
    'Europe/Amsterdam': 'CEST',
    'Africa/Maputo': 'CAT',
    'Europe/San_Marino': 'CEST',
    'Asia/Damascus': 'EET',
    'Poland': 'CEST',
    'Europe/Andorra': 'CEST',
    'Europe/Oslo': 'CEST',
    'Europe/Podgorica': 'CEST',
    'Africa/Bujumbura': 'CAT',
    'Atlantic/Jan_Mayen': 'CEST',
    'Africa/Maseru': 'SAST',
    'Europe/Madrid': 'CEST',
    'Africa/Blantyre': 'CAT',
    'Africa/Lusaka': 'CAT',
    'Africa/Harare': 'CAT',
    'Africa/Khartoum': 'CAT',
    'Africa/Johannesburg': 'SAST',
    'Europe/Belgrade': 'CEST',
    'Europe/Bratislava': 'CEST',
    'Arctic/Longyearbyen': 'CEST',
    'Egypt': 'EET',
    'Europe/Vatican': 'CEST',
    'Asia/Amman': 'EET',
    'Europe/Monaco': 'CEST',
    'Asia/Aden': 'AST',
    'Africa/Nairobi': 'EAT',
    'Europe/Istanbul': 'EET',
    'Europe/Zaporozhye': 'EEST',
    'Israel': 'IDT',
    'Indian/Comoro': 'EAT',
    'Antarctica/Syowa': 'SYOT',
    'Africa/Mogadishu': 'EAT',
    'Europe/Bucharest': 'EEST',
    'Africa/Asmera': 'EAT',
    'Europe/Mariehamn': 'EEST',
    'Asia/Istanbul': 'EET',
    'Europe/Tiraspol': 'EEST',
    'Europe/Moscow': 'MSK',
    'Europe/Chisinau': 'EEST',
    'Europe/Helsinki': 'EEST',
    'Asia/Beirut': 'EEST',
    'Asia/Tel_Aviv': 'IDT',
    'Africa/Djibouti': 'EAT',
    'Europe/Simferopol': 'MSK',
    'Europe/Sofia': 'EEST',
    'Asia/Gaza': 'EEST',
    'Africa/Asmara': 'EAT',
    'Europe/Riga': 'EEST',
    'Asia/Baghdad': 'AST',
    'Africa/Dar_es_Salaam': 'EAT',
    'Africa/Addis_Ababa': 'EAT',
    'Europe/Uzhgorod': 'EEST',
    'Asia/Jerusalem': 'IDT',
    'Asia/Riyadh': 'AST',
    'Asia/Kuwait': 'AST',
    'Europe/Kirov': 'Europe/Kirov',
    'Africa/Kampala': 'EAT',
    'Europe/Minsk': 'MSK',
    'Asia/Qatar': 'AST',
    'Europe/Kiev': 'EEST',
    'Asia/Bahrain': 'AST',
    'Europe/Vilnius': 'EEST',
    'Indian/Antananarivo': 'EAT',
    'Indian/Mayotte': 'EAT',
    'Europe/Tallinn': 'EEST',
    'Turkey': 'EET',
    'Africa/Juba': 'EAT',
    'Asia/Nicosia': 'EEST',
    'Asia/Famagusta': 'Asia/Famagusta',
    'W-SU': 'MSK',
    'EET': 'EEST',
    'Asia/Hebron': 'EEST',
    'Europe/Nicosia': 'EEST',
    'Europe/Athens': 'EEST',
    'Iran': 'IRST',
    'Asia/Tehran': 'IRST',
    'Asia/Yerevan': 'AMT',
    'Asia/Dubai': 'GST',
    'Indian/Reunion': 'RET',
    'Indian/Mauritius': 'MUT',
    'Europe/Saratov': 'Europe/Saratov',
    'Europe/Samara': 'SAMT',
    'Indian/Mahe': 'SCT',
    'Asia/Baku': 'AZT',
    'Asia/Muscat': 'GST',
    'Europe/Volgograd': 'MSK',
    'Europe/Astrakhan': 'Europe/Astrakhan',
    'Asia/Tbilisi': 'GET',
    'Europe/Ulyanovsk': 'Europe/Ulyanovsk',
    'Asia/Kabul': 'AFT',
    'Asia/Aqtau': 'AQTT',
    'Asia/Samarkand': 'UZT',
    'Asia/Karachi': 'PKT',
    'Asia/Yekaterinburg': 'YEKT',
    'Asia/Dushanbe': 'TJT',
    'Indian/Maldives': 'MVT',
    'Asia/Oral': 'ORAT',
    'Asia/Tashkent': 'UZT',
    'Antarctica/Mawson': 'MAWT',
    'Asia/Aqtobe': 'AQTT',
    'Asia/Ashkhabad': 'TMT',
    'Asia/Ashgabat': 'TMT',
    'Asia/Atyrau': 'Asia/Atyrau',
    'Indian/Kerguelen': 'TFT',
    'Asia/Kolkata': 'IST',
    'Asia/Colombo': 'IST',
    'Asia/Calcutta': 'IST',
    'Asia/Kathmandu': 'NPT',
    'Asia/Katmandu': 'NPT',
    'Asia/Kashgar': 'XJT',
    'Asia/Almaty': 'ALMT',
    'Asia/Dacca': 'BDT',
    'Asia/Omsk': 'OMST',
    'Asia/Dhaka': 'BDT',
    'Indian/Chagos': 'IOT',
    'Asia/Qyzylorda': 'QYZT',
    'Asia/Bishkek': 'KGT',
    'Antarctica/Vostok': 'VOST',
    'Asia/Urumqi': 'XJT',
    'Asia/Thimbu': 'BTT',
    'Asia/Thimphu': 'BTT',
    'Asia/Yangon': 'MMT',
    'Asia/Rangoon': 'MMT',
    'Indian/Cocos': 'CCT',
    'Asia/Pontianak': 'WIB',
    'Asia/Phnom_Penh': 'ICT',
    'Asia/Novosibirsk': 'NOVT',
    'Antarctica/Davis': 'DAVT',
    'Asia/Tomsk': 'Asia/Tomsk',
    'Asia/Jakarta': 'WIB',
    'Asia/Barnaul': 'Asia/Barnaul',
    'Indian/Christmas': 'CXT',
    'Asia/Ho_Chi_Minh': 'ICT',
    'Asia/Hovd': 'HOVT',
    'Asia/Bangkok': 'ICT',
    'Asia/Vientiane': 'ICT',
    'Asia/Novokuznetsk': 'KRAT',
    'Asia/Krasnoyarsk': 'KRAT',
    'Asia/Saigon': 'ICT',
    'Asia/Kuching': 'MYT',
    'Asia/Chungking': 'CST',
    'Australia/Perth': 'AWST',
    'Asia/Macao': 'CST',
    'Asia/Macau': 'CST',
    'Asia/Choibalsan': 'CHOT',
    'Asia/Shanghai': 'CST',
    'Antarctica/Casey': 'AWST',
    'Asia/Ulan_Bator': 'ULAT',
    'Asia/Chongqing': 'CST',
    'Asia/Ulaanbaatar': 'ULAT',
    'Asia/Taipei': 'CST',
    'Asia/Manila': 'PST',
    'PRC': 'CST',
    'Asia/Ujung_Pandang': 'WITA',
    'Asia/Harbin': 'CST',
    'Singapore': 'SGT',
    'Asia/Brunei': 'BNT',
    'Australia/West': 'AWST',
    'Asia/Hong_Kong': 'HKT',
    'Asia/Makassar': 'WITA',
    'Hongkong': 'HKT',
    'Asia/Kuala_Lumpur': 'MYT',
    'Asia/Irkutsk': 'IRKT',
    'Asia/Singapore': 'SGT',
    'Australia/Eucla': 'ACWST',
    'Pacific/Palau': 'PWT',
    'Asia/Chita': 'YAKT',
    'Asia/Dili': 'TLT',
    'Asia/Jayapura': 'WIT',
    'Asia/Yakutsk': 'YAKT',
    'Asia/Pyongyang': 'KST',
    'ROK': 'KST',
    'Asia/Seoul': 'KST',
    'Asia/Khandyga': 'YAKT',
    'Japan': 'JST',
    'Asia/Tokyo': 'JST',
    'Australia/North': 'ACST',
    'Australia/Darwin': 'ACST',
    'Pacific/Yap': 'CHUT',
    'Pacific/Port_Moresby': 'PGT',
    'Pacific/Chuuk': 'CHUT',
    'Australia/Queensland': 'AEST',
    'Pacific/Guam': 'ChST',
    'Pacific/Truk': 'CHUT',
    'Asia/Vladivostok': 'VLAT',
    'Pacific/Saipan': 'ChST',
    'Antarctica/DumontDUrville': 'DDUT',
    'Australia/Brisbane': 'AEST',
    'Asia/Ust-Nera': 'VLAT',
    'Australia/Lindeman': 'AEST',
    'Australia/Yancowinna': 'ACDT',
    'Australia/Adelaide': 'ACDT',
    'Australia/Broken_Hill': 'ACDT',
    'Australia/South': 'ACDT',
    'Australia/Hobart': 'AEDT',
    'Australia/Tasmania': 'AEDT',
    'Australia/ACT': 'AEDT',
    'Pacific/Ponape': 'PONT',
    'Pacific/Bougainville': 'BST',
    'Australia/Victoria': 'AEDT',
    'Antarctica/Macquarie': 'MIST',
    'Australia/Canberra': 'AEDT',
    'Australia/Currie': 'AEDT',
    'Australia/Lord_Howe': 'LHDT',
    'Australia/NSW': 'AEDT',
    'Pacific/Pohnpei': 'PONT',
    'Pacific/Efate': 'VUT',
    'Pacific/Norfolk': 'NFT',
    'Asia/Magadan': 'MAGT',
    'Pacific/Kosrae': 'KOST',
    'Australia/Sydney': 'AEDT',
    'Australia/LHI': 'LHDT',
    'Asia/Sakhalin': 'SAKT',
    'Pacific/Noumea': 'NCT',
    'Asia/Srednekolymsk': 'SRET',
    'Australia/Melbourne': 'AEDT',
    'Pacific/Guadalcanal': 'SBT',
    'Pacific/Kwajalein': 'MHT',
    'Pacific/Wallis': 'WFT',
    'Pacific/Fiji': 'FJT',
    'Pacific/Funafuti': 'TVT',
    'Pacific/Nauru': 'NRT',
    'Kwajalein': 'MHT',
    'Pacific/Wake': 'WAKT',
    'Pacific/Tarawa': 'GILT',
    'Asia/Kamchatka': 'PETT',
    'Asia/Anadyr': 'ANAT',
    'Pacific/Majuro': 'MHT',
    'Pacific/Fakaofo': 'TKT',
    'Antarctica/McMurdo': 'NZDT',
    'Pacific/Enderbury': 'PHOT',
    'NZ': 'NZDT',
    'Antarctica/South_Pole': 'NZDT',
    'Pacific/Auckland': 'NZDT',
    'Pacific/Tongatapu': 'TOT',
    'NZ-CHAT': 'CHADT',
    'Pacific/Chatham': 'CHADT',
    'Pacific/Apia': 'WSDT',
    'Pacific/Kiritimati': 'LINT',
}