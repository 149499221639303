<template>
  <div class="payment-retries-container">
    <div class="title">
      {{ $t('tableOfContent.transaction.payment_retries') }}
    </div>

    <div class="payment-retries-info">
      <div
        class="payment-retries-row"
        v-for="attempt in getDunningAttemptsData()"
        :key="attempt.id"
        @mouseover="() => { id = attempt.id }"
        @mouseleave="() => { id = null }"
      >
        <div class="status-tag-container">
          <div
            class="status"
            :class="{
              'success-status': ['SUCCESS', 'REFUNDED'].includes(attempt.state),
              'returned-status': attempt.state === 'RETURNED',
              'failure-status': ['FAILED', 'NOT_REFUNDED'].includes(attempt.state),
              'canceled-status': attempt.state === 'CANCELED',
              'progress-status':
                !['SUCCESS', 'FAILED', 'CANCELED', 'NOT_REFUNDED', 'REFUNDED', 'RETURNED'].includes(attempt.state)
            }"
          >
            {{ $t('transactions.status.' + attempt.state) }}
          </div>
        </div>
        <div
          class="created-at-container"
          @click="goToTransaction(attempt.id)"
        >
          {{ formatDateWithTimezone(attempt.created_at.toString()) }}
        </div>
        <div v-if="attempt.error_reason">
          —
        </div>
        <div
          class="status-info-container"
          v-if="attempt.error_reason"
        >
          {{ "'" + attempt.error_reason + "'" }}
        </div>
        <div class="link-icon-container">
          <div v-if="id === attempt.id" class="icon" @click="goToTransaction(attempt.id)">
            <LinkIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {TransactionResponseDto} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import LinkIcon from "@/components/icons/LinkIcon.vue";
import {navigateWithParam} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "PaymentRetries",
  components: {LinkIcon},
  props: {
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
  },
  data() {
    return {
      id: null,
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
  },
  methods: {
    goToTransaction(dunningAttemptId) {
      navigateWithParam(RouteConstants.TRANSACTION_DETAILS,
          {transactionId: dunningAttemptId},
          {
            ...this.$route.query,
            consumerId: this.transaction.consumer.id,
          })
    },
    getDunningAttemptsData() {
      return this.transaction.dunning_attempts.slice().reverse();
    },
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
  },
}
</script>

<style lang="scss">
.payment-retries-container {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .payment-retries-info {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    & .payment-retries-row {
      display: flex;
      text-align: left;
      line-height: 24px;
      font-size: 14px;

      & .status-tag-container {
        display: flex;
        margin-right: 12px;

          & .status {
            display: flex;
            margin: auto;
            font-size: 12px;
            padding: 2px 10px;
            border-radius: 10px;
            height: fit-content;
            font-style: normal;
            font-weight: $weight_medium;
            line-height: 16px;
          }

          & .success-status {
            background-color: $success_100;
            color: $success_600;
          }

          & .returned-status {
            background-color: $danger_50;
            color: $danger_800;
          }

          & .failure-status {
            background-color: $danger_50;
            color: $danger_800;
          }

          & .canceled-status {
            background-color: $warn_50;
            color: $warn_800;
          }

          & .progress-status {
            background-color: $primary_100;
            color: $primary_800;
          }
        }

      & .created-at-container {
        flex-shrink: 0;
        margin-right: 3px;
        font-weight: $weight_normal;
        color: $primary_600;
        cursor: pointer;
      }

      & .status-info-container {
        margin-left: 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $weight_bold;
      }

      & .link-icon-container {
        margin-left: auto;
        padding-left: 20px;
        display: flex;
        color: $neutral_400;
        align-items: center;

        & .icon {
          display: flex;
        }

        & .icon:hover {
          cursor: pointer;
        }
      }
    }
  }

}
</style>