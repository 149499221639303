<template>
  <div class="field">
    <c-label :label="label" />
    <span v-if="optional" class="optional">&nbsp;(Optional)</span>
    <span v-if="showMandatory" class="mandatory">*</span>
    <c-input
      :id="id"
      :value="format ? formatValue(value, format) : value"
      :min="min"
      :max="max"
      :max-length="maxLength"
      :type="type"
      @input="(event) =>{
        if (format) {
          event.target.value = deformatValue(event.target.value)
        }
        return setValue(event)
      }"
      @keyup.enter="onEnter"
      @click.right="(event) => {if (blockCopyPaste) event.preventDefault()}"
      @copy="(event) => {if (blockCopyPaste) event.preventDefault()}"
      @paste="(event) => {handlePaste(event)}"
      class="c-flex-grow-1"
      :data-testid="id"
    >
      <div slot="append" class="slot" v-if="appendSlot !== undefined">
        <span>{{ appendSlot }}</span>
      </div>
      <div slot="prepend" class="slot" v-if="prependSlot !== undefined">
        <span>{{ prependSlot }}</span>
      </div>
    </c-input>
    <c-inline-error
      v-if="Boolean(error)"
      :message="error"
    />
    <div class="hint" v-if="!Boolean(error) && Boolean(hint)">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/events/event-bus";
import {FormEvents} from "@/app/utils/common/constants";

export default {
  name: "InputField",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    setValue: {
      type: Function,
      required: true
    },
    error: {
      type: String,
      required: false,
      default: undefined
    },
    hint: {
      type: String,
      required: false,
      default: undefined
    },
    format: {
      type: String,
      required: false,
      default: undefined,
    },
    appendSlot: {
      type: String,
      required: false,
      default: undefined
    },
    prependSlot: {
      type: String,
      required: false,
      default: undefined
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    min: {
      type: Number,
      required: false,
      default: undefined
    },
    max: {
      type: Number,
      required: false,
      default: undefined
    },
    maxLength: {
      type: Number,
      required: false,
      default: undefined
    },
    dataTestId: {
      type: String,
      required: false,
      default: undefined

    },
    optional: Boolean,
    blockCopyPaste: Boolean,
    showMandatory: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    onEnter() {
      EventBus.$emit(FormEvents.ENTER, {source: this});
    },
    handlePaste(event) {
      if(this.type === 'number' && !(/^\d+$/.test(event.clipboardData.getData('text/plain')))) {
        event.preventDefault();
      }
      if(this.blockCopyPaste) event.preventDefault();
    },
    formatValue(value, strFormat) {
      if (value === undefined || value === null) return value;
      let inputIndex = 0;
      let result = [...strFormat]
      for (let index = 0; index < result.length; index++)
        if (result[index] === 'x')
          result[index] = value.charAt(inputIndex++);
      return result.join("").replace(/-$/g, "")
    },
    deformatValue(formattedValue) {
      return formattedValue.replaceAll("-", "")
    }
  }
}
</script>

<style lang="scss">

.field {
  margin-top: 24px;
  width: inherit;

  & .optional {
    color: $subtitle_text;
    font-weight: $weight-medium;
  }

  & .mandatory {
    color: #DE3618;
  }

  & .c-input {
    margin-top: 4px;
    margin-bottom: 8px;

    & .c-input__group {
      padding: 9px 13px;
      gap: 8px;
    }

    & .c-input__addon {
      padding: 0;
    }

    & .c-input__element {
      padding: 0;
      outline:0 none !important;
    }

    & .c-input__element:focus ::-moz-focus-inner {
      outline:0 none !important;
    }

    & .slot {
      color: $subtitle_text;
    }
  }

  & .hint {
    font-size: 12px;
    color: $primary_text_dark;
  }

  & .c-label {
    font-weight: $weight-medium;
    white-space: nowrap;
    color: $text-color;
  }
}
</style>