import {CountryBasedApiField, OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";
import {
    organisationRegistrationNumber,
    organisationTaxId,
    organisationVatNumber,
    soleProprietorRegistrationNumber,
    soleProprietorTaxId,
    soleProprietorVatNumber
} from "@/app/validators/countrySpecificValidationSpec";
import {
    assertBusinessCountryFromApiResponse,
    assertBusinessCountryFromStore,
    assertSoloProprietorshipFromApiResponse,
    assertSoloProprietorshipFromStore,
} from "@/store/modules/onboarding/conditionalApiFields/conditionHelpers";

export default {
    apiFields: {
        organisationTaxId: {
            apiPath: "business_info.tax_info.tax_id",
            value: null,
            formattedValue: undefined,
            valid: false,
            label: {
                ["USA"]: "businessStructure.taxId.usa.label",
                ["DEU"]: "businessStructure.taxId.deu.label",
                ["GBR"]: "businessStructure.taxId.gbr.label",
            },
            instruction: {
                ["USA"]: "businessStructure.taxId.usa.instruction",
                ["DEU"]: "businessStructure.taxId.deu.instruction",
                ["GBR"]: "businessStructure.taxId.gbr.instruction",
            },
            shouldShowInView: (rootGetters) =>
              assertBusinessCountryFromStore(rootGetters, "USA", "DEU", "GBR") &&
              !assertSoloProprietorshipFromStore(rootGetters),
            shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
              assertBusinessCountryFromApiResponse(rootState, flatApiResponse,
                "USA", "DEU", "GBR") && !assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse),
            validationSpec: organisationTaxId
        },
        organisationRegistrationNumber: {
            apiPath: "business_info.tax_info.registration_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            label: {
                ["FRA"]: "businessStructure.registrationNumber.fra.label",
                ["DEU"]: "businessStructure.registrationNumber.deu.label",
                ["GBR"]: "businessStructure.registrationNumber.gbr.label",
            },
            instruction: {
                ["FRA"]: "businessStructure.registrationNumber.fra.instruction",
                ["DEU"]: "businessStructure.registrationNumber.deu.instruction",
                ["GBR"]: "businessStructure.registrationNumber.gbr.instruction",
            },
            shouldShowInView: (rootGetters) =>
              assertBusinessCountryFromStore(rootGetters, "DEU", "FRA", "GBR") &&
              !assertSoloProprietorshipFromStore(rootGetters),
            shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
              assertBusinessCountryFromApiResponse(rootState, flatApiResponse, "DEU", "FRA", "GBR") &&
            !assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse),
            validationSpec: organisationRegistrationNumber
        },
        organisationVatNumber: {
            apiPath: "business_info.tax_info.vat_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            label: {
                ["FRA"]: "businessStructure.vatNumber.fra.label",
                ["DEU"]: "businessStructure.vatNumber.deu.label",
                ["GBR"]: "businessStructure.vatNumber.gbr.label",
            },
            instruction: {
                ["FRA"]: "businessStructure.vatNumber.fra.instruction",
                ["DEU"]: "businessStructure.vatNumber.deu.instruction",
                ["GBR"]: "businessStructure.vatNumber.gbr.instruction",
            },
            shouldShowInView: (rootGetters) =>
              assertBusinessCountryFromStore(rootGetters, "DEU", "FRA", "GBR") &&
              !assertSoloProprietorshipFromStore(rootGetters),
            shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
              assertBusinessCountryFromApiResponse(rootState, flatApiResponse, "DEU", "FRA", "GBR") &&
              !assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse),
            validationSpec: organisationVatNumber
        },
        soleProprietorTaxId: {
            apiPath: "business_info.tax_info.tax_id",
            value: null,
            formattedValue: undefined,
            valid: false,
            label: {
                ["USA"]: "businessStructure.taxId.usa.label",
            },
            instruction: {
                ["USA"]: "businessStructure.taxId.usa.instruction",
            },
            shouldShowInView: (rootGetters) =>
              assertBusinessCountryFromStore(rootGetters, "USA") &&
              assertSoloProprietorshipFromStore(rootGetters),
            shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
              assertBusinessCountryFromApiResponse(rootState, flatApiResponse, "USA") &&
              assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse),
            validationSpec: soleProprietorTaxId,
        },
        soleProprietorRegistrationNumber: {
            apiPath: "business_info.tax_info.registration_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            label: {
                ["FRA"]: "businessStructure.registrationNumber.fra.label",
                ["DEU"]: "businessStructure.registrationNumber.deu.label",
                ["GBR"]: "businessStructure.registrationNumber.gbr.label",
            },
            instruction: {
                ["FRA"]: "businessStructure.registrationNumber.fra.instruction",
                ["DEU"]: "businessStructure.registrationNumber.deu.instruction",
                ["GBR"]: "businessStructure.registrationNumber.gbr.instruction",
            },
            shouldShowInView: (rootGetters) =>
                assertBusinessCountryFromStore(rootGetters, "DEU", "FRA", "GBR") &&
                assertSoloProprietorshipFromStore(rootGetters),
            shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
                assertBusinessCountryFromApiResponse(rootState, flatApiResponse, "DEU", "FRA", "GBR") &&
                assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse),
            validationSpec: soleProprietorRegistrationNumber,
        },
        soleProprietorVatNumber: {
            apiPath: "business_info.tax_info.vat_number",
            value: null,
            formattedValue: undefined,
            valid: false,
            label: {
                ["GBR"]: "businessStructure.vatNumber.gbr.label",
            },
            instruction: {
                ["GBR"]: "businessStructure.vatNumber.gbr.instruction",
            },
            shouldShowInView: (rootGetters) =>
              assertBusinessCountryFromStore(rootGetters, "GBR") &&
              assertSoloProprietorshipFromStore(rootGetters),
            shouldAllowCopyFromApiResponse: (rootState, flatApiResponse) =>
              assertBusinessCountryFromApiResponse(rootState, flatApiResponse, "GBR") &&
              assertSoloProprietorshipFromApiResponse(rootState, flatApiResponse),
            validationSpec: soleProprietorVatNumber,
        },
    },
    getters: {
        shouldShowApiField: (state: OnboardingSectionData, getters, rootState, rootGetters) => (apiFieldName) => {
            return (state.data[apiFieldName] as CountryBasedApiField)
                .shouldShowInView(rootGetters)
        },
        shouldAllowCopyFromApiResponseToApiField: (state, getters, rootState) => (flatApiResponse, apiFieldName) => {
            return (state.data[apiFieldName] as CountryBasedApiField)
                .shouldAllowCopyFromApiResponse(rootState, flatApiResponse)
        },
    },
    actions: {
    },
    mutations: {
    },
}