<template>
  <div class="transaction-audit-info" v-if="transaction">
    <div class="title">
      Audit Logs
    </div>

    <div class="content">
      <div v-for="refund in refunds" :key="refund.id">
        <div class="event">
          {{ `Refund of  ${format('currency', refund.amount, null, refund.currency)} created` }}
        </div>
        <div class="event-time">
          {{ formatDateWithTimezone(refund.created_at.toString()) }}
          {{ $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()}) }}
        </div>
      </div>
      <div v-if="dispute">
        <div class="event">
          {{ `Payment disputed (Reason: ${dispute.reason})` }}
        </div>
        <div class="event-time">
          {{ formatDateWithTimezone(dispute.occurred_at.toString()) }}
          {{ $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()}) }}
        </div>
      </div>


      <div class="event">
        {{ getEvent() }}
      </div>
      <div class="event-time">
        {{ formatDateWithTimezone(transaction.created_at.toString()) }}
        {{ $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()}) }}
      </div>
    </div>
  </div>
</template>

<script>
import {TransactionResponseDto, DisputeResponseDto, TransactionState} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import {getUtcTimezoneDifference} from "@/app/utils/common/functions/dateUtils";

export default {
  name: "TransactionAuditLogs",
  props: {
    refunds: {
      type: Array,
      required: false,
      default: () => []
    },
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
    dispute: {
      type: DisputeResponseDto,
      required: false,
      default: null
    },
    sourceTransaction: {
      type: TransactionResponseDto,
      required: true,
    },
  },
  methods: {
    getUtcTimezoneDifference,
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode}) + " " + currencyCode
    },
    getEvent() {
      switch (this.transaction.state) {
        case TransactionState.INITIATIONSTARTED:
        case TransactionState.QUEUEDACCEPTED:
        case TransactionState.QUEUEDFAILED:
        case TransactionState.QUEUEDSUCCEEDED:
        case TransactionState.INITIATIONATPAYFACFAILED:
        case TransactionState.PENDING:
          return 'Payment initiated';
        case TransactionState.SUCCESS:
        case TransactionState.REFUNDED:
          return 'Payment succeeded';
        case TransactionState.RETURNED:
          return 'Payment returned';
        case TransactionState.FAILED:
        case TransactionState.NOTREFUNDED:
          return 'Payment failed';
        case TransactionState.CANCELED:
          return 'Payment canceled';
      }
    }
  },
}
</script>

<style lang="scss">
.transaction-audit-info {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .content {
    margin-top: 12px;
    text-align: left;

    & .event-time {
      color: $neutral_400;
      margin-top: 4px;
      margin-bottom: 16px;
      font-size: 12px;
    }
  }
}
</style>