<template>
  <div class="field">
    <c-label :label="label" />
    <span v-if="showMandatory" class="mandatory">*</span>
    <c-date-time-picker
      class=""
      label=""
      :id="id"
      :use-time="false"
      zone="Local"
      :use-milliseconds="useMilliseconds"
      :is-range="false"
      :max-date="maxDate"
      :value="value"
      :error="error"
      use-mask="MM/dd/yyyy"
      @keyup.enter="onEnter"
      @update="setValue"
    />
    <c-inline-error
      v-if="Boolean(error)"
      :message="error"
    />
    <div class="hint" v-else-if="Boolean(hint)">
      {{ hint }}
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/events/event-bus";
import {FormEvents} from "@/app/utils/common/constants";

export default {
  name: "DateInput",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    setValue: {
      type: Function,
      required: true
    },
    hint: {
      type: String,
      required: false,
      default: undefined
    },
    error: {
      type: String,
      required: false,
      default: undefined
    },
    maxDate: {
      type: Date,
      default: new Date()
    },
    showMandatory: {
      type: Boolean,
      required: false,
      default: false
    },
    useMilliseconds: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    onEnter() {
      EventBus.$emit(FormEvents.ENTER);
    }
  },
}
</script>
<style lang="scss">

.mandatory {
  color: #DE3618;
}
</style>
