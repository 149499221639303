<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.83335 13.6667L0.666687 9.50004L4.83335 5.33337L6.00002 6.52087L3.85419 8.66671H9.83335V10.3334H3.85419L6.00002 12.4792L4.83335 13.6667ZM13.1667 8.66671L12 7.47921L14.1459 5.33337H8.16669V3.66671H14.1459L12 1.52087L13.1667 0.333374L17.3334 4.50004L13.1667 8.66671Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'TransactionsIcon',
}
</script>