/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/


import {copyFromApiResponse, copyFromErrorResponse} from "@/store/storeMappers";
import {OnboardingSectionData} from "@/store/modules/onboarding/onboardingTypes";
export const agreementAcceptanceDefaultState: OnboardingSectionData = {
    api: "createMerchant",
    errors: {},
    data: {
        merchantAgreement: {
            apiPath: "processing_info.merchant_agreement_accepted",
            value: null,
        },
        merchantAgreementTimestamp: {
            apiPath: "processing_info.merchant_agreement_timestamp",
            value: null,
        },
        merchantAgreementIpAddress: {
            apiPath: "processing_info.merchant_agreement_ip_address",
            value: null,
        },
        merchantAgreementUserAgent: {
            apiPath: "processing_info.merchant_agreement_user_agent",
            value: null,
        },
    }
};

const getters = {
    getSubMerchantAgreementAcceptance: state => state.data.merchantAgreement.value
};

const actions = {
    async acceptAgreement({commit, rootState}, accepted) {
        const timestamp = new Date().toISOString();
        const userAgent = navigator.userAgent.split(" ")[0]
        const ipAddress = rootState.app.clientIpAddress
        commit('acceptAgreement', {accepted, timestamp, ipAddress, userAgent});
    },
    copyFromFieldErrorsResponse({commit}, subErrors) {
        commit('copyFromFieldErrorsResponse', subErrors)
    },
    copyFromMerchantApiResponse({commit}, merchantApiResponse) {
        commit('copyFromMerchantApiResponse', merchantApiResponse)
    }
};

const mutations = {
    acceptAgreement(state, {accepted, timestamp, ipAddress, userAgent}): void {
        if (accepted) {
            state.data.merchantAgreement.value = accepted
            state.data.merchantAgreementTimestamp.value = timestamp
            state.data.merchantAgreementIpAddress.value = ipAddress
            state.data.merchantAgreementUserAgent.value = userAgent
        } else {
            state.data.merchantAgreement.value = accepted
            state.data.merchantAgreementTimestamp.value = null
            state.data.merchantAgreementIpAddress.value = null
            state.data.merchantAgreementUserAgent.value = null
        }
    },
    copyFromFieldErrorsResponse: function (state, subErrors): void {
        state.errors = copyFromErrorResponse(subErrors, state.data)
    },
    copyFromMerchantApiResponse(state, merchantApiResponse): void {
        state.data = copyFromApiResponse(merchantApiResponse, state.data);
    }
};

export const agreementAcceptance = {
    namespaced: true,
    state:agreementAcceptanceDefaultState,
    getters,
    actions,
    mutations
};