<template>
  <div class="edit-owner-container">
    <div class="home">
      <div class="top-fixed">
        <AppHeader
          :secondary-action-label="$t('global.action.dismiss')"
          :secondary-action="navigateToStatusScreen"
          :primary-action-label="primaryActionLabel"
          :primary-action="primaryAction"
          :disable-primary-action="loading"
        />
      </div>
      <div class="bo-container">
        <AddBeneficiary
          :i="activeBeneficiaryIndex"
          :is-control-person="isControlPerson"
          :close="() => {openModel = false}"
        >
          <div class="title-container">
            <div class="title">
              {{ $t('edit.businessOwner.title') }}
            </div>
            <div class="subtitle">
              {{ $t('edit.businessOwner.subtitle') }}
            </div>
          </div>
        </AddBeneficiary>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import AddBeneficiary from "@/components/onboarding/AddBeneficiary";
import AppHeader from "@/components/onboarding/AppHeader";
import FullStoryHelper from '@/app/utils/fullstory';
import { EDIT_OWNER_INFO_PAGE } from '@/app/utils/fullstory/constants';
import {editOnboardingMixin} from "@/mixin/editOnboardingMixin";

export default {
  name: 'EditOwnerInfoView',
  components: {AppHeader , AddBeneficiary},
  mixins: [editOnboardingMixin],
  data: () => {
    return {
      failureCode: "",
      activeBeneficiaryIndex: 0,
      isControlPerson: true,
      openModel: true,
      loading: false
    }
  },
  computed: {
    ...editOnboardingMixin,
    ...mapGetters('stakeholdersDetails', [
      'getControlPerson',
      'getBeneficiaries',
      'canAddMoreBeneficiaries',
      'getOwnershipErrors',
      'getErrors',
      'getAdditionalBeneficiaries'
    ]),
    ...mapState({
      ownershipError: state => state.stakeholdersDetails.errors.ownership,
    }),
  },
  mounted() {
    this.failureCode = this.$route.query.errorCode;
    if (this.getSessionPayfac === 'ADYEN' &&  this.getAdditionalBeneficiaries.length !== 0 &&
        this.getAdditionalBeneficiaries.map(x => x['idAtPayfac'].value)
        .indexOf(this.failureCode.split('-')[0]) !== -1) {
      this.activeBeneficiaryIndex = this.getAdditionalBeneficiaries.map(x => x['idAtPayfac'].value)
          .indexOf(this.failureCode.split('-')[0]) + 1;
      this.isControlPerson = false;
    }
    FullStoryHelper.event(EDIT_OWNER_INFO_PAGE);
  },
  methods: {
    ...editOnboardingMixin,
    ...mapActions('stakeholdersDetails', [
      'validate',
      'createStackholder',
      'deleteStackholder',
      'setIdAtPayfac',
    ]),
    primaryAction() {
     this.validateControlPerson(() => this.successCallBackFunction());
    },
    validateControlPerson(successCallback) {
      this.validate(0).then(errors => {
        if (!errors) {
          this.$danger(this.$t('errors.validationErrorToastSave'))
        } else {
          successCallback()
        }
      })
    },
  }
}
</script>

<style lang="scss">

.edit-owner-container {

  .top-fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;
    display: inline-block;
  }

  & .bo-container {
    text-align: left;
    width: 100%;
    max-width: 720px;
    align-items: start;
    flex-direction: column;
    padding: 8px;
    display: inline-block;

    & .header {
      position: relative;
      font-weight: $weight_bold;
      font-size: 24px;
      text-align: left;
      margin-bottom: 8px;
      margin-top: 24px;
      padding-top: 0;
      z-index: 1;
    }
    & .subtitle {
      font-size: 12px;
      font-weight: $weight_medium;
    }

  }
}

</style>

<style src="v-calendar/lib/v-calendar.min.css"/>