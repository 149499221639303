import { navigateTo } from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import Merchants from "@/api/merchants";
import {updateOnboardingMerchant} from "@/service/merchantService";
import {mapActions, mapGetters} from "vuex";

export const editOnboardingMixin = {
    computed: {
        ...mapGetters('onboarding', ['getMerchantUploadedDocuments']),
        ...mapGetters('app', ['getPayfacErrorCodeMapper', 'getMerchantId', 'getActivePayfac', 'getSessionPayfac']),
        ...mapGetters('merchantPayfacDetails', [ 'isEligibleForMigration', 'getErrorCodesToBeFixed', 'getErrorCodes']),
        isMigrating() {
            return Boolean(this.$route.query.migration === true) && this.isEligibleForMigration;
        },
        primaryActionLabel() {
            return this.getErrorCodesToBeFixed.length === 1 ? this.$t('global.action.submit')
                : this.$t('global.action.save');
        },
    },
    methods: {
        ...mapActions('app', ['updateAppState']),
        ...mapActions('onboarding', ['clearMerchantUploadedDocuments']),
        ...mapActions('merchantPayfacDetails', ['setErrorCodes', 'setErrorCodeFixed']),
        async updateMerchant() {
            this.loading = true;
            if (this.getSessionPayfac === 'ADYEN' && this.getMerchantUploadedDocuments.length !== 0) {
                try {
                    await Merchants.asyncFileUpload(this.getMerchantId, this.getMerchantUploadedDocuments);
                    await this.clearMerchantUploadedDocuments([]);
                } catch (e) {
                    this.loading = false;
                    await this.clearMerchantUploadedDocuments(this.getMerchantUploadedDocuments);
                    this.$danger(this.$t('errors.submitOnboardingFailed') + " - " + e.source?.detail);
                    this.setErrorCodes(this.getErrorCodes)
                    this.navigateToStatusScreen();
                    return;
                }
            }
            updateOnboardingMerchant().then(response => {
                this.loading = false;
                this.updateAppState({key: 'merchantId', value: response.id})
                this.setFixedErrorCode()
                this.navigateToStatusScreen();
                this.$success(this.$t('applicationSuccess.title'));
            }).catch(err => {
                this.loading = false;
                let errorMsg = err.source?.detail;
                if (errorMsg) {
                    const errorIndex = errorMsg.indexOf("Error");
                    if (errorIndex !== -1) {
                        errorMsg = errorMsg.substring(errorIndex);
                    }
                }
                this.$danger(this.$t('errors.submitOnboardingFailed') + " - " + errorMsg,
                  {dismissable: true, timeout: 20000});
                this.setErrorCodes(this.getErrorCodes);
                this.navigateToStatusScreen();
            })
        },
        navigateToStatusScreen() {
            const query = this.$route.query;
            delete query['errorCode'];
            navigateTo(RouteConstants.ONBOARDING_STATUS, true, query);
        },
        setFixedErrorCode() {
            this.setErrorCodeFixed(this.getPayfacErrorCodeMapper.getErrorCodeFixed(this.failureCode));
        },
        saveAndNavigateToStatusScreen() {
            this.setFixedErrorCode();
            this.navigateToStatusScreen()
        },
        successCallBackFunction() {
            this.getErrorCodesToBeFixed.length === 1 ? this.updateMerchant() : this.saveAndNavigateToStatusScreen();
        },
    }
};