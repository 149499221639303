<template>
  <div>
    <div class="table-sub-header">
      {{ $t('profile.description' ) }}
    </div>

    <div class="header-container">
      <div class="compliance-forms-header-count">
        <div v-if="count!==0" class="showing-count-top" :class="{'hide-loader': loading || complianceForms.length===0}">
          {{ $t('profile.showing') }} <b class="pagination"> {{ complianceFormsCount }} </b>
        </div>
        <div v-else class="showing-count-top" :class="{'hide-loader': loading}">
          {{ $t('profile.noComplianceFound') }}
        </div>
        <div class="loader" :class="{'hide-loader': !loading}"></div>
      </div>
    </div>
    <ComplianceFormTable
      :complianceforms="complianceForms"
      :all-items-loaded="allItemsLoaded"
      :loading="loading"
      :infinite-scroll="infiniteScroll"
      :columns="getColumns"
    />
  </div>
</template>

<script>

import {mapActions,mapGetters} from "vuex";
import { portalCheckMixin } from "@/mixin/portalPageCheck";
import ComplianceFormTable from "@/components/complianceforms/ComplianceFormTable.vue";
import complianceForms from "@/api/complianceForms";

export default {
  name: 'ComplianceFormList',
  components: {ComplianceFormTable},
  mixins: [portalCheckMixin],
  beforeRouteEnter(to, from, next) {
    if (to.name === "profile" && from.name === "compliance_form_details"){
      next(vm => {
      vm.clearData();
      vm.initialize();
      });
      return;
    }
    next();
  },
  data: () => {
    return {
      complianceForms: [],
      loading: false,
      allItemsLoaded: false,
      infiniteScroll: false,
      count: 0
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    complianceFormsCount() {
      return this.complianceForms.length + " / " + this.count;
    },
    getColumns() {
      let columns = {};
      columns['id'] = 'profile.columns.id';
      columns['created_on'] = 'profile.columns.created_on';
      columns['form_type'] = 'profile.columns.form_type';
      columns['due_on'] = 'profile.columns.due_on';
      columns['valid_until'] = 'profile.columns.valid_until';
      columns['status'] = 'profile.columns.status';
      return columns;
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    async initialize() {
      this.loading = true;

      let response;
      try{
        response = await complianceForms.listComplianceForms(
          this.getMerchantId);
      }catch (e) {
        this.$danger(this.$t('profile.getComplianceFormFailed'));
        this.loading = false;
        return;
      }
      this.complianceForms = response.compliance_forms.filter((form) => {
        return !(form.compliance_form_status === "INCOMPLETE" && form.unsigned_file_id === null);
      });
      this.count = this.complianceForms.length;
      this.loading = false;
      this.allItemsLoaded = true;
    },
    clearData: function() {
      this.complianceForms = [];
    }
  }
}
</script>

<style lang="scss">

.table-sub-header {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $neutral_600;
  text-align: left;
  padding: 0 0 24px 0;
}

.header-container {
  background-color: $color_white;
  width: 100%;
  height: fit-content;
  border: 1px solid $neutral_100;
  border-radius: 6px 6px 0px 0px ;
  display: flex;
  flex-direction: row;
  justify-content: right;
  padding: 15px 25px 5px 25px;

  & .compliance-forms-header-count{
    font-style: normal;
    font-weight: $weight_normal;
    font-size: 14px;
    line-height: 20px;
    color: $neutral_800;
    width: 100%;
    text-align: left;
    align-self: center;
    padding-bottom: 10px;
    display: flex;

    & .showing-count-top {
      display: inline-block;
      justify-content: left;

      & .pagination {
        font-weight: $weight_bold;
      }
    }

    & .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 3px solid #D4D4E8;
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      justify-content: center;
    }


    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    @-webkit-keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }

    .hide-loader {
      display: none;
      visibility: hidden;
    }

  }
}

.loader-container {
  background-color: $color_white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #D4D4E8;
  border-left: 1px solid #D4D4E8;
  border-right: 1px solid #D4D4E8;
  border-radius: 0px 0px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .showing_count {
    display: inline-block;
    justify-content: left;
  }

  & .loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid #D4D4E8;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    justify-content: center;
  }


  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .hide-loader {
    display: none;
    visibility: hidden;
  }
}
</style>