<template>
  <div class="disputes-evidences-info">
    <div class="title">
      Responded Details
    </div>
    <div class="evidences-content">
      <table aria-describedby="Show responded details for the dispute">
        <tr class="table-header">
          <th>ID</th>
          <th> File Name</th>
          <th>File Type</th>
          <th>Submitted On</th>
        </tr>
        <tbody>
          <tr v-for="file in evidenceInfo.file_evidences.evidences" :key="file.file_id">
            <td>{{ file.id }}</td>
            <td>{{ file.file_name }}</td>
            <td>{{ file.file_name.split('.').pop() }}</td>
            <td>{{ formatDateWithTimezone(file.created_at.toString()) }}</td>
          </tr>
        </tbody>
      </table>

      <DisputeViewPhysicalGoods
        :evidence="evidenceInfo"
        v-if="evidenceInfo.type_of_purchase === 'PHYSICAL_GOODS' && showMore"
      />
      <DisputeViewDigitalGoods
        :evidence="evidenceInfo"
        v-if="evidenceInfo.type_of_purchase === 'DIGITAL_GOODS' && showMore"
      />
      <DisputeViewServices
        :evidence="evidenceInfo"
        v-if="evidenceInfo.type_of_purchase === 'SERVICE' && showMore"
      />
      <div class="view-more-button" @click="() => {showMore = !showMore}">
        {{ showMore ? "View less" : "View more" }}
      </div>
    </div>
  </div>
</template>gs


<script>
import Formatter from "@/app/utils/common/functions/formatter";
import DisputeViewPhysicalGoods from "@/components/disputes/DisputeViewPhysicalGoods";
import DisputeViewDigitalGoods from "@/components/disputes/DisputeViewDigitalGoods";
import DisputeViewServices from "@/components/disputes/DisputeViewServices";

export default {
  name: "DisputeEvidenceInfo",
  components: {DisputeViewPhysicalGoods, DisputeViewDigitalGoods, DisputeViewServices},
  props: {
    evidenceInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showMore: false,
    }
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
  },
}
</script>

<style lang="scss">
.disputes-evidences-info {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .view-more-button {
    margin-top: 20px;
    color: $primary_600;
    cursor: pointer;
    text-align: left;
  }

  & table {
    margin-top: 12px;
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    & .table-header {
      height: 40px;
    }

    & tr {
      height: 60px;
      border-bottom: 1pt solid #E5E7EB;
    }
    & th {
      font-size: 12px;
      font-weight: $weight-bold;
      color: #6B7280;
      min-width: 100px;
    }

    & td {
      font-size: 14px;
      padding-right: 20px;
    }
  }


}
</style>