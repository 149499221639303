<template>
  <div class="c-popover">
    <span ref="trigger" class="c-popover__trigger" v-on="listeners">
      <slot name="trigger"></slot>
    </span>
    <transition name="popup-in">
      <div
        v-if="show"
        ref="container"
        class="c-popover__container"
        @keydown.enter.stop.prevent="close('primary')"
      >
        <div class="c-popover__wrap">
          <div class="c-popover__content">
            <slot></slot>
          </div>
          <slot name="title"></slot>
          <div
            v-if="secondaryAction || primaryAction"
            class="c-popover__footer"
          >
            <c-button
              v-if="secondaryAction"
              class="c-mr--xs"
              @click="close('cancel')"
            >
              <div>
                <div class="action-name">
                  {{ secondaryAction }}
                </div>
                <div class="action-name-help">
                  {{ secondaryActionHelpText }}
                </div>
              </div>
            </c-button>
            <c-button
              v-if="primaryAction"
              variant="primary"
              :disabled="disablePrimaryAction"
              @click="close('primary')"
            >
              <div>
                <div class="action-name">
                  {{ primaryAction }}
                </div>
                <div class="action-name-help">
                  {{ primaryActionHelpText }}
                </div>
              </div>
            </c-button>
          </div>
        </div>
        <div v-if="arrow" data-popper-arrow></div>
      </div>
    </transition>
  </div>
</template>

<script>
import {CPopover} from "@chargebee/cookie-ui"

export default {
  name: "PopOver",
  extends: CPopover,
  props: {
    primaryActionHelpText: {
      default: null,
      type: String
    },
    secondaryActionHelpText: {
      default: null,
      type: String
    },
  }
}
</script>
<style lang="scss">

.c-button {
  margin-bottom: 10px;
  & .c-button__label {
    padding: 20px;
  }
}
.action-name {
  font-weight: $weight-bold;
  font-size: 14px;
  margin-bottom: 2px;
}

.action-name-help {
  font-weight: $weight-medium;
  font-size: 12px;
  color: $primary_text_dark;
}
</style>