<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_12066_99269" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
      width="20" height="20"
    >
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_12066_99269)">
      <path d="M4.16675 14.1654V8.33203H5.83342V14.1654H4.16675ZM9.16675 14.1654V8.33203H10.8334V14.1654H9.16675ZM1.66675 6.66536V4.9987L10.0001 0.832031L18.3334 4.9987V6.66536H1.66675ZM5.39591 4.9987H14.6042L10.0001 2.70703L5.39591 4.9987ZM1.66675 17.4987V15.832H11.7084C11.7362 16.1237 11.7709 16.4049 11.8126 16.6758C11.8542 16.9466 11.9167 17.2209 12.0001 17.4987H1.66675ZM14.1667 11.0404V8.33203H15.8334V10.207L14.1667 11.0404ZM16.6667 19.9987C15.7084 19.7626 14.9133 19.2105 14.2813 18.3424C13.6494 17.4744 13.3334 16.5126 13.3334 15.457V13.332L16.6667 11.6654L20.0001 13.332V15.457C20.0001 16.5126 19.6841 17.4744 19.0522 18.3424C18.4202 19.2105 17.6251 19.7626 16.6667 19.9987ZM16.0626 17.4987L18.9584 14.6237L18.0834 13.7487L16.0626 15.7279L15.2501 14.9154L14.3751 15.8112L16.0626 17.4987Z" fill="currentColor" />
    </g>
  </svg>
</template>

<script>

export default {
  name: 'ComplianceFormIcon'
}
</script>