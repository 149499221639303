<template>
  <div class="authorization-detail-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="authorization-detail-content"
      :class="{['authorization-detail-content-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="authorization-detail-sub-content">
        <div class="options-bar">
          <div class="back-to-authorizations" @click="navigateTo('authorizations')" :id="getElementId('go_back')">
            <img src="@/app/assets/images/back.svg" alt="Back to authorizations icon" />
            <span>{{ $t('authorizations.actions.backToAuthorizations') }}</span>
          </div>
        </div>
        <div v-if="authorization && authorization.error_reason">
          <div
            class="error"
            :class="{
              'error-canceled': ['CANCELED', 'UNAVAILABLE'].includes(authorization.status)
            }"
          >
            <img v-if="['CANCELED', 'UNAVAILABLE'].includes(authorization.status)" src="@/app/assets/images/amber-exclamation.svg" alt="Warning" />
            <img v-else src="@/app/assets/images/red-exclamation.svg" alt="Error" />
            {{ errorReason }}
          </div>
        </div>
        <div class="loading-message" v-if="loading">
          Fetching authorization...
        </div>
        <div class="main-layout-container" v-else>
          <div class="cl-dock__main">
            <div class="layout-container">
              <div class="cards">
                <div class="card-container first-card">
                  <div class="card">
                    <AuthorizationInfo :authorization="authorization" />
                  </div>
                </div>
                <div class="card-container" id="overview">
                  <div class="card">
                    <AuthorizationOverview :authorization="authorization" />
                  </div>
                </div>
                <div class="card-container" id="payment">
                  <div class="card">
                    <PaymentInstrument :payment-instrument="authorization.payment_instrument" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cl-dock__sidebar">
            <TableOfContents :sections="sections" />
          </div>
        </div>
        <PortalFooter />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from "@/components/SidebarNav";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {navigateTo} from "@/router";
import {mapGetters} from "vuex";
import SearchBar from "@/components/SearchBar";
import PortalFooter from "@/components/portal/PortalFooter.vue";
import {PendoPrefixes} from "@/app/utils/common/constants";
import TableOfContents from "@/components/common/TableOfContents.vue";
import locales from "@/i18n/locales";
import AuthorizationOverview from "@/components/authorizations/AuthorizationOverview.vue";
import AuthorizationInfo from "@/components/authorizations/AuthorizationInfo.vue";
import authorizations from "@/api/Authorizations";
import PaymentInstrument from "@/components/PaymentInstrument.vue";

export default {
  name: "AuthorizationDetailsView",
  components: {
    PaymentInstrument,
    AuthorizationInfo,
    AuthorizationOverview,
    TableOfContents,
    SidebarNav,
    SearchBar,
    PortalFooter
  },
  mixins: [portalCheckMixin],
  data: function () {
    return {
      sidebarNavCollapsed: false,
      authorization: null,
      sections: []
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    loading() {
      return this.authorization === null;
    },
    errorReason() {
      return (this.authorization.error_reason || "").replaceAll("ReasonDescription: ", "");
    }
  },
  mounted: async function () {
    if (this.$route.params.data === undefined || this.$route.params.data === null) {
      this.authorization = await authorizations.fetchAuthorization(this.getMerchantId,
          this.$route.query.consumerId, this.$route.params.authorizationId)
    } else {
      this.authorization = this.$route.params.data
    }

    this.sections = [
      { id: 'overview', title: locales.en.tableOfContent.overview, show: true},
      { id: 'payment', title: locales.en.tableOfContent.authorization.payment_instrument, show: true},
    ]
  },
  methods: {
    navigateTo: function (route) {
      const query = { ...this.$route.query };
      delete query.consumerId;

      navigateTo(route, false, query);
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">
.authorization-detail-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;

  & .loading-message {
    color: $primary_text_dark;
    margin-top: 52px;
  }

  & .error {
    background-color: $danger_50;
    color: $neutral_800;
    padding: 6px 8px;
    width: calc(75% - 16px);
    display: flex;
    gap: 10px;
    align-items: center;
    border: 0.5px solid $danger_100;
    border-radius: 6px;
    margin-bottom: 24px;
    text-align: left;
  }

  & .error-canceled {
    background-color: $warn_50;
    border-color: $warn_100;
  }

  & .main-layout-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    & .cl-dock__main {
      width: calc(75% - 8px);
      margin-right: 32px;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    & .cl-dock__sidebar {
      top: 50px;
      position: sticky;
      border-radius: 8px;
      width: calc(25% - 12px);
      align-self: flex-start;

      @media screen and (max-width: 700px) {
        display: none;
        width: 0;
      }
    }

    & .layout-container {
      width: 100%;
      overflow: auto;

      & .cards {
        width: 100%;

        & .card-container {
          scroll-margin-top: 70px;
          background-color: #FFFFFF;
          padding: 24px;
          border-radius: 6px;
          margin-top: 24px;

          & .card {
            width: 100%;
          }
        }

        & .first-card {
          margin-top: 0;
        }
      }

    }
  }

  & .authorization-detail-content {
    left: 256px;
    top: 0;
    position: relative;
    width: calc(100% - 256px);
    padding: 24px 32px;
    transition: all 0.2s ease-in;
    justify-content: center;
    display: flex;

    & .authorization-detail-sub-content {
      max-width: 1360px;
      width: 100%;
    }

    & .options-bar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;

      & .back-to-authorizations {
        cursor: pointer;
        color: $neutral_600;
        font-weight: $weight_medium;

        & span {
          margin-left: 8px;
        }
      }
    }
  }

  & .authorization-detail-content-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }
}
</style>