/* eslint-disable  @typescript-eslint/no-non-null-assertion */

const unsupportedMccCodes = {
    FINIX: [     
        "1711",
        "2741",
        "2791",
        "2842",
        "4011",
        "4112",
        "4131",
        "4215", 
        "4411",
        "4468",
        "4511",
        "4582",
        "4722",
        "4723",
        "4784",
        "4814",
        "4816",
        "4821",
        "4829",
        "5013",
        "5021",
        "5044",
        "5046",
        "5051",
        "5065",
        "5072",
        "5094",
        "5111",
        "5122",
        "5131",
        "5139",
        "5169",
        "5172",
        "5198",
        "5200",
        "5211",
        "5231",
        "5251",
        "5261",
        "5262",
        "5300",
        "5309",
        "5310",
        "5311",
        "5331",
        "5399",
        "5411",
        "5422",
        "5441",
        "5451",
        "5462",
        "5511",
        "5521",
        "5532",
        "5541",
        "5542",
        "5551",
        "5552",
        "5571",
        "5592",
        "5598",
        "5599",
        "5681",
        "5697",
        "5698",
        "5699",
        "5712",
        "5713",
        "5714",
        "5718",
        "5719",
        "5722",
        "5732",
        "5733",
        "5735",
        "5815",
        "5816",
        "5817",
        "5818",
        "5912",
        "5921",
        "5931",
        "5932",
        "5933",
        "5935",
        "5937",
        "5940",
        "5941",
        "5942",
        "5943",
        "5944",
        "5945",
        "5946",
        "5947",
        "5948",
        "5949",
        "5950",
        "5960",
        "5962",
        "5963",
        "5964",
        "5965",
        "5966",
        "5967",
        "5968",
        "5969",
        "5970",
        "5971",
        "5972",
        "5973",
        "5975",
        "5976",
        "5978",
        "5983",
        "5992",
        "5993",
        "5994",
        "5995",
        "5997",
        "5998",
        "6010",
        "6011",
        "6012",
        "6051",
        "6211",
        "6540",
        "7012",
        "7033",
        "7210",
        "7211",
        "7216",
        "7217",
        "7221",
        "7251",
        "7261",
        "7273",
        "7276",
        "7277",
        "7278",
        "7296",
        "7297",
        "7299",
        "7321",
        "7338",
        "7339",
        "7342",
        "7349",
        "7361",
        "7375",
        "7379",
        "7393",
        "7394",
        "7395",
        "7399",
        "7512",
        "7513",
        "7519",
        "7523",
        "7531",
        "7534",
        "7535",
        "7538",
        "7549",
        "7622",
        "7623",
        "7629",
        "7631",
        "7641",
        "7692",
        "7800",
        "7801",
        "7802",
        "7829",
        "7841",
        "7911",
        "7922",
        "7929",
        "7932",
        "7933",
        "7992",
        "7993",
        "7994",
        "7995",
        "7996",
        "7998",
        "8651",
        "8675",
        "8734",
        "8911",
        "9211",
        "9223",
        "9402",
        "9405",
        "9406"
    ],
    ADYEN: [
        "9223"
    ]
};

export const unsupportedMccCodesList = (payfac: string): Array<string> => {
    return unsupportedMccCodes[payfac];
};
