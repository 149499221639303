<template>
  <div class="statistics-container" v-if="!loading">
    <div class="data-container" :class="{'statistics-disabled': disabled}">
      <div :class="{'statistics-content-blurred': disabled}">
        <div class="title-container">
          <div class="title">
            <div class="statistics-title">
              {{ getTitle() }}
            </div>
            <ToolTip
              placement="top-start"
              v-if="$t(`dashboard.tooltip.${statisticsType}`) !== `dashboard.tooltip.${statisticsType}`"
              :content="$t(`dashboard.tooltip.${statisticsType}`)"
              :arrow="false"
            >
              <img alt="dropdown" class="statistics-info-icon" src="@/app/assets/images/info-outline.svg" />
            </ToolTip>
          </div>
          <div v-if="disabledChart" class="view" :class="{clickable: !!onClick}" @click="() => onClick && onClick()" data-html2canvas-ignore="true">
            {{ $t('dashboard.view') }}
          </div>
        </div>

        <div class="statistics-amount">
          {{ getTotal() }}
        </div>

        <div class="details-container">
          <StatisticsIcon class="statistics-icon" :change="getChange()" :status="getStatus()" v-if="!disabled" />
          <div
            class="percent-change"
            :class="{
              'percent-change-failed' : checkPercentageAndStatisticsType() === false,
              'percent-change-zero': checkPercentageAndStatisticsType() === null}"
          >
            {{ Math.min(Math.abs(percentage), 1000) }}
          </div>
          <div class="date-range-info" v-if="disableDateRange">
            vs {{ statisticsDateRange }}
          </div>
        </div>
        <div>
          <StatisticsChart
            :chart-data="chartData" v-if="!disabledChart" :interval-type="intervalType"
            :statistics-type="statisticsType"
          />
        </div>
      </div>
    </div>

    <div class="coming-soon-placeholder" v-if="disabled">
      <img
        v-if="statisticsType === 'PAYMENT_ACCEPTANCE_RATE'"
        class="placeholder-image"
        alt="payment_acceptance_rate" src="@/app/assets/images/payment_acceptance_rate.svg"
      />
      <img
        v-if="statisticsType === 'TOTAL_ACTIVE_CUSTOMERS'"
        class="placeholder-image"
        alt="total_active_customers" src="@/app/assets/images/total_active_customers.svg"
      />
      <div class="placeholder-title">
        {{ getTitle() }}
      </div>
      <div class="placeholder-description">
        Coming soon
      </div>
    </div>
  </div>
  <div class="statistics-container" v-else>
    <div class="data-container">
      <div class="box loading-shimmer"></div>
    </div>
  </div>
</template>

<script>
import Formatter from "@/app/utils/common/functions/formatter";
import StatisticsIcon from "@/components/icons/StatisticsIcon";
import StatisticsChart from "@/components/common/StatisticsChart.vue";
import {Metric, Interval} from "@/api/paymentApi";
import StatisticApi from "@/api/statistics"
import {mapGetters} from "vuex";
import {getDateTimeFromJSDate, getTimezoneName} from "@/app/utils/common/functions/dateUtils";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: "Statistics",
  components: {ToolTip, StatisticsIcon, StatisticsChart},
  props: {
    disableDateRange: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledChart: {
      type: Boolean,
      required: false,
      default: false,
    },
    statisticsType: {
      type: String,
      required: true,
    },
    intervalType: {
      type: String,
      required: false,
      default: Interval.DAY,
    },
    dateFilter: {
      type: Object,
      required: true,
    },
    onClick: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  data: () => {
    return {
      loading: false,
      total: 0,
      percentage: 0,
      chartData: [Object],
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'getMerchantCurrency']),
    statisticsDateRange() {
      let diff = this.dateFilter.endDate - this.dateFilter.startDate;
      let prefix = getDateTimeFromJSDate(this.dateFilter.startDate).toMillis() - diff;
      let suffix = getDateTimeFromJSDate(this.dateFilter.endDate).toMillis() - diff;
      prefix = this.formatter({type : "fromMillisDateFilter", data: prefix})
      suffix = this.formatter({type : "fromMillisDateFilter", data: suffix})
      if (prefix === suffix || diff === 86400000) {
        return prefix.toString()
      }
      return prefix + ' - ' + suffix
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatter: Formatter.format,
    async loadData() {
      if (this.disabled)
        return;
      this.loading = true;
      let response = await StatisticApi.fetchMerchantDashboardData(this.getMerchantId, this.statisticsType,
          !this.disabledChart, this.intervalType, getTimezoneName(), this.dateFilter.startDate, this.dateFilter.endDate);
      this.chartData = response.data;
      this.total = response.total;
      this.percentage = response.percentage;
      this.loading = false;
    },
    getTitle() {
      return this.statisticsType
          .replace(/_/g, ' ')
          .replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
    },
    getTotal() {
      if ([Metric.CHARGEBACKS, Metric.TRANSACTIONS, Metric.INQUIRIES, Metric.TOTALACTIVECUSTOMERS, Metric.NEWCUSTOMERS]
          .indexOf(this.statisticsType) > -1) {
        return this.total;
      } else if ([Metric.PAYMENTACCEPTANCERATE, Metric.CHARGEBACKRATE]
          .indexOf(this.statisticsType) > -1) {
        return parseFloat(this.total.toFixed(2)) + "%";
      }
      return Formatter.format({type : "currency", data:  this.total, currencyCode: this.getMerchantCurrency})
    },
    getChange() {
      if (this.percentage > 0) {
        return "INCREASE";
      } else if (this.percentage < 0) {
        return "DECREASE";
      } else {
        return "IDEAL";
      }
    },
    getStatus() {
      let check = this.checkPercentageAndStatisticsType();
      if (check === true) {
        return "SUCCESS";
      } else if (check === false) {
        return "FAILED";
      } else {
        return "IDEAL";
      }
    },
    checkPercentageAndStatisticsType() {
      let dashboardMetricPositive = [Metric.GROSSVOLUME, Metric.DEPOSITS, Metric.TRANSACTIONS,
        Metric.PAYMENTACCEPTANCERATE, Metric.TOTALACTIVECUSTOMERS, Metric.NEWCUSTOMERS];
      let dashboardMetricNegative = [Metric.REFUNDS, Metric.CHARGEBACKS, Metric.CHARGEBACKRATE, Metric.INQUIRIES]
      if (this.percentage > 0) {
        return dashboardMetricPositive.includes(this.statisticsType);
      } else if (this.percentage < 0) {
        return dashboardMetricNegative.includes(this.statisticsType);
      } else {
        return null;
      }
    }
  },
}
</script>

<style lang="scss">

.statistics-container {
  display: flex;
  flex: 50;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  & .data-container {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;

    & .title-container {
      display: flex;
      flex-direction: row;
      height: fit-content;
      align-items: center;

      & .title{
        flex:1;
        display: flex;
        align-items: center;

        & .statistics-title {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: $neutral_400;
          margin-right: 5px;
        }

        & .statistics-info-icon {
          height: fit-content;
        }
      }
    }

    & .view {
      font-style: normal;
      font-weight: $weight_medium;
      font-size: 12px;
      line-height: 18px;
      color: $primary_600;
      margin-right: 5px;
    }

    & .statistics-amount {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: $neutral_1000;
      margin-top: 12px;
    }

    & .details-container {
      display: flex;
      flex-direction: row;
      height: fit-content;
      align-items: center;
      margin: 8px 0 12px 0;

      & .statistics-icon {
        margin-right: 5px;
      }

      & .percent-change {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        color: $success_800;
      }

      & .percent-change-failed {
        color: $danger_1000;
      }

      & .percent-change-zero {
        color: $primary_text_dark;
      }

      & .percent-change:after {
        content: "%";
        margin-left: -4px;
      }

      & .date-range-info {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        color: $neutral_400;
        margin-left: 8px;
      }
    }

    & .details-container-empty {
      height: 26px;
    }

    & .loading-shimmer {
      animation: shimmer 2s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
      border-radius: 10px;
    }

    & .box {
      height: 100%;
      width: 100%;
      min-height: 70px;
      margin: 10px 0;
    }

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }

    @-webkit-keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  }

  & .coming-soon-placeholder {
    border-radius: 6px;
    padding: 20px;
    display: flex;
    height: 100%;
    position: absolute;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    & .placeholder-image {
      margin-bottom: 24px;
    }

    & .placeholder-title {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $neutral_1000;
    }

    & .placeholder-description {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: $neutral_400;
    }
  }

  & .no-data-placeholder {
    display: flex;
    height: 100%;
    text-align: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    color: $neutral_400;
  }

  & .statistics-disabled {
    filter: blur(2px);
  }

  & .statistics-content-blurred {
    opacity: 0.5;
  }
}

</style>