<template>
  <div class="field select-field" :class="{ [fieldClassname]: fieldClassname, disabled: disabled}">
    <c-label :label="label" />

    <c-select
      autofocus
      :searchable="searchable"
      :clearable="clearable"
      :options="options"
      :disabled="disabled"
      @update="onUpdate"
      :value="value"
      :class="'c-flex-grow-1 ' + selectClassname"
    />

    <c-inline-error
      v-if="Boolean(error)"
      :message="error"
    />
    <slot name="hint"></slot>
    <div class="hint" v-if="!Boolean(error) && hint !== null">
      {{ hint }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    onUpdate: {
      type: Function,
      required: true
    },
    disabled: Boolean,
    clearable: Boolean,
    searchable: Boolean,
    selectClassname: {
      type: String,
      required: true
    },
    fieldClassname: {
      type: String,
      required: false,
      default: ""
    },
    error: {
      type: String,
      required: false,
      default: undefined
    },
    hint: {
      type: String,
      required: false,
      default: undefined
    },
  }
}
</script>

<style lang="scss">
.select-field {
  margin-top: 24px;
  width: inherit;

  &.disabled {
    & .c-label {
      opacity: 60%;
    }

    & .hint {
      opacity: 60%;
    }

    & .c-select > * {
      opacity: 60%;
    }
  }

  & .c-select {
    margin-top: 4px;
    margin-bottom: 8px;
  }

  & .hint {
    font-size: 12px;
    color: $primary_text_dark;
  }

  & .c-label {
    white-space: nowrap;
  }
}
</style>