<template>
  <div class="notification-todo">
    <div class="content">
      <span class="icon">
        <FormIcon />
      </span>
      <span>
        <div class="id-title">
          <div class="title">{{ title }}</div>
          <c-tooltip placement="top" v-if="showUnreadMarker" :content="$t('notifications.markAsRead')" variant="dark">
            <div class="read-marker" @click="handleMarkAsRead">
              <svg class="dot" viewBox="0 0 10 10">
                <circle cx="5" cy="5" r="4" />
              </svg>
            </div>
          </c-tooltip>
        </div>

        <div class="description">
          {{ description }}
        </div>
        <div class="links" v-if="currentTab === 'TODO'">
          <div class="clickable" @click="() => { openDetails(); handleMarkAsRead(); }" :id="getElementId('todo-see_details')">
            {{ $t('notifications.seeDetails') }}
          </div>
        </div>
      </span>
    </div>
    <div class="relative-date-container">
      {{ relativeDate(audit.created_at) }}
    </div>
  </div>
</template>


<script>
import {navigateWithParam} from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import {AuditResponseDto} from "@/api/paymentApi";
import {notificationMixin} from "@/mixin/notificationMixin";
import FormIcon from "@/components/icons/FormIcon.vue";

export default {
  name: "ComplianceFormNotif",
  components: {FormIcon},
  mixins: [notificationMixin],
  props: {
    audit: {
      type: AuditResponseDto,
      default: null,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
  },
  methods: {
    ...notificationMixin.methods,
    openDetails() {
        navigateWithParam(RouteConstants.COMPLIANCE_FORM_DETAILS, { complianceFormId: this.audit.resource_id }, this.$route.query);
    },
  }
};
</script>

<style lang="scss">
@import 'notification-common-style.scss';
</style>