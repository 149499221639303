<template>
  <div class="authorization-info" v-if="authorization !== null">
    <div class="line">
      <div class="amount">
        <div class="value">
          {{ getAmount() }}
        </div>
        <div class="currency-code">
          {{ authorization.currency }}
        </div>
      </div>
      <AuthorizationStatusTag :authorization="authorization" />
    </div>

    <div class="divider"></div>

    <div class="row">
      <FieldValue
        :field-name="$t('fieldNames.authorizationId')"
        :field-value="authorization.id" :show-copy-value="true"
      />
      <div class="border"></div>
      <FieldValue
        :field-name="$t('fieldNames.customer')"
        :field-value="getCustomerName()"
      >
        <span v-if="getCustomerName()"> {{ getCustomerName() }}</span>
        <span v-else class="empty-placeholder">—</span>
      </FieldValue>
    </div>
  </div>
</template>
<script>

import FieldValue from "@/components/common/FieldValue.vue";
import {AuthorizationsResponseDto} from "@/api/paymentApi";
import {getName} from "@/app/utils/common/functions/functions";
import Formatter from "@/app/utils/common/functions/formatter";
import AuthorizationStatusTag from "@/components/authorizations/AuthorizationStatusTag.vue";

export default {
  name: "AuthorizationInfo",
  components: {AuthorizationStatusTag, FieldValue},
  props: {
    authorization: {
      type: AuthorizationsResponseDto,
      required: true,
    },
  },
  methods: {
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    getCustomerFullName() {
      let firstName = this.authorization.consumer.first_name;
      let lastName = this.authorization.consumer.last_name;
      return getName(firstName, lastName);
    },
    getCustomerName() {
      return this.getCustomerFullName() || this.authorization.payment_instrument.card?.card_holder_name || this.authorization.payment_instrument.bank_account?.name;
    },
    getAmount() {
      let amount = this.authorization.amount;
      return this.format('currency', amount, "", this.authorization.currency)
    },
  },
}
</script>
<style lang="scss">

.authorization-info {

  & .line {
    display: flex;
    align-items: center;

    & .amount {
      font-size: 24px;
      display: flex;
      align-items: center;
      margin-right: 12px;

      & .value {
        font-weight: $weight_bold;
        margin-right: 4px;
      }

      & .currency_code {
        font-weight: $weight_normal;
      }
    }
  }

  & .field {
    width: fit-content;
    text-align: left;

    & .field-label {
      font-size: 12px;
      margin-bottom: 4px;
    }

    & .field-value {
      & .icon {
        cursor: pointer;
      }
    }
  }

  & .divider {
    width: 100%;
    background: #ECECF8;
    height: 1px;
    margin-top: 16px;
  }

  & .row {
    display: flex;

    & .border {
      width: 1px;
      background: #CDD2DB;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 24px;
    }

    & .empty-placeholder {
      color: $neutral_300;
    }
  }

}
</style>