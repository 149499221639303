<template>
  <div class="dispute-detail" v-if="dispute !== null">
    <div class="line1">
      <div class="dispute-type">
        <div class="icon">
          <TransactionsIcon />
        </div>
        {{ $t('disputes.type.' + dispute.type) }}
      </div>
      <ToolTip
        placement="top"
        :content="getDisputeTypeDescription()"
      >
        <span>
          <img class="info-icon" src="@/app/assets/images/info-outline.svg" alt="info icon" />
        </span>
      </ToolTip>
    </div>

    <div class="line2">
      <div class="amount">
        <div class="value">
          {{ format('currency', dispute.amount, "", dispute.currency) }}
        </div>
        <div class="currency-code">
          {{ dispute.currency }}
        </div>
      </div>
      <DisputeStatusTag :dispute="dispute" />
    </div>

    <div class="divider"></div>

    <FieldValue
      :field-name="$t('fieldNames.disputeId')"
      :field-value="dispute.id"
      :show-copy-value="true"
    />
  </div>
</template>
<script>
import TransactionsIcon from "@/components/icons/TransactionsIcon";
import {DisputeResponseDto} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import DisputeStatusTag from "@/components/disputes/DisputeStatusTag";
import FieldValue from "@/components/common/FieldValue.vue";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: "DisputeDetail",
  components: {ToolTip, FieldValue, TransactionsIcon, DisputeStatusTag},
  props: {
    dispute: {
      type: DisputeResponseDto,
      required: true,
    },
  },
  methods: {
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    getDisputeTypeDescription() {
      return this.dispute.type === 'INQUIRY' ? this.$t('disputes.inquiry') : this.$t('disputes.chargeback')
    }
  },
}
</script>
<style lang="scss">

.dispute-detail {


  & .shimmer-container {
    & div {
      margin-bottom: 8px;
    }
  }

  & .loading-shimmer {
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    border-radius: 10px;
  }

  & .size-120-24 {
    width: 120px;
    height: 24px;
  }

  & .size-100-36 {
    width: 100px;
    height: 36px;
  }


  & .size-50-24 {
    width: 50px;
    height: 24px;
  }

  & .size-50-16 {
    width: 50px;
    height: 16px;
  }

  & .size-100-24 {
    width: 100px;
    height: 24px;
  }

  & .line2 {
    display: flex;
    align-items: center;
    margin-top: 9px;

    & .amount {
      color: $neutral_800;
      font-size: 24px;
      display: flex;
      align-items: center;
      margin-right: 12px;

      & .value {
        font-weight: $weight_bold;
        margin-right: 4px;
      }

      & .currency_code {
        font-weight: $weight_normal;
      }
    }
  }

  & .field {
    width: fit-content;
    text-align: left;

    & .field-label {
      font-size: 12px;
      color: #64648C;
      margin-bottom: 4px;
    }

    & .field-value {
      & .icon {
        cursor: pointer;
      }
    }
  }

  & .divider {
    width: 100%;
    background: #ECECF8;
    height: 1px;
    margin-top: 16px;
  }

  & .line1 {
    display: flex;

    & .info-icon {
      display: inherit;
      margin-top: 5px;
      margin-left: 8px;
    }

    & .dispute-type {
      color: $neutral_800;
      background-color: #F4F7FC;
      width: fit-content;
      border: 1px solid #E2E9F9;
      border-radius: 4px;
      padding: 2px;
      font-size: 12px;
      font-weight: $weight_bold;
      display: flex;
      align-items: center;

      & .icon {
        display: flex;
        align-items: center;
        margin-right: 5px;

        & svg {
          color: #64648C;
        }
      }
    }
  }


}
</style>


