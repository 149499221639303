<template>
  <AppPreLoader :show-pre-loader="true" :loading-message="$t('landing.loggingOut')" />
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {cbAppUrl, openUrlInSameTab} from "@/app/utils/common/functions/url-helper";
import axios from "axios";
import * as Sentry from "@sentry/vue";
import AppPreLoader from "@/components/common/AppPreLoader.vue";

export default {
  name: 'Logout',
  components: {AppPreLoader},
  computed: {
    ...mapGetters('app', ['isAdminMode', 'getCallbackUrl']),
    ...mapGetters('app', ['getApplicationName', 'getMerchantId', 'getMerchantName']),
  },
  mounted() {
    window.cbStorage.clear();
    if (this.isAdminMode) {
      axios.post("/api/admin/logout")
          .then(() => {
            openUrlInSameTab(cbAppUrl());
          })
          .catch(e => {
            Sentry.captureException(e);
          });
    } else {
      openUrlInSameTab(this.getCallbackUrl ? this.getCallbackUrl : cbAppUrl());
    }
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
  },
}
</script>