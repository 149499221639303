<template>
  <div class="c-tooltip" v-on="handleEvents()">
    <span ref="trigger" class="c-tooltip__trigger">
      <slot></slot>
    </span>
    <c-portal :disabled="disabledPortal">
      <transition name="fade" appear @afterLeave="disabledPortal = false">
        <div
          v-if="show"
          ref="tooltip"
          :class="classList"
          @mouseleave="close"
        >
          <div class="c-tooltip-content__container">
            <div v-c-html="content" class="c-tooltip-content__wrap"></div>
            <div v-if="arrow" data-popper-arrow></div>
          </div>
        </div>
      </transition>
    </c-portal>
  </div>
</template>

<script>
import {CTooltip} from '@chargebee/cookie-ui';
export default {
  name: 'ToolTip',
  extends: CTooltip,
  props: {
    dismissOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    popperOptions() {
      return {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'arrow',
            options: {
              padding: 10, // 10px from the edges of the popper
            },
          },
        ],
        ...this.options,
      };
    }
  },
  methods: {
    handleEvents() {
      if (this.showOnClick) {
        return {
          click: this.onClick,
        };
      }
      const events = {
        mouseenter: this.open,
        mouseleave: this.close,
      };
      if (this.dismissOnClick) {
        events.click = this.close;
      }
      return events
    }
  }
}
</script>

<style lang="scss">
$tooltip-content: '.c-tooltip-content';
#{$tooltip-content} {
  &--{
    &no-wrap {
      #{$tooltip-content} {
        &__container {
          max-width: 500px !important;
        }
      }
    }
  }
}

</style>