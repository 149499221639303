<template>
  <div class="onboarding-container operations-container">
    <div class="sidebar" v-if="!readOnly">
      <ul class="menu" v-scroll-spy-active="{class: 'custom-active'}" v-scroll-spy-link="{selector: '.menu-item'}">
        <li :key="item" v-for="item in titles" class="menu-item" :id="getElementId('nav_menu_item-' + item)">
          <a>{{ item }}</a>
        </li>
      </ul>
    </div>

    <div class="main" :class="{'no-scroll-spy': readOnly}" v-scroll-spy="{offset: 150, time: 100}">
      <div class="section-one">
        <BusinessStructure />
      </div>
      <div class="section-two">
        <BusinessOwnership :read-only="readOnly" />
      </div>
      <div class="next-step">
        <c-button
          @click="prev"
          size="large"
          class="c-mt--xxl c-mb--xxl"
        >
          {{ $t('global.action.prevStep') }}
        </c-button>
        <c-button
          @click="next"
          variant="primary"
          size="large"
          class="c-mt--xxl c-mb--xxl"
        >
          {{ $t('global.action.nextStep') }}
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessStructure from "@/components/onboarding/BusinessStructure.vue";
import BusinessOwnership from "@/components/onboarding/BusinessOwnership.vue";
import {mapActions, mapState} from "vuex";
import FullStoryHelper from '@/app/utils/fullstory';
import { OPERATIONS_OWNERSHIP_PAGE } from '@/app/utils/fullstory/constants';
import {PendoPrefixes} from "@/app/utils/common/constants";

export default {
  name: "OperationsForm",
  components: {
    BusinessStructure,
    BusinessOwnership
  },
  props: {
    next: {
      type: Function,
      required: true
    },
    prev: {
      type: Function,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      titles: [
        this.$t('businessStructure.title'),
        this.$t('businessOwnership.title'),
      ]
    }
  },
  computed: {
    ...mapState({
      businessStructure: state => state.businessStructure,
    })
  },
  mounted() {
    FullStoryHelper.event(OPERATIONS_OWNERSHIP_PAGE);
  },
  methods: {
    ...mapActions('businessStructure', {
      validateBusinessStructure: 'validate',
    }),
    ...mapActions('stakeholdersDetails', {
      validateControlPersonPresent: 'validateControlPersonPresent',
    }),
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase().replace(/\s+/g, '_');
    }
  },
}


</script>

<style src="@/app/assets/style/onboarding-form.scss" lang="scss"></style>