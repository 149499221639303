<template>
  <ModalPopup class="payments-app-loader" :show="showPreLoader">
    <div slot="body">
      <div id="payments-preloader" class="preloader-scale-up"></div>
      <template v-if="loadingMessage">
        <div id="payments-preloader-status-msg">
          {{ loadingMessage }}
        </div>
      </template>
    </div>
  </ModalPopup>
</template>

<script>

import ModalPopup from "@/components/common/ModalPopup.vue";

export default {
  name: 'AppPreLoader',
  components: {ModalPopup},
  props: {
    loadingMessage: {
      type: String,
      required: false,
      default: null
    },
    showPreLoader: {
      type: Boolean,
      required: true,
      default: true
    }
  }
}
</script>

<style lang="scss">
.preloader-scale-up {
  width: 50px !important;
  height: 50px !important;
}
.payments-app-loader {
  background-color: rgba(255, 255, 255, 0.75);
& .modal-container {
    border-radius: 0;
    background: none;
    box-shadow: none;
  }
}
</style>
