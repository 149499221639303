
export const TypesOfBusiness = {
  INDIVIDUAL_SOLE_PROPRIETOR: "INDIVIDUAL_SOLE_PROPRIETOR",
  PARTNERSHIP: "PARTNERSHIP",
  LIMITED_LIABILITY_COMPANY: "LIMITED_LIABILITY_COMPANY",
  CORPORATION: "CORPORATION",
  TAX_EXEMPT: "TAX_EXEMPT",
  GOVERNMENT_ENTITY: "GOVERNMENT_ENTITY",
  ASSOCIATIONS_AND_TRUSTS: "ASSOCIATIONS_AND_TRUSTS",
}

export const isSoleProprietor = (type) => {
  return type === TypesOfBusiness.INDIVIDUAL_SOLE_PROPRIETOR;
}

const TypesOfBusinessList = {
  INDIVIDUAL_SOLE_PROPRIETOR: {displayText: "Individual / Sole proprietor", description: "Type of enterprise owned and run by one person and in which there is no legal distinction between the owner and the business entity."},
  PARTNERSHIP: {displayText: "Partnership", description: "Formal arrangement by two or more parties to manage and operate a business and share its profits."},
  LIMITED_LIABILITY_COMPANY: {displayText: "Limited liability company", description: "Business entity with all the protection of a corporation plus the ability to pass through any business profits and losses to your personal income tax return."},
  CORPORATION: {displayText: "Corporation", description: "Legal entity that is separate and distinct from its owners."},
  TAX_EXEMPT: {displayText: "Tax exempt", description: "Organization is exempt from paying federal corporate income tax on income generated from activities that are substantially related to the purposes for which the entity was organized."},
  GOVERNMENT_ENTITY: {displayText: "Government agency", description: "Government organization responsible for a certain area of administration."},
  ASSOCIATIONS_AND_TRUSTS: {displayText: "Associations and Trusts", description: "An association is a non-profit registered entity that’s organized around a purpose such as recreation, culture, or charity.<br/><br/>" +
        "A trust is a relationship where one person holds title to a property for the benefit of another."},
}

export default Object.keys(TypesOfBusinessList).map(key => {
  return {
    displayText: TypesOfBusinessList[key].displayText,
    value: key,
    description: TypesOfBusinessList[key].description,
  }
});
