<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.779 14V14.0049C10.7741 14.0049 9.21606 14 8.80059 15.4887C8.41975 16.8538 7.34647 20.6211 7.31679 20.7249H7.02003C7.02003 20.7249 5.92202 16.8736 5.53623 15.4838C5.12076 13.9951 3.55783 14 3.55783 14H0V25.3231H3.55948V18.5981H3.85624L5.93356 25.323H8.40491L10.4822 18.603H10.779V25.323H14.3385V14H10.779ZM23.782 14C23.782 14 22.7384 14.094 22.2488 15.1871L19.728 20.7249H19.4312V14H15.8717V25.3231H19.2334C19.2334 25.3231 20.3264 25.2241 20.8161 24.136L23.2875 18.5981H23.5843V25.3231H27.1437V14H23.782ZM28.7264 19.1422V25.3231H32.2859V21.7141H36.1422C37.8238 21.7141 39.2466 20.6408 39.7758 19.1438H28.7264V19.1422Z" fill="#4DB45E" />
    <path d="M36.1438 14H28.2269C28.6225 16.1565 30.2398 17.8859 32.3304 18.4498C32.8157 18.5812 33.3163 18.6477 33.8191 18.6476H39.9208C39.9752 18.3905 40 18.1283 40 17.8563C40 15.7262 38.2739 14 36.1438 14Z" fill="url(#paint0_linear_18791_46939)" />
    <defs>
      <linearGradient
        id="paint0_linear_18791_46939" x1="28.2268" y1="16.3238" x2="40" y2="16.3238"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3" stop-color="#00B4E6" />
        <stop offset="1" stop-color="#088CCB" />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'MIRIcon',
}
</script>