import Vue from "vue";
import {init as initSentry, configureScope} from '@sentry/vue';
import SentryFullStory from '@sentry/fullstory';
import { SENTRY_ORG_NAME } from '@/app/utils/fullstory/constants';

export default class SentryWrapper {
    static init({config}) {
        const integrations: any[] = [];
        integrations.push(new SentryFullStory(SENTRY_ORG_NAME));
        initSentry({
            dsn: config.dsn,
            integrations,
            environment: config.environment,
            tracesSampleRate: 1.0,
            Vue,
            attachProps: false,
            beforeBreadcrumb() {
                return null;
            },
            beforeSend(event) {
                if (event.extra?.propsData) {
                    delete event.extra.propsData;
                }
                if(event.request?.url) {
                    event.request.url = event.request.url.split("?")[0];
                }
                if(event.breadcrumbs) {
                    delete event.breadcrumbs;
                }
                return event;
            }
        });
        configureScope(scope => {
            scope.setTag("cbpayments_merchant_id", config.merchantId);
            scope.setTag("cbpayments_external_id", config.externalId);
            scope.setTag("cbpayments_merchant_name", config.merchantName);
        });
    }
}