import {mapGetters} from "vuex";
import {storeMerchantContextForAdmin} from "@/service/saveInStore";
import {navigateTo} from "@/router";
import {getAndStoreMerchantAndConfiguration} from "@/service/sessionService";
import {RouteConstants} from "@/router/routeConstants";
import {OnboardingStatus} from "@/app/utils/common/constants";

export const portalCheckMixin = {
    computed: {
        ...mapGetters('app', ['getMerchantId', 'getMerchantCreatedAt', 'isMerchantAlreadyStored', 'getActivePayfac']),
        ...mapGetters('merchantPayfacDetails', ['getOnboardingStatus']),
        isAdmin() {
            return String(this.$router.currentRoute.query.adminMode) === "true"
        }, 
        isMerchantReadOnly() {
            return !this.isAdmin && Boolean(this.getMerchantId)
        },
    },
    created: async function () {
        if (this.isAdmin && !storeMerchantContextForAdmin(this.$router.currentRoute.query)) {
            return
        }

        if (!this.getMerchantId) {
            navigateTo(RouteConstants.ONBOARDING_STATUS)
            return
        }

        if (this.isMerchantAlreadyStored === false) {
            await getAndStoreMerchantAndConfiguration(this.getMerchantId);
        }

        if (this.isMerchantReadOnly && this.getOnboardingStatus(this.getActivePayfac) !== OnboardingStatus.APPROVED) {
            navigateTo(RouteConstants.ONBOARDING_STATUS)
        }
    }
}
