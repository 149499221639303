import merchantBalanceAccount from "@/api/merchantBalanceAccount";
import {MerchantBalanceAccountType} from "@/api/paymentApi";

export const balanceAccountMixin = {
    methods: {
        async getReserveBalance() {
            let response;
            try{
                response = await merchantBalanceAccount.fetchMerchantBalanceAccount(
                    this.getMerchantId);
            }catch (err) {
                return;
            }
            if (response?.merchantBalanceAccounts?.length && Array.isArray(response?.merchantBalanceAccounts)) {
                let chargebackReserveAmount = this.getBalanceAccountByType(response, MerchantBalanceAccountType.CHARGEBACKRESERVE).amount
                    + this.getBalanceAccountByType(response, MerchantBalanceAccountType.ROLLINGRESERVE).amount
                    + this.getBalanceAccountByType(response, MerchantBalanceAccountType.UPFRONTRESERVE).amount;

                let chargebackReservePendingAmount = this.getBalanceAccountByType(response, MerchantBalanceAccountType.CHARGEBACKRESERVE).pending_amount
                    + this.getBalanceAccountByType(response, MerchantBalanceAccountType.ROLLINGRESERVE).pending_amount
                    + this.getBalanceAccountByType(response, MerchantBalanceAccountType.UPFRONTRESERVE).pending_amount;

                await this.updateBalanceReserveState({key: 'currentAmountInRefundReserve',
                    value: this.getBalanceAccountByType(response, MerchantBalanceAccountType.REFUNDRESERVE).amount});
                await this.updateBalanceReserveState({key: 'pendingAmountToRefundReserve',
                    value: this.getBalanceAccountByType(response, MerchantBalanceAccountType.REFUNDRESERVE).pending_amount});
                await this.updateBalanceReserveState({key: 'currentAmountInChargebackReserve',
                    value: chargebackReserveAmount});
                await this.updateBalanceReserveState({key: 'pendingAmountToChargebackReserve',
                    value: chargebackReservePendingAmount});
                await this.updateBalanceReserveState({key: 'amountToBeHeldForUpfrontReserve',
                    value: this.getUpfrontReserveAmount(response)});
            }
        },
        getBalanceAccountByType: function (response, type) {
            return response.merchantBalanceAccounts.find(
                (account) => account.type === type
            );
        },
        getUpfrontReserveAmount: function (response) {
            let amountToBeHeld = this.getBalanceAccountByType(response,
                MerchantBalanceAccountType.UPFRONTRESERVE).additional_info?.amount_to_be_held ?? 0;
            let amount = this.getBalanceAccountByType(response,
                MerchantBalanceAccountType.UPFRONTRESERVE).amount;
            return Math.max(amountToBeHeld - amount, 0);
        }
    }
}