import {DateTime} from 'luxon';
import {ExcelDataType} from "@/app/utils/common/constants";
import _ from 'lodash';
import {getTimezone} from "@/app/utils/common/functions/dateUtils";
import {isNullOrEmpty} from "@/app/utils/common/functions/functions";

export default class Formatter {
    static format(
        config: any
    ) {
        const timezone = getTimezone();
        switch (config.type) {
            case 'zonedDate':
                return (
                    config.data &&
                    DateTime.fromISO(config.data, { zone: timezone }).toFormat(
                        config.format || 'MMM dd, yyyy'
                    )
                );
            case 'zonedDateWithTime':
                return (
                    config.data &&
                    DateTime.fromISO(config.data, { zone: timezone }).toFormat(
                        config.format || 'MMM dd, yyyy HH:mm'
                    )
                );
            case 'timeWithZone':
                return (
                    config.data &&
                    DateTime.fromISO(config.data, { zone: timezone }).toFormat(
                        config.format || 'HH:mm'
                    ) + " " + DateTime.fromISO(config.data, { zone: timezone }).toFormat('ZZZZZ')
                );
            case 'fromMillisDateFilter':
                return (
                    config.data &&
                    DateTime.fromMillis(config.data, { zone: timezone }).toFormat(
                        config.format || 'MMM dd yyyy'
                    )
                );
            case 'currency':
                return (
                    `${currencyFormat(
                        config.data,
                        config.currencyCode || ''
                    )}`
                );
            case 'number':
                return config.data && numberWithCommas(config.data, 3, ',', '.');
            default:
                return config.data;
        }
    }

    static setHourOfTheDay(
        datetime: any,
        hour: any
    ) {
        return datetime.set({hour: hour}).set({minutes: 0}).set({seconds: 0}).set({milliseconds: 0}).toUTC().toJSDate()
    }

    static formatReportDataForExcel(value: string, dataType: string) {
        /**
         * @property {number|string|date|boolean} formattedValue - The converted value
         */
        if (_.isEmpty(value)) {
            const defVal = Object.values(ExcelDataType).filter(x => x.key === dataType);
            return defVal.length !== 0 ? defVal[0].defaultValue : ExcelDataType.TEXT.defaultValue;
        }
        switch (dataType) {
            case 'NUMBER':
            case 'CURRENCY': {
                return isNaN(Number(value)) ? value : Number(value);
            }
            case 'DATE': {
                const dateTimeWithZone = DateTime.fromISO(value, {setZone: true});
                //Check if luxon DateTime contains invalid object, if so return the value else proceed.
                if (!isNullOrEmpty(dateTimeWithZone.invalid)) {
                    return value;
                }
                return new Date(Date.UTC(dateTimeWithZone.year, dateTimeWithZone.month-1, dateTimeWithZone.day,
                    dateTimeWithZone.hour, dateTimeWithZone.minute, dateTimeWithZone.second, dateTimeWithZone.millisecond))
            }
            case 'BOOLEAN': {
                return value.toUpperCase() === "TRUE";
            }
            default: {
                return value;
            }
        }
    }
    static formatTransactionsTimelineForASettlement(data, configType) {
        if (isNullOrEmpty(data) || isNullOrEmpty(data.first_transaction_date) || isNullOrEmpty(data.last_transaction_date)) {
            return null;
        }
        let format = null;
        if (configType === 'timeWithZone') {
            // @ts-ignore
            format = "HH:mm MMM dd";
        }
        let minCreatedAt = Formatter.format({type: 'zonedDate',
            data: data.first_transaction_date, format: format});
        const maxCreatedAt = Formatter.format({type: configType, data:
            data.last_transaction_date, format: format});

        if (data.first_transaction_date === data.last_transaction_date) {
            return maxCreatedAt;
        }
        //Only slice the year information when first and last transaction date are in same year.
        if (configType === 'zonedDate' && data.first_transaction_date.slice(0, 4) === data.last_transaction_date.slice(0, 4)) {
            minCreatedAt = minCreatedAt.substring(0, minCreatedAt.length - 6);
        }
        return minCreatedAt + " – " + maxCreatedAt;
    }
}

const numberWithCommas = (
    x,
    grouping,
    groupingSeparator,
    decimalSeparator
) => {
    const parts = x.toString().split('.');
    const regex = new RegExp('\\B(?=(\\d{' + grouping + '})+(?!\\d))', 'g');
    // grouping separator
    parts[0] = parts[0].replace(regex, groupingSeparator);
    // decimal separator
    return parts.join(decimalSeparator);
};

const currencyFormat = (
    value: number,
    currencyCode: string
) => {
    return numberFormat(currencyCode, value);
};

const numberFormat = (currencyCode: string, value: number) => (
    currencyCode &&
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currencySign: 'accounting',
        currency: currencyCode,
    }).format(value / 100.0)
);


