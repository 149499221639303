<template>
  <router-view />
</template>

<script>
import {mapGetters} from "vuex";
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "OnboardingIndex",
  computed: {
    ...mapGetters('app', ['hideOnboardingPage']),
  },
  mounted() {
    if(this.hideOnboardingPage){
      navigateTo(RouteConstants.ERROR);
    }
  }
}
</script>