import AmericanExpressIcon from "@/components/icons/card-brand/AmericanExpressIcon.vue";
import VisaIcon from "@/components/icons/card-brand/VisaIcon.vue";
import MastercardIcon from "@/components/icons/card-brand/MastercardIcon.vue";
import UnknownCardIcon from "@/components/icons/card-brand/UnknownCardIcon.vue";
import ChinaUnionPayIcon from "@/components/icons/card-brand/ChinaUnionPayIcon.vue";
import DankortIcon from "@/components/icons/card-brand/DankortIcon.vue";
import DinersClubIcon from "@/components/icons/card-brand/DinersClubIcon.vue";
import DiscoverIcon from "@/components/icons/card-brand/DiscoverIcon.vue";
import InstapaymentIcon from "@/components/icons/card-brand/InstapaymentIcon.vue";
import InterpaymentIcon from "@/components/icons/card-brand/InterpaymentIcon.vue";
import JCBIcon from "@/components/icons/card-brand/JCBIcon.vue";
import LankapayIcon from "@/components/icons/card-brand/LankapayIcon.vue";
import MaestroIcon from "@/components/icons/card-brand/MaestroIcon.vue";
import MIRIcon from "@/components/icons/card-brand/MIRIcon.vue";
import RupayIcon from "@/components/icons/card-brand/RupayIcon.vue";
import TroyIcon from "@/components/icons/card-brand/TroyIcon.vue";
import UATPIcon from "@/components/icons/card-brand/UATPIcon.vue";
import VerveIcon from "@/components/icons/card-brand/VerveIcon.vue";
import ChinaTUnionIcon from "@/components/icons/card-brand/ChinaTUnionIcon.vue";

export const cardBrandLogo = {
    'AMERICAN_EXPRESS': AmericanExpressIcon,
    'CHINA_T_UNION': ChinaTUnionIcon,
    'CHINA_UNION_PAY': ChinaUnionPayIcon,
    'DANKORT': DankortIcon,
    'DINERS_CLUB': DinersClubIcon,
    'DINERS_CLUB_INTERNATIONAL': DinersClubIcon,
    'DISCOVER': DiscoverIcon,
    'INSTAPAYMENT': InstapaymentIcon,
    'INTERPAYMENT': InterpaymentIcon,
    'JCB': JCBIcon,
    'LANKAPAY': LankapayIcon,
    'MAESTRO': MaestroIcon,
    'MASTERCARD': MastercardIcon,
    'MIR': MIRIcon,
    'RUPAY': RupayIcon,
    'TROY': TroyIcon,
    'UATP': UATPIcon,
    'UNKNOWN': UnknownCardIcon,
    'VERVE': VerveIcon,
    'VISA': VisaIcon
};
