<template>
  <div class="onboarding-container business-profile-container">
    <div class="sidebar" v-if="!readOnly">
      <ul class="menu" v-scroll-spy-active="{class: 'custom-active'}" v-scroll-spy-link="{selector: '.menu-item'}">
        <li :key="item" v-for="item in titles" class="menu-item" :id="getElementId('nav_menu_item-' + item)">
          <a>{{ item }}</a>
        </li>
      </ul>
    </div>

    <div class="main" :class="{'no-scroll-spy': readOnly}" v-scroll-spy="{offset: 150, time: 100}">
      <div class="section-one">
        <BusinessProfile />
      </div>
      <div class="section-two">
        <BusinessAddress />
      </div>
      <div class="agreement">
        {{ $t('businessProfile.agreement') }}
      </div>
      <div class="next-step">
        <c-button
          @click="next"
          variant="primary"
          size="large"
          class="c-mt--xxl c-mb--xxl"
        >
          {{ $t('global.action.nextStep') }}
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessProfile from "@/components/onboarding/BusinessProfile.vue"
import BusinessAddress from "@/components/onboarding/BusinessAddress.vue";
import { mapState } from "vuex";
import FullStoryHelper from '@/app/utils/fullstory';
import { BUSINESS_PROFILE_PAGE } from '@/app/utils/fullstory/constants';
import {PendoPrefixes} from "@/app/utils/common/constants";

export default {
  name: 'BusinessProfileForm',
  components: {
    BusinessAddress,
    BusinessProfile
  },
  props: {
    next: {
      type: Function,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return ({
      titles: [
        this.$t('businessProfile.title'),
        this.$t('businessAddress.title'),
      ]
    });
  },
  computed: {
    ...mapState({
      businessProfile: s => s.businessProfile,
      businessAddress: s => s.businessAddress,
    }),
  },
  mounted() {
    FullStoryHelper.event(BUSINESS_PROFILE_PAGE);
  },
  methods: {
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase().replace(/\s+/g, '_');
    }
  }
}


</script>

<style src="@/app/assets/style/onboarding-form.scss" lang="scss"></style>