<template>
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 9.70711C-0.097631 9.31658 -0.097631 8.68342 0.292893 8.2929L3.58579 5.00001L0.292893 1.70712C-0.0976311 1.3166 -0.0976311 0.683432 0.292893 0.292909C0.683417 -0.0976162 1.31658 -0.0976162 1.70711 0.292909L5.70711 4.2929C6.09763 4.68343 6.09763 5.31659 5.70711 5.70711L1.70711 9.70711C1.31658 10.0976 0.683417 10.0976 0.292893 9.70711Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'RightArrowIcon',
}
</script>