<template>
  <div class="edit-business-structure-container">
    <div class="home">
      <div class="top-fixed">
        <AppHeader
          :secondary-action-label="$t('global.action.dismiss')"
          :secondary-action="navigateToStatusScreen"
          :primary-action-label="primaryActionLabel"
          :primary-action="primaryAction"
          :disable-primary-action="loading"
        />
      </div>
      <div class="bs-container">
        <BusinessStructure />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import AppHeader from "@/components/onboarding/AppHeader";
import BusinessStructure from "@/components/onboarding/BusinessStructure";
import FullStoryHelper from '@/app/utils/fullstory';
import { EDIT_BUSINESS_STRUCTURE_PAGE } from '@/app/utils/fullstory/constants';
import {editOnboardingMixin} from "@/mixin/editOnboardingMixin";

export default {
  name: 'EditBusinessStructure',
  components: {BusinessStructure, AppHeader},
  mixins: [editOnboardingMixin],
  data: () => {
    return {
      loading: false,
      failureCode: "",
    }
  },
  computed: {
    ...editOnboardingMixin,
  },
  mounted() {
    this.failureCode = this.$route.query.errorCode;
    FullStoryHelper.event(EDIT_BUSINESS_STRUCTURE_PAGE);
  },
  methods: {
    ...editOnboardingMixin,
    ...mapActions('businessStructure', ['validate']),
    primaryAction() {
      this.validateBusinessStructure(() => this.successCallBackFunction());

    },
    validateBusinessStructure(successCallback) {
      this.validate().then(errors => {
        if (!errors) {
          this.$danger(this.$t('errors.validationErrorToastSave'))
        } else {
          successCallback()
        }
      })
    },
  }
}
</script>

<style lang="scss">

.edit-business-structure-container {

  & .top-fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;
    display: inline-block;
  }

  & .bs-container {
    margin: auto;
    margin-top: 24px;
    padding-bottom: 150px;

    & .header {
      color: $text-color;
    }
  }

}

</style>

<style src="v-calendar/lib/v-calendar.min.css"/>