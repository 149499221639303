<template>
  <div class="error-banner">
    <div class="left">
      <img src="../../app/assets/images/orang-exclamation-triangle.svg" alt="Chargebee Warning Icon" />
      <div class="title-container">
        <div class="title">
          {{ title }}
        </div>
        <div class="sub-title">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "ErrorBanner",
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
}
</script>

<style lang="scss">
.error-banner {
  background-color: #FEF2F2;
  padding: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 12px;

  & .title {
    font-size: 14px;
    font-weight: $weight-medium;
    line-height: 24px;
  }

  & .sub-title {
    font-size: 12px;
    color: $subtitle-text;
    line-height: 24px;
  }


  & .left {
    display: flex;
    flex-direction: row;

    & img {
      width: 15.17px;
      height: 13.92px;
      margin: 5px;
      margin-right: 14px;
    }
  }
  & .right {
    & button {
      color: $color_red;
      font-weight: $weight-medium;
      & span {
        padding: 8px;
        border-radius: 4px;
        background-color: $color_white;
      }
    }
  }
}

</style>