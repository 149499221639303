<template>
  <div class="ba-container">
    <slot>
      <div class="header">
        {{ $t('businessAddress.title') }}
      </div>
      <div class="subtitle">
        {{ $t('businessAddress.subtitle') }}
      </div>
      <div class="separator"></div>
    </slot>

    <SelectField
      :label="$t('businessAddress.country.label')"
      :searchable="true"
      :clearable="true"
      :value="getCountry"
      :options="getCountriesEnabledForCbPayments()"
      :on-update="changeCountry"
      :error="getErrors.country"
      select-classname="country-select"
      field-classname="country-field"
      data-testid="country-field"
    />

    <InputField
      :label="$t('businessAddress.addressLine1.label')"
      :value="getLine1"
      id="address-line-1"
      data-testid="address-line-1"
      :set-value="setLine1"
      :error="getErrors.line1"
      :max-length="getMax(getLine1ApiPath)"
      class="fs-mask"
    />

    <InputField
      :label="$t('businessAddress.addressLine2.label')"
      :value="getLine2"
      id="address-line-2"
      data-testid="address-line-2"
      :set-value="setLine2"
      :error="getErrors.line2"
      :max-length="getMax(getLine2ApiPath)"
      class="fs-mask"
    />

    <div class="city-state-zip">
      <InputField
        :label="$t('businessAddress.city.label')"
        :value="getCity"
        id="city"
        data-testid="city"
        :set-value="setCity"
        :error="getErrors.city"
        :max-length="getMax(getCityApiPath)"
      />

      <InputField
        v-if="stateOptions.length === 0"
        :label="$t('businessAddress.state.label')"
        :value="getState"
        id="state"
        data-testid="state"
        :set-value="setState"
        :error="getErrors.state"
        :max-length="getMax(getStateApiPath)"
      />

      <SelectField
        v-else
        :label="$t('businessAddress.state.label')"
        :searchable="true"
        :clearable="false"
        :options="stateOptions"
        :on-update="setState"
        :error="getErrors.state"
        :value="getState"
        select-classname="state-select"
        data-testid="state-select"
      />

      <InputField
        :label="$t('businessAddress.zip.label')"
        :value="getFormattedZip"
        :set-value="setZip"
        :error="getErrors.zip"
        id="zip-code"
        :max-length="10"
        data-testid="zip-code"
        class="fs-mask"
      />
    </div>
  </div>
</template>

<script>
import {
  CBPaymentsEnabledCountries,
  CountryCodeListSortedByName
} from "@/data/country-code-list";
import StateCodeList from "@/data/state-code-list";
import validations from "@/app/validators/api/openapi.ts"
import {mapActions, mapGetters} from "vuex";
import InputField from "@/components/common/InputField";
import SelectField from "@/components/common/SelectField";

export default {
  name: 'BusinessAddress',
  components: {SelectField, InputField},
  props: {},
  data: () => ({
    stateOptions: []
  }),
  computed: {
    ...mapGetters('businessAddress', {
      getErrors: 'getErrors',
      getCountry: 'getCountry',
      getLine1: 'getLine1',
      getLine2: 'getLine2',
      getState: 'getState',
      getCity: 'getCity',
      getZip: 'getZip',
      getFormattedZip: 'getFormattedZip',
      getLine1ApiPath: 'getLine1ApiPath',
      getLine2ApiPath: 'getLine2ApiPath',
      getStateApiPath: 'getStateApiPath',
      getCityApiPath: 'getCityApiPath',
      getZipApiPath: 'getZipApiPath',
    }),
  },
  mounted() {
    if (this.getCountry !== null) {
      this.onCountryChange(this.getCountry)
    }
  },
  methods: {
    ...mapActions('businessAddress', {
      setCountry: 'updateCountry',
      setLine1: 'updateLine1',
      setLine2: 'updateLine2',
      setState: 'updateState',
      setCity: 'updateCity',
      setZip: 'updateZip',
    }),
    ...mapActions('bankAccountForPayouts', { setBankAccountCountry: 'updateCountry' }),

    getMax: key => validations.createMerchant[key].length.maximum,
    getCountries: () => CountryCodeListSortedByName,
    getCountriesEnabledForCbPayments: () => CBPaymentsEnabledCountries,

    changeCountry: function (countryCode) {
      this.setState(null);
      this.onCountryChange(countryCode)
    },
    onCountryChange: function (countryCode) {
      this.setCountry(countryCode)
      this.setBankAccountCountry(countryCode)
      if (StateCodeList[countryCode]) {
        const statesList = StateCodeList[countryCode];
        this.stateOptions = Object.keys(statesList).map(stateName => ({
          displayText: stateName,
          value: statesList[stateName]
        }));
      } else {
        this.stateOptions = [];
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.ba-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $primary_text;
    text-align: left;
    margin-bottom: 8px;
  }

  & .subtitle {
    font-size: 12px;
    color: $neutral_600;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }

  & .city-state-zip {
    display: flex;
    align-self: normal;
    justify-content: space-between;
    gap: 20px;

    @media screen and (min-width: 801px) {
      flex-direction: row;
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    & .field {
      flex: 1;
    }
  }


  & .country-field {
    width: 300px;
  }

  & .field {
    & .country-select {
      width: 300px;
      display: block;
    }
  }
}


</style>
