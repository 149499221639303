const Applications = {
    "local": [{
        name: "Chargebee - Sandbox",
    }],
    "predev": [
        {name: "Chargebee - Sandbox"},
        {name: "Chargebee - Live"}
    ],
    "dev": [
        {name: "Chargebee"},
    ],
    "dev-eu": [
        {name: "Chargebee - EU"},
    ],
    "staging": [
        {name: "Chargebee - Sandbox"},
        {name: "Chargebee - Live"},
    ],
    "prod": [
        {name: "Chargebee - Sandbox"},
        {name: "Chargebee - Live"}
    ],
    "prod-eu": [
        {name: "Chargebee - Sandbox EU"},
        {name: "Chargebee - Live EU"}
    ]

}
export default Applications
