<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_18791_46683" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="6"
      width="40" height="27"
    >
      <path d="M0 33H40V6.55779H0V33Z" fill="white" />
    </mask>
    <g mask="url(#mask0_18791_46683)">
      <path d="M12.0512 7.97071C5.53975 7.97071 0.242065 13.2676 0.242065 19.7782C0.242065 26.2896 5.53975 31.5869 12.0512 31.5869H27.9549C34.4655 31.5869 39.7625 26.2896 39.7625 19.7782C39.7625 13.2676 34.4655 7.97071 27.9549 7.97071H12.0512Z" fill="white" />
      <path d="M12.0512 7.97115C5.53975 7.97115 0.242061 13.268 0.242061 19.7787C0.242061 26.29 5.53975 31.5873 12.0512 31.5873H27.9549C34.4655 31.5873 39.7625 26.29 39.7625 19.7787C39.7625 13.268 34.4655 7.97115 27.9549 7.97115H12.0512ZM27.9549 31.8271H12.0512C5.40742 31.8271 0.0022583 26.4222 0.0022583 19.7787C0.0022583 13.1358 5.40742 7.73126 12.0512 7.73126H27.9549C34.5978 7.73126 40.0023 13.1358 40.0023 19.7787C40.0023 26.4222 34.5978 31.8271 27.9549 31.8271Z" fill="#211D1D" />
      <path d="M30.735 19.0573L36.0234 25.3467C37.1187 23.7638 37.762 21.8451 37.762 19.7784C37.762 17.597 37.0453 15.5797 35.836 13.9491L30.735 19.0573Z" fill="#E41D27" />
      <path d="M15.7491 13.4182C19.6984 13.4182 22.6741 14.423 22.9804 17.7505L27.1367 13.4177H35.413C33.6126 11.3104 30.9371 9.97174 27.9548 9.97174H12.0512C9.06782 9.97174 6.39184 11.3104 4.59137 13.4182H15.7491Z" fill="#E41D27" />
      <path d="M9.24209 17.6132L7.83179 21.4907H13.5218C15.2039 21.4907 15.7129 20.7943 16.0893 19.4537C16.4619 18.1277 15.5261 17.6132 14.3765 17.6132H9.24209Z" fill="#E41D27" />
      <path d="M27.1713 25.876L22.7755 20.3207C21.9623 24.1084 19.3017 25.8753 14.314 25.8753H4.3739C6.17213 28.135 8.94511 29.5864 12.0512 29.5864H27.9549C31.0598 29.5864 33.8322 28.1356 35.6305 25.876H27.1713Z" fill="#E41D27" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'DankortIcon',
}
</script>