let untilDev = {
    local: true, ci: true, predev: true, dev: true, 'dev-eu': true
};

let everywhere = {
    local: true, ci: true, predev: true, dev: true, staging: true, prod: true, 'dev-eu': true, 'prod-eu': true
};

export const Features = {
    DISPUTES: "DISPUTES",
    FEES: "FEES",
}

const FeatureToggles = {
    [Features.DISPUTES]: untilDev,
    [Features.FEES]: everywhere,
}

export const getFeatureToggle = (feature) => {
    return Boolean(FeatureToggles[feature][process.env.VUE_APP_BUILD_ENV]);
}

