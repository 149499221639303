<template>
  <div class="edit-bank-container">
    <div class="home">
      <div class="top-fixed">
        <AppHeader
          :secondary-action-label="$t('global.action.dismiss')"
          :secondary-action="navigateToStatusScreen"
          :primary-action-label="primaryActionLabel"
          :primary-action="primaryAction"
          :disable-primary-action="currentComponent !== 'BusinessStructure'"
        />
      </div>
      <div class="bi-container">
        <div class="section">
          <BusinessProfile v-if="currentComponent === 'BusinessProfile'">
            <div class="header">
              {{ $t('businessProfile.title') }} 1/3
            </div>
            <div class="subtitle">
              {{ $t('edit.businessInformation.subtitle') }}
            </div>
            <div class="separator"></div>

            <ErrorBanner
              :title="$t('edit.businessInformation.bannerTitle')"
              :description="$t('edit.businessInformation.bannerDescription')"
            />
          </BusinessProfile>


          <BusinessAddress v-if="currentComponent === 'BusinessAddress'">
            <div class="header">
              {{ $t('businessAddress.title') }} 2/3
            </div>
            <div class="subtitle">
              {{ $t('edit.businessInformation.subtitle') }}
            </div>
            <div class="separator"></div>

            <ErrorBanner
              :title="$t('edit.businessInformation.bannerTitle')"
              :description="$t('edit.businessInformation.bannerDescription')"
            />
          </BusinessAddress>


          <BusinessStructure v-if="currentComponent === 'BusinessStructure'">
            <div class="header">
              {{ $t('businessStructure.title') }} 3/3
            </div>
            <div class="subtitle">
              {{ $t('edit.businessInformation.subtitle') }}
            </div>
            <div class="separator"></div>

            <ErrorBanner
              :title="$t('edit.businessInformation.bannerTitle')"
              :description="$t('edit.businessInformation.bannerDescription')"
            />
          </BusinessStructure>
        </div>
        <div class="nav">
          <c-button
            variant="secondary"
            @click="secondaryNavButtonAction"
            v-if="currentComponent !== 'BusinessProfile'"
          >
            {{ $t('global.action.previous') }}
          </c-button>
          <c-button variant="primary" @click="primaryNavButtonAction">
            {{ primaryButtonLabel }}
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import AppHeader from "@/components/onboarding/AppHeader";
import BusinessProfile from "@/components/onboarding/BusinessProfile";
import ErrorBanner from "@/components/common/ErrorBanner";
import BusinessAddress from "@/components/onboarding/BusinessAddress";
import BusinessStructure from "@/components/onboarding/BusinessStructure";
import FullStoryHelper from '@/app/utils/fullstory';
import { EDIT_BUSINESS_INFORMATION_PAGE } from '@/app/utils/fullstory/constants';
import {editOnboardingMixin} from "@/mixin/editOnboardingMixin";

export default {
  name: 'EditBusinessInformationView',
  components: {BusinessStructure, BusinessAddress, ErrorBanner, BusinessProfile, AppHeader},
  mixins: [editOnboardingMixin],
  data: () => {
    return {
      currentComponent: "BusinessProfile",
      businessProfileTab: "BusinessProfile",
      businessAddressTab: "BusinessAddress",
      businessStructureTab: "BusinessStructure",
      failureCode: "",
    }
  },
  computed: {
    ...editOnboardingMixin,
    primaryButtonLabel() {
      return this.currentComponent === 'BusinessStructure' ? this.$t('global.action.submit') :
          this.$t('global.action.next');
    },
  },
  mounted() {
    this.failureCode = this.$route.query.errorCode;
    FullStoryHelper.event(EDIT_BUSINESS_INFORMATION_PAGE);
  },
  methods: {
    ...editOnboardingMixin,
    ...mapActions('businessProfile', {validateBusinessProfile: 'validate'}),
    ...mapActions('businessAddress', {validateBusinessAddress: 'validate'}),
    ...mapActions('businessStructure', {validateBusinessStructure: 'validate'}),
    validateCurrentTab(callback) {
      if (this.currentComponent === this.businessProfileTab) {
        this.validate(this.validateBusinessProfile, callback)
      } else if (this.currentComponent === this.businessAddressTab) {
        this.validate(this.validateBusinessAddress, callback)
      } else if (this.currentComponent === this.businessStructureTab) {
        this.validate(this.validateBusinessStructure, callback)
      }
    },
    validate(validator, callback) {
      validator().then(errors => {
        if (!errors) {
          this.$danger(this.$t('errors.validationErrorToastSave'))
        } else {
          callback()
        }
      })
    },
    secondaryNavButtonAction() {
      if (this.currentComponent === 'BusinessAddress') {
        this.currentComponent = 'BusinessProfile'
      } else if (this.currentComponent === 'BusinessStructure') {
        this.currentComponent = 'BusinessAddress'
      }
      window.scrollTo(0, 0);
    },
    primaryNavButtonAction() {
      if (this.currentComponent === 'BusinessProfile') {
        this.validateCurrentTab(() => this.currentComponent = 'BusinessAddress')
      } else if (this.currentComponent === 'BusinessAddress') {
        this.validateCurrentTab(() => this.currentComponent = 'BusinessStructure')
      } else if (this.currentComponent === 'BusinessStructure') {
        this.validateCurrentTab(() => this.successCallBackFunction());
      }
      window.scrollTo(0, 0);
    },
    primaryAction() {
        this.validateCurrentTab(() => this.successCallBackFunction());
    },
  },
}
</script>

<style lang="scss">

.edit-bank-container {

  & .top-fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;
    display: inline-block;
  }

  & .bi-container {
    margin: auto;
    margin-top: 24px;
    padding-bottom: 150px;

    & .section {
      margin-bottom: 10px;
    }

    & .header {
      color: $text-color;
    }

    & .separator {
      margin-bottom: 20px;
    }

    & .bp-container {
      margin: auto;
    }

    & .ba-container {
      margin: auto;
      padding-bottom: 50px;
    }

    & .bs-container {
      margin: auto;
    }

    & .nav {
      max-width: 700px;
      margin: auto;
      padding: 8px;
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }

}

</style>

<style src="v-calendar/lib/v-calendar.min.css"/>