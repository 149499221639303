<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_18791_47082)">
      <path d="M0.20942 15.2094C-0.0418889 15.4607 -0.0523601 15.712 0.167535 16.0262C0.356017 16.2984 0.848163 16.3403 1.089 16.089C1.34031 15.8481 1.29843 15.356 1.02617 15.1675C0.712038 14.9476 0.460729 14.9581 0.20942 15.2094Z" fill="black" />
      <path d="M13.4555 15.6704C13.0367 15.8065 13.0367 15.8065 13.0053 16.3929L12.9738 16.9898H12.4608H11.9372V17.461V17.9322H12.4608H12.9843L12.9948 19.6285C12.9948 21.5029 13.089 21.9322 13.5288 22.2568C13.8325 22.4871 14.7644 22.6128 15.2356 22.4976C15.466 22.4348 15.4974 22.372 15.4974 21.9845V21.5447L14.9215 21.5762C14.0314 21.639 14.0314 21.639 14.0314 19.6495V17.9322H14.7644H15.4974V17.461V16.9898H14.7644H14.0314V16.2568C14.0314 15.8484 14 15.5238 13.9581 15.5343C13.9058 15.5447 13.6859 15.6076 13.4555 15.6704Z" fill="black" />
      <path d="M17.9058 15.555C17.8744 15.5864 17.8639 15.8273 17.8848 16.0891L17.9267 16.5707H18.5445C19.5393 16.5707 20.1047 16.9058 20.1047 17.5027V17.7121L19.7382 17.4922C19.1518 17.1571 18.1885 17.1152 17.5079 17.4084C16.5655 17.8168 15.8115 18.9268 15.8115 19.9215C15.8115 21.0001 16.6178 22.0891 17.644 22.3927C18.3665 22.5917 18.8796 22.5393 19.6545 22.1624C20.2199 21.8901 20.5969 21.555 22.1675 19.9006C23.3403 18.6545 24 18.2461 24.8168 18.2461C25.7801 18.2461 26.3246 18.7173 26.4503 19.6283C26.6387 20.9686 25.2565 21.9739 24.0838 21.3666C23.5393 21.0838 23.1833 20.6126 23.089 20.0262C23.0471 19.7959 22.9843 19.6074 22.9529 19.6179C22.911 19.6179 22.7016 19.8378 22.4922 20.0891L22.0942 20.5603V22.6545V24.7383H22.6178H23.1414V23.377V22.0262L23.5079 22.2357C23.9791 22.5184 24.9738 22.6126 25.6021 22.4346C25.8639 22.3613 26.2827 22.1205 26.534 21.9006C27.1623 21.3456 27.4346 20.7278 27.4346 19.8378C27.4346 17.7016 25.4241 16.5917 23.3613 17.576C22.911 17.7959 22.3874 18.2461 21.1414 19.5132C20.1361 20.5393 19.3717 21.2304 19.1309 21.3351C17.644 21.9529 16.3246 20.5289 17.0681 19.0943C17.3403 18.5498 17.8534 18.2461 18.4712 18.2461C19.267 18.2461 19.8953 18.6964 20.1466 19.4399L20.2723 19.8168L20.7225 19.3561L21.1833 18.8953L21.1309 18.0472C21.0367 16.4032 20.3037 15.6388 18.7644 15.5446C18.3246 15.5236 17.9372 15.5236 17.9058 15.555Z" fill="#EC1C2C" />
      <path d="M3.7173 17.1362C3.43458 17.2828 3.18327 17.4084 3.1728 17.4084C3.15185 17.4084 3.14138 17.3142 3.14138 17.199C3.14138 17.021 3.06808 16.9896 2.67018 16.9896H2.19897V19.7121V22.4346H2.67018H3.14138V21.021C3.14138 19.3037 3.25657 18.6859 3.65447 18.309C4.24086 17.7435 5.24609 17.7749 5.67541 18.3613C5.85343 18.5917 5.87437 18.843 5.89531 20.5603L5.91625 22.487L6.41887 22.5184L6.91102 22.5498V20.5812C6.91102 18.7278 6.89007 18.5812 6.65971 18.0681C6.16756 17.0315 4.78536 16.5917 3.7173 17.1362Z" fill="black" />
      <path d="M8.84814 17.0212C8.0628 17.3353 7.64396 17.8798 7.64396 18.5814C7.64396 19.3458 8.25129 19.8903 9.45547 20.1939C10.6178 20.4767 10.9738 20.9269 10.4084 21.3772C10.1047 21.618 9.42406 21.6704 8.95286 21.4924C8.80626 21.44 8.58636 21.2201 8.47118 21.0212L8.25129 20.6547L7.86385 20.885C7.65443 21.0107 7.47642 21.1259 7.45547 21.1363C7.38218 21.1887 7.74867 21.7751 8.03139 22.0369C8.43977 22.4243 8.84814 22.5604 9.61254 22.5604C10.8167 22.5709 11.623 21.9112 11.623 20.9164C11.623 20.4034 11.3193 19.8798 10.911 19.6704C10.7434 19.5866 10.2722 19.4191 9.86385 19.3039C9.46595 19.1992 9.03663 19.0526 8.92144 18.9897C8.41882 18.7175 8.70155 18.0159 9.36123 17.8903C9.83244 17.796 10.4293 18.0473 10.5968 18.3929C10.7434 18.7175 10.7748 18.728 11.246 18.4452C11.6125 18.2358 11.6125 18.2253 11.4659 17.9322C11.0471 17.1154 9.74867 16.6651 8.84814 17.0212Z" fill="black" />
      <path d="M30.0419 17.0525C29.4241 17.241 28.6702 17.9111 28.3665 18.5394C28.1885 18.8954 28.1257 19.2096 28.1257 19.7122C28.1257 20.8849 28.6806 21.7959 29.6859 22.2881C30.1885 22.5289 30.356 22.5603 31.0786 22.5289C31.7592 22.4975 31.9686 22.4347 32.3351 22.1938C32.8691 21.8483 32.8796 21.8483 32.8796 22.1729C32.8796 22.4137 32.9215 22.4347 33.3508 22.4347H33.822V19.7122V16.9897H33.3508C32.8901 16.9897 32.8796 17.0001 32.8796 17.3143V17.6284L32.555 17.398C31.8639 16.9164 30.9215 16.7802 30.0419 17.0525ZM31.7173 18.0368C32.199 18.2776 32.6492 18.7802 32.733 19.1677C32.7644 19.3247 32.8063 19.5027 32.8272 19.5656C32.8901 19.7855 32.6597 20.5813 32.4398 20.8954C32.3142 21.063 32.0314 21.2933 31.7906 21.398C30.1885 22.131 28.5969 20.6232 29.267 19.0106C29.4869 18.487 29.7068 18.2567 30.2094 18.0263C30.7539 17.775 31.2147 17.775 31.7173 18.0368Z" fill="black" />
      <path d="M0.209412 19.7647V22.5396H0.680616H1.15182V19.7647V16.9898H0.680616H0.209412V19.7647Z" fill="black" />
      <path d="M34.3455 17.0315C34.3455 17.0524 34.8691 18.2671 35.4974 19.7226C36.1361 21.1781 36.6492 22.4556 36.6492 22.5812C36.6492 23.0943 35.7382 23.9111 35.3298 23.754C35.2146 23.7121 35.1832 23.8063 35.1832 24.2147C35.1832 24.7383 35.1832 24.7383 35.5288 24.7383C35.9476 24.7383 36.6282 24.4241 36.9319 24.0995C37.2879 23.6912 37.5183 23.1781 38.733 20.0262L39.8743 17.0419L39.4555 17.0105C39.2251 16.9896 38.9738 17.0001 38.911 17.021C38.8481 17.0524 38.4398 17.9948 38.0104 19.1257C37.5916 20.2566 37.2356 21.1676 37.2146 21.1467C37.2042 21.1362 36.7958 20.2043 36.3036 19.0838L35.4136 17.0419L34.8796 17.0105C34.5864 16.9896 34.3455 17.0001 34.3455 17.0315Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_18791_47082">
        <rect width="40" height="9.84293" fill="white" transform="translate(0 15)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'InstapaymentIcon',
}
</script>