import { format, length, regex, strictInt } from "@/app/validators/api/customValidations";

export const organisationRegistrationNumber = {
    ["FRA"]: {
        length: length(14,14), "strictInt": strictInt()
    },
    ["DEU"]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$")
    },
    ["GBR"]: {
        length: length(8,8)
    }
}

export const organisationTaxId = {
    ["DEU"]: {
        length: length(10,11), "strictInt": strictInt()
    },
    ["GBR"]: {
        length: length(10,10), "strictInt": strictInt()
    },
    ["USA"]: {
        length: length(9,9), "strictInt": strictInt()
    }
}

export const organisationVatNumber = {
    ["FRA"]: {
        regex: regex("^FR\\d{11}$", "start with FR followed by 11 digits")
    },
    ["DEU"]: {
        regex: regex("^DE\\d{9}$", "start with DE followed by 9 digits")
    },
    ["GBR"]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits")
    }
}

export const soleProprietorRegistrationNumber = {
    ["FRA"]: {
        length: length(14,14), "strictInt": strictInt()
    },
    ["DEU"]: {
        format: format("^[A-Za-z]{2,3}\\d{1,6}[A-Za-z]{0,5}$")
    },
    ["GBR"]: {
        length: length(8,8), "strictInt": strictInt()
    }
}

export const soleProprietorTaxId = {
    ["USA"]: {
        length: length(9,9), "strictInt": strictInt()
    }
}

export const soleProprietorVatNumber = {
    ["GBR"]: {
        regex: regex("^GB\\d{9,12}$", "start with GB followed by 9-12 digits")
    }
}

export const accountNumber = {
    ["GBR"]: {
        length: length(8,8), "strictInt": strictInt()
    },
    ["USA"]: {
        length: length(3,17), "strictInt": strictInt()
    }
}

export const bankCode = {
    ["USA"]: {
        length: length(9,10), "strictInt": strictInt()
    }
}

export const iban = {
    ["FRA"]: {
        regex: regex("^FR\\d{25}$", "start with FR followed by 25 digits")
    },
    ["DEU"]: {
        regex: regex("^DE\\d{20}$", "start with DE followed by 20 digits")
    }
}

export const sortCode = {
    ["GBR"]: {
        length: length(6,6), "strictInt": strictInt()
    }
}
