<template>
  <div class="compliance-form-overview">
    <div
      class="title"
      v-if="showTitle"
    >
      Overview
    </div>
    <div class="fields" v-if="complianceForm">
      <div class="column">
        <FieldValue
          class-name="created-on"
          :field-value="formatDateWithTimezone(complianceForm.created_at.toString())"
          :field-name="$t('fieldNames.createdOn')"
        />
        <FieldValue
          v-if="!inProgressOrCompleteStatus"
          class-name="due-on"
          :field-value="formatDateWithoutTimezone(complianceForm.due_at.toString())"
          :field-name="$t('fieldNames.dueOn')"
        />
        <FieldValue
          class-name="attested-on"
          v-if="inProgressOrCompleteStatus && showAttestedAt"
          :field-value="formatDateWithTimezone(complianceForm.attestation_details.signed_at.toString())"
          :field-name="$t('fieldNames.attestedOn')"
        />
      </div>
      <div class="column">
        <FieldValue
          class-name="valid-until"
          :field-value="formatDateWithoutTimezone(complianceForm.valid_until)"
          :field-name="$t('fieldNames.validUntil')"
          :tooltip="nullDescriptionForValidUntil"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { ComplianceFormResponseDto } from "@/api/paymentApi";
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";

export default {
  name: 'ComplianceFormOverview',
  components: { FieldValue },
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true,
    },
    inProgressOrCompleteStatus: {
      type: Boolean,
      required: true
    },
    showTitle: {
      type: Boolean,
      required: true,
      default: true,
    },
    showAttestedAt: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    nullDescriptionForValidUntil() {
      return this.complianceForm.valid_until === null ? this.$t('profile.validUntilNullDescription') : null;
    }
  },
  methods: {
    formatDateWithTimezone(date) {
      return date == null ? null : Formatter.format({type: 'zonedDateWithTime', data: date.toString()})
    },
    formatDateWithoutTimezone(date) {
      return date == null ? null : Formatter.format({type: 'zonedDate', data: date.toString()})
    }
  }
}
</script>

<style lang="scss">
.compliance-form-overview {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight_bold;
    line-height: 30px;
  }

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }

    & .due-on {
      & .field-value {
        color: $danger_400;
      }
    }
  }
}

</style>