<template>
  <div class="add-funds-reserve-view">
    <DisputeHeader :header="$t('addFundsToReserve.title')" :add-funds-view="true" />
    <div class="stepper">
      <div class="nav">
        <div
          class="nav-item"
          :class="{'nav-item-active': currentTab === tabs.SELECT_BANK_ACCOUNT}"
          @click="() => validateAndSwitchTab(tabs.SELECT_BANK_ACCOUNT)"
          :id="getElementId('header-tab-' + tabs.SELECT_BANK_ACCOUNT)"
        >
          <c-icon name="check-circle" :size="18" v-if="tabValidations['SELECT_BANK_ACCOUNT'].valid" />
          <BulletIcon :step-bullet="true" v-else-if="currentTab === tabs.SELECT_BANK_ACCOUNT" />
          <BulletIcon v-else />
          <span>{{ $t('addFundsToReserve.tabs.selectBankAccount') }}</span>
        </div>

        <div
          class="nav-item"
          :class="{['nav-item-active']: currentTab === tabs.ENTER_AMOUNT}"
          @click="() => validateAndSwitchTab(tabs.ENTER_AMOUNT)"
          :id="getElementId('header-tab-' + tabs.ENTER_AMOUNT)"
        >
          <c-icon name="check-circle" :size="18" v-if="tabValidations['ENTER_AMOUNT'].valid" />
          <BulletIcon :step-bullet="true" v-else-if="currentTab === tabs.ENTER_AMOUNT" />
          <BulletIcon v-else />
          <span>{{ $t('addFundsToReserve.tabs.enterAmount') }}</span>
        </div>

        <div
          class="nav-item"
          :class="{['nav-item-active']: currentTab === tabs.REVIEW_AND_CONFIRM}"
          @click="() => validateAndSwitchTab(tabs.REVIEW_AND_CONFIRM)"
          :id="getElementId('header-tab-' + tabs.REVIEW_AND_CONFIRM)"
        >
          <c-icon name="check-circle" :size="18" v-if="isValidReviewAndAddFunds" />
          <BulletIcon :step-bullet="true" v-else-if="currentTab === tabs.REVIEW_AND_CONFIRM" />
          <BulletIcon v-else />
          <span>{{ $t('addFundsToReserve.tabs.reviewAndConfirm') }}</span>
        </div>
      </div>
    </div>
    <div class="add-funds-reserve-form">
      <div class="add-funds-reserve-form-content">
        <c-toggle>
          <SelectBankAccount
            :next="() => validateAndSwitchTab(tabs.ENTER_AMOUNT)"
            v-if="currentTab === tabs.SELECT_BANK_ACCOUNT && merchantPaymentInstruments !== null"
            :merchant-payment-instruments="merchantPaymentInstruments"
            @merchantPaymentInstrumentUpdated="merchantPaymentInstrumentUpdated"
          />
          <AddFundsToReserve
            :loading="loading"
            :calculate-fee-and-switch-tab="calculateACHFeeAndSwitchTab"
            v-if="currentTab === tabs.ENTER_AMOUNT"
          />
          <ReviewAndAddFunds
            :loading="loading"
            :switch-tab-on-edit="(value) => validateAndSwitchTab(value)"
            v-if="currentTab === tabs.REVIEW_AND_CONFIRM"
            :on-submit="initiateFundTransferTransaction"
          />
        </c-toggle>
      </div>
    </div>
    <div class="add-funds-reserve-footer">
      <div class="footer-text">
        {{ $t('addFundsToReserve.message.footerText') }}
      </div>
    </div>

    <ModalPopup class="add-funds-initiated-modal" :show="showAddFundsPopup">
      <div slot="body">
        <AddFundsInitiatedPopup
          :transaction-id="txnId"
          :on-dismiss="onDismissFromModal"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import DisputeHeader from "@/components/disputes/DisputeHeader.vue";
import SelectBankAccount from "@/components/deposits/SelectBankAccount.vue";
import AddFundsToReserve from "@/components/deposits/AddFundsToReserve.vue";
import ReviewAndAddFunds from "@/components/deposits/ReviewAndAddFunds.vue";
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import {mapActions, mapGetters} from "vuex";
import {TabOrderForAddFunds, TabForAddFunds, PendoPrefixes} from "@/app/utils/common/constants";
import BulletIcon from "@/components/icons/BulletIcon.vue";
import merchantBalanceAccount from "@/api/merchantBalanceAccount";
import AddFundsInitiatedPopup from "@/components/deposits/AddFundsInitiatedPopup.vue";
import ModalPopup from "@/components/common/ModalPopup.vue";
import paymentInstruments from "@/api/paymentInstruments";
import {getMerchantConfiguration} from "@/service/merchantService";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import {MerchantBalanceAccountType} from "@/api/paymentApi";
import {balanceAccountMixin} from "@/mixin/balanceAccountMixin";
export default {
  name: 'AddFundsToReserveView',
  components: {
    AddFundsInitiatedPopup,
    BulletIcon,
    ReviewAndAddFunds,
    AddFundsToReserve,
    SelectBankAccount,
    DisputeHeader,
    ModalPopup
  },
  mixins: [portalCheckMixin, balanceAccountMixin],
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('merchantBalanceReserve/resetState')
        .then(() => {
          next();
        })
        .catch(() => {
          next(false);
        });
  },
  data: function() {
    return {
      balanceAddedForRefundReserve: null,
      balanceAddedForChargebackReserve: null,
      tabs: {},
      tabsOrder: [],
      currentTab: TabForAddFunds.SELECT_BANK_ACCOUNT,
      isValidReviewAndAddFunds: false,
      loading: false,
      showAddFundsPopup: false,
      txnId: null,
      merchantPaymentInstruments: null
    }
  },
  computed: {
    ...mapGetters('app', ['isAdminMode', 'getActivePayfac', 'getMerchantId']),
    ...mapGetters('merchantBalanceReserve', [
        'getSelectedPaymentInstrumentForAchFundTransfer',
        'getAmountAddedForChargebackReserve',
        'getAmountAddedForRefundReserve',
        'isValidSelectBankAccount',
        'isValidEnterAmount'
    ]),
    isAdmin() {
      return String(this.$router.currentRoute.query.adminMode) === "true"
    },
    tabValidations() {
      return {
        [TabForAddFunds.SELECT_BANK_ACCOUNT]: {
          valid: this.isValidSelectBankAccount,
          generateError: this.generateErrorForSelectBankAccount,
        },
        [TabForAddFunds.ENTER_AMOUNT]: {
          valid: this.isValidEnterAmount,
          generateError: this.generateErrorForEnterAmount,
        }
      }
    },
  },
  mounted: function() {
    if (this.isAdminMode || this.getActivePayfac !== "ADYEN") {
      navigateTo(RouteConstants.DEPOSITS, false, this.$route.query);
      return ;
    }
    this.tabs = TabForAddFunds;
    this.tabsOrder = TabOrderForAddFunds;
    this.resetErrors();
    this.loadMerchantPaymentInstruments();
    this.getReserveBalance();
  },
  methods: {
    ...mapActions('merchantBalanceReserve', [
        'resetErrors',
        'resetState',
        'setPaymentInstrumentForAchFundTransfer',
        'updateBalanceReserveState'
    ]),
    ...mapActions('merchantBalanceReserve', {generateErrors: 'validateAndGenerateError'}),
    async loadMerchantPaymentInstruments() {
      try {
        this.merchantPaymentInstruments = await paymentInstruments.listMerchantPaymentInstruments(this.getMerchantId);
      } catch (error) {
        this.$danger(this.$t('bankAccountForPayouts.message.getMerchantPaymentInstrumentFailed'));
        navigateTo(RouteConstants.DEPOSITS, false, this.$route.query);
      }
    },
    switchTab(nextTab) {
      if(this.currentTab === nextTab) {
        return ;
      }
      this.currentTab = nextTab;
      window.scrollTo(0, 0);
    },
    validateAndSwitchTab(nextTab) {
      //Skipping validations on moving back to previous tab via stepper or edit
      if(TabOrderForAddFunds.indexOf(nextTab) < TabOrderForAddFunds.indexOf(this.currentTab)) {
        this.switchTab(nextTab);
        return;
      }
      const tabsToValidate = this.tabsOrder.slice(this.tabsOrder.indexOf(this.currentTab),
          this.tabsOrder.indexOf(nextTab));
      let firstInvalidTab = tabsToValidate.find(tab => !this.tabValidations[tab].valid);

      if (!firstInvalidTab) {
        this.switchTab(nextTab)
        return
      }
      this.tabValidations[firstInvalidTab].generateError().then(() => {
        this.switchTab(firstInvalidTab);
      })
    },
    generateErrorForSelectBankAccount() {
      return Promise.all([
        this.generateErrors("SELECT_BANK_ACCOUNT")
      ])
    },
    generateErrorForEnterAmount() {
      return Promise.all([
          this.generateErrors("ENTER_AMOUNT")
      ])
    },
    //Create a transaction for adding funds here
    async initiateFundTransferTransaction() {
      try {
       this.loading = true;
        const response = await merchantBalanceAccount.addFundToBalanceAccount(this.getMerchantId, {
         merchant_payment_instrument_id: this.getSelectedPaymentInstrumentForAchFundTransfer.id,
         split: this.getSplitRequest()
       });
        this.isValidReviewAndAddFunds = true;
        this.showAddFundsPopup = true;
        this.txnId = response.id;
      }catch(error) {
       this.$danger(this.$t('addFundsToReserve.actionMessage.failedToAddFunds') +  error?.source?.detail);
       this.loading = false;
        this.isValidReviewAndAddFunds = false;
      }
     this.loading = false;
    },
    async calculateACHFeeAndSwitchTab() {
      if (!this.tabValidations[TabForAddFunds.ENTER_AMOUNT].valid) {
        this.validateAndSwitchTab(TabForAddFunds.REVIEW_AND_CONFIRM)
        return;
      }
      try {
        this.loading = true;
        const response = await getMerchantConfiguration(this.getMerchantId)
        if(response?.ach_pricing) {
          let achFee = this.calculateACHFee(this.getTotalOfEnteredAmountToReserve(), response?.ach_pricing)
          await this.updateBalanceReserveState({key: 'achFeesForFundTransferForAdyen', value: achFee});
        }
      }catch(error) {
        this.$danger(this.$t('addFundsToReserve.message.achFeeCalculationError') +  error)
        this.loading = false;
      }
      this.loading = false;
      this.validateAndSwitchTab(TabForAddFunds.REVIEW_AND_CONFIRM)
    },
    getSplitRequest() {
      let splits = [];
      if(this.getAmountAddedForRefundReserve !== 0) {
        splits.push({
          balance_account_type: MerchantBalanceAccountType.REFUNDRESERVE,
          amount: this.getAmountAddedForRefundReserve
        });
      }
      if(this.getAmountAddedForChargebackReserve !== 0) {
        splits.push({
          balance_account_type: MerchantBalanceAccountType.CHARGEBACKRESERVE,
          amount: this.getAmountAddedForChargebackReserve
        });
      }
      return splits;
    },
    calculateACHFee(amount, achPricing) {
      const achFee = this.calculatePercentageValue(amount, achPricing.basis_points);
      if (achFee >= 1) {
        if (achFee < achPricing.min_fee) {
          return achPricing.min_fee;
        } else if (achFee > achPricing.max_fee) {
          return achPricing.max_fee;
        }
      }
      return achFee;
    },
    getTotalOfEnteredAmountToReserve() {
      return this.getAmountAddedForRefundReserve + this.getAmountAddedForChargebackReserve;
    },
    calculatePercentageValue(amount, percentage) {
      return Math.round((amount * (percentage / 100.0)) / 100);
    },
    onDismissFromModal() {
      this.showAddFundsPopup = false;
      navigateTo(RouteConstants.DEPOSITS, false, this.$route.query);

    },
    merchantPaymentInstrumentUpdated(response) {
      this.merchantPaymentInstruments = [...this.merchantPaymentInstruments, response];
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">

.add-funds-reserve-view {
  padding-bottom: 200px;

  & .stepper {
    position: fixed;
    z-index: 999;
    padding: 50px;
    margin-left: 0;
    margin-top: 70px;

    & .nav {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      font-size: 14px;
      font-weight: $weight_medium;

      & .nav-item {
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;

        & .c-icon {
          color: $primary_text
        }

        &:hover {
          color: $primary_text;
        }
      }

      & .nav-item-active {
        color: $primary_text;
      }
      
      & .nav-item-active-disabled:hover {
        background-color: $color-white;
        cursor: not-allowed;
      }
    }

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .add-funds-reserve-form {
    position: relative;
    width: 700px;
    padding: 50px;
    margin: auto;

    & .add-funds-reserve-form-content {
      width: 100%;
      margin-top: 70px;
    }

    @media screen and (max-width: 1053px) {
      left: 216px;
      width: calc(100% - 216px);
      margin-left: 0;
    }

    @media screen and (max-width: 600px) {
      left: 0;
      margin-left: 0;
    }
  }

  & .add-funds-initiated-modal {
    & .modal-container {
      max-width: 500px;

      & .footer {
        padding: 0;
      }
    }
  }

  & .add-funds-reserve-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 20px;
    background: #F4F5F7;

    & .footer-text {
      font-size: 12px;
      font-weight: $weight-normal;
      line-height: 20px;
      margin: auto;
      max-width: 700px;
      text-align: left;
    }

  }
}
</style>