<template>
  <div class="notification-todo">
    <div class="content">
      <span class="icon">
        <DepositIcon />
      </span>
      <span>
        <div class="id-title">
          <div class="title">{{ title }}</div>
          <c-tooltip placement="top" v-if="showUnreadMarker" :content="$t('notifications.markAsRead')" variant="dark">
            <div class="read-marker" @click="handleMarkAsRead">
              <svg class="dot" viewBox="0 0 10 10">
                <circle cx="5" cy="5" r="4" />
              </svg>
            </div>
          </c-tooltip>
        </div>

        <div class="description">
          {{ description }}
          <div class="id">
            <div class="id-key"> {{ $t('notifications.id') }} </div>
          </div>
          <div class="id">
            <div class="id_tag">{{ audit.resource_id }}</div>
          </div>
        </div>
        <div class="links" v-if="currentTab === 'TODO'">
          <div class="clickable" @click="() => { navigateToAddFunds(); handleMarkAsRead(); }" :id="getElementId('todo-add_funds')">
            <!--            need to update pendo document-->
            {{ $t('notifications.addFunds') }}
          </div>
        </div>
      </span>
    </div>
    <div class="relative-date-container">
      {{ relativeDate(audit.created_at) }}
    </div>
  </div>
</template>


<script>
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import {AuditResponseDto} from "@/api/paymentApi";
import {notificationMixin} from "@/mixin/notificationMixin";
import DepositIcon from "@/components/icons/DepositIcon.vue";

export default {
  name: "MerchantBalanceAccountNotif",
  components: {DepositIcon},
  mixins: [notificationMixin],
  props: {
    audit: {
      type: AuditResponseDto,
      default: null,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    hideAudit: {
      type: Function,
      required: true
    },
  },
  methods: {
    ...notificationMixin.methods,
    navigateToAddFunds() {
      navigateTo(RouteConstants.ADD_FUNDS, true, {...this.$route.query});
    },
  }
};
</script>

<style lang="scss">
@import 'notification-common-style.scss';
</style>