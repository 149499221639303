<template>
  <img class="card-icon" src="@/app/assets/images/card-brand/UATP.png" alt="lanka pay" />
</template>
<script>
export default {
  name: 'UATPIcon',
}
</script>
<style lang="scss">
.card-icon {
  width: 20px;
  height: 20px;
}
</style>