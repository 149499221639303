<template>
  <div id="error-disp">
    <div id="error-body">
      <div id="error-card">
        <h1>Chargebee Payments is open in multiple tabs</h1>
        <p id="error_page_reason">
          Looks like you have Chargebee Payments open in more than one tab.
        </p>
        <div id="error-button">
          <c-button @click="openRedirectUrl" variant="primary" size="large">
            {{ $t('applicationSuccess.goBack') }}
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {cbAppUrl, openUrlInSameTab} from "@/app/utils/common/functions/url-helper";

export default {
  name: 'DuplicateTab',
  methods: {
    openRedirectUrl() {
      openUrlInSameTab(cbAppUrl());
    }
  }
};
</script>
<style lang="scss">
#error-disp {
  font-size: 13px !important;
  background-color: #eaeaf4;
  line-height: 1.5;
  margin: 0;
  height: 100%;
}
#error-body {
  padding-bottom: 20px;
  margin: 0 auto;
  width: 750px;
  float: none;
  padding-top: 5%;
  position: relative;
}

#error-card {
  font-size: 14px;
  max-width: 600px;
  background-color: white;
  border: 1px solid #cdcdcd;
  border-radius: 8px;
  padding: 42px 30px !important;
  text-align: center;
  margin: auto;
  color: #202022;
}

#error-card > h1 {
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 12px;
  line-height: 1.2;
}

p:not(:last-child) {
  margin-bottom: 12px;
}

#error-button {
  padding-top: 5%;
}
</style>