<template>
  <div class="notification-table-container" :class="{'notification-table-size': shouldRenderFullLengthTable || loading}">
    <div id="infinite-list">
      <div v-if="visibleAudits.length !== 0" class="card-container">
        <div class="notification-row" v-for="audit in visibleAudits" :key="audit.id">
          <div class="header-container" v-if="audit.shouldShowDividerHeader">
            <div class="transaction-header-count">
              <div class="showing-count-top">
                <span class="count">{{ audit.dividerHeading }}</span>
              </div>
            </div>
          </div>
          <div class="card">
            <component :is="getNotificationType(audit)" :audit="audit" :current-tab="currentTab" :hide-audit="hideAudit" />
          </div>
        </div>
        <!-- Loader screen while fetching results -->
        <div v-if="loading">
          <div class="card" v-for="index in notificationLoaderContainerLength" :key="index">
            <NotificationLoader />
          </div>
        </div>

        <div v-if="allItemsLoaded" class="end-of-table">
          {{ $t('notifications.endOfData', {tabName: getTabName, days: getDays}) }}
        </div>
      </div>

      <!-- When no data is present at all for to-do/events list -->
      <div class="empty-container" v-if="!filterApplied && !loading && visibleAudits.length === 0">
        <EmptyPlaceholder
          :heading-tag="emptyListTitle"
          :description-tag="emptyListDesc"
          :clear-filter-flag="false"
        >
          <div slot="icon">
            <NoResultsIcon class="empty-placeholder-notifications-icon" />
          </div>
        </EmptyPlaceholder>
      </div>

      <!-- When data is present but on applying a filter, no data found -->
      <div class="empty-container" v-if="filterApplied && !loading && visibleAudits.length === 0">
        <EmptyPlaceholder
          heading-tag="emptyPlaceHolder.noPaymentsFilterData"
          description-tag="notifications.noTodoFoundDesc"
          :clear-filter-flag="false"
        >
          <div slot="icon">
            <NoResultsIcon class="empty-placeholder-downloads-icon" />
          </div>
        </EmptyPlaceholder>
      </div>

      <div v-if="loading && visibleAudits.length === 0">
        <div class="card-container">
          <div class="notification-row">
            <div class="card" v-for="index in notificationLoaderContainerLength" :key="index">
              <NotificationLoader />
            </div>
          </div>
        </div>
        <div class="loader-container">
          <div
            class="loader"
            :class="{'hide-loader': !loading}"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NoResultsIcon from "@/components/icons/NoResultsIcon.vue";
import NotificationLoader from "@/components/notifications/NotificationLoader.vue";
import EmptyPlaceholder from "@/components/transactions/EmptyPlaceholder.vue";
import MerchantBalanceAccountNotif from "@/components/notifications/notificationTypes/MerchantBalanceAccountNotif.vue";
import ComplianceFormNotif from "@/components/notifications/notificationTypes/ComplianceFormNotif.vue";
import DepositNotif from "@/components/notifications/notificationTypes/DepositNotif.vue";
import DisputeNotif from "@/components/notifications/notificationTypes/DisputeNotif.vue";
import FundsAddedNotif from "@/components/notifications/notificationTypes/FundsAddedNotif.vue";

const MAX_ALLOWED_ITEMS_IN_TABLE_VIEW = 5;

export default {
  name: "NotificationTable",
  components: {EmptyPlaceholder, NotificationLoader, NoResultsIcon},
  props: {
    visibleAudits: {
      type: Array,
      required: true,
    },
    hideAudit: {
      type: Function,
      required: true
    },
    currentTab: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    filterApplied: {
      type: Boolean,
      required: true,
    },
    allItemsLoaded: {
      type: Boolean,
      required: true,
    }
  },
  data: () => {
    return {
      notificationLoaderContainerLength: 5,
    }
  },
  computed: {
    getTabName() {
      return this.currentTab === "TODO" ? "Tasks" : "Events";
    },
    getDays() {
      return this.currentTab === "TODO" ? 60 : 30;
    },
    emptyListTitle() {
      return this.currentTab === "TODO" ? "notifications.noTodoTitle" : "notifications.noEventsTitle";
    },
    emptyListDesc() {
      return this.currentTab === "TODO" ? "notifications.noTodoDesc" : "notifications.noEventsDesc";
    },
    shouldRenderFullLengthTable() {
      return this.visibleAudits.length > MAX_ALLOWED_ITEMS_IN_TABLE_VIEW
    },
  },
  methods: {
    getNotificationType(audit) {
      switch (audit.resource_type) {
        case "DISPUTE":
          return DisputeNotif;
        case "DEPOSIT":
        case "SETTLEMENT":
          return DepositNotif;
        case "COMPLIANCE_FORM":
          return ComplianceFormNotif;
        case "MERCHANT_BALANCE_ACCOUNT":
          return MerchantBalanceAccountNotif;
        case "TRANSACTION": {
          return FundsAddedNotif;
        }
      }
    },
    amount: function (audit) {
      return audit.event === "SETTLEMENT_APPROVED" ? JSON.parse(audit.content).net_amount : JSON.parse(audit.content).amount;
    },
    resourceId : function (audit ) {
      return audit.event === "DEPOSIT_FAILED" || audit.event === "DEPOSIT_SUCCEEDED" ?
          JSON.parse(audit.content).settlement_id : audit.resource_id;
    },
  },
}
</script>


<style lang="scss">
.notification-table-container {
  padding: 20px;
  border: none;
  background: white;
  border-radius: 6px;
  height: fit-content;

  & #infinite-list {
    overflow: scroll;
    height: 100%;

    & .empty-container {
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      height: 500px;

      & .empty-placeholder-notifications-icon {
        display: flex;
        width: 40px;
        height: 40px;
      }

      & .empty-placeholder-downloads-icon {
        display: flex;
        width: 40px;
        height: 40px;
      }
    }

    & .loading-shimmer {
      animation: shimmer 2s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
      border-radius: 10px;
    }

    & .shimmer-row {
      text-align: left;
      height: 50px;
    }

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }

    @-webkit-keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  }

  & .card-container {
    background-color: #FFFFFF;
    border-radius: 6px;
    height: 100%;

    & .header-container {
      background-color: $color_white;
      width: 100%;
      height: fit-content;
      border: none;
      border-radius: 6px 6px 0px 0px ;
      display: flex;
      flex-direction: row;
      justify-content: right;

      & .transaction-header-count{
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
        color: $neutral_800;
        width: 100%;
        text-align: left;
        align-self: center;
        padding-bottom: 10px;
        padding-top: 32px;
        display: flex;

        & .showing-count-top {
          display: inline-block;
          justify-content: left;

          & .count {
            color: #6A7787;
            font-weight: $weight_bold;
          }
        }
      }
    }

    & .notification-row:nth-child(1) {
      & .transaction-header-count {
        padding-top: 0px;
      }
    }

    & .card {
      border: 1px solid $neutral_100;
      margin: 10px 0px 10px 0px;
      border-radius: 6px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & .end-of-table {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      margin-top: 40px;
      line-height: 18px;
      text-align: center;
      color: #4E5762;
    }
  }

  & .loader-container {
    background-color: $color_white;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 0px 0px 6px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    & .showing-count-bottom {
      display: inline-block;
      justify-content: left;
    }

    & .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border: 3px solid #D4D4E8;
      border-radius: 50%;
      border-top-color: #fff;
      animation: spin 1s ease-in-out infinite;
      justify-content: center;
    }
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
    .hide-loader {
      display: none;
      visibility: hidden;
    }
  }
}

.notification-table-size {
  height: 100vh;
}
</style>