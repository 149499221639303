<template>
  <div>
    <div class="progression-container">
      <div class="progress">
        <div class="bold">
          {{ $t('status.description.' + status) }}
        </div>

        <div class="progress-bar">
          <template v-for="section in sections">
            <span :key="section.name" v-if="section.type === 'step'" class="progress-step">
              <ProgressionCircle :type="getProgressionStatus(section.name)" />
            </span>
            <span
              :key="section.name" :class="`${getProgressionStatus(section.name)} progress-line`"
              v-if="section.type === 'intermediate'"
            >
            </span>
          </template>
        </div>

        <div class="progress-description">
          <div class="progress-description-item" v-for="desc in sectionsDescription" :key="desc.label">
            <div class="progress-label">
              {{ desc.label }}
              <img src="../../app/assets/images/orange-exclamation.svg" v-if="desc.showWarning" alt="Chargebee Warning Icon" />
              <img src="../../app/assets/images/red-exclamation.svg" v-if="desc.showError" alt="Chargebee Error Icon" />
            </div>
            <div class="progress-instruction">
              {{ desc.instruction }}
            </div>
          </div>
        </div>
      </div>
      <div class="graphic">
        <img src="@/app/assets/images/onboarding-status-graphic.svg" alt="Chargebee Status Icon" />
      </div>
    </div>
    <div class="resume-application" v-if="status === 'DRAFT'">
      <c-button variant="primary" @click="resumeApplication">
        {{ $t('status.resumeApplication') }}
      </c-button>
    </div>
    <RejectionAction :failure-codes="failureCodes" v-if="failureCodes.length > 0 && status === 'REJECTED'" />
  </div>
</template>
<script>
import ProgressionCircle from "@/components/onboarding/ProgressionCircle";
import {OnboardingStatus} from "@/app/utils/common/constants";
import RejectionAction from "@/components/onboarding/RejectionAction";
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

const ProgressionStatus = {
  inProgress: "in-progress",
  complete: "complete",
  notInitiated: "not-initiated",
  error: "error",
  hidden: "hidden",
}

const Sections = {
  CONFIGURE: "configure",
  CONFIGURE_TO_REVIEW: "configureToReview",
  REVIEW: "review",
  REVIEW_TO_GO_LIVE: "reviewToGoLive",
  GO_LIVE: "goLive",
  REJECTED: "rejected",
}

const SectionType = {
  STEP: "step",
  INTERMEDIATE: "intermediate"
}

const statusMatrixConfig = {
  [Sections.CONFIGURE]: {
    DRAFT: ProgressionStatus.inProgress,
    PROVISIONING: ProgressionStatus.complete,
    REJECTED_WITH_DECLINE: ProgressionStatus.complete,
    REJECTED: ProgressionStatus.complete,
    REJECTED_BUT_FIXED: ProgressionStatus.complete,
    APPROVED: ProgressionStatus.complete,
  },
  [Sections.CONFIGURE_TO_REVIEW]: {
    DRAFT: ProgressionStatus.notInitiated,
    PROVISIONING: ProgressionStatus.complete,
    REJECTED_WITH_DECLINE: ProgressionStatus.complete,
    REJECTED: ProgressionStatus.complete,
    REJECTED_BUT_FIXED: ProgressionStatus.complete,
    APPROVED: ProgressionStatus.complete,
  },
  [Sections.REVIEW]: {
    DRAFT: ProgressionStatus.notInitiated,
    PROVISIONING: ProgressionStatus.inProgress,
    REJECTED_WITH_DECLINE: ProgressionStatus.complete,
    REJECTED: ProgressionStatus.inProgress,
    REJECTED_BUT_FIXED: ProgressionStatus.inProgress,
    APPROVED: ProgressionStatus.complete,
  },
  [Sections.REVIEW_TO_GO_LIVE]: {
    DRAFT: ProgressionStatus.notInitiated,
    PROVISIONING: ProgressionStatus.notInitiated,
    REJECTED_WITH_DECLINE: ProgressionStatus.error,
    REJECTED: ProgressionStatus.notInitiated,
    REJECTED_BUT_FIXED: ProgressionStatus.notInitiated,
    APPROVED: ProgressionStatus.complete,
  },
  [Sections.GO_LIVE]: {
    DRAFT: ProgressionStatus.notInitiated,
    PROVISIONING: ProgressionStatus.notInitiated,
    REJECTED_WITH_DECLINE: ProgressionStatus.hidden,
    REJECTED: ProgressionStatus.notInitiated,
    REJECTED_BUT_FIXED: ProgressionStatus.notInitiated,
    APPROVED: ProgressionStatus.complete,
  },
  [Sections.REJECTED]: {
    DRAFT: ProgressionStatus.hidden,
    PROVISIONING: ProgressionStatus.hidden,
    REJECTED_WITH_DECLINE: ProgressionStatus.error,
    REJECTED: ProgressionStatus.hidden,
    REJECTED_BUT_FIXED: ProgressionStatus.hidden,
    APPROVED: ProgressionStatus.hidden,
  },
}

export default {
  name: "StatusProgression",
  components: {RejectionAction, ProgressionCircle},
  props: {
    status: {
      type: String,
      required: true
    },
    failureCodes: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    sections: [
      {name: Sections.CONFIGURE, type: SectionType.STEP},
      {name: Sections.CONFIGURE_TO_REVIEW, type: SectionType.INTERMEDIATE},
      {name: Sections.REVIEW, type: SectionType.STEP},
      {name: Sections.REVIEW_TO_GO_LIVE, type: SectionType.INTERMEDIATE},
      {name: Sections.GO_LIVE, type: SectionType.STEP},
      {name: Sections.REJECTED, type: SectionType.STEP},
    ],
  }),
  computed: {
    sectionsDescription() {
      return [
        {
          label: this.$t('status.configureLabel'),
          instruction: this.$t('status.configureInstruction'),
        },
        {
          label: this.$t('status.reviewLabel'),
          instruction: this.$t('status.reviewInstruction'),
          showWarning: this.status === OnboardingStatus.REJECTED
        },
        {
          label: this.$t('status.goLiveLabel'),
          instruction: this.$t('status.goLiveInstruction'),
          showError: this.status === OnboardingStatus.REJECTED_WITH_DECLINE
        }
      ];
    }
  },
  methods: {
    getProgressionStatus(section) {
      return statusMatrixConfig[section][this.status]
    },
    resumeApplication() {
      navigateTo(RouteConstants.ONBOARDING)
    }
  }
}
</script>

<style lang="scss">

.resume-application {
  display: flex;
  justify-content: start;
  padding: 32px;
}

.progression-container {
  max-width: 1000px;
  padding: 24px 32px;
  padding-right: 54px;
  display: flex;
  justify-content: space-between;

  & .progress-bar {
    display: flex;
    align-items: center;
    margin-top: 32px;

    & .progress-line {
      min-width: 200px;
    }

    & .in-progress {
      border: 2px dashed $divider_color;
    }

    & .not-initiated {
      border: 2px dashed $divider_color;
    }

    & .complete {
      border: 2px solid $primary_text;
    }

    & .error {
      border: 2px solid $color_red;
    }
  }


  & .progress-description {
    display: flex;


    & .progress-description-item {
      margin-top: 24px;
      width: 232px;

      & .progress-instruction {
        width: 200px;
      }

      & .progress-label {
        display: flex;
        font-weight: $weight_bold;
        margin-bottom: 10px;

        & img {
          margin-left: 10px;
        }
      }
    }
  }

  & .progress {
    text-align: left;
  }

  & .bold {
    font-weight: $weight-bolder;
  }
}
</style>