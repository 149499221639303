<template>
  <div
    v-if="complianceForm != null"
    class="status"
    :class="{
      'incomplete-status': complianceForm.compliance_form_status === 'INCOMPLETE',
      'complete-status': complianceForm.compliance_form_status === 'COMPLETE',
      'overdue-status': complianceForm.compliance_form_status === 'OVERDUE',
      'invalid-status': complianceForm.compliance_form_status === 'INVALID',
      'expired-status': complianceForm.compliance_form_status === 'EXPIRED',
      'progress-status': inProgressStatus
    }"
  >
    {{ getComplianceFormStatus }}
  </div>
</template>

<script>

import { ComplianceFormResponseDto } from "@/api/paymentApi";

export default {
  name: 'ComplianceFormStatusTag',
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true,
    }
  },
  computed: {
    inProgressStatus() {
      return this.complianceForm.compliance_form_status === 'INCOMPLETE' &&
          this.complianceForm.attestation_details.is_accepted
    },
    getComplianceFormStatus() {
      if(this.inProgressStatus) {
        return "Under Review";
      }
      return this.complianceForm.compliance_form_status
          .toLowerCase()
          .replace(/\b\w/g, (match) => match.toUpperCase());
    },
  },

}
</script>

<style lang="scss">
.status {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  height: fit-content;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;

  &.complete-status {
    background-color: $success_50;
    color: $success_800;
  }

  &.incomplete-status {
    background-color: $warn_50;
    color: $warn_800;
  }

  &.overdue-status {
    background-color: $yellow_100;
    color: $yellow_800;
  }

  &.invalid-status {
    background-color: $red_100;
    color: $red_800;
  }

  &.expired-status {
    background-color: $gray_100;
    color: $gray_800;
  }

  &.progress-status {
    background-color: $warn_50;
    color: $warn_800;
  }

}
</style>