<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <mask
      id="mask0_7411_32629" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
      width="16" height="16"
    >
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_7411_32629)">
      <path d="M9.66667 12.0007C9.2 12.0007 8.80556 11.8395 8.48333 11.5173C8.16111 11.1951 8 10.8007 8 10.334C8 9.86732 8.16111 9.47287 8.48333 9.15065C8.80556 8.82843 9.2 8.66732 9.66667 8.66732C10.1333 8.66732 10.5278 8.82843 10.85 9.15065C11.1722 9.47287 11.3333 9.86732 11.3333 10.334C11.3333 10.8007 11.1722 11.1951 10.85 11.5173C10.5278 11.8395 10.1333 12.0007 9.66667 12.0007ZM3.33333 14.6673C2.96667 14.6673 2.65267 14.5369 2.39133 14.276C2.13044 14.0147 2 13.7007 2 13.334V4.00065C2 3.63398 2.13044 3.32021 2.39133 3.05932C2.65267 2.79798 2.96667 2.66732 3.33333 2.66732H4V1.33398H5.33333V2.66732H10.6667V1.33398H12V2.66732H12.6667C13.0333 2.66732 13.3473 2.79798 13.6087 3.05932C13.8696 3.32021 14 3.63398 14 4.00065V13.334C14 13.7007 13.8696 14.0147 13.6087 14.276C13.3473 14.5369 13.0333 14.6673 12.6667 14.6673H3.33333ZM3.33333 13.334H12.6667V6.66732H3.33333V13.334ZM3.33333 5.33398H12.6667V4.00065H3.33333V5.33398Z" fill="#2D6A8A" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EventIcon'
}
</script>