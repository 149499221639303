<template>
  <div class="transaction-refunds">
    <div class="header">
      <div class="title">
        Refunds
      </div>
    </div>

    <div class="refunds">
      <table aria-describedby="Show refunds of a transaction" v-if="refunds.length !== 0">
        <tr class="table-header">
          <th>ID</th>
          <th>Created On</th>
          <th>Status</th>
          <th>Amount</th>
        </tr>
        <tbody>
          <tr v-for="refund in refunds" :key="refund.transaction_id">
            <td class="refund-id" @click="() => openRefund(refund)">
              {{ refund.transaction_id }}
            </td>
            <td>{{ formatDateWithTimezone(refund.created_at) }}</td>
            <td><TransactionStatusTag :transaction="getRefundAsTransaction(refund)" /></td>
            <td>{{ format('currency', refund.amount, '', refund.currency) + ' ' + refund.currency }}</td>
          </tr>
        </tbody>
      </table>

      <div class="no-refunds" v-else>
        {{ $t('refunds.noRefundsAvailable') }}
      </div>
    </div>

    <ModalPopup :show="openModel">
      <div slot="body">
        <CreateRefund
          :refunds="refunds"
          :transaction="transaction"
          :on-dismiss="() => {openModel = false}"
          @refundCreated="refundCreated"
        />
      </div>
    </ModalPopup>
  </div>
</template>


<script>
import Formatter from "@/app/utils/common/functions/formatter";
import ModalPopup from "@/components/common/ModalPopup";
import CreateRefund from "@/components/transactions/CreateRefund";
import {navigateWithParam} from "@/router";
import {mapRefundToTransaction} from "@/service/transactionService";
import TransactionStatusTag from "@/components/transactions/TransactionStatusTag";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "TransactionRefunds",
  components: {TransactionStatusTag, CreateRefund, ModalPopup},
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    refunds: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      showMore: false,
      openModel: false,
    }
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    createRefund() {
      this.openModel = true;
    },
    refundCreated() {
      this.$emit("refundCreated")
    },
    openRefund(refund) {
      let transaction = mapRefundToTransaction(refund);
      navigateWithParam(RouteConstants.TRANSACTION_DETAILS,
          {transactionId: transaction.id, data: transaction},
          {
            ...this.$route.query,
            consumerId: refund.consumer.id,
          })
    },
    getRefundAsTransaction(refund) {
      return mapRefundToTransaction(refund)
    }
  },
}
</script>

<style lang="scss">
.transaction-refunds {

  & .modal-container {
    max-width: 600px;
  }

  & .no-refunds {
    color: $neutral_400;
    text-align: left;
    margin-top: 20px;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      text-align: left;
      font-size: 16px;
      font-weight: $weight-bold;
    }

    & .create-refund {
      padding: 12px;
      border-radius: .3rem;
      border: solid 1px $primary_200;
      text-align: center;
      font-weight: bold;
      color: $primary_600;
      width: fit-content;
      position: relative;
      cursor: pointer;
    }
  }

  & .refunds {
    overflow: scroll;
  }

  & .view-more-button {
    margin-top: 20px;
    color: $primary_600;
    cursor: pointer;
    text-align: left;
  }

  & table {
    margin-top: 12px;
    text-align: left;
    border-collapse: collapse;
    width: 100%;

    & .refund-id {
      color: $primary_600;
      cursor: pointer;
      font-weight: $weight-medium;
    }

    & .table-header {
      height: 40px;
    }

    & tr {
      height: 60px;
      border-bottom: 1pt solid $neutral_200;
    }

    & th {
      font-size: 12px;
      font-weight: $weight-bold;
      color: $neutral_400;
      min-width: 100px;
    }

    & td {
      font-size: 14px;
      padding-right: 20px;
    }
  }


}
</style>