<template>
  <div class="terms-container">
    <div class="header">
      <div class="title-container">
        <div class="title">
          {{ $t('termsAndCharges.title') }}
        </div>
        <div class="subtitle">
          {{ $t('termsAndCharges.subtitle') }}
        </div>
      </div>
    </div>
    <component
      :is="getPayfacComponent()"
      :on-cancel="onCancel"
      :loading="loading"
      :onboard-merchant="onboardMerchant"
      :close-popup="closePopup"
    />
  </div>
</template>
<script>


import {submitOnboardingMerchant} from "@/service/merchantService";
import {mapActions, mapGetters} from "vuex";
import {navigateTo} from "@/router";
import {callbackApplicationAjax} from "@/app/utils/common/functions/functions";
import {storeErrorDetails} from "@/service/saveInStore";
import FullStoryHelper from '@/app/utils/fullstory';
import {
  MERCHANT_ONBOARD_SUBMIT,
  MERCHANT_CREATED_SUCCESS,
  MERCHANT_CREATED_FAILED,
  MERCHANT_MIGRATED_FAILED,
  MERCHANT_MIGRATED_INITIATED,
  MERCHANT_MIGRATED_SUBMIT
} from "@/app/utils/fullstory/constants";
import FinixTermsAndConditions from "@/components/onboarding/termsAndConditions/FinixTermsAndConditions";
import AdyenTermsAndConditions from "@/components/onboarding/termsAndConditions/AdyenTermsAndConditions";
import {RouteConstants} from "@/router/routeConstants";
import {TermsAndConditions} from "@/app/utils/common/constants";
import {PayfacType} from "@/api/paymentApi";

export default {
  name: "TermsAndConditions",
  components: {AdyenTermsAndConditions, FinixTermsAndConditions},
  props: {
    onCancel: {
      type: Function,
      required: true
    },
    isMigrating: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('app', ['getCallbackUrl', 'getDraftMerchantId', "getSessionPayfac"])
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    ...mapActions('agreementAcceptance', ['acceptAgreement']),
    ...mapActions('merchantPayfacDetails', ['setOnboardingStatus']),
    getPayfacComponent() {
      return this.getSessionPayfac === PayfacType.FINIX ? TermsAndConditions.FINIX : TermsAndConditions.ADYEN;
    },
    onboardMerchant() {
      this.loading = true;
      FullStoryHelper.event(this.isMigrating ? MERCHANT_MIGRATED_SUBMIT : MERCHANT_ONBOARD_SUBMIT)
      submitOnboardingMerchant().then(response => {
        this.loading = false;
        this.setOnboardingStatus({"payfac": this.getSessionPayfac, "provisioningStatus": "PROVISIONING"});
        FullStoryHelper.event(this.isMigrating ? MERCHANT_MIGRATED_INITIATED : MERCHANT_CREATED_SUCCESS)
        if (this.isMigrating) {
          navigateTo(RouteConstants.DASHBOARD, false, {})
          return;
        }
        this.updateAppState({key: 'merchantId', value: response.id});
        callbackApplicationAjax(this.getCallbackUrl, response.id, this.getDraftMerchantId);
        navigateTo(RouteConstants.ONBOARDING_SUCCESS);
      }).catch(err => {
        this.loading = false;
        storeErrorDetails(err);
        let errorMsg = err.source?.detail;
        if (err.source?.sub_errors) {
          errorMsg = err.source.sub_errors[0]?.message;
        } else {
          if (errorMsg) {
            const errorIndex = errorMsg.indexOf("Error");
            if (errorIndex !== -1) {
              errorMsg = errorMsg.substring(errorIndex);
            }
          }
        }
        this.$danger(this.$t('errors.submitOnboardingFailed') + " - " + errorMsg,
            {dismissable: true, timeout: 20000});
        this.closePopup();
        FullStoryHelper.event(this.isMigrating ? MERCHANT_MIGRATED_FAILED : MERCHANT_CREATED_FAILED )
      })
    },
    closePopup() {
      this.acceptAgreement(false);
      this.onCancel();
    }
  }
}
</script>

<style lang="scss">


.terms-container {
  padding-right: 40px;
  padding-left: 40px;
  color: $subtitle-text;
  font-size: 14px;

  & .bold {
    font-weight: $weight-bolder;
    color: $text-color;
    margin-right: 10px;
  }

  & .header {
    display: flex;
    justify-content: start;
    position: sticky;
    color: $text-color;
    top: 0;
    padding-top: 30px;
    padding-bottom: 20px;
    background-color: $color-white;
    border-bottom: 1px solid #D1D5DB;
    z-index: 99;

    & .title-container {
      text-align: left;

      & .title {
        font-size: 24px;
        font-weight: $weight_bold;
        color: $text-color;
        margin-bottom: 8px;
      }

      & .subtitle {
        font-size: 12px;
        color: $subtitle_text;
      }
    }
  }

}


</style>