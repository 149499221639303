<template>
  <div
    v-if="transaction !== null"
    class="transaction-status"
    :class="{
      'success-status': ['SUCCESS', 'REFUNDED'].includes(transaction.state),
      'returned-status': transaction.state === 'RETURNED',
      'failure-status': ['FAILED', 'NOT_REFUNDED'].includes(transaction.state),
      'canceled-status': transaction.state === 'CANCELED',
      'progress-status':
        !['SUCCESS', 'FAILED', 'CANCELED', 'NOT_REFUNDED', 'REFUNDED', 'RETURNED'].includes(transaction.state)
    }"
  >
    {{ $t('transactions.status.' + transaction.state) }}
  </div>
</template>

<script>
export default {
  name: "TransactionStatusTag",
  props: {
    transaction: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.transaction-status {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  height: fit-content;
  max-width: fit-content;

  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.success-status {
    background-color: $success_100;
    color: $success_600;
  }

  &.returned-status {
    background-color: $danger_50;
    color: $danger_800;
  }

  &.failure-status {
    background-color: $danger_50;
    color: $danger_800;
  }

  &.canceled-status {
    background-color: $warn_50;
    color: $warn_800;
  }

  &.progress-status {
    background-color: $primary_100;
    color: $primary_800;
  }
}



</style>