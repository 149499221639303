const locales = {
    en: {
        footer: {
            helpDocumentation: "Help Documentation",
            support: "Support",
            legalPrivacyPolicy: "Legal & Privacy Policy"
        },
        tabs: {
            businessProfile: "Business Profile",
            operations: "Operations and Ownership",
            settlements: "Transactions and Settlements"
        },
        dismissConfirm: {
            title: "Are you sure you want to leave this page?",
            description: "You will lose all unsaved changes."
        },
        clearTodos : {
            title : "Clear your to-do list?",
            description : "All tasks in your list will be cleared."
        },
        applicationSuccess: {
            title: "Application submitted successfully",
            description: "Your application is under review. Check back often to see if its status has changed.",
            helpAndSupport: "Help and Support",
            contact: "If you have questions about your application, look through our {faq} or write to {email}.",
            email: "support@chargebee.com",
            faq: "FAQ",
            goBack: "Go Back To Chargebee"
        },
        rejectionAction: {
            title: {
                INVALID_OWNER_DATA: "Principal owner verification failed",
                INVALID_BANK_ACCOUNT_NUMBER: "The bank account number is incorrect",
                INVALID_BANK_ROUTING_NUMBER: "The routing number is incorrect.",
                INVALID_BUSINESS_TYPE: "Your account's business type needs review",
                INVALID_BUSINESS_INFORMATION: "Business information could not be verified.",
                INVALID_TAX_ID_NUMBER: "Business Tax ID Number could not be verified.",
                INVALID_MCC: "This Merchant Category Code (MCC) is invalid.",
                PROHIBITED_MCC: "This Merchant Category Code (MCC) is prohibited.",
                PO_BOX_ADDRESS_NOT_SUPPORTED: "This Merchant Category Code (MCC) is invalid.",
                ID_VERIFICATION_NEEDED: "ID verification needed for {name}",
                ID_VERIFICATION_FAILED: "ID verification failed for {name}",
                BANK_VERIFICATION_NEEDED: "Additional documents are required to complete bank verification",
                BANK_VERIFICATION_FAILED: "Previous bank documents were not clear to complete the verification.",
                BANK_NOT_SUPPORTED: "Bank not supported",
                INVALID_BANK_ACCOUNT_OWNERSHIP: "Owner of the bank account does not match the business or owner(s) provided.",
                TAX_ID_VERIFICATION_NEEDED: "EIN or Tax ID requires verification.",
                TAX_ID_VERIFICATION_FAILED: "EIN or Tax ID requires verification.",
                INSUFFICIENT_BUSINESS_HISTORY: "Business does not have sufficient history. EIN or Tax ID documentation could not be verified",

                '1_100': "No remediation possible.",
                '1_101': "Contact Support.",
                '1_102': "Upload a different document.",
                '1_300': "Update individual details for {name}.",
                '1_301': "Upload an ID document for {name}.",
                '1_302': "Upload a different ID document for {name}.",
                '1_303': "Upload a different image of the ID document for {name}.",
                '1_304': "Upload proof of residency for {name}.",
                '1_305': "Upload a different proof of residency for {name}.",
                '1_306': "Upload a different image of the proof of residency for {name}.",
                '1_307': "Upload a proof of national ID number for {name}.",
                '1_308': "Upload a different proof of national ID number for {name}.",
                '1_309': "Upload a different image of the proof of national ID number for {name}.",
                '1_310': "Update the name for {name}.",
                '1_311': "Update the name for {name}.",
                '1_312': "Update the residency street for {name}.",
                '1_313': "Upload a different image of the proof of the individual tax ID for {name}.",
                '1_314': "Upload a proof of the individual tax ID for {name}.",
                '1_315': "Upload a different proof of the individual tax ID for {name}.",
                '1_316': "Update the national Id number to a 9 digit SSN for {name}.",
                '1_500': "Update organization details.",
                '1_501': "Upload a registration document.",
                '1_502': "Upload a different registration document.",
                '1_503': "Upload a proofOfOrganizationTaxInfo.",
                '1_504': "Upload a different proofOfOrganizationTaxInfo.",
                '1_505': "Upload a proof of address.",
                '1_506': "Upload a different proof of address.",
                '1_507': "Update organization ownership details.",
                '1_508': "Upload a proof of ownership.",
                '1_510': "Ensure signing person has the rights to represent the organization.",
                '1_511': "Upload a document that proves the payout entity is 100% affiliated with the legal entity.",
                '1_512': "Upload a VAT document.",
                '1_513': "Upload a different VAT document.",
                '1_514': "Update the organization name.",
                '1_515': "Update the organization name.",
                '1_516': "Update the registered street.",
                '1_600': "Update sole proprietorship details.",
                '1_601': "Upload a constitutional document.",
                '1_602': "Upload a different constitutional document.",
                '1_700': "Update bank account details.",
                '1_701': "Use different bank account.",
                '1_703': "Upload a bank statement.",
                '1_704': "Upload a different bank statement.",
                '1_705': "Upload an official bank statement.",
                '1_900': "Upload a proof of industry.",
                '1_901': "Update industry.",
                '1_902': "Update the web address.",
                '1_903': "Upload a different proof of industry.",
                '1_904': "Upload a different image of the proof of industry.",
                '1_905': "Add webAddress to business line.",
                '2_101': "Add identificationData for {name}.",
                '2_102': "Add firstName for {name}.",
                '2_103': "Add lastName for {name}.",
                '2_104': "Add country for {name}.",
                '2_105': "Add stateOrProvince for {name}.",
                '2_106': "Add street for {name}.",
                '2_107': "Add city for {name}.",
                '2_108': "Add postal code for {name}.",
                '2_109': "Add street2 for {name}.",
                '2_110': "Add nationality for {name}.",
                '2_112': "Add dateOfBirth for {name}.",
                '2_113': "Add iban to bank account.",
                '2_114': "Add accountNumber to bank account.",
                '2_115': "Add bank account.",
                '2_116': "Add branchCode to bank account.",
                '2_117': "Add registrationNumber to legal entity.",
                '2_118': "Add taxId to legal entity.",
                '2_119': "Add attachments to document.",
                '2_120': "Add name for {name}.",
                '2_121': "Add birthData for {name}.",
                '2_122': "Add residentialAddress for {name}.",
                '2_123': "Add entityAssociations of type uboThroughOwnership to legal entity.",
                '2_124': "Add entityAssociations of type signatory to legal entity.",
                '2_125': "Add organisation type to legal entity.",
                '2_126': "Upload a document.",
                '2_127': "Upload a drivers license.",
                '2_128': "Upload the front of a drivers license.",
                '2_129': "Upload the back of a drivers license.",
                '2_130': "Upload the front of an ID card.",
                '2_131': "Upload the back of an ID card.",
                '2_132': "Add organization legalName to legal entity.",
                '2_133': "Add organization doingBusinessAs to legal entity.",
                '2_134': "Upload a VAT document.",
                '2_135': "Add bankAccount sortCode to bank account.",
                '2_136': "Add business line.",
                '2_137': "Upload a proof of registration.",
                '2_138': "Add individual identification data number for {name}.",
                '2_139': "Add individual identification data type for {name}.",
                '2_140': "Add bankAccount accountType to bank account.",
                '2_141': "Add bankAccount bankBicSwift to bank account.",
                '2_142': "Add bankAccount bankCity to bank account.",
                '2_143': "Add bankAccount bankCode to bank account.",
                '2_144': "Add bankAccount bankName to bank account.",
                '2_145': "Add bankAccount checkCode to bank account.",
                '2_146': "Add bankAccount bankCountry to bank account.",
                '2_147': "Add bankAccount currencyCode to bank account.",
                '2_148': "Add organization stockData market Identifier  for {name}.",
                '2_149': "Add organization stockData stockNumber to legal entity.",
                '2_150': "Add organization stockData tickerSymbol to legal entity.",
                '2_151': "Add organization entityAssociations of type uboThroughControl to legal entity.",
                '2_152': "Add individual email for {name}.",
                '2_153': "Add phone number for {name}.",
                '2_154': "Add phone type for {name}.",
                '2_155': "Add phone countryCode for {name}.",
                '2_156': "Add entityAssociation jobTitle for {name}.",
                '2_157': "Upload a passport.",
                '2_158': "Add organization.vatNumber to legal entity.",
                '2_159': "Add source of funds to business line.",
                '2_160': "Add businessLine sourceOfFunds.type to business line.",
                '2_164': "Add businessLine sourceOfFunds adyenProcessedFunds to business line.",
                '2_166': "Add businessLine sourceOfFunds description to business line.",
                '2_170': "Add tax information for {name}.",
                '2_171': "Add taxInformation number for {name}.",
                '2_172': "Add taxInformation type for {name}.",
                '2_173': "Add taxInformation country for {name}.",
                '2_174': "Add proof Of Individual Tax Id for {name}.",
                '2_175': "Add vatDocument for {name}.",
                '2_176': "Add industryCode to business line.",
                '2_177': "Add channel to business line.",
                '2_178': "Add webAddress to business line.",
                '2_179': "Add taxReportingClassification for {name}.",
                '2_180': "Add taxReportingClassification type for {name}.",
                '2_181': "Add taxReportingClassification businessType for {name}.",
                '2_182': "Add taxReportingClassification mainSourceOfIncome for {name}.",
                '2_183': "Add taxReportingClassification financialInstitutionNumber for {name}.",
                '2_184': "Add constitutional document for verification for {name}",
                '2_189': "Add soleProprietorship.registrationNumber for {name}."
            },
            description: {
                INVALID_OWNER_DATA: "Review principal owner information and resubmit your application.",
                INVALID_BANK_ROUTING_NUMBER: "Review your bank routing number and resubmit your application.",
                INVALID_BANK_ACCOUNT_NUMBER: "Review your bank account number and resubmit your application.",
                INVALID_BUSINESS_TYPE: "Review your business type and resubmit your application.",
                INVALID_BUSINESS_INFORMATION: "Review your business information and resubmit your application.",
                INVALID_TAX_ID_NUMBER: "Review Business Tax ID and resubmit your application.",
                INVALID_MCC: "Review MCC and resubmit your application.",
                PROHIBITED_MCC: "Review MCC and resubmit your application.",
                PO_BOX_ADDRESS_NOT_SUPPORTED: "Review MCC and resubmit your application.",
                ID_VERIFICATION_NEEDED: "Upload a document to verify the identity information you entered.",
                ID_VERIFICATION_FAILED: "Upload a document to verify the identity information you entered.",
                BANK_VERIFICATION_NEEDED: "Upload a document to verify the bank account information you entered.",
                BANK_VERIFICATION_FAILED: "Upload a document to verify the bank account information you entered.",
                BANK_NOT_SUPPORTED: "Use a different bank account and resubmit your application",
                INVALID_BANK_ACCOUNT_OWNERSHIP: "Review your bank account and resubmit your application.",
                TAX_ID_VERIFICATION_NEEDED: "Please review your Tax ID and resubmit your application",
                TAX_ID_VERIFICATION_FAILED: "Please review your Tax ID and resubmit your application",
                INSUFFICIENT_BUSINESS_HISTORY: "Upload a document to verify the bank account information you entered.",

                '1_100': "No remediation possible.",
                '1_101': "Contact Support.",
                '1_102': "Upload a different document.",
                '1_300': "Update individual details.",
                '1_301': "Upload an ID document.",
                '1_302': "Upload a different ID document.",
                '1_303': "Upload a different image of the ID document.",
                '1_304': "Upload proof of residency.",
                '1_305': "Upload a different proof of residency.",
                '1_306': "Upload a different image of the proof of residency.",
                '1_307': "Upload a proof of national ID number.",
                '1_308': "Upload a different proof of national ID number.",
                '1_309': "Upload a different image of the proof of national ID number.",
                '1_310': "Update the name (Katakana) with only full-width Katakana characters and remove any invalid characters such as spaces, restricted or special characters.",
                '1_311': "Update the name (Kanji) with only full-width Katakana, Kanji or Latin characters, and remove any invalid characters such as spaces, restricted or special characters.",
                '1_312': "Update the residency street in full-width Kanji and remove any invalid characters such as spaces, restricted or special characters.",
                '1_313': "Upload a different image of the proof of the individual tax ID.",
                '1_314': "Upload a proof of the individual tax ID.",
                '1_315': "Upload a different proof of the individual tax ID.",
                '1_316': "Update the national Id number to a 9 digit SSN.",
                '1_500': "Update organization details.",
                '1_501': "Upload a registration document.",
                '1_502': "Upload a different registration document.",
                '1_503': "Upload a proofOfOrganizationTaxInfo.",
                '1_504': "Upload a different proofOfOrganizationTaxInfo.",
                '1_505': "Upload a proof of address.",
                '1_506': "Upload a different proof of address.",
                '1_507': "Update organization ownership details.",
                '1_508': "Upload a proof of ownership.",
                '1_510': "Ensure signing person has the rights to represent the organization or is a listed professional member (lawyer, accountant) in your country of business and upload the right document.",
                '1_511': "Upload a document that proves the payout entity is 100% affiliated with the legal entity.",
                '1_512': "Upload a VAT document.",
                '1_513': "Upload a different VAT document.",
                '1_514': "Update the organization name (Katakana) with only full-width Katakana characters and remove any invalid characters such as spaces, restricted or special characters.",
                '1_515': "Update the organization name (Kanji) with only full-width Katakana, Kanji or Latin characters, and remove any invalid characters such as spaces, restricted or special characters.",
                '1_516': "Update the registered street in full-width Kanji and remove any invalid characters such as spaces, restricted or special characters.",
                '1_600': "Update sole proprietorship details.",
                '1_601': "Upload a constitutional document.",
                '1_602': "Upload a different constitutional document.",
                '1_700': "Update bank account details.",
                '1_701': "Use different bank account.",
                '1_703': "Upload a bank statement.",
                '1_704': "Upload a different bank statement.",
                '1_705': "Upload an official bank statement.",
                '1_900': "Upload a proof of industry.",
                '1_901': "Update industry.",
                '1_902': "Update the web address with a website or application that represents your business.",
                '1_903': "Upload a different proof of industry.",
                '1_904': "Upload a different image of the proof of industry.",
                '1_905': "Add webData.webAddress to business line.",
                '2_101': "Add individual.identificationData to legal entity.",
                '2_102': "Add individual.name.firstName to legal entity.",
                '2_103': "Add individual.name.lastName to legal entity.",
                '2_104': "Add individual.residentialAddress.country to legal entity.",
                '2_105': "Add individual.residentialAddress.stateOrProvince to legal entity.",
                '2_106': "Add individual.residentialAddress.street to legal entity.",
                '2_107': "Add individual.residentialAddress.city to legal entity.",
                '2_108': "Add individual.residentialAddress.postalcode to legal entity.",
                '2_109': "Add individual.residentialAddress.street2 to legal entity.",
                '2_110': "Add individual.nationality to legal entity.",
                '2_112': "Add individual.birthData.dateOfBirth to legal entity.",
                '2_113': "Add bankAccount.iban to bank account.",
                '2_114': "Add bankAccount.accountNumber to bank account.",
                '2_115': "Add bank account.",
                '2_116': "Add bankAccount.branchCode to bank account.",
                '2_117': "Add organization.registrationNumber to legal entity.",
                '2_118': "Add organization.taxId to legal entity.",
                '2_119': "Add attachments to document.",
                '2_120': "Add individual.name to legal entity.",
                '2_121': "Add individual.birthData to legal entity.",
                '2_122': "Add individual.residentialAddress to legal entity.",
                '2_123': "Add organization.entityAssociations of type uboThroughOwnership to legal entity.",
                '2_124': "Add organization.entityAssociations of type signatory to legal entity.",
                '2_125': "Add organization.type to legal entity.",
                '2_126': "Upload a document.",
                '2_127': "Upload a drivers license.",
                '2_128': "Upload the front of a drivers license.",
                '2_129': "Upload the back of a drivers license.",
                '2_130': "Upload the front of an ID card.",
                '2_131': "Upload the back of an ID card.",
                '2_132': "Add organization.legalName to legal entity.",
                '2_133': "Add organization.doingBusinessAs to legal entity.",
                '2_134': "Upload a VAT document.",
                '2_135': "Add bankAccount.sortCode to bank account.",
                '2_136': "Add business line.",
                '2_137': "Upload a proof of registration.",
                '2_138': "Add individual.identificationData.number to legal entity.",
                '2_139': "Add individual.identificationData.type to legal entity.",
                '2_140': "Add bankAccount.accountType to bank account.",
                '2_141': "Add bankAccount.bankBicSwift to bank account.",
                '2_142': "Add bankAccount.bankCity to bank account.",
                '2_143': "Add bankAccount.bankCode to bank account.",
                '2_144': "Add bankAccount.bankName to bank account.",
                '2_145': "Add bankAccount.checkCode to bank account.",
                '2_146': "Add bankAccount.bankCountry to bank account.",
                '2_147': "Add bankAccount.currencyCode to bank account.",
                '2_148': "Add organization.stockData.marketIdentifier to legal entity.",
                '2_149': "Add organization.stockData.stockNumber to legal entity.",
                '2_150': "Add organization.stockData.tickerSymbol to legal entity.",
                '2_151': "Add organization.entityAssociations of type uboThroughControl to legal entity.",
                '2_152': "Add individual.email to legal entity.",
                '2_153': "Add phone.number to legal entity.",
                '2_154': "Add phone.type to legal entity.",
                '2_155': "Add phone.countryCode to legal entity.",
                '2_156': "Add entityAssociation.jobTitle to legal entity.",
                '2_157': "Upload a passport.",
                '2_158': "Add organization.vatNumber to legal entity.",
                '2_159': "Add source of funds to business line.",
                '2_160': "Add businessLine.sourceOfFunds.type to business line.",
                '2_164': "Add businessLine.sourceOfFunds.adyenProcessedFunds to business line.",
                '2_166': "Add businessLine.sourceOfFunds.description to business line.",
                '2_170': "Add tax information to legal entity.",
                '2_171': "Add taxInformation.number to legal entity.",
                '2_172': "Add taxInformation.type to legal entity.",
                '2_173': "Add taxInformation.country to legal entity.",
                '2_174': "Add proofOfIndividualTaxId to legal entity.",
                '2_175': "Add vatDocument to legal entity.",
                '2_176': "Add industryCode to business line.",
                '2_177': "Add channel to business line.",
                '2_178': "Add webAddress to business line.",
                '2_179': "Add taxReportingClassification to legal entity.",
                '2_180': "Add taxReportingClassification.type to legal entity.",
                '2_181': "Add taxReportingClassification.businessType to legal entity.",
                '2_182': "Add taxReportingClassification.mainSourceOfIncome to legal entity.",
                '2_183': "Add taxReportingClassification.financialInstitutionNumber to legal entity.",
                '2_184': "Add constitutional document for verification to legal entity.",
                '2_189': "Add soleProprietorship.registrationNumber to legal entity."
            },
            actionLabel: {
                INVALID_OWNER_DATA: "Review ->",
                INVALID_TAX_ID_NUMBER: "Review ->",
                INVALID_BANK_ROUTING_NUMBER: "Review ->",
                INVALID_BANK_ACCOUNT_NUMBER: "Review ->",
                BANK_NOT_SUPPORTED: "Review ->",
                INVALID_BUSINESS_TYPE: "Review ->",
                INVALID_BUSINESS_INFORMATION: "Review ->",
                INVALID_BANK_ACCOUNT_OWNERSHIP: "Review ->",
                INVALID_MCC: "Review ->",
                PROHIBITED_MCC: "Review ->",
                PO_BOX_ADDRESS_NOT_SUPPORTED: "Review ->",
                ID_VERIFICATION_NEEDED: "Upload Document ->",
                ID_VERIFICATION_FAILED: "Upload Document ->",
                BANK_VERIFICATION_FAILED: "Upload Document ->",
                BANK_VERIFICATION_NEEDED: "Upload Document ->",
                TAX_ID_VERIFICATION_NEEDED: "Upload Document ->",
                TAX_ID_VERIFICATION_FAILED: "Upload Document ->",
                INSUFFICIENT_BUSINESS_HISTORY: "Upload Document ->",

                '1_100': "Review ->",
                '1_101': "Review ->",
                '1_102': "Upload Document ->",
                '1_300': "Review ->",
                '1_301': "Upload Document ->",
                '1_302': "Upload Document ->",
                '1_303': "Upload Document ->",
                '1_304': "Upload Document ->",
                '1_305': "Upload Document ->",
                '1_306': "Upload Document ->",
                '1_307': "Upload Document ->",
                '1_308': "Upload Document ->",
                '1_309': "Upload Document ->",
                '1_310': "Review ->",
                '1_311': "Review ->",
                '1_312': "Review ->",
                '1_313': "Upload Document ->",
                '1_314': "Upload Document ->",
                '1_315': "Upload Document ->",
                '1_316': "Review ->",
                '1_500': "Review ->",
                '1_501': "Upload Document ->",
                '1_502': "Upload Document ->",
                '1_503': "Upload Document ->",
                '1_504': "Upload Document ->",
                '1_505': "Upload Document ->",
                '1_506': "Upload Document ->",
                '1_507': "Review ->",
                '1_508': "Upload Document ->",
                '1_510': "Upload Document ->",
                '1_511': "Upload Document ->",
                '1_512': "Upload Document ->",
                '1_513': "Upload Document ->",
                '1_514': "Review ->",
                '1_515': "Review ->",
                '1_516': "Review ->",
                '1_600': "Review ->",
                '1_601': "Upload Document ->",
                '1_602': "Upload Document ->",
                '1_700': "Review ->",
                '1_701': "Review ->",
                '1_703': "Upload Document ->",
                '1_704': "Upload Document ->",
                '1_705': "Upload Document ->",
                '1_900': "Upload Document ->",
                '1_901': "Review ->",
                '1_902': "Review ->",
                '1_903': "Upload Document ->",
                '1_904': "Upload Document ->",
                '1_905': "Review ->",
                '2_101': "Review ->",
                '2_102': "Review ->",
                '2_103': "Review ->",
                '2_104': "Review ->",
                '2_105': "Review ->",
                '2_106': "Review ->",
                '2_107': "Review ->",
                '2_108': "Review ->",
                '2_109': "Review ->",
                '2_110': "Review ->",
                '2_112': "Review ->",
                '2_113': "Review ->",
                '2_114': "Review ->",
                '2_115': "Review ->",
                '2_116': "Review ->",
                '2_117': "Review ->",
                '2_118': "Review ->",
                '2_119': "Review ->",
                '2_120': "Review ->",
                '2_121': "Review ->",
                '2_122': "Review ->",
                '2_123': "Review ->",
                '2_124': "Review ->",
                '2_125': "Review ->",
                '2_126': "Upload Document ->",
                '2_127': "Upload Document ->",
                '2_128': "Upload Document ->",
                '2_129': "Upload Document ->",
                '2_130': "Upload Document ->",
                '2_131': "Upload Document ->",
                '2_132': "Review ->",
                '2_133': "Review ->",
                '2_134': "Upload Document ->",
                '2_135': "Review ->",
                '2_136': "Review ->",
                '2_137': "Upload Document ->",
                '2_138': "Review ->",
                '2_139': "Review ->",
                '2_140': "Review ->",
                '2_141': "Review ->",
                '2_142': "Review ->",
                '2_143': "Review ->",
                '2_144': "Review ->",
                '2_145': "Review ->",
                '2_146': "Review ->",
                '2_147': "Review ->",
                '2_148': "Review ->",
                '2_149': "Review ->",
                '2_150': "Review ->",
                '2_151': "Review ->",
                '2_152': "Review ->",
                '2_153': "Review ->",
                '2_154': "Review ->",
                '2_155': "Review ->",
                '2_156': "Review ->",
                '2_157': "Upload Document ->",
                '2_158': "Review ->",
                '2_159': "Review ->",
                '2_160': "Review ->",
                '2_164': "Review ->",
                '2_166': "Review ->",
                '2_170': "Review ->",
                '2_171': "Review ->",
                '2_172': "Review ->",
                '2_173': "Review ->",
                '2_174': "Review ->",
                '2_175': "Review ->",
                '2_176': "Review ->",
                '2_177': "Review ->",
                '2_178': "Review ->",
                '2_179': "Review ->",
                '2_180': "Review ->",
                '2_181': "Review ->",
                '2_182': "Review ->",
                '2_183': "Review ->",
                '2_184': "Review ->",
                '2_189': "Review ->"
            },
            error: {
                INVALID_BUSINESS_TYPE: "Invalid Business Type",
                INVALID_TAX_ID_NUMBER: "Invalid Business Tax ID",
                INVALID_BANK_ROUTING_NUMBER: 'The routing number is incorrect.',
                INVALID_BANK_ACCOUNT_NUMBER: "The bank account number is incorrect",
                INVALID_MCC: 'This Merchant Category Code (MCC) is invalid.',
                PROHIBITED_MCC: 'This Merchant Category Code (MCC) is prohibited.',
                PO_BOX_ADDRESS_NOT_SUPPORTED: 'This Merchant Category Code (MCC) is invalid.'
            }
        },
        status: {
            label: {
                DRAFT: "Drafted State",
                PROVISIONING: "Under Review",
                REJECTED: "Under Review",
                REJECTED_WITH_DECLINE: "Rejected",
                APPROVED: "Approved",
            },
            description: {
                DRAFT: "Your application is in a draft state.",
                PROVISIONING: "Your application is under review. We will notify you when the status changes.",
                REJECTED: "Your application needs attention.",
                REJECTED_WITH_DECLINE: "Your application was declined.",
                REJECTED_BUT_FIXED: "Your application is under review. We will notify you when the status changes.",
                APPROVED: "Your application is approved",
            },
            draftStatusDescription: "Your Chargebee Payments application is in {draftStatus} state, please fill in required details and submit.",
            draftStatus: "Drafted",
            assistance: "For assistance, look at our {faqPage}, the {documentationPage}, or write to {supportEmail}.",
            faqPage: "FAQ page",
            documentationPage: "Documentation page",
            supportEmail: "support@chargebee.com",
            configureLabel: "Configure",
            configureInstruction: "Submit information about your business.",
            reviewLabel: "Review",
            reviewInstruction: "We process your application.",
            goLiveLabel: "Go Live",
            goLiveInstruction: "Start accepting payments.",
            rejectedLabel: "Rejected",
            rejectedInstruction: "Please resubmit your application.",
            resumeApplication: "Resume Application",
            applicationStatus: "Application Status"
        },
        global: {
            action: {
                close: "Close",
                submit: "Submit",
                dismiss: "Dismiss",
                add: "Add",
                nextStep: "Next Step",
                prevStep: "Previous Step",
                next: "Next",
                previous: "Previous",
                edit: "Edit",
                delete: "Delete",
                iAccept: "I Accept",
                cancel: "Cancel",
                confirm: "Confirm",
                save: "Save",
                configure: "Configure Chargebee Payments",
                learnMore: "Learn More",
                export: "Export (.CSV)",
                view_in_chargebee: "View in Chargebee",
                change: "Change",
                okay: "Okay"
            },
            toast: {
                savedAsDraft: "Changes are saved successfully",
                copiedToClipboard: "%{fieldName} is copied to clipboard",
            },
            hint: {
                optional: "(Optional)",
                ifAny: "(If any)",
                mandatory: "(Mandatory)",
                maxLength: "Max {max} characters"
            },
            title: {
                showing: "Showing: %{count} of %{totalCount} ",
                sandboxSite: "You are on a test site with sample or test data."
            },
            time: {
                timezone: "Timezone: %{timezone} (%{abbreviation})",
                utcTimeZoneDifference: "(UTC %{time})",
            },
            fieldName: {
                total: "Total",
                amount: "Amount",
                bankAccount: "Bank Account"
            }
        },
        errors: {
            submitOnboardingFailed: "Submit onboarding failed",
            saveOnboardingFailed: "Save onboarding failed",
            validationErrorCannotNavigateToTab: "Validation failed! Please fill current tab before navigating",
            validationErrorToastSave: "There were errors while saving",
            validation_error: {
                invalid_pin: "Enter a 5 or 9 digit postal code",
                wrong_format: "Invalid format",
                wrong_regex: "Invalid format. Value should {format}",
                invalid_site: "This is not valid site",
                number: {
                    range: {
                        max: "Value exceeds maximum {max}",
                        min: "Value cannot be less than {min}",
                    }
                },
                otherRefundPolicyMissing: "Expected when the refund policy is 'other'",
                controlPersonExpected: "Beneficial owner details are mandatory",
                sumOfPercentagesExceedsHundred: "Sum of percentages exceeds 100. Max possible is {num}",
                sumIsNotEqualToHundred: "Sum of the entered percentages is {sum}, but should be equal to 100",
                accountNumberNotMatching: "Account number does not match",
                presence: "Missing mandatory field",
                controlPersonOwnershipPercentage: "Value should be between 0 and 24.99 for beneficial owner",
                additionalBeneficiariesOwnershipPercentage: "Value should be between 25 and 100 for beneficial owner",
                enum: {
                    invalid: "Invalid selection. Try again."
                },
                date_format: "Invalid date",
                email: {
                    main: "Invalid email"
                },
                strict_int: "Value should be numeric",
                string: {
                    length: {
                        exact: "Should be exactly {num} characters",
                        max: "Exceeds maximum limit {max} characters",
                        min: "Please enter at least {min} characters"
                    }
                }
            },
            unAuthorized: "Unauthorized! Redirecting to Okta..."
        },
        landing: {
            title: "Chargebee Payments",
            form: {
                heading: "Enter Application context",
                basicAuthToken: "Basic Auth Token",
                operationType: "Select Operation Type",
                merchantName: "Merchant display name",
                externalId: "Merchant external ID",
                redirectUrl: "Redirect URL"
            },
            loggingIn: "Logging in...",
            loggingOut: "Logging out..."
        },
        termsAndCharges: {
            title: "Review Charges and Terms & Conditions",
            subtitle: "To submit your application, please click below to accept the rates and terms applicable to Chargebee Payments.",
            processingRate: "Processing Rate",
            processingRateInstruction: "To review all applicable fees and charges, see the {feeSchedule}.",
            feeSchedule: "Fee Schedule",
            perCreditCardTransfer: "per card transaction",
            perAchTransfer: "per ACH transfer",
            termsAndConditions: "Terms and Conditions",
            termsAndConditionsInstruction: "Review and accept terms and conditions below.",
            acceptPrompt: "By clicking “I Accept” you are",
            guidelinesConditionPayfac: "Agreeing to be bound by the {chargebeeTerms} {payfacTerms}",
            guidelinesCondition: "Certifying that all information provided in your application is true and complete.",
            saqaCondition: "Acknowledging that you must remain compliant with the {pciDssRequirements} at all times as long as you want to receive payments; you must continuously reassess your environment and implement any additional PCI DSS requirements if your environment changes.",
            joinTermsOfService: "and the",
            chargebeeTerms: "Chargebee Payments Terms of Use",
            finixTerms: " Finix Terms of Service",
            adyenTerms: " Adyen Terms of Service",
            pciDssRequirements: " PCI-DSS requirements"
        },
        MCCNotSupported: {
            title: "MCC not supported",
            subtitle: "We currently don't offer Chargebee Pay for your line of business"
        },
        businessProfile: {
            title: "Business Profile",
            subtitle: "Tell us about your business.",
            companyName: {
                label: "Legal Business Name",
                instruction: "Enter your full legal business name. For a sole proprietorship, enter the business owner’s full legal name."
            },
            doingBusinessAs: {
                label: "Doing Business As",
                instruction: "Enter the trade name or operating name of the company."
            },
            description: {
                label: "Business Description",
                instruction: "Describe your business in 1-2 sentences."
            },
            website: {
                label: "Business Website",
                instruction: "If you don’t have a website, enter a social media page, LinkedIn, or other relevant link.",
            },
            phone: {
                label: "Business Phone",
                instruction: ""
            },
            entityRegistrationNumber: {
                label: "Entity Registration Number",
                instruction: "Enter the identification number provided by the state when you registered your business."
            },
            agreement: "The information you provide will be used to verify your identity. Additional information may be requested."
        },
        businessAddress: {
            title: "Business Address",
            subtitle: "If you don’t have a business address, use your home address.",
            country: {
                label: "Country",
                instruction: "Currently, Chargebee Payments is only available to businesses registered in the USA."
            },
            addressLine1: {
                label: "Address Line 1",
            },
            addressLine2: {
                label: "Address Line 2"
            },
            city: {
                label: "City"
            },
            state: {
                label: "State"
            },
            zip: {
                label: "Postal Code"
            }
        },
        businessStructure: {
            title: "Business Operations",
            subtitle: "Tell us how you operate your business.",
            typeOfBusiness: {
                label: "Type of Business",
                instruction: "Select how your business is structured."
            },
            ownershipType: {
                label: "Ownership type",
                instruction: "Select whether you are a publicly traded or privately held company.",
            },
            taxId: {
                usa: {
                    label: "Employer Identification Number (EIN)",
                    instruction: "Find your EIN on your confirmation letter from the IRS, your business credit report, or payroll paperwork. " +
                      "If you use your Social Security number for business tax purposes, you can enter that instead."
                },
                deu : {
                    label: "Tax number (Steuernummer)", //is this correct name @sushank
                    instruction: "Eg: 1234567890" //is this correct name @sushank
                },
                gbr : {
                    label: "Tax number",
                    instruction: "Eg: 3194027616"
                },
            },
            registrationNumber: {
                fra : {
                    label: "Registration number (SIRET)",
                    instruction: "Eg: 54205118000066"
                },
                deu : {
                    label: "Registration number (Handelsregisternummer)",
                    instruction: "Eg: HRB100484"
                },
                gbr : {
                    label: "Registration number",
                    instruction: "Eg: 12345678"
                },
            },
            vatNumber: {
                fra: {
                    label: "VAT number",
                    instruction: "Eg: FR12345678901"
                },
                deu: {
                    label: "VAT number",
                    instruction: "Eg: DE115235681"
                },
                gbr: {
                    label: "VAT number",
                    instruction: "Eg: GB123456789"
                },
            },
            incorporationDate: {
                label: "Incorporation Date",
                instruction: "Enter the date your business was incorporated.",
            },
            merchantCategoryCode: {
                label: "Merchant Category Code (MCC)",
                instruction: "Select a category code that best matches your primary business activity. {whatIsMcc}",
                whatIsMcc: "What is MCC?"
            }
        },
        businessOwnership: {
            title: "Business Ownership",
            subtitle: "Tell us your business ownership details.",
            stackholders: {
                label: "Does any beneficial owner hold 25% or more of the equity interests in the entity?",
                yes: {
                    label: "Yes",
                    instruction: "There are one or more stakeholders who hold 25% or greater ownership"
                },
                no: {
                    label: "No",
                    instruction: "There are no stakeholders who hold 25% or greater ownership"
                }
            },
            controlPersonDetails: {
                title: "Control Person Details",
                subtitle: "Add details of a representative of the business who has decision-making authority (e.g. CEO or CFO).",
                addControlPerson: "Add Control Person",
                add: {
                    title: "Add Control Person",
                    subtitle: "Add details of a representative of the business who has decision-making authority (e.g. CEO or CFO).",
                    ownershipPercentage: "Ownership Percentage",
                    taxId: "Tax ID"
                },

            },
            beneficiaryDetails: {
                title: "Beneficial Owners",
                subtitle: "Add up to four beneficial owners - individuals or entities that own 25% or more of the equity interests in the business.",
                addBeneficiary: "Add Beneficial Owner",
                add: {
                    title: "Add Beneficial Owner",
                    subtitle: "Enter the details of a beneficial owner - an individual or entity that owns 25% or more of the equity interests in the business.",
                    ownershipPercentage: "Ownership Percentage",
                    taxId: "Tax ID"
                }
            }
        },
        additionalUnderwritingData: {
            title: "Transactions and Settlements",
            subtitle: "Tell us more about your business and payment volumes.",
            billingDescriptor: {
                label: "Billing Descriptor",
                instruction: "The business name displayed against charges on the buyer’s bank or card statement."
            },
            refundPolicy: {
                label: "Refund Policy",
                instruction: "Select ‘Other’ if no option matches your business model.",
            },
            otherRefundPolicy: {
                label: "Other Refund Policy",
            },
            averageSingleTransactionAmount: {
                label: "Average Transaction Amount",
                instruction: "Approximate average sale amount.",
            },
            maxSingleTransactionAmount: {
                label: "Max Transaction Amount",
                instruction: "Maximum amount that can be transacted for a single transaction."
            },
            approximateAnnualSalesVolume: {
                label: "Annual Transaction Amount",
                instruction: "Approximate annual sales expected to be processed."
            }
        },
        distributionOfCreditCardVolume: {
            title: "Distribution of Sales Volume",
            subtitle: "Tell us how your sales volume is distributed for these categories.",
            sumOfEnteredPercentages: "You’re up to {sum}%",
            byBusinessType: {
                label: "1. By Type of Customer",
                instruction: "Sum shouldn’t be more than 100%",
                businessToBusiness: "Business to Business",
                businessToConsumer: "Business to Consumer",
                other: "Other"
            },
            byModeOfBusiness: {
                label: "2. By Mode of Payment",
                instruction: "Sum shouldn’t be more than 100%",
                offlineTransactionsWithCard: "Offline transactions with a card (in-store)",
                onlineTransactionsWithCard: "Online transactions with a card",
                cardNotPresent: "Non-card sales (cash, bank transfer)"
            }
        },
        bankAccountForPayouts: {
            title: "Bank Account For Payouts",
            subtitle: "Fill in the bank account details where funds are to be deposited.",
            accountHolderName: {
                label: "Bank Account Holder Name",
                instruction: "Enter the full name of the primary account owner."
            },
            accountNumber: {
                label: "Bank Account Number",
                instruction: "Enter the bank account number where funds are to be deposited."
            },
            iban: {
                label: "IBAN Number",
                instruction: "Enter the International Bank Account Number (IBAN) for your bank account."
            },
            reenterAccountNumber: {
                accountNumber: {
                    label: "Re-enter Bank Account Number"
                },
                iban: {
                    label: "Re-enter IBAN Number"
                }
            },
            bankCode: {
                label: "Bank Account Routing Number",
                instruction: "Find your bank’s routing number on checks, bank statements, or using its online banking application."
            },
            // bankCode: {
            //     label: "Bank Code",
            //     instruction: "Enter the bank code for your bank account."
            // },
            branchCode: {
                label: "Branch Code",
                instruction: "Enter the branch code for your bank account."
            },
            swiftCode: {
                label: "SWIFT Code",
                instruction: "Enter the SWIFT code for your bank account."
            },
            clearingCode: {
                label: "Clearing Code",
                instruction: "Enter the clearing code for your bank account."
            },
            sortCode: {
                label: "Sort Code",
                instruction: "Enter the sort code for your bank account."
            },
            accountType: {
                label: "Account Type",
                instruction: "Select the type of account."
            },
            agreement: "You agree to use this account for legitimate business purposes and not for personal, family, or household purposes.",
            message: {
                success: "A new bank account was added successfully.",
                failure: "Failed to add new bank account.",
                getMerchantPaymentInstrumentFailed: "Failed to retrieve list of payment instruments.",
            },
            action: {
                addBankAccount: "Add Bank Account",
            }

        },
        addBeneficiary: {
            markAsPrimary: "Mark as primary",
            primary: "Primary",
            cannotDeletePrimaryTitle: "Cannot delete Primary Beneficial Owner",
            cannotDeletePrimaryDescription: "Mark a different beneficial owner as primary, and try again.",
            personalDetails: "Personal Details",
            personalDetailsInstructions: "",
            beneficialOwnerDetails: "Beneficial Owner Details",
            beneficialOwnerDetailsInstructions: "Enter the details of a beneficial owner.",
            submit: "Add Beneficiary",
            businessOwnerType: "Beneficial Owner Type",
            businessName: "Business Name",
            firstName: "First name",
            lastName: "Last name",
            jobTitle: "Job title",
            dateOfBirth: "Date of Birth",
            emailAddress: "Email Address",
            phoneNumber: "Phone Number",
            ownership: "Ownership",
            ownershipPercentage: {
                label: "Ownership Percentage",
                instruction: "Percentage of equity interests owned (min {min}%, max {max}%)"
            },
            taxId: {
                label: "SSN or ITIN",
                instruction: "Social Security Number or Individual Taxpayer Identification Number",
                ssnAbbr: "SSN or ITIN",
                einAbbr: "EIN or ITIN",
                ssn: "Social Security Number or Individual Taxpayer Identification Number",
                ein: "Employer Identification Number or Individual Taxpayer Identification Number"
            },
            exceededMaxNumberOfBeneficiaries: "You have exceeded the number of beneficiaries or percentage."
        },
        beneficiary: {
            ownership: "Ownership",
            taxId: "Tax ID"
        },
        address: {
            title: "Address",
            subTitle: "",
            country: {
                label: "Country"
            },
            addressLine1: {
                label: "Address Line 1",
            },
            addressLine2: {
                label: "Address Line 2"
            },
            city: {
                label: "City"
            },
            state: {
                label: "State"
            },
            zip: {
                label: "Postal Code"
            }
        },
        onboarding: {
            title: "Configuring Chargebee Payments",
            saveAsDraft: "Save & Close",
            submitDisabled: "Please fill out the form to proceed",
            assistance: "For assistance, look at the {faqPage} and {documentationPage}",
            faqPage: "FAQ",
            documentationPage: "Documentation",
            goBack: "Go back to Chargebee app"
        },
        uploadDocument: {
            title: "Identity verification",
            subTitle: "Upload a proof of identity document.",
            errorTitle: "Identity verification failed",
            errorSubtitle: "Upload a document to help us verify the identity information you entered.",
            dob: "DOB:",
            maskedDob: "xx/xx/{year}",
            ownershipDetails: "Ownership Details",
            ownershipPercentage: "Ownership Percentage:",
            principleTaxID: "Principal Tax ID:",
            uploadHeader: "Upload Document",
            dataSecurity: "Your data is secure.",
            selectDocument: "Select a document you would like to upload for verification.",
            fileUploadText: "Drag your file here or click to {upload}",
            upload: "upload",
            maxSize: "Supports:",
            support1: "PDF: 1KB - 10MB",
            support2: "JPEG, JPG, PNG, TIFF: 100KB - 10MB",
            IDENTIFICATION_CARD_FRONT: "Identification card - Front Side",
            IDENTIFICATION_CARD_BACK: "Identification card - Back Side",
            PASSPORT: "Passport",
            DRIVERS_LICENSE_FRONT: "Driver's License - Front Side",
            DRIVERS_LICENSE_BACK: "Driver's License - Back Side",
            TAX_DOCUMENT: "Tax Document",
            VAT_DOCUMENT: "VAT document",
            BUSINESS_REGISTRATION: "Business Registration",
            BUSINESS_ADDRESS_VERIFICATION: "Business Address",
            BANK_STATEMENT: "Bank Statement",
            CONSTITUTIONAL_DOCUMENT: "Constitutional document",
            INDUSTRY_PROOF: "Industry Proof",
            OTHER: "Other",
            previouslyUploaded: "Previously uploaded files",
            loading: "Loading...",
            noFilesUploaded: "No files uploaded",
            status: {
                PENDING: "Processing uploaded file",
                REQUIRES_UPLOAD: "Processing uploaded file",
                UPLOADED: "Uploaded",
                INVALID: "Invalid"
            },
            fileUploadErrors: {
                unsupportedFormat: "Unsupported format",
                fileNameSize: "File name cannot be more than 50 characters",
                fileName: "File name can only contain alphanumeric characters, underscores, hyphens, spaces and at most one dot.",
                fileSize: "PDF size should be between 1KB and 10MB. But found %{size}",
                pdfFileSize: "PDF size should be between 1KB and 10MB. But found %{size}",
                sizeExceeded: "File size exceeds limit.",
                fileUploadFailed: "File upload failed!",
            }
        },
        documentType: {
            IDENTIFICATION_CARD: "Identification card",
            PASSPORT: "Passport",
            DRIVER_LICENSE: "Driver's license",
            TAX_DOCUMENT: "Tax document",
            VAT_DOCUMENT: "VAT document",
            BUSINESS_REGISTRATION: "Business registration",
            BUSINESS_ADDRESS_VERIFICATION: "Business address verification",
            BANK_STATEMENT: "Bank statement",
            CONSTITUTIONAL_DOCUMENT: "Constitutional document",
            INDUSTRY_PROOF: "Industry Proof",
            OTHER: "Other",
        },
        edit: {
            businessOwner: {
                title: "Business Owner Information",
                subtitle: "Fill in correct information indicated below."
            },
            businessInformation: {
                subtitle: "Please review the highlighted fields.",
                bannerTitle: "Business information could not be verified.",
                bannerDescription: "Some of the information about your business could not be verified. Please review and resubmit your application.",
            }
        },
        sideNav: {
            titles: {
                dashboard: "Dashboard",
                transactions: "Transactions",
                payments: "Payments & Refunds",
                authorizations: "Authorizations",
                exports: "Exports",
                reports: "Reports",
                downloads: "Downloads",
                deposits: "Deposits",
                disputes: "Disputes",
                payment_profile: "Payment Profile",
                notifications: "Notifications"
            },
            testSite: "TEST",
            liveSite: "LIVE"
        },
        filters: {
            dateFilter: {
                PaymentsAndRefunds: "Payments and Refunds Period:",
                Refunds: "Refunds Period:",
                Authorizations: "Authorizations Period:",
                Deposits: "Deposit Period:",
                Disputes: "Initiated Period:",
                NotificationsTodo: "To Do Period:",
                NotificationsEvents: "Events Period:",
                ReportPeriod: "Report Period:",
                Month: "Month:",
                all_time: "All time",
                today: "Today",
                one_week: "Last 7 days",
                one_month: "Last 30 days",
                three_month: "Last 90 days",
                six_month: "Last 6 months",
                one_year: "Last 1 year",
                custom: "Custom",
                previous_month: "Previous Month",
                quarter_to_date: "Quarter To Date",
                month_to_date: "Month To Date",
                year_to_date: "Year To Date",
                from: "From",
                to: "To",
                applyFilters: "Apply Filters",
                clearFilters: "Clear Filters",
                insights: "Insights Period:"
            },
            statusFilter: {
                PaymentsAndRefunds: {
                    status: "Status",
                },
                Refunds: {
                    status: "Status",
                },
                Authorizations: {
                    status: "Status",
                },
                Deposits: {
                    status: "Status",
                },
                Disputes: {
                    status: "Status",
                },
                ALL: "All",
                SUCCESS: "Success",
                SUCCEEDED: "Success",
                FAILED: "Failed",
                PENDING: "Pending",
                REFUNDED: "Refunded",
                CANCELED: "Cancelled",
                selected: "Selected",
                IN_PROGRESS: "In Progress",
                RETURNED: "Returned",
                OPEN: "Open",
                RESPONDED: "Responded",
                WON: "Won",
                LOST: "Lost",
                INQUIRY: "Inquiry",
                CHARGEBACK: "Chargeback"
            },
            depositStatusFilter: {
                Settlements: {
                    status: "Status",
                },
                ALL: "All",
                SUCCEEDED: "Success",
                FAILED: "Failed",
                PENDING: "Pending",
                REFUNDED: "Refunded",
                CANCELED: "Cancelled",
                selected: "Selected",
                IN_PROGRESS: "In Progress",
            },
            typeFilter: {
                ALL: "All",
                selected: "Selected",
                PaymentsAndRefunds: {
                    DEBIT: "Payment",
                    REFUND: "Refund",
                    ACH_RETURN: "ACH Return",
                    PAYMENT_RETURN: "Payment Return",
                    PAYMENT_REVERSAL: "Payment Reversal",
                    CHARGEBACK_DEBIT : "Debit",
                    CHARGEBACK_CREDIT : "Credit",
                    RESERVE : "Reserve",
                    CHARGEBACK_FEE : "Chargeback Fee",
                    type: "Type",
                },
                Refunds: {
                    REFUND: "Refund",
                    ACH_RETURN: "ACH Return",
                    PAYMENT_RETURN: "Payment Return",
                    PAYMENT_REVERSAL: "Payment Reversal",
                    type: "Type",
                },
                Disputes: {
                    INQUIRY: "Inquiry",
                    CHARGEBACK: "Chargeback",
                    type: "Type",
                }
            },
            depositSubTypeFilter: {
                ALL: "All",
                selected: "Selected",
                Settlements: {
                    type: "Deposit Sub Type",
                    SETTLEMENT_MERCHANT: "Merchant",
                    SETTLEMENT_PLATFORM: "Platform",
                },
            },
            paymentMethodFilter: {
                payment_method: "Payment Method",
                ALL: "All",
                CARD: "Card",
                BANK_ACCOUNT: "Bank Account",
                selected: "Selected",
            },
            amountFilter: {
                PaymentsAndRefunds: {
                    amount: "Amount",
                },
                Refunds: {
                    amount: "Amount",
                },
                Deposits: {
                    amount: "Total Amount",
                },
                Disputes: {
                    amount: "Amount",
                },
                equals: "equals",
                between: "between",
                greater_than: "greater than",
                less_than: "less than",
                label: {
                    equals: "Equals to ",
                    between: "Between ",
                    greater_than: "Greater than ",
                    less_than: "Lesser than",
                }
            },
            depositAmountFilter: {
                Deposits: {
                    amount: "Deposit Amount",
                },
                equals: "equals",
                between: "between",
                greater_than: "greater than",
                less_than: "less than",
                label: {
                    equals: "Equals to ",
                    between: "Between ",
                    greater_than: "Greater than ",
                    less_than: "Lesser than",
                }
            },
            alertTypeFilter: {
                title: "Alert Type:",
                all: "All",
                new_dispute:"New Dispute",
                dispute_reminders: "Dispute Reminders",
                additional_evidence: "Additional Evidence",
                compliance_form: "Compliance Form",
                negative_balance: "Negative Balance",
                fund_added: "Fund Added",
            },
            periodFilter: {
                Reports: {
                    title: "Report Period:",
                },
                monthly: "Monthly"
            },
            monthFilter: {
                title: "Month:",
            }
        },
        cards: {
            cardMasked: " card **** ",
            brands: {
                AMERICAN_EXPRESS: "American Express",
                CHINA_T_UNION: "China T-union",
                CHINA_UNION_PAY: "China UnionPay",
                DANKORT: "Dankort",
                DINERS_CLUB: "Diners club",
                DINERS_CLUB_INTERNATIONAL: "Diners Club International",
                DISCOVER: "Discover",
                INSTAPAYMENT: "InstaPayment",
                INTERPAYMENT: "InterPayments",
                JCB: "JCB",
                LANKAPAY: "LankaPay",
                MAESTRO: "Maestro",
                MASTERCARD: "Mastercard",
                MIR: "Mir",
                RUPAY: "Rupay",
                TROY: "Troy",
                UATP: "uatp",
                UNKNOWN: "Unknown",
                VERVE: "Verve",
                VISA: "Visa"
            }
        },
        fieldNames: {
            authorizationId: "Authorization ID",
            customer: "Customer",
            paymentInstrumentId: "Payment Instrument ID",
            bankAccount: "Bank Account",
            cardholder: "Cardholder",
            origin: "Origin",
            accountHolderName: "Account Holder Name",
            avsCheck: "AVS check",
            cvcCheck: "CVC check",
            address: "Address",
            createdOn: "Created on",
            authorizationDate: "Authorization Date",
            amount: "Amount",
            unsignedForm: "Unsigned form",
            attestedBy: "Attested by",
            title: "Title",
            attestedForm: "Attested form",
            attestedDate: "Attested date",
            auditLogAttestedOn: "PCI SAQ A compliance form signed",
            auditLogCreatedOn: "PCI SAQ A compliance form created",
            id: "ID",
            dueOn: "Due on",
            attestedOn: "Attested on",
            validUntil: "Valid until",
            disputeId: "Dispute ID",
            reason: "Reason",
            chargebackFee: "Chargeback Fee",
            disputeInitiated: "Dispute Initiated",
            respondBy: "Respond By",
            lastUpdated: "Last updated",
            consumerName: "Consumer Name",
            consumerEmail: "Consumer Email",
            transaction: "Transaction",
            transactionDate: "Transaction Date",
            purchaseDate: "Purchase Date",
            purchaseDetails: "Purchase Details",
            refundPolicyDetails: "Refund Policy Details",
            shippingTrackingNumber: "Shipping Tracking Number",
            deliveryPartner: "Delivery Partner",
            serviceDescription: "Service Description",
            serviceDate: "Service Date",
            settlementId: "Settlement ID",
            depositId: "Deposit ID",
            transactionsDate: "Transactions Date",
            settlementDate: "Settlement Date",
            depositDate: "Deposit Date",
            totalAmount: "Total Amount",
            fees: "Fees",
            reserveDeduction: "Reserve Deduction",
            deductions: "Deductions",
            depositAmount: "Deposit Amount",
            transactionId: "Transaction ID",
            transactionAmount: "Transaction Amount",
            refundAmount: "Refund Amount",
            netAmount: "Net Amount",
            parentTransaction: "Parent Transaction",
            disputeType: "Dispute Type",
            disputedAmount: "Disputed Amount"
        },
        fieldTooltips: {
            reserveDeduction: "This was %{percentage}% of the net amount. It was deducted to make a rolling addition to your chargeback reserve."
        },
        emptyPlaceHolder: {
            payments: {
                noPaymentsFound: "No transactions found"
            },
            deposits: {
                noPaymentsFound: "No deposits found",
            },
            disputes: {
                noPaymentsFound: "No disputes found",
            },
            authorizations: {
                noPaymentsFound: "No authorizations found",
            },
            noPaymentsFilterData: "No results found.",
            noPaymentsFoundDescription: "Try changing the filters, or search using an ID.",
            noPaymentsFilterDataDescription: "Check if you’ve applied the right filters, or search using an ID.",
        },
        dashboard: {
            notifications: {
                compliance: {
                    title: "Attest PCI SAQ A compliance form.",
                    reviewForm: "Review the form and attest it to comply with payment processing requirements.",
                    seeDetails: "See Details"
                },
                negativeReserve: {
                    title: "Add $%{amount} to your chargeback reserve",
                    description: "Your chargeback reserve balance is negative. If you don't initiate fund transfer within 24 hours, your payment processing will stop.",
                    addFunds: "Add Funds"
                },
            },
            title: "Dashboard",
            view: "View",
            footer: {
                helpDocumentation: "Help Documentation",
                support: "Support",
                legalPrivacyPolicy: "Legal & Privacy Policy"
            },
            tooltip: {
                GROSS_VOLUME: "Total value of transactions processed",
                CHARGEBACKS: "Total number of chargebacks filed",
                DEPOSITS: "Total deposit amount processed",
                TRANSACTIONS: "Total number of transactions processed",
                PAYMENT_ACCEPTANCE_RATE: "Percentage of payment acceptance",
                CHARGEBACK_RATE: "Percentage of chargeback filed",
                INQUIRIES: "Total number of inquiries filed",
            },
            export: "Export (.PDF)"
        },
        transactions: {
            title: "Payments & Refunds",
            transactionInitiated: "Transaction Initiated",
            search: "Search transactions by ID",
            searchExport: "Clear search to export transactions",
            noExport: "No transactions to export",
            status: {
                SUCCESS: "Success",
                INITIATED: "Initiated",
                CREATED: "Created",
                FAILED: "Failed",
                CANCELED: "Cancelled",
                PROGRESS: "Pending",
                INITIATION_STARTED: "Pending",
                QUEUED_ACCEPTED: "Pending",
                QUEUED_FAILED: "Pending",
                QUEUED_SUCCEEDED: "Pending",
                INITIATION_AT_PAYFAC_FAILED: "Pending",
                PENDING: "Pending",
                VERIFICATION_PENDING: "Verification Pending",
                RETURNED: "Returned",
                REFUNDED: "Success",
                NOT_REFUNDED: "Failed",
            },
            tags: {
                dunning: "Dunning",
            },
            columns: {
                transaction: {
                    heading: "Transaction",
                },
                customer_info: {
                    heading: "Customer",
                },
                type: {
                    heading: 'Type',
                },
                payment_method: {
                    heading: 'Payment Method',
                },
                deductions: {
                    heading: 'Deductions',
                    tooltip: "Deductions include fees and reserves."
                },
                net_amount: {
                    heading: 'Net Amount',
                },
                settlement: {
                    heading: 'Transaction Date',
                },
                declined_error: {
                    heading: 'Declined Error'
                },
            },
            tabs: {
                SUCCESS: "Successful",
                FAILED: "Failed",
                ALL: "All",
                REFUND:  "Refunds"
            },
            footer: {
                helpDocumentation: "Help Documentation",
                support: "Support",
                legalPrivacyPolicy: "Legal & Privacy Policy"
            },
            showing: "Showing: ",
            noTransactionsFound : "No transactions found",
            message : {
                CHARGEBACK_CREDIT: "This amount was credited for winning a chargeback.",
                CHARGEBACK_DEBIT: "This amount was debited because of a chargeback. It will be credited back if you win.",
                CHARGEBACK_FEE: "This amount was debited as a fee for handling a chargeback. It will not be credited back even if you win the chargeback.",
                RESERVE: "This amount was added to your reserves. Funds in your reserve are used for refunds and chargebacks.",
                TRANSACTION_INITIATED: "Check transactions to see its status. You will receive a notification once it completes."
            },
            actions: {
                backToTransactions: "Back to Payments & Refunds",
                seeTransactionDetails: "See transaction details"
            }
        },
        addFundsToReserve: {
            title: "Add Funds",
            subTitle: "to reserve accounts",
            tabs: {
                selectBankAccount: "Select Bank Account",
                enterAmount: "Enter Amount",
                reviewAndConfirm: "Review & Confirm"
            },
            desc: {
                selectBankAccount: "Select the bank account from which you want to add funds.",
                enterAmount: "Enter the amount you want to add. You can add funds to both reserves in a single transaction.",
                reviewAndConfirm: "Review details and confirm."
            },
            reserves: {
                refundReserve: "Refund Reserve",
                refundReserveInfo: "These funds are primarily used for refunds, but can be used for chargebacks under exceptional circumstances.",
                chargebackReserve: "Chargeback Reserve",
                chargebackReserveInfo: "These funds are used to settle chargebacks when your daily balance account has insufficient funds."
            },
            actionMessage: {
                failedToAddFunds: "Failed to add funds to reserves. Error: ",
                selectBankAccountError: "Please select any of the bank account.",
                enterAmountErrorForNegBal: "Amount entered must be greater than or equal to %{amount}.",
                enterAmountMinimumUpfrontFee: "Amount entered must be greater than or equal to upfront fee amount %{amount}",
                enterAmountError: "Please add funds in at least one of the reserves."
            },
            labels: {
                achFees: "(ACH Fees)",
                balance: "Balance:",
                incomingFunds: "Incoming funds:",
                Pending: "Pending",
            },
            actions: {
                learnMoreAboutReserves: "Learn more about reserves",
                addFunds: "Add Funds"
            },
            message: {
                achFeeCalculationError: "Failed to calculate ACH fee. Error: ",
                addAtLeast: "Add at least ",
                achFundTransferInfo: "ACH fund transfer typically takes 2 to 6 business days. You will receive a notification once it completes so you can issue pending refunds, if any.",
                footerText: "Reserve accounts are used to settle chargebacks and issue refunds only when funds in your daily balance \n" +
                    "account are insufficient."
            }
        },
        reserve_split : {
            REFUND_RESERVE: "refunds",
            CHARGEBACK_RESERVE: "chargebacks"
        },
        authorizations: {
            title: "Authorizations",
            description: "An authorization indicates the availability of funds to process a payment.",
            search: "Search authorizations by ID",
            showing: "Showing: ",
            noAuthorizationsFound: "No authorizations found",
            actions: {
                backToAuthorizations: "Back to Authorizations"
            },
            tabs: {
                SUCCESS: "Successful",
                FAILED: "Failed",
                ALL: "All"
            },
            status: {
                SUCCEEDED: "Success",
                FAILED: "Failed",
                CANCELED: "Cancelled",
                PENDING: "Pending",
                UNAVAILABLE: "Unavailable"
            },
            columns: {
                authorization: {
                    heading: "Authorization",
                },
                customer_info: {
                    heading: "Customer",
                },
                authorization_date: {
                    heading: 'Authorization Date',
                },
                card: {
                    heading: 'Card',
                }
            },
        },
        paymentInstrument: {
            title: 'Payment Instrument',
            achdebit: 'ACH Debit',
            sepadebit: 'SEPA Debit',
            bacsdebit: 'BACS Debit',
            transfer: {
                achdebit: 'ACH Debit Transfer',
                sepadebit: 'SEPA Debit Transfer',
                bacsdebit: 'BACS Debit Transfer',
            },
            card: {
                expiresOn: 'Expires on',
                zipCode: 'Postal Code',
            },
            tooltip: {
                origin: "This was your cardholder’s approximate location during the transaction.",
                avs: "This verifies the cardholder’s billing address.",
                cvc: "This verifies the 3- or 4-digit security code on the card.",
            }
        },
        refunds: {
            allowedRefund: "You can create refund upto {amount} for this transaction.",
            noRefundsAvailable : "No refunds available",
        },
        disputes: {
            title: "Disputes",
            search: "Search disputes by ID",
            chargeback: "Chargeback is a type of dispute where a cardholder raises a request to get their money back.",
            inquiry: "Inquiry is a type of dispute where a cardholder raises a request against an unknown transaction.",
            showing: "Showing: ",
            noDisputesFound : "No disputes found",
            message: {
                CHARGEBACK: {
                    WON: "The chargeback amount is credited back 2 to 3 business days after winning the dispute.",
                    LOST: "The chargeback amount has been permanently refunded to the cardholder’s account.",
                    ACCEPTED: "The chargeback amount has been permanently refunded to the cardholder’s account.",
                    PENDING: "The chargeback amount has been deducted from your account. It will be credited back if you win the dispute.",
                    INQUIRY: "The chargeback amount has been deducted from your account. It will be credited back if you win the dispute.",
                    RESPONDED: "The chargeback amount has been deducted from your account. It will be credited back if you win the dispute.",
                },
                INQUIRY: {
                    LOST: "This inquiry has turned into a chargeback.",
                    PENDING: "If this inquiry turns into a chargeback, the disputed amount will be refunded to the cardholder as a preliminary step.",
                    INQUIRY: "If this inquiry turns into a chargeback, the disputed amount will be refunded to the cardholder as a preliminary step.",
                    RESPONDED: "If this inquiry turns into a chargeback, the disputed amount will be refunded to the cardholder during the dispute resolution period.",
                }
            },
            tooltips: {
                reason: "This is the reason for the chargeback as provided by the customer’s bank.",
                chargebackFee: "Your acquiring bank charges this fee for managing the chargeback.",
                respondBy: "If you don’t respond by this deadline, the dispute automatically moves to ‘Lost’.",
            },
            columns: {
                dispute: {
                    heading: "Dispute",
                },
                disputeType: {
                    heading: "Dispute Type",
                },
                initiated: {
                    heading: "Initiated",
                },
                respondBy: {
                    heading: "Respond By",
                },
                responded: {
                    heading: "Responded",
                },
                lastUpdated: {
                    heading: "Last updated",
                },
                customerInfo: {
                    heading: "Customer",
                },
                reason: {
                    heading: "Reason",
                },
                paymentMethod: {
                    heading: "Payment method",
                },
            },
            reason: {
                INQUIRY: "Inquiry",
                QUALITY: "Quality",
                CLERICAL: "Clerical",
                FRAUD: "Fraud",
                TECHNICAL: "Technical",
            },
            tabs: {
                OPEN: "Open",
                RESPONDED: "Responded",
                INQUIRY: "Inquiries",
                ALL: "All",
                WON: "Won",
                LOST: "Lost",
            },
            status: {
                WON: "Won",
                PENDING: "Open",
                INQUIRY: "Open",
                RESPONDED: "Responded",
                ACCEPTED: "Lost",
                ARBITRATION: "Open",
                LOST: "Lost",
            },
            type: {
                CHARGEBACK: "Chargeback",
                INQUIRY: "Inquiry"
            },
            actions: {
                acceptDispute: "Accept Dispute",
                respondToDispute: "Respond to Dispute",
                backToDisputes: "Back to Disputes"
            },
            evidenceSubmitted: "You successfully submitted the details for this dispute",
            markedAsLost: "We’ve marked this dispute as ‘Lost’.",
            markAsLostFailed: "Request to accept the dispute failed!",
            confirmAcceptDispute: "Accept Dispute?",
            confirmAcceptDisputeWarning: "You will not be able to submit evidence once you confirm. The chargeback amount of {amount} will be permanently refunded to the cardholder.",
            selectFile: "Select File",
            responseInfo: "Chargebacks entail a fee regardless of your course of action or the outcome.",
        },
        profile: {
            title: "Profile",
            description: "Review these forms to comply with payment processing requirements.",
            guidelinesCondition: "By submitting the Self-Assessment Questionnaire, I certify that I am an authorised representative of the company and that all information submitted is true and correct.",
            columns: {
                id: {
                    heading: "ID",
                },
                created_on: {
                    heading: "Created On",
                },
                form_type: {
                    heading: "Form Type",
                },
                due_on: {
                    heading: "Due On",
                },
                valid_until: {
                    heading: "Valid Until",
                },
                status: {
                    heading: "Status",
                }
            },
            tabs: {
                PAYMENTS_PROFILE: "Payments Profile",
                COMPLIANCE: "Compliance"
            },
            actions: {
                learnMore: "Learn More",
                backToCompliance: "Back to Compliance",
                attestComplianceForm: "Attest Compliance Form"
            },
            showing: "Showing: ",
            noComplianceFound:"No compliance form found",
            getComplianceFormFailed: "Could not get compliance form",
            getComplianceFormFileError: "Could not fetch compliance form file",
            submitAttestationError: "Error while submitting attestation. %{error}",
            submitAttestationSuccess: "Compliance form attested successfully",
            noComplianceFormYet: "No compliance forms",
            noComplianceFormYetDescription: "View and attest your compliance forms here when they become available.",
            attestComplianceFormNameFieldHint: "This should be a control person, an authorized company representative, or a beneficial owner.",
            validUntilNullDescription: "The expiry date is displayed here after you attest the form."
        },
        settlements: {
            title: "Settlements",
            columns: {
                settlement_id: {
                    heading: "Settlement ID",
                },
                initiated: {
                    heading: "Initiated",
                },
                settled: {
                    heading: "Settled",
                },
                settlement_amount: {
                    heading: "Settlement Amount",
                },
                fee: {
                    heading: "Fee",
                },
                deposit_amount: {
                    heading: "Deposit Amount"
                },
            },
            tabs: {
                SUCCESS: "Successful",
                FAILED: "Failed",
                ALL: "All Deposits"
            },
        },
        exports: {
            title: "Exports",
            sub_title: "Download exported data here for up to 7 days after it becomes available.",
            status: {
                REQUESTED: "Requested",
                INITIATED: "Initiated",
                COMPLETED: "Completed",
                FAILED: "Failed"
            },
            columns: {
                requested: {
                    heading: "Requested",
                },
                expires: {
                    heading: "Expires",
                },
                status: {
                    heading: "Status",
                },
                requested_from: {
                    heading: "Exported From",
                },
                download_link: {
                    heading: "Action"
                },
            },
            download: "Download",
            popup: {
                initiated: "Export initiated",
                description: "We have initiated export. When complete, you can download this file from the Exports page.",
                info_content: "The export file will be available for download for 7 days.",
                goto: "Go to Exports Page",
            },
            showing: "Showing: ",
            noExportsFound : "No exports found",
            noExportsYet: "No exports yet",
            noExportsYetDescription: "When you initiate an export, you can view and download it from here."
        },
        deposits: {
            title: "Deposits",
            view_deposit_schedule: "View Deposit Schedule",
            deposit_schedule: "Deposit Schedule",
            deposit_schedule_settlement_info: "The standard deposit schedule is T+2 business days, where T is the date of transaction.",
            deposit_schedule_settlement_additional_business_days: "Add an additional business day for transactions that occur after 8 p.m. PST or 7 p.m. PDT on business days or those occurring on Saturdays, Sundays, and holidays including any federal holiday not listed below.",
            holidays: "Holidays",
            description: 'A deposit is the transfer of funds to your bank account as the final step of the settlement process.',
            overview: 'Overview',
            estimated_future_deposits: 'Estimated Future Deposits',
            estimated_future_deposits_footer: 'These estimates can change because of new payments, refunds, disputes, or problems during settlement approval.',
            activity: 'Activity',
            deposit_q4: 'Deposits - Q4 2022',
            deposit_q4_footer: 'This is the total amount that has been deposited in your bank account.',
            on_the_way_to_your_bank_account: 'On the way to your bank account',
            on_the_way_to_your_bank_account_footer: 'This amount will be deposited soon.',
            funds_on_hold: 'Funds On Hold',
            funds_on_hold_footer: 'Funds are held in reserve, or account is on hold.',
            funds_in_reserve: 'Funds in Reserve',
            funds_in_reserve_footer: 'These funds are used for refunds and chargebacks.',
            date_of_transactions: "Date of Transactions",
            total_amount: "Total Amount",
            deductions: "Deductions",
            deposit_amount: "Deposit Amount",
            showing: "Showing: ",
            noDepositsFound : "No deposits found",
            actions: {
                backToDeposits: "Back to Deposits",
            },
            columns: {
                deposit: {
                    heading: "Deposit"
                },
                date_of_transactions:  {
                    heading: "Date of Transactions",
                    tooltip: "The date and time of the transactions which has been deposited."
                },
                total_amount:  {
                    heading: "Total Amount",
                },
                deductions:  {
                    heading: "Deductions",
                    tooltip: "Deductions can include fees, dues, and fines.",
                    adyen: {
                        heading: "Deductions",
                        tooltip: "Deductions can include fees, dues, fines, and additions to reserves.",
                    }
                },
                deposit_amount:  {
                    heading: "Deposit Amount"
                },
                holidays: {
                    heading: "Holidays"
                }
            },
            tabs: {
                SUCCESS: "Successful",
                FAILED: "Failed",
                ALL: "All Deposits",
                UPCOMING: "Upcoming",
                NEXT: "Next"
            },
            status: {
                APPROVED: "Success",
                SUCCEEDED: "Success",
                CANCELED: "Failed",
                FAILED: "Failed",
                PENDING: "In Progress",
                AWAITING_APPROVAL: "In Progress",
                NOT_AVAILABLE: "N/A"
            },
            type: {
                CREDIT: "Credit",
                DEBIT: "Debit",
            },
            breakdown: {
                header: {
                    component: "Component",
                    number: "Number",
                    amount: "Amount"
                },
                payments: "Payments",
                paymentsProcessingFee: "Payments processing fee",
                refunds: "Refunds",
                chargebacks: "Chargebacks",
                chargebackFees: "Chargeback fees",
                depositAmount: "Deposit Amount",
                rolling_reserve: {
                    tooltip: "This was %{percentage}% of the net amount. It was deducted to make a rolling addition to your chargeback reserve."
                },
                credits: {
                    fieldName: "Credits",
                    tooltip: "This amount was credited from winning a chargeback."
                }
            }
        },
        notifications: {
            title : "Notifications",
            tabs: {
                TODO: "To Do",
                EVENTS: "Events"
            },
            today: "TODAY",
            noTodoTitle: "No tasks found",
            noTodoDesc: "Try changing the task type.",
            noTodoFoundDesc: "Check if you’ve applied the right filters.",
            noEventsTitle: "No events found",
            noEventsDesc: "Try changing the events period.",
            id: "ID:",
            todo: {
                dispute_pending_title: "Respond to dispute",
                dispute_pending_desc: "A customer raised a chargeback for {amount} transaction.",
                dispute_response_reminder_title: "[Reminder] Respond to dispute",
                dispute_response_reminder_desc: "Dispute’s response deadline is about to over for {amount} transaction raised by a customer.",
                dispute_additional_evidence_required_title: "Submit additional evidence",
                dispute_additional_evidence_required_desc: "Upload a copy of the purchase receipt for {amount} transaction raised by a customer.",
                compliance_form_file_uploaded_title: "Attest PCI SAQ A compliance form",
                compliance_form_file_uploaded_desc: "Review the form and attest to comply with Payment Processing requirements.",
                merchant_balance_account_negative_title: "Add {amount} to your refund reserve.",
                merchant_balance_account_negative_desc: "Refunds are pending due to insufficient reserves. Learn more about reserves.",
                chargeback_reserve_failed_title: "Add {amount} to your chargeback reserve.",
                chargeback_reserve_failed_desc: "Your fund transfer of {amount} failed. Try again, or contact support.",
                refund_reserve_failed_title: "Add {amount} to your refund reserve.",
                refund_reserve_failed_desc: "Your fund transfer of {amount} failed. Try again, or contact support.",
                fund_added_to_reserve_failed_title: "Add {amount} to your reserve.",
                fund_added_to_reserve_failed_desc: "Your fund transfer of {amount} failed. Try again, or contact support.",
            },
            events: {
                dispute_pending_title: "New dispute",
                dispute_pending_desc: "A customer raised a chargeback for {amount} transaction.",
                dispute_won_title: "You won a dispute. 🎉",
                dispute_won_desc: "You won the dispute raised by a customer for {amount} transaction.",
                dispute_lost_title: "You lost a dispute.",
                dispute_lost_desc: "You lost a dispute raised by a customer for {amount}.",
                settlement_approved_title: "Settlement approved",
                settlement_approved_desc: "Settlement amount of {amount} is approved and on the way to your bank account.",
                deposit_succeeded_title: "Settlement deposited",
                deposit_succeeded_desc: "Settlement amount of {amount} is deposited to your bank account.",
                deposit_failed_title: "Settlement deposit failed",
                deposit_failed_desc: "Settlement amount of {amount} USD is failed. We will automatically retry and only contact you if necessary.",
                fund_added_to_reserve_title: "Funds added to your reserve.",
                fund_added_to_reserve_desc: "ACH transfer of {amount} USD was successful.",
                refund_reserve_title: "Funds added to your refund reserve.",
                refund_reserve_desc: "ACH transfer of {amount} USD was successful.",
                chargeback_reserve_title: "Funds added to your chargeback reserve.",
                chargeback_reserve_desc: "ACH transfer of {amount} USD was successful.",
            },
            seeDetails: "See details",
            clear: "Clear",
            addFunds: "Add Funds",
            seeTransactions: "See transactions",
            wait24Hours: "Wait 24 hours",
            clearList: "Clear List",
            markAsRead: "Mark as read",
            endOfData: "{tabName} from the last {days} days are displayed here.",
        },
        reports: {
            title : "Consolidated Reports",
            description: "Download consolidated reports of the activity from your payments account.",
            download: "Download Report",
            heading: "Summary for {month} {year}",
            summary: {
                fee: "Fees",
                refunds: "Total Refunds",
                deposits: "Total Deposits",
                net_volume: "Net Volume (Less Fees)",
                chargebacks: "Total Chargebacks",
                gross_volume: "Gross Volume",
                tooltip: {
                    deposits: "Due to the T+2 days settlement cycle, this amount can’t be derived from the transaction volume of this period alone. It may include settled transactions from the previous period and exclude transactions to be settled in the next period."
                }
            },
            noReportsYetTitle: "No reports yet",
            noReportsYetDesc: "When reports are available, you can view and download it from here.",
            successToast: "Report downloaded",
            reportNotFoundTitle: "No report found",
            reportNotFoundDesc: "This report wasn’t generated due to an internal error. Try again in a while. Contact Support if the problem persists.",
            reportNotAvailable:"Report not available",
            reportNotFoundError: "%{reportType} report type not found for month of %{month}",
            availableNextMonth:"Available on the 6th of "
        },
        tableOfContent: {
            onThisPage: "ON THIS PAGE",
            overview: "Overview",
            transaction: {
                payment_instrument: "Payment Instrument",
                payment_retries: "Payment Retries",
                refunds: "Refunds",
                auditLogs: "Audit Logs"
            },
            deposits: {
                breakdown: "Breakdown"
            },
            disputes: {
                evidence: "Evidence",
                transaction: "Transaction",
                payment_instrument: "Payment Instrument",
                auditLogs: "Audit Logs"
            },
            compliance: {
                attestation: "Attestation",
                auditLogs: "Audit Logs"
            },
            authorization: {
                payment_instrument: "Payment Instrument"
            },
        },
        auditLogs: {
            CHARGEBACK_DEBIT: "Chargeback amount debited",
            CHARGEBACK_CREDIT: "Chargeback amount credited",
            CHARGEBACK_FEE: "Chargeback fee debited"
        },
        migration: {
            title: {
                DRAFT: "Urgent Action required to continue using this service",
                REJECTED: "Action Required: Application Update",
                PROVISIONING: "Application Under Review",
                REJECTED_WITH_DECLINE: "Application Declined"
            },
            headerTitle: "Review & Accept the updated Terms",
            goBack: "Go back to Dashboard",
            description: {
                DRAFT: "Click to Update & Accept",
                REJECTED: "Click to submit the additional information",
                PROVISIONING: "View the status",
                REJECTED_WITH_DECLINE: "See more details"
            },
            header: {
                DRAFT: {
                    "info": "We have upgraded our Chargebee Embedded Payment Service to serve you better.",
                    "action": "You need to immediately review & update your onboarding information before ",
                    "finalAction": "and accept the Terms & Conditions."
                },
                PROVISIONING: "We have received your updated application. It is currently under review.",
                REJECTED: "We need some additional information to complete your application. "
                    + "Please review and update the required details.",
                REJECTED_WITH_DECLINE: "Your application was declined. Contact support to know more."
            },
            notEligibleInfo: {
                DRAFT: "You are not eligible for the upgrade. Contact support to know more.",
                APPROVED: "Your application is already approved."
            }
        }
    }
};
export default locales;