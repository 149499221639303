const BeneficialOwnerTypes = [
  {
    value: "INDIVIDUAL",
    displayText: "Individual"
  },
  {
    value: "ENTITY",
    displayText: "Entity"
  }
];
export default BeneficialOwnerTypes
