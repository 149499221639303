export const DefaultStatusFilterOption = [
    {
        name: "SUCCESS",
    },
    {
        name: "FAILED",
    },
    {
        name: "CANCELED",
    },
    {
        name: "PENDING",
    },
    {
        name: "RETURNED",
    },
];

export const DefaultTypeFilterOption = [
    {
        name: "DEBIT",
    },
    {
        name: "REFUND",
    },
    {
        name: "ACH_RETURN",
    },
    {
        name: "PAYMENT_RETURN"
    },
    {
        name: "PAYMENT_REVERSAL"
    },
    {
        name: "CHARGEBACK_CREDIT"
    },
    {
        name: "CHARGEBACK_DEBIT"
    },
    {
        name: "RESERVE"
    }
];

export const DefaultAlertTypeFilterOption = [
    {
        name: "all",
    },
    {
        name: "new_dispute",
    },
    {
        name: "dispute_reminders",
    },
    {
        name: "additional_evidence"
    },
    {
        name: "compliance_form"
    },
    {
        name: "negative_balance"
    },
    {
        name: "fund_added"
    }
]

export const PaymentMethodFilterOption = [
    {
        name: "CARD",
    },
    {
        name: "BANK_ACCOUNT",
    },
];

export const AmountFilterOption = [
    {
        name: "equals",
    },
    {
        name: "between",
    },
    {
        name: "greater_than",
    },
    {
        name: "less_than",
    },
]

