/* eslint-disable */
import axios, {AxiosInstance} from 'axios';
import {urlEncodeData} from './../functions/url-helper';

const formData = (data: any) => {
  const fd = new FormData();
  for (const p in data) {
    if (data.hasOwnProperty(p) && !(data[p] === undefined || data[p] == null)) {
        fd.append(p, data[p]);
    }
  }
  return fd;
};

export default class RestClient {
  private data: any;
  private axios: AxiosInstance;

  constructor(baseURL: string) {
    this.axios = axios.create({
      baseURL,
      transformRequest: [
        (data, headers) => {
          if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            return urlEncodeData(data);
          } else if (headers['Content-Type'] === 'multipart/form-data') {
            return formData(data);
          } else {
            return JSON.stringify(data);
          }
        }
      ]
    });
  }

  public get(path: string, params: any, headers = {}) {
    return this.axios.request({
      method: 'get',
      params,
      url: path,
      headers: {
        ...headers,
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
  }

  public delete(path: string, params: any, _data = {}, headers = {}) {
    return this.axios.request({
      method: 'delete',
      params,
      url: path,
      headers: {
          ...headers,
          'X-Requested-With': 'XMLHttpRequest'
        }
    });
  }

  public put(path: string, params: any, data = {}, headers = {}) {

    return this.axios.request({
      method: 'put',
      params,
      data,
      url: path,
      headers: {
          ...headers,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
    });
  }

  public patch(path: string, params: any, data = {}, headers = {}) {

    return this.axios.request({
      method: 'patch',
      params,
      data,
      url: path,
      headers: {
          ...headers,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
    });
  }

  public post(path: string, params: any, data = {}, headers = {}) {
    return this.axios.request({
      method: 'post',
      params,
      data,
      url: path,
      headers: {
          ...headers,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
    });
  }

  public postMultipart(path: string, params: any, data = {}, headers = {}, additionalConfig= {}) {
    return this.axios.request({
      method: 'post',
      params,
      data,
      url: path,
      headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        },
      ...additionalConfig
    });
  }

}
