/*eslint-disable @typescript-eslint/explicit-module-boundary-types*/
import { copyFromApiResponse } from "@/store/storeMappers";
import Vue from "vue";
import {isNullOrEmpty} from "@/app/utils/common/functions/functions";
import {ErrorCodeStatus, OnboardingStatus} from "@/app/utils/common/constants";
import {FinixErrorCodeMapper} from "@/app/utils/common/functions/payfac-error-code-mapper/finix/error-code-mapper";
import {AdyenErrorCodeMapper} from "@/app/utils/common/functions/payfac-error-code-mapper/adyen/error-code-mapper";

export const determineOnboardingStatus = (provisioningStatus: string | undefined,
                                          failureReasonCode: string | undefined,
                                          payfac: string | undefined) => {
  let status = provisioningStatus ?? OnboardingStatus.DRAFT;

  if (provisioningStatus === OnboardingStatus.REJECTED) {
    const errorCodeMapper = (payfac === "FINIX") ? new FinixErrorCodeMapper() : new AdyenErrorCodeMapper();
    status = failureReasonCode?.split(",").some(x => errorCodeMapper
        .cannotOnboardErrorCodes()
        .includes(errorCodeMapper.getRawErrorCode(x))) ?
        OnboardingStatus.REJECTED_WITH_DECLINE :
        OnboardingStatus.REJECTED;
  }

  return {
    status, failureCodes: failureReasonCode?.split(",").map(x => x.trim()).filter(x => x) ?? [],
  }
}

export const payfacDetailsDefaultState = () => ({
  errorCodes: {},
  data: {
    provisioningStatus: {
      apiPath: "provisioning_status",
      value: OnboardingStatus.DRAFT
    },
    failureReasonCode: {
      apiPath: "failure_reason_code",
      value: '',
    },
    failureReasonCategory: {
      apiPath : "failure_reason_category",
      value: ''
    },
  }
});


export const payfacDetailsState = {
  ADYEN : payfacDetailsDefaultState(),
  FINIX:  payfacDetailsDefaultState()
}

const payfacDetailsGetters = {
  /* To get onboarding status as per the payfac, Payfac parameter has to be passed.
   Why cannot we use sessionPayfac ? Because, merchant are supposed to onboard into both payfac,
   one will be active payfac, while other will be used during migration. */
  getOnboardingStatus: state => (payfac) => state[payfac].data.provisioningStatus.value,
  isEligibleForMigration: (state, getters, rootState, rootGetters) => {
    /* Not Eligible if active Payfac is Adyen */
    if (rootGetters['app/getActivePayfac'] === "ADYEN") {
      return false;
    }
    // Eligible if active payfac and sessionPayfac are different and  if merchant is not Approved on the migrated payfac
    return (rootGetters['app/getActivePayfac'] !== rootGetters['app/getSessionPayfac'])
        && state["ADYEN"].data.provisioningStatus.value !== "APPROVED";
  },

  /* All the below getters are using sessionPayfac because they will be used during onboarding and migration only. */
  getErrorCodesToBeFixed: (state, getters, rootState, rootGetters) => {
    const errorCodes = state[rootGetters['app/getSessionPayfac']].errorCodes;
    return isNullOrEmpty(errorCodes) ? [] : Object.keys(errorCodes)
        .filter(errorCode => errorCodes[errorCode] === ErrorCodeStatus.NOT_FIXED);
  },
  getErrorCodes: (state, getters, rootState, rootGetters) => {
    const errorCodes = state[rootGetters['app/getSessionPayfac']].errorCodes;
    return isNullOrEmpty(errorCodes) ? [] : Object.keys(errorCodes);
  },
  containsErrorCodeRequiringFileUpload: (state, getters, rootState, rootGetters) => {
    const errorCodes = state[rootGetters['app/getSessionPayfac']].errorCodes;
    return isNullOrEmpty(errorCodes) ? false : Object.keys(errorCodes).some(e => rootGetters["app/getPayfacErrorCodeMapper"]
        .errorCodesRequiringFileUpload().includes(rootGetters['app/getPayfacErrorCodeMapper'].getRawErrorCode(e)));
  },
  containsBankRelatedErrors: (state, getters, rootState, rootGetters) => {
    const errorCodes = state[rootGetters['app/getSessionPayfac']].errorCodes;
    return isNullOrEmpty(errorCodes) ? false : Object.keys(errorCodes)
        .some(x => rootGetters["app/getPayfacErrorCodeMapper"]
            .rejectionDueToBankAccount()
            .includes(rootGetters["app/getPayfacErrorCodeMapper"]
                .getRawErrorCode(x)))
  },
  containsDeclinedErrorCode: (state, getters, rootState, rootGetters) => {
    const errorCodes = state[rootGetters['app/getSessionPayfac']].errorCodes;
    return isNullOrEmpty(errorCodes) ? false : Object.keys(errorCodes)
        .some(x => rootGetters["app/getPayfacErrorCodeMapper"]
            .cannotOnboardErrorCodes()
            .includes(rootGetters["app/getPayfacErrorCodeMapper"]
                .getRawErrorCode(x)))
  }
}

const actions = {
  copyFromMerchantApiResponse({commit}, merchantApiResponse) {
    commit('copyFromApiResponse', merchantApiResponse)
  },
  setErrorCodeFixed({commit, rootGetters}, merchantErrorCode) {
    commit('setErrorCodeFixed', {"payfac" : rootGetters['app/getSessionPayfac'],
      "value" :merchantErrorCode});
  },
  setErrorCodes({commit, rootGetters}, merchantErrorCode) {
    commit('setErrorCodes', {"payfac" : rootGetters['app/getSessionPayfac'],
      "value" :merchantErrorCode});
  },
  setOnboardingStatus({commit}, {payfac, provisioningStatus}) {
    commit('setOnboardingStatus', {"payfac": payfac, "status": provisioningStatus})
  }
}

const mutations = {
  copyFromApiResponse: function (state, merchantApiResponse):void {
    const payfacDetails = merchantApiResponse?.payfac_details;
    if (isNullOrEmpty(payfacDetails) || payfacDetails.length === 0) return;
    payfacDetails.forEach((payfacDetail) => {
      const newPayfacDetail = payfacDetailsDefaultState();
      copyFromApiResponse(payfacDetail, newPayfacDetail.data);
      const merchantOnboardingStatus = determineOnboardingStatus(newPayfacDetail.data.provisioningStatus.value,
          newPayfacDetail.data.failureReasonCode.value, payfacDetail.payfac)
      newPayfacDetail.data.provisioningStatus.value = merchantOnboardingStatus.status;
      (merchantOnboardingStatus.failureCodes || []).forEach(v => {
        Vue.set(newPayfacDetail.errorCodes, v, ErrorCodeStatus.NOT_FIXED)
      });
      state[payfacDetail.payfac] = newPayfacDetail;
    })
  },
  setErrorCodeFixed: function(state, {payfac, value}): void {
    (value || []).forEach(v => {
      Vue.set(state[payfac].errorCodes, v, ErrorCodeStatus.FIXED)
    });
  },
  setErrorCodes: function(state, {payfac, value}): void{
    (value || []).forEach(v => {
      Vue.set(state[payfac].errorCodes, v, ErrorCodeStatus.NOT_FIXED)
    });
  },
  setOnboardingStatus: function (state, {payfac, status}) : void {
    state[payfac].data.provisioningStatus.value = status;
  }
}

export const merchantPayfacDetails = {
  namespaced: true,
  state: payfacDetailsState,
  getters: payfacDetailsGetters,
  actions,
  mutations,
};