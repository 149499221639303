<template>
  <div
    class="deposit-status"
    :class="{
      'progress-status': getMerchantSettlementDeposit(settlement).status === 'PENDING',
      'success-status': getMerchantSettlementDeposit(settlement).status === 'SUCCEEDED',
      'failure-status': getMerchantSettlementDeposit(settlement).status === 'FAILED',
      'canceled-status': getMerchantSettlementDeposit(settlement).status === 'CANCELED',
      'na-status': getMerchantSettlementDeposit(settlement).status === undefined,
    }"
  >
    {{
      getMerchantSettlementDeposit(settlement).status
        ? $t('deposits.status.' + getMerchantSettlementDeposit(settlement).status)
        : $t('deposits.status.NOT_AVAILABLE')
    }}
  </div>
</template>

<script>
import {DepositSubType} from "@/api/paymentApi";

export default {
  name: "DepositStatusTag",
  props: {
    settlement: {
      type: Object,
      required: true
    }
  },
  methods: {
    getMerchantSettlementDeposit(settlement) {
      return settlement.deposits.find(d => d.subType === DepositSubType.MERCHANT) || {}
    },
  }
}
</script>

<style lang="scss">
.deposit-status {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  height: fit-content;

  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  &.success-status {
    background-color: $success_100;
    color: $success_800;
  }

  &.failure-status {
    background-color: $danger_50;
      color: $danger_800;
  }

  &.canceled-status {
    background-color: $warn_50;
      color: $warn_800;
  }

  &.progress-status {
    background-color: $primary_100;
    color: $primary_800;
  }

  &.na-status {
    background-color: $neutral_50;
    color: $neutral_600;
  }
}



</style>