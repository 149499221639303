<template>
  <div class="table-container" :class="{'table-max-size': shouldRenderFullLengthTable || loading}">
    <div class="list-group" id="infinite-list">
      <table aria-describedby="Show transactions/settlements data">
        <tr class="table-header">
          <th v-if="fixedFirstColumn" class="first-column-th" :key="firstColumn">
            <div class="column-name" :class="{'amount-column': columnRightAlign.includes(firstColumn)}">
              {{ $t(columns[firstColumn]+".heading") }}
              <ToolTip
                placement="top-start"
                :content="$t(columns[firstColumn]+'.tooltip')"
                :arrow="false"
                v-if="tooltips.includes(firstColumn)"
              >
                <img alt="dropdown" src="@/app/assets/images/info-outline.svg" />
              </ToolTip>
            </div>
          </th>
          <th
            v-for="columnKey in otherColumns" :key="columnKey"
          >
            <div class="column-name" :class="{'amount-column': columnRightAlign.includes(columnKey)}">
              {{ $t(columns[columnKey]+".heading") }}
              <ToolTip
                placement="top-start"
                :content="$t(columns[columnKey]+'.tooltip')"
                :arrow="false"
                v-if="tooltips.includes(columnKey)"
              >
                <img alt="dropdown" src="@/app/assets/images/info-outline.svg" />
              </ToolTip>
            </div>
          </th>
        </tr>
        <tbody v-if="data.length !== 0">
          <tr
            v-for="item in data"
            :key="item.id"
            class="first-column row"
            @click="rowClicked(item)"
            :class="{clickable: clickableRows}"
            :id="getElementId()"
          >
            <td v-if="fixedFirstColumn" class="first-column-td">
              <slot :name="firstColumn" :item="item"></slot>
            </td>
            <td v-for="column in otherColumns" :key="column">
              <div class="type-column">
                <div class="type">
                  <slot :name="column" :item="item"></slot>
                </div>
              </div>
            </td>
          </tr>
          <template v-if="( !allItemsLoaded || loading ) && infiniteScroll">
            <tr class="shimmer-row" v-for="index in 2" :key="index">
              <td v-if="fixedFirstColumn" class="first-column-td">
                <div :name="firstColumn" class="line loading-shimmer"></div>
              </td>
              <td v-for="column in otherColumns" :key="column">
                <div class="line loading-shimmer"></div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr v-if="!loading">
            <td :colspan="otherColumns.length + (fixedFirstColumn ? 1 : 0)">
              <div class="empty-container">
                <EmptyTablePlaceholder
                  class="empty-container"
                  :entity="entity"
                  @clearFilter="$emit('clearFilter')"
                />
              </div>
            </td>
          </tr>
          <tr v-else class="shimmer-row" v-for="index in 15" :key="index">
            <td v-if="fixedFirstColumn" class="first-column-td">
              <div :name="firstColumn" class="line loading-shimmer"></div>
            </td>
            <td v-for="column in otherColumns" :key="column">
              <div class="line loading-shimmer"></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>


import EmptyTablePlaceholder from "@/components/transactions/EmptyTablePlaceholder";
import {navigateTo} from "@/router";
import {PendoPrefixes} from "@/app/utils/common/constants";
import {RouteConstants} from "@/router/routeConstants";
import ToolTip from "@/components/common/ToolTip.vue";

const MAX_ALLOWED_ITEMS_IN_TABLE_VIEW = 10;

export default {
  name: "TableView",
  components: {ToolTip, EmptyTablePlaceholder},
  props: {
    entity: {
      type: String,
      required: false,
      default: "",
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    allItemsLoaded: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Object,
      required: true
    },
    clickableRows: {
      type: Boolean,
      required: false,
      default: false
    },
    fixedFirstColumn: {
      type: Boolean,
      required: false,
      default: true
    },
    tooltips: {
      type: Array,
      required: false,
      default: () => []
    },
    columnRightAlign: {
      type: Array,
      required: false,
      default: () => []
    },
    infiniteScroll: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    firstColumn() {
      return Object.keys(this.columns)[0];
    },
    otherColumns() {
      return this.fixedFirstColumn ? Object.keys(this.columns).slice(1) : Object.keys(this.columns);
    },
    shouldRenderFullLengthTable() {
      return this.data.length > MAX_ALLOWED_ITEMS_IN_TABLE_VIEW
    },
  },
  methods: {
    configureChargebee:function () { navigateTo(RouteConstants.ONBOARDING_STATUS) },
    getElementId() {
      return PendoPrefixes.CBpay + this.$route.name + '-detail_page';
    },
    rowClicked(item) {
      if (this.clickableRows) {
        this.$emit('row-clicked', item.id, item)
      }
    }
  },
}
</script>
<style lang="scss">
/* width */
.list-group::-webkit-scrollbar {
  display: block;
  width: 8px;
  height: 8px;
}

/* Track */
.list-group::-webkit-scrollbar-track {
  background: $color_white;
  border-radius: 5px;
}

/* Handle */
.list-group::-webkit-scrollbar-thumb {
  background: $neutral_300;
  border-radius: 5px;
}

/* Handle on hover */
.list-group::-webkit-scrollbar-thumb:hover {
  background: $neutral_400;
  border-radius: 5px;
}

.table-container {
  border: 1px solid $neutral_100;
  background-color: $color_white;
  border-radius: 0px 0px 6px 6px;
  overflow: auto;
  max-height: 77vh;

  & #infinite-list {
    overflow: scroll;
    height: 100%;

    & table {
      border-collapse: inherit;
      width: 100%;
      border-spacing: 0;
    }

    & .empty-container {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & tr {
      border-bottom: 1px solid $neutral_100;
      &.clickable {
        &:hover {
          background-color: $primary_50;
          cursor: pointer;
        }
      }


      & td {
        padding-left: 20px;
        white-space: nowrap;
        border-bottom: 1px solid $neutral_100;
        min-height: 60px;
      }


      & th {
        font-weight: $weight_bold;
        color: #4E5762;
        box-shadow: inset 0 -1px 0 $neutral_100;
        font-size: 12px;
        position: sticky;
        height: 42px;
        top: 0;
        background-color: $color_white;
        z-index: 1;
        vertical-align: middle;
        text-align: left;
        padding-left: 20px;
        white-space: nowrap;
        border-radius: 6px 0px 0px 0px;
        border-bottom: 1px solid $neutral_100;
      }
    }

    & tr:last-child {
      & td {
        border-bottom: none;
      }
    }

    & .table-header {
      border-bottom: none;
    }

    & .column-name {
      display: flex;
      align-items: center;
      & .c-tooltip {
        margin-left: 5px;
        & .c-tooltip__trigger {
          display: flex;
        }
      }
    }

    & .amount-column{
      justify-content: right;
      padding-right: 20%;
    }
    & td, th {
      min-width: 110px;
    }

    & td {
      font-weight: $weight_normal;
    }

    & td:last-child, th:last-child {
      padding-right: 30px;
    }

    & .type-column {
      min-height: 60px;
      align-items: center;
    }

    & .first-column-th {
      position: sticky;
      left: 0;
      width: 350px;
      min-width: 350px;
      z-index: 98;
      box-shadow: inset -1px -1px 0 $neutral_100;
    }

    & .first-column-td {
      position: sticky;
      left: 0;
      width: 350px;
      min-width: 350px;
      box-shadow: inset -1px 0px 0 $neutral_100;
      padding: 10px 8px 10px 20px;
      background-color: white;
      &:hover {
        background-color: $primary_50;
        cursor: pointer;
      }
    }

    & .loading-shimmer {
      animation: shimmer 2s infinite linear;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
      border-radius: 10px;
    }

    & .shimmer-row {
      text-align: left;
      height: 50px;
    }

    & .line {
      height: 15px;
      margin: 10px 0;
    }

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }

    @-webkit-keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }
      100% {
        background-position: 1000px 0;
      }
    }
  }
}

.table-max-size {
  height: 100%;
}
</style>