<template>
  <div class="onboarding-view-container">
    <div
      class="onboarding-view no-sidebar"
      :class="{
        ['read-only']: readOnly
      }"
    >
      <div class="top-fixed" :class="{'top-fixed-without-header': readOnly}">
        <OnboardingHeader v-if="!readOnly" :is-migrating="isMigrating" />
        <div class="nav">
          <div
            class="nav-item"
            :class="{['nav-item-active']: currentTab === tabs.businessProfile}"
            @click="() => validateAndSwitchTab(tabs.businessProfile)"
            :id="getElementId('header-tab-business_profile')"
          >
            <PersonIcon />
            <span>{{ $t('tabs.businessProfile') }}</span>
          </div>

          <div
            class="nav-item" :class="{['nav-item-active']: currentTab === tabs.operations}"
            @click="() => validateAndSwitchTab(tabs.operations)"
            :id="getElementId('header-tab-' + tabs.operations)"
          >
            <OfficeBuildingIcon />
            <span>{{ $t('tabs.operations') }}</span>
          </div>

          <div
            class="nav-item" :class="{['nav-item-active']: currentTab === tabs.settlements}"
            @click="() => validateAndSwitchTab(tabs.settlements)"
            :id="getElementId('header-tab-' + tabs.settlements)"
          >
            <CardIcon />
            <span>{{ $t('tabs.settlements') }}</span>
          </div>
        </div>
      </div>
      <div class="form-content">
        <OnboardingForm
          :read-only="readOnly"
          v-if="currentTab === 'businessProfile'"
          :next="() => validateAndSwitchTab(tabs.operations)"
        />
        <OperationsForm
          :read-only="readOnly"
          v-if="currentTab === tabs.operations"
          :next="() => validateAndSwitchTab(tabs.settlements)"
          :prev="() => validateAndSwitchTab(tabs.businessProfile)"
        />
        <SettlementsForm
          :read-only="readOnly"
          v-if="currentTab === tabs.settlements"
          :prev="() => validateAndSwitchTab(tabs.operations)"
          :on-submit="submitFromSettlementsTab"
        />
      </div>

      <ModalPopup :show="showSubmitPopup">
        <div slot="body">
          <TermsAndConditions
            :on-cancel="() => {showSubmitPopup = false}"
            :is-migrating="isMigrating"
          />
        </div>
      </ModalPopup>
      <div class="footer-fixed">
        <i18n path="onboarding.assistance" tag="div" class="title-description">
          <template #faqPage>
            <c-link href="https://www.chargebee.com/docs/2.0/cb_payments_faq.html" target="_blank">
              {{ $t('onboarding.faqPage') }}
            </c-link>
          </template>
          <template #documentationPage>
            <c-link href="https://www.chargebee.com/docs/2.0/cb_payments.html" target="_blank">
              {{ $t('onboarding.documentationPage') }}
            </c-link>
          </template>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/onboarding/OnboardingHeader";
import BusinessProfileForm from "@/components/onboarding/BusinessProfileForm";
import OperationsForm from "@/components/onboarding/OperationsForm";
import SettlementsForm from "@/components/onboarding/SettlementsForm";
import {mapActions, mapGetters, mapState} from "vuex";
import ModalPopup from "@/components/common/ModalPopup";
import TermsAndConditions from "@/components/onboarding/TermsAndConditions";
import PersonIcon from "@/components/icons/PersonIcon";
import OfficeBuildingIcon from "@/components/icons/OfficeBuildingIcon";
import CardIcon from "@/components/icons/CardIcon";
import {FormEvents, PendoPrefixes, TabOrder, Tabs, OnboardingStatus} from "@/app/utils/common/constants";
import {EventBus} from "@/events/event-bus";
import {saveMerchantAsDraft} from "@/service/merchantService";
import FullStoryHelper from '@/app/utils/fullstory';
import {TERMS_AND_CONDITIONS_POPUP} from '@/app/utils/fullstory/constants';
import {storeMerchantContextForAdmin} from "@/service/saveInStore";
import {navigateTo} from "@/router";
import {getAndStoreMerchantAndConfiguration} from "@/service/sessionService";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: 'OnboardingView',
  components: {
    CardIcon,
    OfficeBuildingIcon,
    SettlementsForm,
    OperationsForm,
    ModalPopup,
    PersonIcon,
    OnboardingForm: BusinessProfileForm,
    OnboardingHeader, TermsAndConditions,
  },
  data: function () {
    return {
      showSubmitPopup: false,
      sidebarNavCollapsed: false,
    }
  },
  computed: {
    ...mapState('onboarding', {
      currentTab: state => state.currentTab,
    }),
    ...mapGetters('merchantPayfacDetails', ['isEligibleForMigration']),
    isAdmin() {
      return String(this.$router.currentRoute.query.adminMode) === "true"
    },
    isMigrating() {
      return (this.$route.query?.migration === true && this.isEligibleForMigration);
    },
    isMerchantReadOnly() { //need to check conditions again
      if (!this.isAdmin && this.isMigrating) {
        return false;
      }
      return !this.isAdmin && Boolean(this.getMerchantId)
    },
    readOnly() { //need to check conditions again
      if (this.isAdmin) {
        return true;
      } else if (this.isMigrating) {
        return false;
      }
      return Boolean(this.getMerchantId)
    },
    ...mapGetters('businessProfile', {isBusinessProfileValid: 'isValid'}),
    ...mapGetters('onboarding', ['getAutoSaveStatus']),
    ...mapGetters('businessAddress', {isBusinessAddressValid: 'isValid'}),
    ...mapGetters('businessStructure', {isBusinessStructureValid: 'isValid'}),
    ...mapGetters('stakeholdersDetails', {isStakeholdersDetailsValid: 'isValid'}),
    ...mapGetters('additionalUnderwritingData', {isAdditionalUnderwritingDataValid: 'isValid'}),
    ...mapGetters('bankAccountForPayouts', {isBankAccountForPayoutsValid: 'isValid'}),
    ...mapGetters('app', ['isAdminMode', 'getMerchantId', 'getActivePayfac']),
    ...mapGetters('merchantPayfacDetails', ['getOnboardingStatus']),
    tabValidations() {
      return {
        [Tabs.businessProfile]: {
          valid: this.isBusinessProfileValid
              && this.isBusinessAddressValid,
          generateError: this.generateBusinessProfileTabErrors,
        },
        [Tabs.operations]: {
          valid: this.isBusinessStructureValid
              && this.isStakeholdersDetailsValid,
          generateError: this.generateOperationsTabErrors,
        },
        [Tabs.settlements]: {
          valid: this.isAdditionalUnderwritingDataValid
              && this.isBankAccountForPayoutsValid,
          generateError: this.generateSettlementsTabErrors,
        },
      }
    },
  },
  created() {
    this.tabs = Tabs
  },
  mounted() {
    if (this.isAdmin) {
      if (storeMerchantContextForAdmin(this.$router.currentRoute.query)) {
        getAndStoreMerchantAndConfiguration(this.$router.currentRoute.query.merchantId);
      } else {
        return
      }
    }
    this.getMerchantDetailsIfMerchantUser();

    if (this.isMerchantReadOnly && this.getOnboardingStatus(this.getActivePayfac) !== OnboardingStatus.APPROVED) {
      navigateTo(RouteConstants.ONBOARDING_STATUS)
      return
    } else if (this.isMerchantReadOnly && this.getOnboardingStatus(this.getActivePayfac) === OnboardingStatus.APPROVED
        && this.$route.name === "onboarding" && !this.isMigrating) {
      navigateTo(RouteConstants.PROFILE)
      return
    }

    this.handleEnterButtonPress();
    this.validateAllFields()
  },
  methods: {
    ...mapActions('onboarding', ['setCurrentTab', 'validateAllFields', 'setAutoSaveStatus']),
    ...mapActions('businessProfile', {generateBusinessProfileErrors: 'validate'}),
    ...mapActions('businessAddress', {generateBusinessAddressErrors: 'validate'}),
    ...mapActions('businessStructure', {generateBusinessStructureErrors: 'validate'}),
    ...mapActions('additionalUnderwritingData', {generateAdditionalUnderwritingDataErrors: 'validate'}),
    ...mapActions('bankAccountForPayouts', {generateBankAccountForPayoutsErrors: 'validate'}),
    ...mapActions('app', ['updateAppState']),
    ...mapActions('stakeholdersDetails', {
      generateStackholdersErrors: 'validate',
      generateAtleastOneBeneficiaryPresentError: 'validateControlPersonPresent',
    }),
    ...mapActions('merchantPayfacDetails', ['updateMigrationProvisionStatus']),
    handleEnterButtonPress: function () {
      EventBus.$on(FormEvents.ENTER, ({source}) => {
        let component = source;
        //ignore events from popups
        while (component.$options.name !== this.$options.name) {
          component = component.$parent
          if (component.$options.name === 'ModalPopup') {
            return
          }
        }

        if (this.currentTab === Tabs.businessProfile) {
          this.validateAndSwitchTab(Tabs.operations)
        } else if (this.currentTab === Tabs.operations) {
          this.validateAndSwitchTab(Tabs.settlements)
        } else if (this.currentTab === Tabs.settlements) {
          this.submitFromSettlementsTab()
        }
      })
    },
    getMerchantDetailsIfMerchantUser: function () {
      if (this.isMerchantReadOnly) {
        getAndStoreMerchantAndConfiguration(this.getMerchantId);
      }
    },
    switchTab: function (nextTab) {
      this.setAutoSaveStatus(true);
      if(this.isMerchantReadOnly || this.isMigrating) {
        this.setCurrentTab(nextTab);
      } else {
        saveMerchantAsDraft()
          .then(response => {
            this.updateAppState({key: 'autoSavedDraftMerchantId', value: response.draft_id});
            this.setCurrentTab(nextTab);
            this.scrollToTop();
          })
          .catch(err => {
            this.$danger(this.$t('errors.saveOnboardingFailed') + " - " + err.source?.detail)
          });
      }
    },
    validateAndSwitchTab: function (nextTab) {
      if (this.readOnly) {
        this.setCurrentTab(nextTab);
        this.scrollToTop();
        return;
      }

      if (this.currentTab === nextTab) {
        return
      }

      //Do not perform validation on moving to a previous tab
      if (TabOrder.indexOf(this.currentTab) > TabOrder.indexOf(nextTab)) {
        this.switchTab(nextTab)
        return;
      }

      const tabsToValidate = TabOrder.slice(TabOrder.indexOf(this.currentTab), TabOrder.indexOf(nextTab))
      let firstInvalidTab = tabsToValidate.find(tab => !this.tabValidations[tab].valid);

      if (!firstInvalidTab) {
        this.switchTab(nextTab)
        return
      }

      this.tabValidations[firstInvalidTab].generateError().then(() => {
        this.$danger(this.$t('errors.validationErrorToastSave'))
        this.switchTab(firstInvalidTab);
      })
    },

    generateBusinessProfileTabErrors() {
      return Promise.all([
        this.generateBusinessAddressErrors(),
        this.generateBusinessProfileErrors()])
    },
    generateOperationsTabErrors() {
      return Promise.all([
        this.generateAtleastOneBeneficiaryPresentError(),
        this.generateBusinessStructureErrors()]);
    },
    generateSettlementsTabErrors() {
      let promises = [
        this.generateBankAccountForPayoutsErrors(),
        this.generateAdditionalUnderwritingDataErrors(),
      ];
      return Promise.all(promises);
    },
    submitFromSettlementsTab() {
      if (this.readOnly) {
        return;
      }
      this.generateSettlementsTabErrors()
          .then(values => {
            if (values.some(x => !x)) {
              this.$danger(this.$t('errors.validationErrorToastSave'))
            } else if (this.isMigrating) {
              this.showSubmitPopup = true
              FullStoryHelper.event(TERMS_AND_CONDITIONS_POPUP);
            } else {
                saveMerchantAsDraft()
                    .then(response => {
                      this.updateAppState({key: 'autoSavedDraftMerchantId', value: response.draft_id});
                      this.showSubmitPopup = true
                      FullStoryHelper.event(TERMS_AND_CONDITIONS_POPUP);
                    })
                    .catch(err => {
                      this.$danger(this.$t('errors.saveOnboardingFailed') + " - " + err.source?.detail)
                    });
              }
          })
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id;
    }
  },
}
</script>

<style lang="scss">
.onboarding-view-container {

  display: flex;

  & .onboarding-view {

    &.read-only {
      & input {
        pointer-events: none;
      }

      & .c-select {
        pointer-events: none;
      }

      & .c-dropdown {
        pointer-events: none;
      }
    }
    left: 256px;
    top: 0;
    position: relative;
    width: calc(100% - 256px);
    transition: all 0.2s ease-in;
    &.onboarding-view-sidebar-collapse {
      left: 86px;
      width: calc(100% - 86px);
    }

    &.no-sidebar {
      left: 0;
      width: 100%;
    }
  }

  & .footer-fixed {
    width: 100%;
    background: #F9FAFB;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 15px 8px;
    position: absolute;

    & .title-description {
      @media screen and (min-width: 801px) {
        margin-left: 320px;
        margin-right: 10px;
      }

      @media screen and (max-width: 800px) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  & .top-fixed {
    position: sticky;
    top: 0;
    left: 0;
    height: 20vh;
    width: 100%;
    z-index: 98;
    display: inline-block;

    .nav {
      display: flex;
      flex-direction: row;
      font-size: 14px;
      font-weight: $weight_bold;
      background-color: $color_white;
      padding-left: 20px;
      border-bottom: 1px solid #E5E7EB;
      margin-bottom: 2px;

      &:after {
        content: '';
        height: 2px;
        background-color: $color_white;
      }

      & .nav-item {
        display: flex;
        cursor: pointer;
        align-items: start;

        & svg {
          margin-right: 10px;
          color: $subtitle-text;
          height: 14px;
        }

        @media screen and (min-width: 801px) {
          padding: 20px;
        }

        @media screen and (max-width: 800px) {
          padding: 10px;
        }

        &:hover {
          background-color: $primary_fill_light;
        }

        &.disabled:hover {
          background-color: $color-white;
          cursor: not-allowed;
        }
      }

      & .nav-item-active {
        color: $primary_text;
        border-bottom: 2px solid $primary_text;

        & svg {
          margin-right: 10px;
          color: $primary_text;
        }
      }
    }

    &.top-fixed-without-header {
      height: 10vh;
    }
  }

  & .form-content {
    margin-top: 20px;
  }
}



.tabs {
  display: flex;

  > div:first-child {
    position: fixed;
    width: 100%;
    background-color: $color_white;
    z-index: 999;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

#business-profile:before, #operations:before, #settlement:before {
  font-family: cookie, serif;
  margin-right: 5px;
  font-weight: $weight_bold;
  font-size: 14px;
}

#business-profile, #operations, #settlement {
  padding: 16px;
  font-weight: $weight_bold;
  font-size: 14px;
}

#business-profile:before {
  content: "\E904";
}

#operations:before {
  content: "";
}

#settlement:before {
  content: "";
}


</style>
