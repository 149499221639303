
export default class CbError {
  source: any;
  status!: number;

  constructor(_status, response) {
    this.status = _status;
    this.source = response;
  }
}
