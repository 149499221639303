import RestClient from './../core/rest-client'; //@/cb-dls
import CbError from './../core/cb-error';
import store from '@/store'
import Vue from "vue";
import {CbStorage, HttpHeaders} from "@/app/utils/common/constants";

const handleAdminPortalUnauthorized = (headers: any) => {
    if (headers[HttpHeaders.clientName] === HttpHeaders.values.adminPortal) {
        Vue.prototype.$toast.danger("Unauthorized! Redirecting to Okta");
        window.open("/api/admin/login", "_self")
    }
};

class CbClient {
    // Billing Rules
    features: any;

    private restClient: RestClient;

    constructor() {
        this.restClient = new RestClient('/api/');
    }

    public catchError(error, reject) {
        if (error.response) {
            const cbError = new CbError(
                error.response.status,
                error.response.data
            );
            reject(cbError);
        }
        reject(error);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public get(urlEndPoint: string, args = {}, body = {}, headers = {}) {
        const that = this;
        const isAdminMode = store.getters["app/isAdminMode"];
        const jwtToken = this.getJwtToken(isAdminMode);
        if (!jwtToken && !isAdminMode) {
            return Promise.reject(new Error("Sorry! You are unauthenticated. Please try again."));
        }
        if(jwtToken) {
            headers['Authorization'] = "Bearer " + jwtToken
        }

        const applicationName = store.getters["app/getApplicationName"];
        if(applicationName) {
            headers['x-cb-payments-app-name'] = applicationName;
            headers[HttpHeaders.clientName] = HttpHeaders.values.adminPortal;
        }

        return new Promise((resolve, reject) => {
            that.restClient
                .get(urlEndPoint, args, headers)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.catchError(error, reject);
                });
        });
    }

    public postMultipart(urlEndPoint: string, queryParams = {}, body = {}, headers = {}, additionalConfig = {}) {
        const that = this;
        const isAdminMode = store.getters["app/isAdminMode"];
        const jwtToken = this.getJwtToken(isAdminMode);
        if (!jwtToken && !isAdminMode) {
            return Promise.reject(new Error("Sorry! You are unauthenticated. Please try again."));
        }
        if(jwtToken) {
            headers['Authorization'] = "Bearer " + jwtToken
        }

        return new Promise((resolve, reject) => {
            that.restClient
                .postMultipart(urlEndPoint, queryParams, body, headers, additionalConfig)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.catchError(error, reject);
                });
        });
    }

    public post(urlEndPoint: string, queryParams = {}, body = {}, headers = {}) {
        const that = this;
        const isAdminMode = store.getters["app/isAdminMode"];
        const jwtToken = this.getJwtToken(isAdminMode);
        if (!jwtToken && !isAdminMode) {
            return Promise.reject(new Error("Sorry! You are unauthenticated. Please try again."));
        }
        if(jwtToken) {
            headers['Authorization'] = "Bearer " + jwtToken
        }

        const applicationName = store.getters["app/getApplicationName"];
        if(applicationName) {
            headers['x-cb-payments-app-name'] = applicationName;
            headers[HttpHeaders.clientName] = HttpHeaders.values.adminPortal;
        }

        return new Promise((resolve, reject) => {
            that.restClient
                .post(urlEndPoint, queryParams, body, headers)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.catchError(error, reject);
                });
        });
    }

    public put(urlEndPoint: string, queryParams = {}, body = {}, headers = {}) {
        const that = this;
        const isAdminMode = store.getters["app/isAdminMode"];
        const jwtToken = this.getJwtToken(isAdminMode);
        if (!jwtToken && !isAdminMode) {
            return Promise.reject(new Error("Sorry! You are unauthenticated. Please try again."));
        }
        if(jwtToken) {
            headers['Authorization'] = "Bearer " + jwtToken
        }

        return new Promise((resolve, reject) => {
            that.restClient
                .put(urlEndPoint, queryParams, body, headers)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.catchError(error, reject);
                });
        });
    }

    public delete(
        urlEndPoint: string,
        queryParams = {},
        body = {},
        headers = {}
    ) {
        const that = this;
        const isAdminMode = store.getters["app/isAdminMode"];
        const jwtToken = this.getJwtToken(isAdminMode);
        if (!jwtToken && !isAdminMode) {
            return Promise.reject(new Error("Sorry! You are unauthenticated. Please try again."));
        }
        if(jwtToken) {
            headers['Authorization'] = "Bearer " + jwtToken
        }

        return new Promise((resolve, reject) => {
            that.restClient
                .delete(urlEndPoint, queryParams, body, headers)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    this.catchError(error, reject);
                });
        });
    }

    public cbFetch(
        method: string,
        _decimalConfig: any,
        urlEndPoint: string,
        queryParams: any = {},
        headers: any = {},
        body: any = {}
    ) {
        return new Promise((resolve, reject) => {

            let fn: (
                urlEndPoint: string,
                queryParams?: any,
                body?: any,
                headers?: any
            ) => Promise<any>;

            if (method === 'PUT') {
                fn = this.put.bind(this);
            } else if (method === 'POST') {
                fn = this.post.bind(this);
            } else if (method === 'DELETE') {
                fn = this.delete.bind(this);
            } else {
                fn = this.get.bind(this);
            }

            fn(urlEndPoint, queryParams, body, headers)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    CbClient.handleError(error, headers, reject);
                });
        });
    }

    private static handleError(error, headers: any, reject: (reason?: any) => void) {
        if (error.status && error.status == 401) {
            //unauthorized
            let msg;
            handleAdminPortalUnauthorized(headers);
            if (error.source) {
                msg = error.source.detail ? error.source.detail : error.source;
            } else {
                msg = "Unauthorized";
            }
            Vue.prototype.$toast.danger(msg);
            //redirect to 404 page
            window.open("/error", "_self")
        }
        reject(error);
    }

    getJwtToken(isAdminMode: boolean) {
        if (isAdminMode)
            return null;
        const jwtToken = window.cbStorage.getItem(CbStorage.JwtToken);
        if (!jwtToken) {
            window.open("/error", "_self")
        }
        return jwtToken;
    }
}

export default new CbClient();

export class unSegmentedCbClient {
    // not implemented yet
}
