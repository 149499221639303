import store from '@/store'
import FlattenUtil from "flat";
import {mergeDeep} from "@/app/utils/common/helpers/merge";
import merchants from "@/api/merchants";
import drafts from "@/api/drafts";
import {MerchantRequestDto} from "@/api/paymentApi";
import {storeToApiRequest} from "@/store/storeMappers";
import { CountryBasedApiField } from "@/store/modules/onboarding/onboardingTypes";

export const saveMerchantAsDraft = () => {
    const requestBody = getOnboardingRequest();
    requestBody.auto_save = store.state.onboarding.autoSave;
    return drafts.saveOnboardingDraft(requestBody)
}

export const submitOnboardingMerchant = () => {
    const requestBody = getOnboardingRequest();
    return merchants.createMerchant(requestBody)
}

export const updateOnboardingMerchant = () => {
    const requestBody: MerchantRequestDto = getOnboardingRequest();
    if (!store.getters['merchantPayfacDetails/containsBankRelatedErrors']) {
        requestBody.payment_instrument = undefined
    }

    return merchants.updateMerchant(
        store.getters['app/getMerchantId'],
        requestBody)
}

export const getMerchant = (merchantId) => {
    return merchants.fetchMerchant(merchantId)
}

export const getDraftMerchant = (draftId) => {
    return drafts.fetchOnboardingDraft(draftId)
}

export const getMerchantFiles = (merchantId) => {
    return merchants.listFilesForMerchant(merchantId)
}

export const getMerchantConfiguration = async (merchantId) => {
    return await merchants.fetchMerchantConfiguration(merchantId)
}

const convertStoreDataToApiRequest = (data: CountryBasedApiField) => {
    if (data === undefined) return data;
    const objWithApiPath = Object.keys(data)
        .reduce((obj, key) => {
            if (data[key].shouldShowInView && !data[key].shouldShowInView(store.getters)) {
                return obj;
            }
            const value = data[key].getValue ? data[key].getValue() : data[key].value;
            return ({...obj, [data[key].apiPath]: value});
        }, {});
    return FlattenUtil.unflatten(objWithApiPath);
}

function getOnboardingRequest(): MerchantRequestDto {
    const businessProfile = convertStoreDataToApiRequest(store.state.businessProfile.data);
    const businessAddress = convertStoreDataToApiRequest(store.state.businessAddress.data);
    const businessStructure = convertStoreDataToApiRequest(store.state.businessStructure.data);
    const additionalUnderwritingData = convertStoreDataToApiRequest(store.state.additionalUnderwritingData.data);
    const bankAccountForPayouts = convertStoreDataToApiRequest(store.state.bankAccountForPayouts.data)
    const agreements = convertStoreDataToApiRequest(store.state.agreementAcceptance.data);

    const controlPerson = storeToApiRequest(store.state.stakeholdersDetails.data[0]);
    const additionalBeneficiaries = storeToApiRequest(store.state.stakeholdersDetails.data.slice(1)).additional_beneficiaries;

    return mergeDeep(businessProfile,
        businessAddress,
        businessStructure,
        additionalUnderwritingData,
        bankAccountForPayouts,
        controlPerson,
        {additional_beneficiaries: additionalBeneficiaries || []},
        agreements
    );
}

export const getStore = () => store;
