import Vue from 'vue'
import App from '@/App.vue'
import "@chargebee/cookie-ui/dist/styles.min.css";
import "@/app/assets/style/global.scss"
import router from '@/router';
import CookieUI from '@chargebee/cookie-ui';
import VueI18n from 'vue-i18n';
import store from "@/store";
import Scrollspy from 'vue2-scrollspy';
import i18n from "@/i18n";
import "@/plugins/echarts";
import cbStorage from '@/app/utils/common/core/cb-storage';
import "vue-echarts/dist/csp/index.esm.min.js"
import "vue-echarts/dist/csp/style.css"

Vue.use(Scrollspy);

Vue.use(VueI18n);
Vue.config.productionTip = false

Vue.use(CookieUI);

window.cbStorage = cbStorage;

new Vue({
  i18n,
  store,
  // @ts-ignore
  router,
  render: h => h(App)
}).$mount('#app')
