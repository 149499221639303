<template>
  <div class="admin-view">
    <div class="logo">
      <img src="@/app/assets/images/brand/cb-payments.svg" alt="CB Payments" />
    </div>
    <div class="content-container">
      <div class="welcome" v-if="!loading">
        Hi {{ userName }}, Welcome to Chargebee Payments
      </div>
      <div class="context-chooser" v-if="!loading">
        <ChooseApplication v-if="!getApplicationName" />
        <ChooseMerchant :show-close-popup-button="false" v-if="getApplicationName && !getMerchantId" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ChooseApplication from "@/components/admin/ChooseApplication";
import ChooseMerchant from "@/components/admin/ChooseMerchant";
import {navigateTo} from "@/router";
import axios from "axios";
import {RouteConstants} from "@/router/routeConstants";
import {getAndStoreMerchantAndConfiguration} from "@/service/sessionService";

export default {
  name: "AdminView",
  components: {ChooseApplication, ChooseMerchant},
  data: () => {
    return {
      merchants: [],
      loading: true,
      userName: ""
    };
  },
  computed: {
    ...mapGetters('app', ['getApplicationName', 'getMerchantId', 'getMerchantName', 'isAdminMode']),
  },
  async mounted() {
    axios.get("/api/admin/user").then(response => {
      this.$emit('preLoaderStatusMessage', {loadingMessage: this.$t('landing.loggingIn'), keepPreLoaderOpen: false});
      this.userName = response.data.name
      this.loading = false;
    }).catch(() => {
      this.$emit('preLoaderStatusMessage', {loadingMessage: this.$t('errors.unAuthorized'), keepPreLoaderOpen: true});
      this.$danger(this.$t('errors.unAuthorized'));
      window.open("/admin/login", "_self")
    })

    this.updateAppState({key: 'adminMode', value: true})
    if (this.getApplicationName && this.getMerchantId) {
      await getAndStoreMerchantAndConfiguration(this.getMerchantId)
      navigateTo(RouteConstants.DASHBOARD, true, {
        adminMode: true,
        applicationName: this.getApplicationName,
        merchantId: this.getMerchantId,
        merchantName: this.getMerchantName
      })
    }
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
  },
}
</script>
<style lang="scss">

.admin-view {
  display: flex;

  & .logo {
    width: 200px;
    padding-top: 40px;
  }

  & .content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .welcome {
      font-size: 24px;
      margin-top: 80px;
      font-weight: $weight_bold;
    }
  }
}
</style>