<template>
  <div class="rejection-action-container">
    <div class="title">
      {{ $t('status.applicationStatus') }}
    </div>

    <div class="action-cards">
      <RejectionActionCard v-for="code in failureCodes" :key="code" :failure-code="code" />
    </div>
  </div>
</template>
<script>
import RejectionActionCard from "@/components/onboarding/RejectionActionCard";
export default {
  name: "RejectionAction",
  components: {RejectionActionCard},
  props: {
    failureCodes: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.rejection-action-container {
  padding: 32px;
  text-align: left;
  & .title {
    font-size: 14px;
    font-weight: $weight-bold;
    margin-bottom: 14px;
  }

  & .sub-title {
    font-size: 12px;
    color: $subtitle-text;
    margin-bottom: 14px;
  }
}

</style>