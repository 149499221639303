import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import {businessProfile} from "@/store/modules/onboarding/businessProfile";
import {businessAddress} from "@/store/modules/onboarding/businessAddress";
import {businessStructure} from "@/store/modules/onboarding/businessStructure";
import {bankAccountForPayouts} from "@/store/modules/onboarding/bankAccountForPayouts";
import {additionalUnderwritingData} from "@/store/modules/onboarding/additionalUnderwritingData";
import {stakeholdersDetails} from "@/store/modules/onboarding/stakeholderDetails";
import {onboarding} from "@/store/modules/onboarding/onboarding";
import {app} from "@/store/modules/app";
import {agreementAcceptance} from "@/store/modules/onboarding/agreementAcceptance";
import {disputes} from "@/store/modules/onboarding/disputes";
import {merchantBalanceReserve} from "@/store/modules/merchantBalanceReserve";
import {merchantPayfacDetails} from "@/store/modules/merchantPayfacDetails";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    plugins: debug ? [createLogger()] : [], // set logger only for development
    modules: {
        onboarding,
        businessProfile,
        businessAddress,
        businessStructure,
        bankAccountForPayouts,
        additionalUnderwritingData,
        stakeholdersDetails,
        app,
        agreementAcceptance,
        disputes,
        merchantBalanceReserve,
        merchantPayfacDetails
    }
})
