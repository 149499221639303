<template>
  <div class="bp-container">
    <slot>
      <div class="header">
        {{ $t('businessProfile.title') }}
      </div>
      <div class="subtitle">
        {{ $t('businessProfile.subtitle') }}
      </div>
      <div class="separator"></div>
    </slot>

    <InputField
      id="company-name"
      data-testid="company-name"
      :label="$t('businessProfile.companyName.label')"
      :value="companyName"
      :set-value="updateCompanyName"
      :error="errors.companyName"
      :hint="`${$t('businessProfile.companyName.instruction')}`"
    />

    <InputField
      id="doing-business-as"
      data-testid="doing-business-as"
      :label="$t('businessProfile.doingBusinessAs.label')"
      :value="doingBusinessAs"
      :set-value="updateDoingBusinessAs"
      :error="errors.doingBusinessAs"
      :optional="true"
      :hint="$t('businessProfile.doingBusinessAs.instruction')"
    />

    <InputField
      :label="$t('businessProfile.description.label')"
      id="business-description"
      data-testid="business-description"
      :value="description.value"
      :set-value="updateDescription"
      :error="errors.description"
      :hint="$t('businessProfile.description.instruction', {max: getMaxChars(description.apiPath)})"
    />
    <div class="line">
      <InputField
        :label="$t('businessProfile.website.label')"
        id="website"
        data-testid="website"
        :value="website.value"
        :set-value="updateWebsite"
        :error="errors.website"
        :hint="$t('businessProfile.website.instruction')"
      />
      <PhoneInput
        :label="$t('businessProfile.phone.label')"
        id="phone"
        data-testid="phone"
        :value="phoneFormattedValue"
        :country-code="phoneCountryCode"
        :set-value="(countryCode, value) => updatePhone({countryCode, value})"
        :error="errors.phone"
        :hint="$t('businessProfile.phone.instruction')"
        class="fs-mask"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import InputField from "@/components/common/InputField";
import {getMaxChars} from "@/app/utils/translationUtil";
import PhoneInput from "@/components/common/PhoneInput";
import StateCodeList from "@/data/state-code-list";
import {AppDefaults} from "@/app/utils/common/constants";

export default {
  name: 'BusinessProfile',
  components: {InputField, PhoneInput},
  data: function () {
    return {
      stateOptions: []
    }
  },
  computed: {
    ...mapState({
      errors: state => state.businessProfile.errors,
      companyName: state => state.businessProfile.data.companyName.value,
      doingBusinessAs: state => state.businessProfile.data.doingBusinessAs.value,
      description: state => state.businessProfile.data.description,
      website: state => state.businessProfile.data.website,
      phone: state => state.businessProfile.data.phone,
      phoneFormattedValue: state => state.businessProfile.data.phone.formattedValue,
      phoneCountryCode: state => state.businessProfile.data.phone.value?.split(" ")[0],
    }),
  },
  mounted() {
    const statesList = StateCodeList[AppDefaults.country];
    this.stateOptions = Object.keys(statesList).map(stateName => ({
      displayText: stateName,
      value: statesList[stateName],
    }));
  },
  methods: {
    ...mapActions('businessProfile', [
      'updateCompanyName', 'updateDoingBusinessAs', 'updateDescription', 'updatePhone', 'updateWebsite'
    ]),
    getMaxChars: (key) => getMaxChars('createMerchant', key),
  },
}
</script>

<style lang="scss" scoped>
.bp-container {
  text-align: left;
  width: 100%;
  max-width: 700px;
  align-items: start;
  display: flex;
  flex-direction: column;
  padding: 8px;

  & .header {
    font-weight: $weight_bold;
    font-size: 24px;
    color: $primary_text;
    text-align: left;
    margin-bottom: 8px;
  }

  & .subtitle {
    font-size: 12px;
    color: $neutral_600;
  }

  & .separator {
    background-color: $divider_color;
    height: 1px;
    margin-top: 20px;
    width: 100%;
  }

  & .line {
    display: flex;
    width: 100%;
    gap: 20px;

    & .field {
      flex: 1 1 50%;
    }

  }

  & .field {
    margin-top: 24px;
    width: inherit;

    & .c-input {
      margin-top: 4px;
      margin-bottom: 8px;
    }

    & .hint {
      font-size: 12px;
      color: $primary_text_dark;
    }

    & .c-label {
      white-space: nowrap;
    }
  }
}


</style>
