<template>
  <div class="create-refund-container">
    <div class="title">
      Create Refund
    </div>
    <div class="fields-row">
      <FieldValue
        :field-name="$t('fieldNames.transactionId')"
        :field-value="transaction.id"
      />

      <FieldValue
        :field-name="$t('fieldNames.transactionAmount')"
        :field-value="format('currency', transaction.amount, '', transaction.currency)
          + ' ' + transaction.currency"
      />
    </div>

    <InputField
      label="Refund Amount"
      id="refund-amount"
      append-slot="USD"
      prepend-slot="$"
      :value="refundAmount"
      :set-value="(event) => {refundAmount = event.target.value}"
      :error="error"
      :hint="$t('refunds.allowedRefund', {amount: getAllowedRefund})"
    />

    <SelectField
      label="Reason Code"
      :searchable="false"
      :clearable="true"
      :value="reasonCode"
      :options="getRefundReasons()"
      :on-update="(value) => {reasonCode = value}"
      select-classname="refund-reason-code"
      data-testid="refund-reason-code"
    />

    <div class="buttons">
      <c-button variant="action" @click="onDismiss">
        Dismiss
      </c-button>
      <c-button variant="primary" @click="createRefund" :disabled="loading">
        Create
      </c-button>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import InputField from "@/components/common/InputField";
import SelectField from "@/components/common/SelectField";
import Formatter from "@/app/utils/common/functions/formatter";
import refunds from "@/api/refunds";
import {mapGetters} from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "CreateRefund",
  components: {FieldValue, InputField, SelectField},
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true
    },
    refunds: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      refundAmount: null,
      reasonCode: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId']),
    getAllowedRefund() {
      let alreadyRefundedAmount = this.refunds.reduce((sum, x) => sum + x.amount, 0);
      return this.format('currency', this.transaction.amount - alreadyRefundedAmount,
      "", this.transaction.currency)
    }
  },
  methods: {
    format(type, data, format, currencyCode) {
      return Formatter.format({type, data, format, currencyCode})
    },
    async createRefund() {
      this.loading = true
      this.error = null;
      try {
        await refunds.createRefund(this.getMerchantId, this.transaction.id, uuidv4(),
            {amount: this.refundAmount})
        this.$emit('refundCreated');
        this.onDismiss()
      } catch (error) {
        this.error = error.source?.detail
      }
      this.loading = false
    },
    getRefundReasons () {
      return [
        {
          displayText: "Product unsatisfactory",
          value: "PRODUCT_UNSATISFACTORY"
        },
        {
          displayText: "Service unsatisfactory",
          value: "SERVICE_UNSATISFACTORY"
        },{
          displayText: "Order change",
          value: "ORDER_CHANGE"
        },{
          displayText: "Order cancellation",
          value: "ORDER_CANCELLATION"
        },{
          displayText: "Waiver",
          value: "WAIVER"
        },{
          displayText: "Other",
          value: "OTHER"
        },
      ]
    }
  },
}
</script>

<style lang="scss">
.create-refund-container {
  padding: 24px 32px;

  & .title {
    font-weight: $weight-medium;
    font-size: 20px;
    text-align: left;
  }

  & .fields-row {
    display: flex;
    & div {
      flex: 1;
    }
  }

  & .field {
    text-align: left;
  }

  & .buttons {
    margin-top: 40px;
    display: flex;
    justify-content: end;
    align-items: center;

    gap: 20px;
    & .c-button__label {
      padding: 0;
    }

    & .c-button--primary {
      margin-bottom: 0;
    }
  }

}
</style>