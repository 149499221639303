<template>
  <div class="compliance-form-response-view">
    <div class="header">
      <div class="form-details">
        <div class="form-type-container">
          <div class="form-type">
            {{ getComplianceFormType }}
          </div>
          <ComplianceFormStatusTag
            class="form-status"
            :compliance-form="complianceForm"
          />
        </div>
        <ComplianceFormOverview
          :show-title="false"
          :show-attested-at="true"
          :in-progress-or-complete-status="inProgressOrCompleteStatus"
          :compliance-form="complianceForm"
          class="form-overview"
        />
      </div>
      <div class="buttons-container" v-if="!inProgressOrCompleteStatus">
        <c-button variant="primary" @click="onAttest">
          Attest
        </c-button>
        <c-button variant="action" @click="onDismiss">
          Dismiss
        </c-button>
      </div>
      <div v-else class="close" @click="onDismiss">
        <CloseIcon />
      </div>
    </div>
    <div class="pdf-viewer">
      <iframe
        :src="complianceFormPresignedUrl"
        :title="getIframeTitle"
        type="application/pdf"
        width="820px"
        height="454px"
      ></iframe>
    </div>
  </div>
</template>

<script>
import ComplianceFormStatusTag from '@/components/complianceforms/ComplianceFormStatusTag.vue';
import { ComplianceFormResponseDto, ComplianceFormSignedStatus } from '@/api/paymentApi';
import ComplianceFormOverview from '@/components/complianceforms/ComplianceFormOverview.vue';
import complianceForms from "@/api/complianceForms";
import Formatter from "@/app/utils/common/functions/formatter";
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  name: 'ComplianceFormResponse',
  components: { ComplianceFormOverview, ComplianceFormStatusTag, CloseIcon },
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true,
    },
    inProgressOrCompleteStatus: {
      type: Boolean,
      required: true
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    onAttest: {
      type: Function,
      required: true
    }
  },
  data: function() {
    return {
      complianceFormPresignedUrl: null
    }
  },
  computed: {
    getComplianceFormType() {
      return this.complianceForm.compliance_form_type.replace(/_/g, ' ');
    },
    getComplianceFormSignedStatus() {
      return this.complianceForm.compliance_form_status === 'COMPLETE' ?
        ComplianceFormSignedStatus.SIGNED:ComplianceFormSignedStatus.UNSIGNED;
    },
    getIframeTitle() {
      return "Compliance form " + (this.complianceForm.compliance_form_status === "COMPLETE" ? "signed":"unsigned")
          + " PDF file prefilled with merchant details and required checks.";
    }
  },
  mounted: function () {
    this.fetchComplianceFormPdfUrl();
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
    fetchComplianceFormPdfUrl: async function() {
      let response;
      try{
        response = await complianceForms.downloadComplianceFormBySignedStatus(
            this.complianceForm.merchant_id, this.complianceForm.id, this.getComplianceFormSignedStatus);
        this.complianceFormPresignedUrl = response.url;
      }catch (e) {
        this.$danger(this.$t('profile.getComplianceFormFileError'));
        this.onDismiss();
        return;
      }
    }
  },
};
</script>

<style lang="scss">
.compliance-form-response-view {
  padding: 40px;
  position: relative;

  & .header {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: $color_white;
    z-index: 99;

    & .form-details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;

      & .form-type-container {
        display: flex;
        align-items: center;
        gap: 12px;


        & .form-type {
          color: $neutral_800;
          font-weight: $weight_bold;
          line-height: 36px;
          font-size: 24px;
          margin-right: 12px;
        }
      }

      & .form-overview {
        display: flex;
        width: 600px;
        align-items: flex-start;

        & .fields {
          display: flex;
          width: 600px;
          align-items: flex-start;

          & .column {
            display: flex;
            padding-right: 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            flex: 1 0 0;
          }
        }
      }

      & .column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        text-align: left;
      }
    }

    & .buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      position: absolute;
      top: 0;
      right: 0;

      & .c-button__label {
        padding: 0;
      }

      & .c-button--primary {
        margin-bottom: 0;
        width: 150px;
      }

      & .c-button--action {
        margin-top: 0;
        height: fit-content;
      }
    }
  }

    & .close {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }

  & .pdf-viewer {
    margin-top: 35px;

    iframe {
      border: 0px;
    }
  }
}
</style>

