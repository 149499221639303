<template>
  <div class="notification-todo-loader">
    <div class="content">
      <div class="loader m-title"></div>
      <div class="loader l-description"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "NotificationLoader"
}
</script>

<style lang="scss">
.notification-todo-loader {
  width: 100%;
  justify-content: space-between;
  padding: 16px 16px 16px 16px;

  .content {
    display: inline-block;
    font-size: 14px;
    text-align: left;

    .loader {
      margin-top: 12px;
      background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
      background-size: 1000px 100%;
      border-radius: 10px;
      animation: shimmer 2s infinite linear;
    }

    .m-title {
      height: 14px;
      width: 30vw;
    }

    .l-description {
      line-height: 20px;
      height: 14px;
      width: 55vw;
    }
  }
}
</style>