<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      width="40" height="40" x="0" y="0" style="mask-type:luminance"
      maskUnits="userSpaceOnUse" id="mask0_18791_46953"
    >
      <path d="M0 1.90735e-05H40V40H0V1.90735e-05Z" fill="white" />
    </mask>
    <g mask="url(#mask0_18791_46953)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33.8849 14.7933C33.4202 14.7933 32.9075 15.0525 32.703 15.5181L30.5305 20.4983L29.7479 15.5181C29.6559 15.0525 29.3395 14.7933 28.8388 14.7933H26.0864L28.4406 23.0642C28.4839 23.2228 28.4921 23.3987 28.4604 23.5837C28.3372 24.2739 27.6779 24.8338 26.9874 24.8338H25.4461C25.0547 24.8338 24.7969 25.0762 24.6709 25.6251L24.3285 27.6748H27.0429C28.459 27.6748 30.1221 26.9633 31.2314 24.9961L36.6664 14.7933H33.8849Z" fill="#323E48" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.16615 12.3252C7.97899 12.3252 8.37181 12.6495 8.22878 13.4643L7.99457 14.7918H9.86213L9.4506 17.1238H7.5822L7.07325 20.0112C6.89736 21.0172 7.89316 21.1512 8.46443 21.1512C8.57828 21.1512 8.67287 21.1487 8.74056 21.1447L8.28373 23.7452C8.14269 23.7602 7.9974 23.7798 7.68983 23.7798C6.27201 23.7798 3.58991 23.4 4.11727 20.408L4.69476 17.1238H3.33331L3.74312 14.7918H5.09126L5.52516 12.3252H7.16615Z" fill="#323E48" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8596 14.8983L22.3798 17.6172C22.9714 17.9195 23.3767 18.5332 23.3767 19.2421C23.3767 20.1767 22.676 20.9425 21.774 21.0527L21.2945 23.7711C21.3792 23.7765 21.4653 23.7793 21.5511 23.7793C24.0584 23.7793 26.0891 21.7472 26.0891 19.2421C26.0891 17.1938 24.7291 15.4608 22.8596 14.8983Z" fill="#00ADBC" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7239 20.8678C20.1328 20.5673 19.7267 19.9507 19.7267 19.2426C19.7267 18.314 20.4288 17.5422 21.3306 17.434L21.8095 14.7154C21.7243 14.71 21.6376 14.706 21.5523 14.706C19.047 14.706 17.0151 16.7395 17.0151 19.2426C17.0151 21.2917 18.3757 23.0259 20.2453 23.5884L20.7239 20.8678Z" fill="#00ADBC" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9432 14.7935H12.548C13.3611 14.7935 13.7522 15.1183 13.6089 15.9334L13.4333 16.9196C14.0312 15.706 15.3281 14.7048 16.6583 14.7048C16.8328 14.7048 17.0008 14.7394 17.0008 14.7394L16.4822 17.6798C16.4822 17.6798 16.2494 17.6257 15.886 17.6257C15.1771 17.6257 13.9782 17.8506 13.311 19.1825C13.1518 19.5108 13.0294 19.9101 12.9448 20.3913L12.3642 23.6948H9.37476L10.9432 14.7935Z" fill="#323E48" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M36.6667 24.1091C36.6667 24.3745 36.5726 24.6014 36.3851 24.7875C36.1996 24.9741 35.9736 25.0698 35.7068 25.0698C35.4428 25.0698 35.2168 24.9741 35.0285 24.7875C34.8416 24.6014 34.7473 24.3745 34.7473 24.1091C34.7473 23.8446 34.8416 23.6183 35.0285 23.4303C35.2168 23.2439 35.4428 23.1499 35.7068 23.1499C35.9736 23.1499 36.1996 23.2439 36.3851 23.4303C36.5726 23.6183 36.6667 23.8446 36.6667 24.1091ZM36.516 24.1091C36.516 24.336 36.4367 24.5289 36.2781 24.6878C36.1212 24.8478 35.9289 24.9274 35.7068 24.9274C35.4853 24.9274 35.2953 24.8478 35.137 24.6878C34.9806 24.5289 34.9027 24.336 34.9027 24.1091C34.9027 23.8831 34.9806 23.6908 35.137 23.5311C35.2953 23.3705 35.4853 23.2909 35.7068 23.2909C35.9289 23.2909 36.1212 23.3705 36.2781 23.5311C36.4367 23.6908 36.516 23.8831 36.516 24.1091ZM36.1104 24.6345H35.9399L35.6989 24.2051H35.5403V24.6345H35.395V23.5823H35.7521C35.8442 23.5823 35.9226 23.6138 35.9869 23.6733C36.0506 23.7336 36.0846 23.8086 36.0846 23.8973C36.0846 24.0434 36.0082 24.1403 35.8578 24.1862L36.1104 24.6345ZM35.9342 23.8973C35.9342 23.8446 35.9167 23.8004 35.8787 23.7645C35.8416 23.7288 35.7943 23.7118 35.736 23.7118H35.5403V24.0808H35.736C35.7943 24.0808 35.8416 24.0624 35.8787 24.0293C35.9167 23.9942 35.9342 23.9514 35.9342 23.8973Z" fill="#323E48" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'TroyIcon',
}
</script>