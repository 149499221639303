<template>
  <div class="dispute-details-form">
    <div class="dispute-details-title">
      Details
    </div>
    <div class="dispute-details-content">
      <DateInput
        label="Purchase Date"
        :value="disputeResponse.digital_goods_details.purchase_date"
        id="purchase-date"
        :error="error.purchase_date"
        :set-value="(date) => updateDisputeDetail('purchase_date', date)"
        :show-mandatory="true"
        :use-milliseconds="false"
      />

      <InputTextAreaField
        label="Purchase Details"
        :value="disputeResponse.digital_goods_details.purchase_details"
        id="purchase-details"
        data-testid="purchase-details"
        :error="error.purchase_details"
        hint="Specify the goods or services that were purchased. Include links, where relevant."
        :set-value="(value) =>updateDisputeDetail('purchase_details', value)"
        :show-mandatory="true"
      />
      <InputTextAreaField
        label="Refund Policy Details"
        :value="disputeResponse.digital_goods_details.refund_policy_details"
        id="refund-policy-details"
        :error="error.refund_policy_details"
        data-testid="refund-policy-details"
        hint="Specify how the refund policy does not apply to this purchase. Include links, where relevant."
        :set-value="(value) => updateDisputeDetail('refund_policy_details', value)"
        :show-mandatory="true"
      />
    </div>
  </div>
</template>

<script>
import InputTextAreaField from "@/components/common/InputTextAreaField";
import DateInput from "@/components/common/DateInput";

export default {
  name: "DisputeDetailsDigitalProductsInput",
  components: {DateInput, InputTextAreaField},
  props: {
    disputeResponse: {
      type: Object,
      required: true
    },
    updateDisputeDetail: {
      type: Function,
      required: true
    },
    error: {
      type: Object,
      required: true
    }
  },
}
</script>
<style lang="scss">
.dispute-details-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;

  & .dispute-details-title {
    font-size: 16px;
    font-weight: $weight_bold;
  }


}
</style>