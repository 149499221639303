<template>
  <div class="refund-audit-info" v-if="transaction">
    <div class="title">
      Audit Logs
    </div>

    <div class="content">
      <div class="event">
        ACH returned payment
      </div>
      <div class="event-time">
        {{ formatDateWithTimezone(transaction.created_at.toString()) }}
        {{ $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()}) }}
      </div>

      <div class="event" v-if="sourceTransaction">
        Payment succeeded
      </div>
      <div class="event-time" v-if="sourceTransaction">
        {{ formatDateWithTimezone(sourceTransaction.created_at.toString()) }}
        {{ $t('global.time.utcTimeZoneDifference', { time: getUtcTimezoneDifference()}) }}
      </div>
    </div>
  </div>
</template>

<script>
import {TransactionResponseDto} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import {getUtcTimezoneDifference} from "@/app/utils/common/functions/dateUtils";

export default {
  name: "AchReturnAuditLogs",
  props: {
    transaction: {
      type: TransactionResponseDto,
      required: true,
    },
    sourceTransaction: {
      type: TransactionResponseDto,
      required: true,
    },
  },
  methods: {
    getUtcTimezoneDifference,
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDateWithTime', data: date})
    },
  },
}
</script>

<style lang="scss">
.refund-audit-info {
  & .title {
    text-align: left;
    font-size: 16px;
    font-weight: $weight-bold;
  }

  & .content {
    margin-top: 12px;
    text-align: left;

    & .event-time {
      color: $neutral_400;
      margin-top: 4px;
      margin-bottom: 16px;
      font-size: 12px;
    }
  }
}
</style>