var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-retries-container"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('tableOfContent.transaction.payment_retries'))+" ")]),_c('div',{staticClass:"payment-retries-info"},_vm._l((_vm.getDunningAttemptsData()),function(attempt){return _c('div',{key:attempt.id,staticClass:"payment-retries-row",on:{"mouseover":function () { _vm.id = attempt.id },"mouseleave":function () { _vm.id = null }}},[_c('div',{staticClass:"status-tag-container"},[_c('div',{staticClass:"status",class:{
            'success-status': ['SUCCESS', 'REFUNDED'].includes(attempt.state),
            'returned-status': attempt.state === 'RETURNED',
            'failure-status': ['FAILED', 'NOT_REFUNDED'].includes(attempt.state),
            'canceled-status': attempt.state === 'CANCELED',
            'progress-status':
              !['SUCCESS', 'FAILED', 'CANCELED', 'NOT_REFUNDED', 'REFUNDED', 'RETURNED'].includes(attempt.state)
          }},[_vm._v(" "+_vm._s(_vm.$t('transactions.status.' + attempt.state))+" ")])]),_c('div',{staticClass:"created-at-container",on:{"click":function($event){return _vm.goToTransaction(attempt.id)}}},[_vm._v(" "+_vm._s(_vm.formatDateWithTimezone(attempt.created_at.toString()))+" ")]),(attempt.error_reason)?_c('div',[_vm._v(" — ")]):_vm._e(),(attempt.error_reason)?_c('div',{staticClass:"status-info-container"},[_vm._v(" "+_vm._s("'" + attempt.error_reason + "'")+" ")]):_vm._e(),_c('div',{staticClass:"link-icon-container"},[(_vm.id === attempt.id)?_c('div',{staticClass:"icon",on:{"click":function($event){return _vm.goToTransaction(attempt.id)}}},[_c('LinkIcon')],1):_vm._e()])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }