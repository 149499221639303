import { init, setUserVars, event} from '@fullstory/browser';
import { FULL_STORY_OPTIONS } from '@/app/utils/fullstory/constants';

class FullStoryHelper {
    private isEnabled = false;
    private isReady = false;
    private options!: any;

    enable(options) {
        this.options = options;
        init(FULL_STORY_OPTIONS);
        this.isEnabled = true;
        this.setCustomVariables();
        window['_fs_ready'] = () => this.onFsReady();
    }

    private onFsReady() {
        this.isReady = true;
    }

    event(name, data: { [key: string]: any }) {
        this.isEnabled && event(name, data);
    }

    private setCustomVariables() {
        setUserVars({
            cbpayments_merchant_id: this.options.merchantId,
            cbpayments_external_id: this.options.externalId,
            cbpayments_merchant_name: this.options.merchantName
        });
    }
}

export default new FullStoryHelper();