<template>
  <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99998 13.6654C1.54165 13.6654 1.14942 13.5023 0.823313 13.1762C0.496646 12.8495 0.333313 12.457 0.333313 11.9987V9.4987H1.99998V11.9987H12V9.4987H13.6666V11.9987C13.6666 12.457 13.5036 12.8495 13.1775 13.1762C12.8508 13.5023 12.4583 13.6654 12 13.6654H1.99998ZM6.99998 10.332L2.83331 6.16536L3.99998 4.95703L6.16665 7.1237V0.332031H7.83331V7.1237L9.99998 4.95703L11.1666 6.16536L6.99998 10.332Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'DownloadsIcon',
}
</script>