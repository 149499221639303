import {
    RefundResponseDto, RefundStatus,
    SaleResponseDto,
    SaleStatus,
    TransactionResponseDto,
    TransactionState,
    TransactionType,
} from "@/api/paymentApi";

export const mapSaleToTransaction = (sale: SaleResponseDto): TransactionResponseDto => {
    return {
        id: sale.transaction_id,
        consumer: sale.consumer,
        type: TransactionType.DEBIT,
        merchant_id: sale.merchant_id,
        payment_instrument: sale.payment_instrument,
        currency: sale.currency,
        state: mapSaleStatusToTransactionState(sale.status),
        error_reason: sale.error_reason,
        error_code: sale.error_code,
        fraud_session_id: sale.fraud_session_id,
        amount: sale.amount,
        fee: sale.fee,
        net_amount: sale.net_amount,
        created_at: sale.created_at,
        updated_at: sale.updated_at,
        tags: sale.tags
    } as TransactionResponseDto
}

export const mapRefundToTransaction = (refund: RefundResponseDto): TransactionResponseDto => {
    return {
        id: refund.transaction_id,
        consumer: refund.consumer,
        type: refund.type,
        merchant_id: refund.merchant_id,
        payment_instrument: refund.payment_instrument,
        currency: refund.currency,
        state: mapRefundStatusToTransactionState(refund.status),
        source_transaction_id: refund.source_transaction_id,
        error_reason: refund.error_reason,
        error_code: refund.error_code,
        fraud_session_id: undefined,
        amount: refund.amount,
        created_at: refund.created_at,
        updated_at: refund.updated_at,
    } as TransactionResponseDto
}

export const mapRefundStatusToTransactionState =
    (refundStatus: RefundStatus | undefined): TransactionState | undefined => {
    switch (refundStatus) {
        case RefundStatus.SUCCEEDED:
            return TransactionState.SUCCESS;
        case RefundStatus.FAILED:
            return TransactionState.FAILED;
        case undefined:
            return undefined;
    }
}

export const mapSaleStatusToTransactionState = (saleStatus: SaleStatus | undefined): TransactionState | undefined => {
    switch (saleStatus) {
        case SaleStatus.CREATED:
            return TransactionState.INITIATIONSTARTED;
        case SaleStatus.INITIATED:
            return TransactionState.PENDING;
        case SaleStatus.SUCCEEDED:
            return TransactionState.SUCCESS;
        case SaleStatus.FAILED:
            return TransactionState.FAILED;
        case SaleStatus.CANCELED:
            return TransactionState.CANCELED;
        case undefined:
            return undefined
    }
}