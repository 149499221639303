<template>
  <div class="main-container">
    <SidebarNav @update:sidebarNavCollapsed="sidebarNavCollapsed = $event" />
    <SearchBar :sidebar-nav-collapsed="sidebarNavCollapsed" />
    <div
      class="disputes-container"
      :class="{['disputes-container-sidebar-collapse']: sidebarNavCollapsed}"
    >
      <div class="disputes-sub-container">
        <div class="table-header">
          {{ $t('disputes.title') }}
        </div>

        <div class="nav">
          <div
            class="nav-item"
            v-for="tab in tabs"
            :key="tab.name"
            :class="{'nav-item-active': tab.name === currentTab, [tab.name]: true}"
            @click="() => switchTab(tab.name)"
            :id="getElementId('tab-' + tab.name)"
          >
            <span>{{ $t('disputes.tabs.' + tab.name) }}</span>
          </div>
        </div>
        <TableFilterOption
          :disabled="loading.all"
          table-label="Disputes"
          @childApplyFilter="applyFilter"
          @childClearFilter="applyFilter"
          :current-tab="currentTab"
          :enable-filters="['dateFilter', 'statusFilter', 'typeFilter', 'amountFilter', 'paymentMethodTypeFilter']"
          :date-filter-duration="12"
          ref="tableFilterOptionComponent"
          v-bind="tableFilterOptionConfig"
        />
        <Search
          :placeholder="$t('disputes.search')"
          @search="searchDisputes"
          class="search-container"
        />

        <div class="header-container">
          <div class="transaction-header-count">
            <div v-if="count!==0" class="showing-count-top" :class="{'hide-loader': loading.all || getDisputes.length===0}">
              {{ $t('disputes.showing') }} <b class="pagination"> {{ disputesCount }} </b>
            </div>
            <div v-else class="showing-count-top" :class="{'hide-loader': loading.all}">
              {{ $t('disputes.noDisputesFound') }}
            </div>
            <div
              class="loader"
              :class="{'hide-loader': !loading.all}"
            ></div>
          </div>
          <export-button
            :id="getElementId('export')"
            :tooltip-content="tooltipContent()"
            :tooltip-enabled="!(searchQuery ==='' && getDisputes.length>0) && !isAdminMode"
            :create-export="createExport"
            :disabled="loading.all || getDisputes.length === 0 || searchQuery !== '' || isAdminMode"
            :loading="loading.export"
          />
        </div>

        <DisputeTable
          :disputes="filteredDisputes"
          :loading="loading.all"
          :all-items-loaded="allItemsLoaded"
          :infinite-scroll="searchQuery === ''"
          @clearFilter="clearFilterFromEmptyPlaceholder"
          :columns="getColumns"
        />

        <PortalFooter />
      </div>
    </div>

    <ModalPopup :show="showSubmitPopup">
      <div slot="body">
        <ExportsPopUp
          :on-cancel="() => {showSubmitPopup = false}"
        />
      </div>
    </ModalPopup>
  </div>
</template>

<script>

import SidebarNav from "@/components/SidebarNav";
import {mapActions, mapGetters} from "vuex";
import TableFilterOption from "@/components/common/TableFilterOption";
import Search from "@/components/common/Search";
import DisputeTable from "@/components/disputes/DisputeTable";
import disputes from "@/api/disputes";
import {portalCheckMixin} from "@/mixin/portalPageCheck";
import PortalFooter from "@/components/portal/PortalFooter";
import {ResourceType, Sort, SortBy} from "@/api/paymentApi";
import Formatter from "@/app/utils/common/functions/formatter";
import SearchBar from "@/components/SearchBar";
import {DateFilters, PendoPrefixes} from "@/app/utils/common/constants";
import ModalPopup from "@/components/common/ModalPopup.vue";
import ExportsPopUp from "@/components/exports/ExportsPopUp.vue";
import ExportButton from "@/components/common/ExportButton.vue";
import {exportMixin} from "@/mixin/exportMixin";
import {
  getDatePeriodFromFilter,
} from "@/app/utils/common/functions/dateUtils";

export default {
  name: 'DisputeView',
  components: {
    ExportButton,
    ExportsPopUp, ModalPopup, SearchBar, DisputeTable, Search, SidebarNav, TableFilterOption, PortalFooter},
  mixins: [portalCheckMixin, exportMixin],
  beforeRouteEnter(to, from, next) {
    if (to.name === "disputes" && from.name === "dispute_details"){
      next(vm => {
        vm.clearData();
        vm.initialize(true);
      });
      return;
    }
    next();
  },
  data: () => {
    return {
      disputes: [],
      offsetId: null,
      listElm: null,
      pageSize: 15,
      dateFilter: {
        startDate: undefined,
        endDate: undefined,
      },
      allItemsLoaded: false,
      loading: {
        all: false,
        export: false
      },
      sidebarNavCollapsed: false,
      count: 0,
      searchQuery: "",
      searchResults: [],
      currentTab: "OPEN",
      selectedStatus: ["PENDING", "INQUIRY"],
      selectedType: [],
      selectedPaymentMethodType: [],
      amountFilter: {
        gt: null,
        gte: null,
        lt: null,
        lte: null,
      },
      tabs: [
        {
          name: "OPEN",
        },
        {
          name: "RESPONDED",
        },
        {
          name: "INQUIRY",
        },
        {
          name: "ALL",
        },
        {
          name: "WON",
        },
        {
          name: "LOST",
        },
      ],
      disputeStatusFilter: [
        {
          name: "OPEN",
        },
        {
          name: "RESPONDED",
        },
        {
          name: "WON",
        },
        {
          name: "LOST",
        }
      ],
      disputeTypeFilter: [
        {
          name: "INQUIRY",
        },
        {
          name: "CHARGEBACK",
        }
      ],
      showSubmitPopup: false,
      tableFilterOptionConfig: {},
    }
  },
  computed: {
    ...mapGetters('app', ['getMerchantId', 'isAdminMode']),
    ...mapGetters('disputes', ['getDisputes']),
    isAdmin() {
      return String(this.$router.currentRoute.query.adminMode) === "true"
    },
    disputesCount() {
      return this.getDisputes.length + " / " + this.count;
    },
    filteredDisputes() {
      return this.searchQuery === ""
          ? this.getDisputes
              .filter(x => (this.setSelectedStatus(this.currentTab) || [x.status]).includes(x.status))
          : this.searchResults;
    },
    getColumns() {
      let columns = {};
      columns['dispute'] = 'disputes.columns.dispute'
      columns['dispute_type'] = 'disputes.columns.disputeType'
      columns['initiated'] = 'disputes.columns.initiated'
      if (this.currentTab === "OPEN" || this.currentTab === "INQUIRY" || this.currentTab === "ALL") {
        columns['respond_by'] = 'disputes.columns.respondBy'
      }

      if (this.currentTab === "RESPONDED" || this.currentTab === "ALL") {
        columns['responded'] = 'disputes.columns.responded'
      }

      if (this.currentTab === "ALL" || this.currentTab === "WON" || this.currentTab === "LOST") {
        columns['last_updated'] = 'disputes.columns.lastUpdated';
      }
      columns['customer_info'] = 'disputes.columns.customerInfo'
      columns['reason'] = 'disputes.columns.reason'
      columns['payment_method'] = 'disputes.columns.paymentMethod'
      return columns;
    },
  },
  mounted() {
    window.cbStorage.setItem('dateFilter', DateFilters.MONTH_TO_DATE);
    ({ startDate: this.dateFilter.startDate, endDate: this.dateFilter.endDate } =
        getDatePeriodFromFilter(DateFilters.MONTH_TO_DATE))

    this.tableFilterOptionConfig = {
      statusFilterOption: this.disputeStatusFilter,
      typeFilterOption: this.disputeTypeFilter,
    }

    this.initialize();
  },
  methods: {
    ...mapActions('app', ['updateAppState']),
    ...mapActions('disputes', ['setDisputes']),
    setHourOfTheDay: Formatter.setHourOfTheDay,
    initialize(forceLoad = false) {
      this.currentTab = window.cbStorage.getItem("cb_payments_disputes_current_tab") || "OPEN"
      this.selectedStatus = this.setSelectedStatus(this.currentTab)
      if(this.currentTab === "INQUIRY") {
        this.selectedType = [ this.currentTab ];
      }

      if (window.cbStorage.getItem("cb_payments_disputes_current_tab")) {
        this.currentTab = window.cbStorage.getItem("cb_payments_disputes_current_tab")
      }

      this.listElm = document.querySelector('#infinite-list');
      this.listElm?.addEventListener('scroll', () => {
        if ((this.listElm.scrollTop + this.listElm.clientHeight + 1 >= this.listElm.scrollHeight) && this.listElm.scrollTop !== 0 && !this.loading.all) {
          this.loadMore();
        }
      });

      this.loadMore(forceLoad);
    },
    async loadMore(forceLoad = false) {
      if ((this.allItemsLoaded === true || this.searchQuery !== '') && !forceLoad) return;

      this.loading.all = true;

      let response;
      let params = this.getCommonQueryParams();
      try {
        response = await disputes.listMerchantDisputes(
            this.getMerchantId, params.cursor, params.size, params.sort_by, params.sort, params.start_date,
            params.end_date, undefined, params.status, params.type, params.payment_method_type,
            params.amount_gt, params.amount_gte, params.amount_lt, params.amount_lte);
      } catch (e) {
        this.$danger("Could not load disputes!")
        this.loading.all = false;
        return;
      }
      this.offsetId = response.cursor;
      this.count = response.count;
      this.setDisputes(this.getDisputes.concat(response.disputes));

      if (response.disputes.length < this.pageSize) {
        this.allItemsLoaded = true;
      }
      this.loading.all = false;
      this.searchDisputes(this.searchQuery);
    },
    getCommonQueryParams() {
      return {
        cursor: this.offsetId,
        size: this.pageSize,
        sort_by: SortBy.ID,
        sort: Sort.DESC,
        start_date: this.dateFilter.startDate,
        end_date: this.dateFilter.endDate,
        status: this.selectedStatus,
        type: this.selectedType,
        payment_method_type: this.selectedPaymentMethodType,
        amount_gt: this.amountFilter.gt,
        amount_gte: this.amountFilter.gte > this.amountFilter.lte ? this.amountFilter.lte : this.amountFilter.gte,
        amount_lt: this.amountFilter.lt,
        amount_lte: this.amountFilter.gte < this.amountFilter.lte ? this.amountFilter.lte : this.amountFilter.gte
      }
    },
    applyFilter: function (filter) {
      this.setDisputes([])
      this.searchResults = [];
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
      this.selectedStatus = filter.statusFilter.flatMap(x => this.setSelectedStatus(x));
      this.selectedType = filter.typeFilter;
      this.amountFilter = filter.amountFilter;
      this.selectedPaymentMethodType = filter.paymentMethodFilter;
      this.dateFilter.startDate = filter.dateFilterValue.startDate;
      this.dateFilter.endDate = filter.dateFilterValue.endDate;
      this.loadMore(true)
      this.listElm.scrollTo(0, 0);
    },
    searchDisputes: function (value) {
      this.searchQuery = value;
      this.searchResults = this.getDisputes.filter(x => x.id.toLowerCase().includes(value.toLowerCase()))
    },
    clearFilterFromEmptyPlaceholder: function () {
      this.$refs.tableFilterOptionComponent.clearFilter();
    },
    setSelectedStatus: function (nextTab) {
      switch (nextTab) {
        case "OPEN":
          return ["PENDING", "INQUIRY"];
        case "LOST":
          return ["LOST", "ACCEPTED"];
        case "RESPONDED":
          return ["RESPONDED"];
        case "WON":
          return ["WON"];
        case "INQUIRY":
        case "ALL":
        default:
          return null
      }
    },
    setSelectedType: function (nextTab) {
      if(nextTab === "INQUIRY") {
        this.selectedType = [ nextTab ];
      } else if(this.currentTab === "INQUIRY") {
        this.selectedType = null;
      }
    },
    switchTab: function (nextTab) {
      if (this.currentTab === nextTab) {
        return
      }
      if(!["ALL", "INQUIRY"].includes(nextTab) || ["OPEN", "LOST", "RESPONDED", "WON"].includes(this.currentTab)) {
        this.selectedStatus = this.setSelectedStatus(nextTab);
      }
      this.setSelectedType(nextTab);
      this.currentTab = nextTab
      window.cbStorage.setItem("cb_payments_disputes_current_tab", this.currentTab)
      this.clearData()
      this.loadMore(true)
      this.listElm?.scrollTo(0, 0);
    },
    clearData: function () {
      this.setDisputes([])
      this.searchResults = [];
      this.offsetId = null
      this.allItemsLoaded = false;
      this.count = 0;
    },
    tooltipContent() {
      if (this.searchQuery !== '') {
        return "Clear search to export dispute";
      }
      if (this.getDisputes.length === 0) {
        return "No disputes to export";
      }
    },
    getCreateExportParams() {
      return {
        resource_type: ResourceType.DISPUTE,
        dispute_filter: {
          ...this.getCommonQueryParams(),
          cursor: undefined,
          size: undefined,
          requested_from: "Disputes",
        },
      };
    },
    getElementId(id) {
      return PendoPrefixes.CBpay + this.$route.name + '-' + id.toLowerCase();
    }
  },
}

</script>

<style lang="scss">

.main-container {
  background: $neutral_50;
  height: 100%;
  overflow: scroll;
}

.disputes-container {
  left: 256px;
  top: 0;
  position: relative;
  width: calc(100% - 256px);
  padding: 20px 30px 30px 30px;
  transition: all 0.2s ease-in;
  justify-content: center;
  display: flex;

  & .disputes-sub-container {
    width: 100%;
    max-width: 1360px;
  }

  & .nav {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: $weight_bold;
    background-color: transparent;
    padding-left: 0;
    border-bottom: 1px solid $neutral_200;
    margin-bottom: 16px;

    &:after {
      content: '';
      height: 2px;
      background-color: $color_white;
    }

    & .nav-item {
      display: flex;
      cursor: pointer;
      align-items: start;
      margin-right: 16px;

      @media screen and (min-width: 801px) {
        padding: 12px 16px;
      }

      @media screen and (max-width: 800px) {
        padding: 10px 16px;
      }

      &:hover {
        background-color: $primary_fill_light;
      }

      &.disabled:hover {
        background-color: $color-white;
        cursor: not-allowed;
      }
    }

    & .nav-item-active {
      color: $primary_text;
      border-bottom: 2px solid $primary_text;

      & svg {
        margin-right: 10px;
        color: $primary_text;
      }
    }
  }

  & .table-header {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: $neutral_1000;
    text-align: left;
    padding: 20px 0;
  }

  &.disputes-container-sidebar-collapse {
    left: 86px;
    width: calc(100% - 86px);
  }

  & .action-header {
    background-color: $color_white;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 80px;
    justify-content: left;
    display: flex;
    padding: 0 20px;
    margin-top: 20px;
    flex-direction: row;

    & .transaction-count {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_1000;
      align-self: center;
    }
  }

  & .settlement-id-column {
    display: flex;
    font-size: 14px;
    font-weight: $weight_medium;
    line-height: 20px;
    color: $neutral_1000;
    padding: 8px 8px 8px 0;
  }

  & .type-column {
    display: flex;
    min-height: 60px;
    align-items: center;

    & .type {
      display: flex;
      flex-direction: row;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_1000;
      width: 100%;
      justify-content: start;

      & .amount-container {
        justify-content: right;
        display: flex;
        flex-direction: row;
        padding-right: 20%;
        padding-left: 20px;
        width: 100%;
        font-weight: $weight-bold;

        & .amount {
          margin-right: 5px;
        }

        & .currency_code {
          color: $neutral_400;
          font-size: 14px;
        }
      }
    }
  }

  & .payment-method-column {
    display: flex;

    & .payment-method {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: start;
      font-size: 14px;
      font-weight: $weight-normal;
      line-height: 20px;
      color: $neutral_800;
    }

    & .card-logo {
      align-self: center;
      margin-right: 7px;
    }
  }

  & .search-container {
    width: 320px;
    margin-bottom: 16px;
  }

  .header-container {
    background-color: $color_white;
    width: 100%;
    height: fit-content;
    border: 1px solid $neutral_100;
    border-radius: 6px 6px 0px 0px ;
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 15px 25px 5px 25px;

    & .transaction-header-count{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral_800;
      width: 100%;
      text-align: left;
      align-self: center;
      padding-bottom: 10px;
      display: flex;

      & .showing-count-top {
        display: inline-block;
        justify-content: left;

        & .pagination {
          font-weight: $weight_bold;
        }
      }

      & .loader {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 3px solid $neutral_100;
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        justify-content: center;
      }


      @keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      @-webkit-keyframes spin {
        to {
          transform: rotate(360deg);
        }
      }

      .hide-loader {
        display: none;
        visibility: hidden;
      }

    }

    & .c-button {
      width: fit-content;
      height: 32px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      margin-left: 15px;
      display: flex;
      flex-direction: row;
      padding: 0;

      & img{
        margin-right: 5px;
      }
    }
  }

}

</style>