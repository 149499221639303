<template>
  <svg width="14" height="18" viewBox="0 0 14 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.333313 14.8346V13.168H1.99998V7.33464C1.99998 6.18186 2.3472 5.15741 3.04165 4.2613C3.73609 3.36575 4.63887 2.77908 5.74998 2.5013V1.91797C5.74998 1.57075 5.87165 1.27575 6.11498 1.03297C6.35776 0.789635 6.65276 0.667969 6.99998 0.667969C7.3472 0.667969 7.6422 0.789635 7.88498 1.03297C8.12831 1.27575 8.24998 1.57075 8.24998 1.91797V2.5013C9.36109 2.77908 10.2639 3.36575 10.9583 4.2613C11.6528 5.15741 12 6.18186 12 7.33464V13.168H13.6666V14.8346H0.333313ZM6.99998 17.3346C6.54165 17.3346 6.14942 17.1716 5.82331 16.8455C5.49665 16.5188 5.33331 16.1263 5.33331 15.668H8.66665C8.66665 16.1263 8.50359 16.5188 8.17748 16.8455C7.85081 17.1716 7.45831 17.3346 6.99998 17.3346ZM3.66665 13.168H10.3333V7.33464C10.3333 6.41797 10.0069 5.63325 9.35415 4.98047C8.70137 4.32769 7.91665 4.0013 6.99998 4.0013C6.08331 4.0013 5.29859 4.32769 4.64581 4.98047C3.99304 5.63325 3.66665 6.41797 3.66665 7.33464V13.168Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'NotificationsIcon',
}
</script>