<template>
  <div id="login">
    <div class="redirecting">
      Redirecting...
    </div>
  </div>
</template>


<script>
import {navigateTo} from "@/router";
import {mapGetters} from "vuex";
import {RouteConstants} from "@/router/routeConstants";
import {OnboardingStatus} from "@/app/utils/common/constants";

export default {
  name: 'Login',
  computed: {
    ...mapGetters('app', ['isAdminMode', 'getMerchantId', 'getActivePayfac']),
    ...mapGetters('merchantPayfacDetails', ['getOnboardingStatus']),
    isAdmin() {
      return String(this.$router.currentRoute.query.adminMode) === "true"
    },
    isMerchantReadOnly() {
      return !this.isAdmin && Boolean(this.getMerchantId)
    },
  },
  mounted() {
    if (!this.getMerchantId) {
      navigateTo(RouteConstants.ONBOARDING_STATUS)
      return;
    }
    if (this.isMerchantReadOnly && this.getOnboardingStatus(this.getActivePayfac) !== OnboardingStatus.APPROVED) {
      navigateTo(RouteConstants.ONBOARDING_STATUS)
      return;
    }
    navigateTo(RouteConstants.DASHBOARD);
  }
}
</script>

<style lang="scss">
#login .redirecting {
  font-size: 42px;
  color: $primary_text-dark;
  margin-top: 52px;
}
</style>