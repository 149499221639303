<template>
  <div class="field-value-container" :class="className">
    <div class="field">
      <div class="field-label">
        <c-link v-if="linkUrl" @click="openUrlInNewTab(linkUrl)">
          {{ fieldName }}
        </c-link>
        <template v-else>
          {{ fieldName }}
        </template>
        <ToolTip
          placement="top-start"
          :arrow="false"
          :content="tooltip"
          v-if="tooltip"
        >
          <span class="info-icon" v-if="tooltip">
            <img class="info-icon" src="../../app/assets/images/info-outline.svg" alt="info icon" />
          </span>
        </ToolTip>
      </div>
      <div
        class="field-value"
        :class="{clickable: fieldValue && onClick}"
        @click="() => {fieldValue && onClick && onClick()}"
      >
        <slot>
          <span v-if="fieldValue !== null">{{ (fieldValue) }}</span>
          <span v-else class="empty-placeholder">—</span>
          <span class="icon" @click="copyValueToClipboard" v-if="showCopyValue">
            <img v-if="clickToCopy" class="check-circle" src="@/app/assets/images/check_circle.svg" alt="copy-to-clipboard" />
            <img v-else class="clipboard-icon" src="@/app/assets/images/clipboard.svg" alt="copy-to-clipboard" />
          </span>
          <span class="check-mark" v-if="showStatusIcon">
            <img v-if="showPassedIcon" src="@/app/assets/images/check_circle.svg" alt="copy-to-clipboard" />
            <img v-else src="@/app/assets/images/cancel.svg" alt="copy-to-clipboard" />
          </span>
        </slot>
      </div>
      <div class="field-message" v-if="getFieldMessage() !== null">
        {{ getFieldMessage() }}
      </div>
    </div>
  </div>
</template>

<script>

import {openUrlInNewTab} from "@/app/utils/common/functions/url-helper";
import ToolTip from "@/components/common/ToolTip.vue";

export default {
  name: "FieldValue",
  components: {ToolTip},
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    fieldValue: {
      type: String,
      required: true,
    },
    fieldMessage: {
      type: String,
      required: false,
      default: null,
    },
    showCopyValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPassedIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    showStatusIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      required: false,
      default: ""
    },
    onClick: {
      type: Function,
      required: false,
      default: null
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    },
    linkUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      clickToCopy: false
    }
  },
  methods: {
    openUrlInNewTab,
    getFieldMessage() {
      if (this.fieldMessage == null) {
        return null;
      }
      const message = this.fieldMessage.split(';')[0];
      return message.length === 0 ? null : message;
    },
    copyValueToClipboard() {
      this.clickToCopy = true;
      navigator.clipboard.writeText(this.fieldValue);
      this.$success(this.$t('global.toast.copiedToClipboard', {fieldName: this.fieldName}), { timeout: 2000});
      setTimeout(() => {
        this.clickToCopy = false;
      }, 2000);
    },
  },
}
</script>
<style lang="scss">
.field-value-container {
  overflow: auto;

  & .field {
    & .clickable {
      color: $primary_600;
      font-weight: $weight_medium;
      cursor: pointer;
    }
  }

  & .empty-placeholder {
    color: $neutral_300;
  }

  & .field-label {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: $weight_normal;
    color: $neutral_600;
    display: flex;
    height: 18px;
    align-items: center;
    gap:4px;
  }

  & .field-value {
    margin-bottom: 4px;
    display: flex;
    height: 20px;
    align-items: center;
    font-weight: $weight_normal;
    color: $neutral_800;
    gap:4px;
  }

  & .field-message {
    width: 95%;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: $weight_normal;
    color: $neutral_600;
  }

  .c-tooltip {
    padding-top: 1px;
  }

  & .info-icon {
    display: flex;
    margin-bottom: 0.5px;
  }

  & .icon {
    margin-left: 6px;
  }

  & .check-mark {
    margin-left: 6px;
    display: flex;
    align-items: center;
  }
}

</style>