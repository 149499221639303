<template>
  <svg width="20" height="20" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_18791_47086)">
      <path d="M39.8667 28.5659C39.8667 31.9661 37.098 34.7348 33.6978 34.7348H0.133362V10.3022C0.133362 6.902 2.90207 4.1333 6.30223 4.1333H39.8667V28.5659Z" fill="white" />
      <path d="M28.9376 22.2998H31.4877C31.5605 22.2998 31.7306 22.2755 31.8034 22.2755C32.2892 22.1784 32.702 21.7412 32.702 21.134C32.702 20.5511 32.2892 20.114 31.8034 19.9925C31.7306 19.9683 31.5848 19.9683 31.4877 19.9683H28.9376V22.2998Z" fill="url(#paint0_linear_18791_47086)" />
      <path d="M31.1963 6.19775C28.7676 6.19775 26.7761 8.16499 26.7761 10.618V15.2082H33.0178C33.1635 15.2082 33.3335 15.2082 33.4549 15.2325C34.8636 15.3053 35.9079 16.0339 35.9079 17.2969C35.9079 18.2926 35.2036 19.1427 33.8921 19.3127V19.3612C35.325 19.4584 36.4179 20.2599 36.4179 21.4985C36.4179 22.8343 35.2036 23.7086 33.6007 23.7086H26.7518V32.6947H33.2364C35.6651 32.6947 37.6566 30.7275 37.6566 28.2745V6.19775H31.1963Z" fill="url(#paint1_linear_18791_47086)" />
      <path d="M32.3863 17.5882C32.3863 17.0053 31.9734 16.6167 31.4877 16.5438C31.4391 16.5438 31.3177 16.5195 31.2448 16.5195H28.9376V18.6568H31.2448C31.3177 18.6568 31.4634 18.6568 31.4877 18.6325C31.9734 18.5596 32.3863 18.171 32.3863 17.5882Z" fill="url(#paint2_linear_18791_47086)" />
      <path d="M6.76365 6.19775C4.33496 6.19775 2.34344 8.16499 2.34344 10.618V21.5228C3.58207 22.1299 4.86928 22.5185 6.15648 22.5185C7.68655 22.5185 8.51231 21.5956 8.51231 20.3327V15.1839H12.3011V20.3084C12.3011 22.3 11.0624 23.9272 6.8608 23.9272C4.31068 23.9272 2.31915 23.3686 2.31915 23.3686V32.6705H8.80375C11.2324 32.6705 13.224 30.7032 13.224 28.2502V6.19775H6.76365Z" fill="url(#paint3_linear_18791_47086)" />
      <path d="M18.98 6.19775C16.5513 6.19775 14.5598 8.16499 14.5598 10.618V16.3982C15.6769 15.4511 17.6199 14.8439 20.7529 14.9896C22.4287 15.0625 24.2259 15.5239 24.2259 15.5239V17.394C23.3273 16.9326 22.2587 16.5197 20.8743 16.4225C18.4942 16.2525 17.0613 17.4183 17.0613 19.4584C17.0613 21.5228 18.4942 22.6885 20.8743 22.4943C22.2587 22.3971 23.3273 21.9599 24.2259 21.5228V23.3929C24.2259 23.3929 22.453 23.8543 20.7529 23.9272C17.6199 24.0729 15.6769 23.4657 14.5598 22.5185V32.719H21.0443C23.473 32.719 25.4646 30.7518 25.4646 28.2988V6.19775H18.98Z" fill="url(#paint4_linear_18791_47086)" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_18791_47086" x1="26.772" y1="21.1369" x2="37.6858" y2="21.1369"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#007940" />
        <stop offset="0.2285" stop-color="#00873F" />
        <stop offset="0.7433" stop-color="#40A737" />
        <stop offset="1" stop-color="#5CB531" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_18791_47086" x1="26.7717" y1="19.4356" x2="37.6864" y2="19.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#007940" />
        <stop offset="0.2285" stop-color="#00873F" />
        <stop offset="0.7433" stop-color="#40A737" />
        <stop offset="1" stop-color="#5CB531" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_18791_47086" x1="26.7717" y1="17.5851" x2="37.686" y2="17.5851"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#007940" />
        <stop offset="0.2285" stop-color="#00873F" />
        <stop offset="0.7433" stop-color="#40A737" />
        <stop offset="1" stop-color="#5CB531" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_18791_47086" x1="2.3383" y1="19.4356" x2="13.4211" y2="19.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1F286F" />
        <stop offset="0.4751" stop-color="#004E94" />
        <stop offset="0.8261" stop-color="#0066B1" />
        <stop offset="1" stop-color="#006FBC" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_18791_47086" x1="14.497" y1="19.4356" x2="25.261" y2="19.4356"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6C2C2F" />
        <stop offset="0.1735" stop-color="#882730" />
        <stop offset="0.5731" stop-color="#BE1833" />
        <stop offset="0.8585" stop-color="#DC0436" />
        <stop offset="1" stop-color="#E60039" />
      </linearGradient>
      <clipPath id="clip0_18791_47086">
        <rect width="40" height="30.8681" fill="white" transform="translate(0 4)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'JCBIcon',
}
</script>