<template>
  <div class="disputes-digital-goods-info">
    <div class="fields">
      <div class="column">
        <FieldValue
          :field-value="formatDateWithTimezone(evidence.service_details.purchase_date)"
          :field-name="$t('fieldNames.purchaseDate')"
        />
        <FieldValue
          :field-value="evidence.service_details.type_of_service"
          :field-name="$t('fieldNames.purchaseDetails')"
        />
        <FieldValue
          :field-value="evidence.service_details.description"
          :field-name="$t('fieldNames.serviceDescription')"
        />
      </div>
      <div class="column">
        <FieldValue
          :field-value="formatDateWithTimezone(evidence.service_details.service_date)"
          :field-name="$t('fieldNames.serviceDate')"
        />
        <FieldValue
          :field-value="evidence.service_details.refund_policy_details"
          :field-name="$t('fieldNames.refundPolicyDetails')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FieldValue from "@/components/common/FieldValue.vue";
import Formatter from "@/app/utils/common/functions/formatter";

export default {
  name: "DisputeViewServices",
  components: {FieldValue},
  props: {
    evidence: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDateWithTimezone(date) {
      return Formatter.format({type: 'zonedDate', data: date})
    }
  }
}
</script>

<style lang="scss">
.disputes-digital-goods-info {

  & .fields {
    display: flex;

    & .column {
      text-align: left;
      flex: 1;
    }
  }
}
</style>