<template>
  <div class="notification-todo">
    <div class="content">
      <span class="icon">
        <DepositIcon />
      </span>
      <span>
        <div class="id-title">
          <div class="title">
            {{ getTitle() }}
          </div>
          <c-tooltip placement="top" v-if="showUnreadMarker" :content="$t('notifications.markAsRead')" variant="dark">
            <div class="read-marker" @click="handleMarkAsRead">
              <svg class="dot" viewBox="0 0 10 10">
                <circle cx="5" cy="5" r="4" />
              </svg>
            </div>
          </c-tooltip>
        </div>

        <div class="description">
          {{ getDescription() }}
          <div class="id">
            <div class="id-key"> {{ $t('notifications.id') }} </div>
          </div>
          <div class="id">
            <div class="id_tag">{{ audit.resource_id }}</div>
          </div>
        </div>
        <div class="links" v-if="currentTab === 'TODO' && transactionStatus === 'FAILED'">
          <div class="clickable" @click="() => { navigateToAddFunds(); handleMarkAsRead(); }" :id="getElementId('todo-add_funds')">
            <!--            need to update pendo document-->
            {{ $t('notifications.addFunds') }}
          </div>
        </div>
      </span>
    </div>
    <div class="relative-date-container">
      {{ relativeDate(audit.created_at) }}
    </div>
  </div>
</template>


<script>
import Formatter from "@/app/utils/common/functions/formatter";
import {AuditResponseDto} from "@/api/paymentApi";
import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";
import DepositIcon from "@/components/icons/DepositIcon.vue";
import {notificationMixin} from "@/mixin/notificationMixin";
import { mapGetters } from "vuex";

export default {
  name: "FundsAddedNotif",
  components: {DepositIcon},
  mixins : [notificationMixin],
  props: {
    audit: {
      type: AuditResponseDto,
      default: null,
      required: true
    },
    currentTab: {
      type: String,
      required: true
    },
    hideAudit: {
      type: Function,
      required: true
    },

  },
  data: () => {
    return {
      ...notificationMixin.data(),
      balanceReserves: [],
      transactionStatus: null,
    }
  },
  computed:{
    ...mapGetters('app', ['getMerchantCurrency'])
  },
  mounted() {
    this.getBalanceReserves();
  },
  methods: {
    getTitleOrDescription(type) {
      let fundType = this.audit.event;
      if (this.balanceReserves.length === 1) {
        fundType = this.balanceReserves[0].balance_account_type;
      }
      if (this.transactionStatus === 'FAILED') {
        fundType += "_FAILED";
      }
      const formattedAmount = Formatter.format({
        type: "currency",
        data: this.getAmount(),
        currencyCode: this.getMerchantCurrency
      });
      return this.$t(`notifications.${this.currentTab.toLowerCase()}.${fundType.toLowerCase()}_${type}`, {amount: formattedAmount});
    },
    getTitle() {
      return this.getTitleOrDescription("title");
    },
    getDescription() {
      return this.getTitleOrDescription("desc");
    },
    navigateToAddFunds() {
      navigateTo(RouteConstants.ADD_FUNDS, true, {...this.$route.query});
    },
    getAmount() {
      let totalAmount = 0;
      this.balanceReserves.forEach(reserve => {
        totalAmount += reserve.amount;
      })
      return totalAmount;
    },
    getBalanceReserves() {
      this.balanceReserves = JSON.parse(this.audit.content).additional_info.reserve_split.filter(balanceReserve => balanceReserve.balance_account_type !== 'UPFRONT_RESERVE');
      this.transactionStatus = JSON.parse(this.audit.content).state;
    },
  }
};
</script>

<style lang="scss">
@import 'notification-common-style.scss';
</style>