class PendoHelper {

    initializeWindowPendo(apiKey, p, e, n, d) {
        let v, w, x, y, z;
        const o = p[d] = p[d] || {};
        // @ts-ignore
        o._q = o._q || [];
        // eslint-disable-next-line prefer-const
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
                // @ts-ignore
                o[m] =
                    // @ts-ignore
                    o[m] ||
                    function () {
                        // @ts-ignore
                        o._q[m === v[0] ? 'unshift' : 'push'](
                            // eslint-disable-next-line prefer-rest-params
                            [m].concat([].slice.call(arguments, 0))
                        );
                    };
            })(v[w]);
        // eslint-disable-next-line prefer-const
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        // eslint-disable-next-line prefer-const
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
    }

    init(externalId, profile, token) {
        this.initializeWindowPendo(token, window, document, 'script', 'pendo');

        // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
        // Call this function after users are authenticated in your app and your visitor and account id values are available
        // Please use Strings, Numbers, or Bools for value types.

        // @ts-ignore
        pendo.initialize({
            visitor: {
                id: profile !== "prod" ? profile + "_" + externalId : externalId
            },
            account: {
                id: profile !== "prod" ? profile + "_" + externalId : externalId,
                name: externalId,
                domain: externalId,
                merchant_id: externalId,
                site_type: externalId.endsWith("-test") ? "sandbox" : "live",
                account_name: externalId.replace("-test", ""),
            },
        });
    }
}

export default new PendoHelper();