<template>
  <div class="progression-circle">
    <div class="in-progress-circle" v-if="type==='in-progress'">
      <div class="in-progress-inner-circle">
      </div>
    </div>

    <div class="not-initiated-circle" v-if="type==='not-initiated'">
    </div>

    <div class="complete-circle" v-if="type==='complete'">
      <div class="complete-inner-circle">
        <img src="@/app/assets/images/tick.svg" alt="Chargebee Verified Icon" />
      </div>
    </div>

    <div class="error-circle" v-if="type==='error'">
      <div class="error-inner-circle">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressionCircle",
  props: {
    type: {
      type: String,
      required: true
    }
  }

}
</script>

<style lang="scss">
.progression-circle {
  & div {
    border-radius: 50%;
  }

  & .error-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 32px;
    height: 32px;
    left: 194px;
    top: 212px;

    background: $color-white;
    border: 2px solid $color-red;
    box-sizing: border-box;
    border-radius: 16px;

    & .error-inner-circle {
      position: static;
      width: 10px;
      height: 10px;
      left: 11px;
      top: 11px;

      background: $color-red;

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 0px;
    }
  }

  & .not-initiated-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 32px;
    height: 32px;
    left: 194px;
    top: 212px;

    background: $color-white;
    border: 2px solid $divider_color;
    box-sizing: border-box;
    border-radius: 16px;

  }

  & .in-progress-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 32px;
    height: 32px;
    left: 194px;
    top: 212px;

    background: $color-white;
    border: 2px solid $primary_text;
    box-sizing: border-box;
    border-radius: 16px;

    & .in-progress-inner-circle {
      position: static;
      width: 10px;
      height: 10px;
      left: 11px;
      top: 11px;

      background: $primary_text;

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 0px;
    }
  }

  & .complete-circle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 32px;
    height: 32px;
    left: 194px;
    top: 212px;

    background: $primary_text;
    box-sizing: border-box;
    border-radius: 16px;
  }


}
</style>