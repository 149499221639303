/* eslint-disable */
import {mergeDeep} from "@/app/utils/common/helpers/merge";
import validations from "@/app/validators/api/openapi"

let postalCodeFormat = {
    "message": "validation_error.invalid_pin",
    "with": "^(\\d{5}(\\d{4})?)?$",
};

let presence = {
    "message": "validation_error.presence",
};

export function range(minimum: number, maximum: number) {
    return {
        "messages": {
            "max": "validation_error.number.range.max",
            "min": "validation_error.number.range.min",
        },
        "minimum": minimum,
        "maximum": maximum,
    };
}

export function length(minimum: number, maximum: number) {
    return {
        messages: {
            max: "validation_error.string.length.max",
            min: "validation_error.string.length.min",
        },
        minimum: minimum,
        maximum: maximum,
    };
}

export function format(pattern: string) {
    return {
        "message": "validation_error.wrong_format",
        "with": pattern,
    };
}
export function regex(pattern: string, format: string) {
    return {
        "message": "validation_error.wrong_regex",
        "with": pattern,
        "format": format,
    };
}

export function url(pattern: string) {
    return {
        "message": "validation_error.invalid_site",
        "with": pattern,
    };
}

export function strictInt() {
    return {"message": "validation_error.strict_int"}
}
const customValidations = {
    "createMerchant": {
        "processing_info.statement_descriptor": {
            "length": length(1, 20),
        },
        "business_info.url": {
            "format": url("(^|\\s)((https?:\\/\\/)[\\w-]+(\\.[\\w-]+)+\\.?(:\\d+)?(\\/\\S*)?)"),
        },
    },
    "updateMerchant": {
        "business_info.url": {
            "format": url("(^|\\s)((https?:\\/\\/)[\\w-]+(\\.[\\w-]+)+\\.?(:\\d+)?(\\/\\S*)?)"),
        },
    },
};
let allValidations = mergeDeep(validations, customValidations)
export default allValidations;
