<template>
  <div class="cs-header">
    <div class="cs-header__container">
      <div class="cs-header__main">
        <div class="cs-header__brand cs-header__logo"></div>
        <div class="cs-header__divider"></div>

        <div class="cs-header__detail">
          <div class="cs-header__title">
            {{ $t(appHeader) }}
          </div>
          <div class="cs-header__merchant_name">
            {{ getMerchantName }}
          </div>
        </div>
      </div>
      <div class="cs-header__aside">
        <div class="cs-header__doables">
          <div class="cs-header__doable" v-if="secondaryActionLabel">
            <c-button
              @click="secondaryAction"
              size="large"
            >
              {{ secondaryActionLabel }}
            </c-button>
          </div>
          <div class="cs-header__doable" v-if="Boolean(primaryActionLabel)">
            <c-button
              @click="primaryAction"
              variant="primary"
              :disabled="disablePrimaryAction"
              size="large"
            >
              {{ primaryActionLabel }}
            </c-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AppHeader",
  props: {
    appHeader: {
      type: String,
      required: false,
      default: "onboarding.title"
    },
    secondaryActionLabel: {
      type: String,
      required: true
    },
    secondaryAction: {
      type: Function,
      required: true
    },
    primaryActionLabel: {
      type: String,
      default: null,
      required: false
    },
    primaryAction: {
      type: Function,
      default: null,
      required: false
    },
    disablePrimaryAction: Boolean
  },
  computed: {
    ...mapGetters('app', ['getMerchantName']),
    ...mapGetters('businessProfile', {isBusinessProfileValid: 'isValid'}),
    ...mapGetters('businessAddress', {isBusinessAddressValid: 'isValid'}),
    ...mapGetters('businessStructure', {isBusinessStructureValid: 'isValid'}),
    ...mapGetters('stakeholdersDetails', {isStakeholdersDetailsValid: 'isValid'}),
    ...mapGetters('additionalUnderwritingData', {isAdditionalUnderwritingDataValid: 'isValid'}),
    ...mapGetters('bankAccountForPayouts', {isBankAccountForPayoutsValid: 'isValid'}),
    enableSubmitButton() {
      return this.isBusinessProfileValid &&
          this.isBusinessAddressValid &&
          this.isBusinessStructureValid &&
          this.isStakeholdersDetailsValid &&
          this.isAdditionalUnderwritingDataValid &&
          this.isBankAccountForPayoutsValid
    },
  },
}
</script>
<style lang="scss">
.cs-header {
  border-bottom: 1px solid #D1D5DB;
  background-color: $color_white;

  &__ {
    &container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: 801px) {
        flex-direction: row
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        flex-direction: column;
      }

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &main {
      display: flex;
      align-items: center;
      margin-left: 30px;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    &aside {
      padding-left: 40px;
    }

    &doable {
      &s {
        margin-right: 30px;
        display: flex;
        flex-wrap: wrap;
      }

      font-weight: $weight_medium;
      margin-left: 8px;
      margin-bottom: 8px;

    }

    &brand {
      position: relative;
      margin-right: 26px;
    }

    &divider {
      width: 1px;
      background-color: $divider_color;
      min-height: 40px;
    }

    &logo {
      position: relative;
      font-size: 0;
      @include c-box(46px);
      background-image: url('~@/app/assets/images/brand/cv-logomask-primary.svg');
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        @include c-box(26px);
        background-image: url('~@/app/assets/images/brand/cv-logo-white.svg');
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
      }
    }

    &detail {
      padding-left: 16px;
      position: relative;
      min-height: 40px; // TODO
      display: flex;
      flex-direction: column;
      justify-content: left;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &title {
      margin-bottom: 4px;
      font-weight: $weight_bolder;
      text-align: left;

      @media screen and (min-width: 801px) {
        font-size: 30px;
      }

      @media screen and (min-width: 601px) and (max-width: 800px) {
        font-size: 24px;
      }

      @media screen and (max-width: 600px) {
        font-size: 20px;
      }
    }

    &merchant_name {
      font-size: 14px;
      width: fit-content;
      color: #64648C;
      font-weight: $weight_bold;
    }
  }
}
</style>