<template>
  <div
    v-if="dispute !== null"
    class="status" :class="{
      'pending-status': dispute.status === 'PENDING' || dispute.status === 'INQUIRY' ,
      'won-status': dispute.status === 'WON',
      'lost-status': dispute.status === 'LOST' || dispute.status === 'ACCEPTED',
      'responded-status': dispute.status === 'RESPONDED'
    }"
  >
    {{ $t('disputes.status.' + dispute.status) }}
  </div>  
</template>

<script>
export default {
  name: "DisputeStatusTag",
  props: {
    dispute: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.status {
  font-size: 12px;
  padding: 2px 10px;
  border-radius: 10px;
  height: fit-content;
  line-height: 16px;
  font-weight: 500;
  font-style: normal;

  &.won-status {
    background-color: $success_100;
    color: $success_800;
  }

  &.responded-status {
    background-color: $neutral_100;
    color: $neutral_800;
  }

  &.lost-status {
    background-color: $danger_50;
    color: $danger_800;
  }

  &.pending-status {
    background-color: $warn_50;
    color: $warn_800;
  }

}
</style>