<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.7999 0.800781C1.47442 0.800781 0.399902 1.8753 0.399902 3.20078V4.40078H19.5999V3.20078C19.5999 1.8753 18.5254 0.800781 17.1999 0.800781H2.7999Z" fill="currentColor" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5999 6.80078H0.399902V12.8008C0.399902 14.1263 1.47442 15.2008 2.7999 15.2008H17.1999C18.5254 15.2008 19.5999 14.1263 19.5999 12.8008V6.80078ZM2.7999 11.6008C2.7999 10.938 3.33716 10.4008 3.9999 10.4008H5.1999C5.86264 10.4008 6.3999 10.938 6.3999 11.6008C6.3999 12.2635 5.86264 12.8008 5.1999 12.8008H3.9999C3.33716 12.8008 2.7999 12.2635 2.7999 11.6008ZM8.7999 10.4008C8.13716 10.4008 7.5999 10.938 7.5999 11.6008C7.5999 12.2635 8.13716 12.8008 8.7999 12.8008H9.9999C10.6626 12.8008 11.1999 12.2635 11.1999 11.6008C11.1999 10.938 10.6626 10.4008 9.9999 10.4008H8.7999Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  name: 'CardIcon',
}
</script>