<template>
  <div class="upload-container">
    <div class="top-fixed">
      <AppHeader
        secondary-action-label="Back"
        :primary-action-label="primaryActionLabel"
        :secondary-action="navigateToStatusScreen"
        :primary-action="successCallBackFunction"
        :disable-primary-action="!submitButtonEnabled || loading"
      />
    </div>
    <div class="main">
      <UploadDocument :failure-code="failureCode" :all-upload-complete="enableSubmitButton" />
    </div>
  </div>
</template>

<script>
import UploadDocument from "@/components/UploadDocument";
import AppHeader from "@/components/onboarding/AppHeader";
import FullStoryHelper from '@/app/utils/fullstory';
import { UPLOAD_DOCUMENTS_PAGE } from '@/app/utils/fullstory/constants';
import {editOnboardingMixin} from "@/mixin/editOnboardingMixin";

export default {
  name: 'UploadDocumentView',
  components: {AppHeader, UploadDocument},
  mixins: [editOnboardingMixin],
  data: function () {
    return {
      failureCode: "",
      submitButtonEnabled: false,
      loading: false
    }
  },
  computed: {
    ...editOnboardingMixin,
  },
  mounted() {
    this.failureCode = this.$route.query.errorCode;
    FullStoryHelper.event(UPLOAD_DOCUMENTS_PAGE);
  },
  methods: {
    ...editOnboardingMixin,
    enableSubmitButton() {
      this.submitButtonEnabled = true
    },
  },
}
</script>

<style lang="scss">

.upload-container {

  & .top-fixed {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 98;
    display: inline-block;
  }

  & .menu {
    padding: 0;
    list-style: disc;
    text-align: left;

    &.custom-active {
      color: $primary_text;
      transition: all 0.5s;
      background-color: $primary_fill_light;
      border: 1px solid $primary_tint;
    }

    & .menu-item {
      list-style-position: outside;
      margin-left: 10px;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 6px;
      cursor: pointer;
      min-height: 40px;
      border: 1px solid $color_white;

      &:hover {
        border: 1px solid $primary_tint;
      }

      & a {
        cursor: pointer;
      }
    }
  }
}


</style>
