<template>
  <div class="type-of-purchase-details">
    <div class="type-of-purchase-details-title">
      Type of Purchase
    </div>
    <div class="type-of-purchase-details-content">
      <RadioOption
        id="physical-goods" option-name="Physical goods"
        :value="value === typeOfPurchase.PHYSICALGOODS"
        :select="() => chooseTypeOfPurchase(typeOfPurchase.PHYSICALGOODS)"
        option-instruction="These are tangible goods that were delivered to the customer’s location, e.g. furniture or books."
      />
      <RadioOption
        id="digital-goods" option-name="Digital goods"
        :value="value === typeOfPurchase.DIGITALGOODS"
        :select="() => chooseTypeOfPurchase(typeOfPurchase.DIGITALGOODS)"
        option-instruction="These are goods delivered electronically to the customer, e.g. online music or access to software."
      />
      <RadioOption
        id="services" option-name="Services"
        :value="value === typeOfPurchase.SERVICE"
        :select="() => chooseTypeOfPurchase(typeOfPurchase.SERVICE)"
        option-instruction="These are activities or experiences provided on location or remotely, e.g. interior decoration or access to a gym."
      />
    </div>
  </div>
</template>

<script>
import RadioOption from "@/components/common/RadioOption";
import {DisputeEvidenceDto} from "@/api/paymentApi";

export default {
  name: "DisputeTypeOfPurchaseInput",
  components: {RadioOption},
  props: {
    value: {
      type: String,
      required: true
    },
    chooseTypeOfPurchase: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      typeOfPurchase: DisputeEvidenceDto.TypeOfPurchaseEnum
    }
  }
}
</script>
<style lang="scss">
.type-of-purchase-details {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-top: 32px;

  & .type-of-purchase-details-title {
    font-size: 16px;
    font-weight: $weight_bold;
  }

  & .type-of-purchase-details-content {
    display: flex;

    flex-direction: column;
  }
}
</style>