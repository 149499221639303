<template>
  <div class="attest-compliance-form-container">
    <div class="title">
      Attest Compliance Form
    </div>
    <InputField
      id="merchant-executive-name"
      :set-value="(event) => {merchantExecutiveName = event.target.value}"
      :value="merchantExecutiveName"
      label="Merchant executive name"
      :hint="$t('profile.attestComplianceFormNameFieldHint')"
    />
    <InputField
      id="title"
      :set-value="(event) => {title = event.target.value}"
      :value="title"
      label="Title"
    />
    <div class="terms-to-accept">
      <label class="container">
        <i18n path="profile.guidelinesCondition" tag="div" />
        <input type="checkbox" @click="acceptAgreement()" />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="buttons">
      <c-button variant="action" @click="onDismiss" :disabled="loading">
        Dismiss
      </c-button>
      <c-button
        variant="primary" @click="attestComplianceForm" :loading="loading"
        :disabled="shouldDisableAttestation || !agreementAccepted"
      >
        Attest
      </c-button>
    </div>
  </div>
</template>

<script>

import { ComplianceFormResponseDto } from "@/api/paymentApi";
import InputField from "@/components/common/InputField.vue";
import complianceForms from "@/api/complianceForms";
import { mapGetters } from "vuex";
import { EventBus } from "@/events/event-bus";

export default {
  name: 'AttestComplianceForm',
  components: { InputField },
  props: {
    complianceForm: {
      type: ComplianceFormResponseDto,
      required: true
    },
    onDismiss: {
      type: Function,
      required: true
    }
  },
  data: function() {
    return {
      merchantExecutiveName: '',
      title: '',
      agreementAccepted: false,
      userAgent: null,
      signedAt: null,
      ipAddress: null,
      complianceFormResponse: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters('app', ['getClientIpAddress']),
    shouldDisableAttestation() {
      return (this.merchantExecutiveName.trim().length === 0 || this.title.trim().length === 0);
    },
  },
  methods: {
    acceptAgreement: function() {
      this.agreementAccepted = !this.agreementAccepted;
    },
    attestComplianceForm: async function() {
      this.loading = true;
      this.signedAt = new Date().toISOString().slice(0, -5) + "Z";
      this.userAgent = window.navigator.userAgent;
      this.ipAddress = this.getClientIpAddress;
      try {
        this.complianceFormResponse = await complianceForms.submitComplianceFormAttestation(
            this.complianceForm.merchant_id, this.complianceForm.id, {
              name: this.merchantExecutiveName,
              ip_address: this.ipAddress,
              signed_at: this.signedAt,
              user_agent: this.userAgent,
              title: this.title,
              is_accepted: true
            });
        this.loading = false;
        EventBus.$emit("complianceFormUpdated", this.complianceFormResponse)
        this.$success(this.$t('profile.submitAttestationSuccess'));
        this.onDismiss();
      }catch (e) {
        this.loading = false;
        this.$danger(this.$t('profile.submitAttestationError', {'error': e?.source?.detail}));
        this.onDismiss();
      }
    }
  }
}
</script>

<style lang="scss">
.attest-compliance-form-container {
 padding: 24px 32px;

  & .title {
    font-weight: $weight-medium;
    font-size: 20px;
    text-align: left;
  }

  & .field {
    text-align: left;
  }

  & .buttons {
    margin-top: 40px;
    display: flex;
    justify-content: end;
    align-items: center;

    gap: 24px;
    & .c-button__label {
      padding: 0;
    }

    & .c-button--primary {
      margin-bottom: 0;
      width: 140px;
    }

    & .c-button--action:disabled {
      color: $neutral_300;
    }
  }

  & .terms-to-accept {

    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-top: 30px;
      margin-bottom: 12px;
      text-align: left;
      cursor: pointer;
      user-select: none;
      line-height: 1.4;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid $disablea_text;
      border-radius: 4px;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: $primary_fill;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      left: 6px;
      top: 3px;
      width: 6px;
      height: 11px;
    }
  }
}
</style>