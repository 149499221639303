<template>
  <div class="empty-place-holder">
    <slot name="icon">
      default body
    </slot>
    <div class="empty-placeholder-content">
      <div class="empty-placeholder-msg1">
        {{ heading() }}
      </div>
      <div class="empty-placeholder-msg2">
        {{ description() }}
      </div>
      <c-link
        class="clear-filter-button"
        v-if="clearFilterFlag"
        @click="$emit('clearFilter')"
      >
        {{ $t('filters.dateFilter.clearFilters') }}
      </c-link>
    </div>
  </div>
</template>

<script>

import {navigateTo} from "@/router";
import {RouteConstants} from "@/router/routeConstants";

export default {
  name: "EmptyPlaceholder",
  props: {
    headingTag: {
      type: String,
      required: true,
    },
    descriptionTag: {
      type: String,
      required: true,
    },
    clearFilterFlag: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    configureChargebee() { 
      navigateTo(RouteConstants.ONBOARDING_STATUS, true) 
    },
    heading() {
      return this.$t(this.headingTag);
    },
    description() {
      return this.$t(this.descriptionTag);
    },
  }
}
</script>
<style lang="scss">
.empty-place-holder {
  display: flex;
  flex-direction: column;
  & svg {
    color: $neutral_400;
  }

  & .empty-placeholder-content {
    position: relative;
    color: $neutral_400;
    & .empty-placeholder-msg1 {
      display: flex;
      padding: 20px 10px 10px 0px;
      font-weight: $weight-bold;
      font-size: 15px;
      width: 100%;
    }

    & .empty-placeholder-msg2 {
      text-align: left;
      float: left;
      font-size: 12px;
      padding-bottom: 15px;
      width: 240px;
    }

    & .clear-filter-button {
      height: 32px;
      line-height: 18px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: $primary_600;
      cursor: pointer;
      display: flex;
      width: 100%;
    }
  }

}
</style>